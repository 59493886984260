import {TextInput, PasswordInput} from '@mantine/core';
import passwordVisible from '../../assets/passwordViss.svg'
import passwordHide from '../../assets/passordHide.svg'
import {useState} from "react";

export default function CustomTextInputValue({
                                            customDimensions = '',
                                            isPassword = false,
                                            defaultValue = '',
                                            label = "",
                                            placeholder = "",
                                            description = "",
                                            readOnly = false,
                                            onChange = (text: string) => {
                                            },
                                            isBlack = false,
                                            onEnterPress = () => {
                                            }
                                        }) {

    const [passwordVisibleStatus, setPasswordVisibleStatus] = useState(false)


    return (
        <div className={` rounded-md   ${customDimensions !== '' ? customDimensions : 'w-full'}`} onKeyDown={(e) => {
        }}>
            {isPassword ? <PasswordInput
                    onKeyPress={(e) => {
                        if (e.code === 'Enter') {
                            onEnterPress()
                        }
                    }}
                    rightSection={<img alt={''} onClick={() => {
                        setPasswordVisibleStatus(!passwordVisibleStatus)
                    }} className={'mr-10'} src={passwordVisibleStatus ? passwordVisible : passwordHide}/>}
                    label={label}
                    value={defaultValue}
                    autoComplete="new-password"
                    visible={passwordVisibleStatus}
                    classNames={{
                        label: isBlack ? 'labelStyleBlack' : 'labelStyle',
                        input: 'inputStyle',
                        wrapper: 'wrapperStyle'
                    }}
                    description={description}
                    placeholder={placeholder}
                    onChange={(res) => {
                        onChange(res.currentTarget.value)
                    }}
                /> :
                <TextInput
                    onKeyPress={(e) => {
                        if (e.code === 'Enter') {
                            onEnterPress()
                        }
                    }}
                    classNames={{
                        label: isBlack ? 'labelStyleBlack' : 'labelStyle',
                        input: 'inputStyle',
                        wrapper: 'wrapperStyle'
                    }}
                    label={label}
                    autoComplete="off"
                    readOnly={readOnly}
                    value={defaultValue}
                    description={description}
                    placeholder={placeholder}
                    onChange={(res) => {
                        onChange(res.currentTarget.value)
                    }}
                />}
        </div>


    )
}
