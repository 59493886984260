import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Popover, Slider} from "@mantine/core";
import GenericImg from "./generic/GenericImg";
import {LoginResponseType} from "../types/LoginResponseType";
import iconPosition from '../assets/menu-change-position-icon3.png'
import GoogleAddressSearch from "./GoogleAddressSearch";

export default function MenuChangePosition() {
    const [popOverStatus, setPopOverStatus] = useState(false)
    const [dataSearch, setDataSearch] = useState<{
        geometry: any,
        radius: number
    }>(() => {
        const storedData = JSON.parse(localStorage.getItem('geoData') as string);
        return {
            geometry: storedData?.geometry ?? null,
            radius: storedData?.radius >= 1 ? storedData.radius : 1 // Ensure minimum radius is 1
        };
    });

    let navigate = useNavigate()
    var userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType

    useEffect(() => {
        localStorage.setItem('geoData', JSON.stringify(dataSearch))
    }, [dataSearch])

    return (
        <div className={'w-full h-auto flex items-center justify-around'}>
        <Popover width={400} position="top" withArrow shadow="md" onOpen={() => {
            setPopOverStatus(true)
        }} onClose={() => setPopOverStatus(false)}>
            <Popover.Target>
                <div className={`cursor-pointer hover:scale-125 duration-500`}>
                    <GenericImg className={`w-[35px] mr-2`} src={iconPosition}/>
                </div>
            </Popover.Target>
            <Popover.Dropdown classNames={{dropdown: 'dropDownLogin'}}>
                <div className={'w-full flex flex-col items-center py-5 gap-y-7'}>
                    <div className={'w-full flex flex-col items-center justify-center'}>
                        <h1 className={'font-poppins-semi-bold text-xs'}>Stai cercando:</h1>
                        <GoogleAddressSearch
                            inputPlaceholder={localStorage.getItem('closedGeodata')!==null && localStorage.getItem('formatted_address')?(localStorage.getItem('formatted_address') as string).replace('\"', '').replace('\"', ''):'Via Pitagora ,98051 Barcellona Pozzo di Gotto'}
                            setNameTextInput={'formatted_address'} defaultOpen={false}
                            onPlaceSelect={(value) => {
                                setDataSearch({...dataSearch, geometry: value.geometry.location})
                                localStorage.setItem('formatted_address', JSON.stringify(value.formatted_address))
                                window.location.reload()
                            }}/>
                    </div>

                    <div className={'w-full flex flex-col items-center justify-center '}>
                        <div className={'flex flex-col justify-center w-full items-center gap-y-5'}>
                            <div className={'flex gap-x-3'}>
                                <h1 className={'font-poppins-semi-bold text-xs'}>con un raggio di:</h1>
                                <h1 className={'font-poppins-semi-bold text-xs'}>{dataSearch.radius}KM</h1>
                            </div>
                        </div>
                        <Slider
                            className={'w-full'}
                            max={50}
                            min={1}
                            defaultValue={dataSearch.radius}
                            color="yellow"
                            marks={[
                                {value: 10, label: ''},
                                {value: 20, label: ''},
                                {value: 30, label: ''},
                                {value: 40, label: ''},
                            ]}
                            onChangeEnd={(value) => {
                                setDataSearch({...dataSearch, radius: value})
                                window.location.reload()
                            }}
                        />
                    </div>
                </div>
            </Popover.Dropdown>
        </Popover>
        </div>
    )
}
