import {LineChart} from "@mantine/charts";
import React from 'react';


export default function StatisticDashAdminComponent() {
    const data = [
        {
            date: 'Mar 22',
            Mele: 45,
            Ananas: 32,
        },
        {
            date: 'Mar 23',
            Mele: 43,
            Ananas: 48,
        },
        {
            date: 'Mar 24',
            Mele: 44,
            Ananas: 29,
        },
        {
            date: 'Mar 25',
            Mele: 22,
            Ananas: 35,
        },
        {
            date: 'Mar 26',
            Mele: 42,
            Ananas: 28,
        },
    ];
    return (
        <div style={{backgroundColor: 'black'}} className={'w-[550px] rounded-2xl text-white font-poppins-regular p-7'}>
            <LineChart
                h={300}
                data={data}
                dataKey="date"
                unit="€"
                series={[
                    {name: 'Mele', color: 'violet.6'},
                    {name: 'Ananas', color: 'green.6'},

                ]}
            />
        </div>
    )
}
