import {Grid} from "@mantine/core";
import QrCategoryComponent from "./QrCategoryComponent";
import {useEffect, useState} from "react";
import {CategoryType} from "../types/CategoryType";
import {getCategories, getCategoriesQr, getInfoLocal} from "../network/controllers/admin/CategoryController";
import {isMobile} from "react-device-detect";
import {LocalType} from "../types/LocalType";

export default function QrCategoryDetailComponent(props: {local:LocalType, categorySelected: (category: CategoryType) => void }) {
    const [categoryList, setCategoryList] = useState<CategoryType[]>([])

    useEffect(() => {


            getCategories(props.local.id, (list) => {
                setCategoryList(list)
            })


    }, [])
    return (
        <div className={'w-full '}>

            <div className={'w-full h-14 bg-black p-2'}>
                <div className={'flex w-full h-full items-center justify-center '}>
                    <h1 className={'font-bold text-amber-400 text-lg ml-2'}>Seleziona Categoria</h1>
                </div>
            </div>
            <div className={'max-w-[100%]  flex flex-col gap-y-7 px-4 pt-5'}>
                <Grid>
                    {categoryList.map((item) => (
                        <Grid.Col span={isMobile ? 6 : {base: 12, xs: 6, md: 4, xl: 3}}>
                            <QrCategoryComponent category={item} onClick={(sel) => {

                                props.categorySelected(sel)
                            }}/>
                        </Grid.Col>
                    ))}
                </Grid>
            </div>
        </div>
    );
}
