import {LocalType} from "../../types/LocalType";
import {makeRequest} from "../RestAdapter";
import {HttpMethodsEnum} from "../HttpMethodsEnum";
import pin1 from "../../assets/m1.webp";
import pin2 from "../../assets/map-marker/pin2.webp";
import pin3 from "../../assets/map-marker/pin3.webp";
import pin4 from "../../assets/map-marker/pin4.webp";
import pin5 from "../../assets/map-marker/pin5.webp";
import pin6 from "../../assets/map-marker/pin6.webp";
import pin7 from "../../assets/map-marker/pin7.webp";
import pin8 from "../../assets/map-marker/pin8.webp";
import pin9 from "../../assets/map-marker/pin9.webp";
import pin10 from "../../assets/map-marker/pin10.webp";
import pin11 from "../../assets/map-marker/pin11.webp";
import {showFailNotification} from "../../utils/NotificationUtils";

export function getLocalMap(south:number,west:number,north:number, east:number, onFinish:(response: LocalType[]) => void) {
    makeRequest<LocalType[]>(`/users/restaurants/map?south=${south}&west=${west}&north=${north}&east=${east}`, HttpMethodsEnum.GET).then((res) => {
        if (res.data) {
            onFinish(res.data)
        }else {
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }

    })
}

export  const clusterIcons = [
    {
        textColor: '#f10d0d',
        url: pin1,
        height: 26,
        width: 26,
    },
    {
        textColor: '#f10d0d',
        url: pin2,
        height: 46,
        width: 46,
    },
    {
        textColor: '#f10d0d',
        url: pin3,
        height: 46,
        width: 46,
    },
    {
        textColor: '#f10d0d',
        url: pin4,
        height: 46,
        width: 46,
    },
    {
        textColor: '#f10d0d',
        url: pin5,
        height: 46,
        width: 46,
    },
    {
        textColor: '#f10d0d',
        url: pin6,
        height: 46,
        width: 46,
    },
    {
        textColor: '#f10d0d',
        url: pin7,
        height: 46,
        width: 46,
    },
    {
        textColor: '#f10d0d',
        url: pin8,
        height: 46,
        width: 46,
    },
    {
        textColor: '#f10d0d',
        url: pin9,
        height: 46,
        width: 46,
    },
    {
        textColor: '#f10d0d',
        url: pin10,
        height: 46,
        width: 46,
    },
    {
        textColor: '#f10d0d',
        url: pin11,
        height: 46,
        width: 46,
    },
    // add more styles if needed
];
