import {Switch, Table} from "@mantine/core";
import {setActiveMenuRestaurants} from "../../../network/controllers/admin/AdminController";
import {GetRestaurantResponseType} from "../../../types/GetRestaurantResponseType";
import GenericImg from "../../../components/generic/GenericImg";
import trashIcon from "../../../assets/delete-icon.svg";
import {showFailNotification, showSuccessNotification, showWarningNotification} from "../../../utils/NotificationUtils";
import {deleteMenu, getMenuPDF} from "../../../network/controllers/admin/MenuController";
import {MenuType} from "../../../types/CategoryType";
import pdfImage from "../../../assets/icona_pdf.svg";
import loader from '../../../assets/loader-white.svg'
import editIcon from '../../../assets/edit-icon.svg'
import {useEffect, useState} from "react";
import {isMobile} from "react-device-detect";

export default function MenuAdminTable(props: {
    menuList: MenuType[],
    onClickItem: (menu: any) => void
    onEdit: (menu: any) => void
    onChangeStatus: (menu: GetRestaurantResponseType) => void
}) {
    var idMenuActive = JSON.parse(localStorage.getItem('selectedRestaurant')!).idMenuAvalible
    const [isLoading, setIsLoading] = useState<Map<string, boolean>>(new Map());

    useEffect(() => {
        var tmpListStatus: Map<string, boolean> = new Map();
        props.menuList.forEach(item => {
            tmpListStatus.set(item.id, false)
        })
        setIsLoading(tmpListStatus)
    }, []);


    const rows = props.menuList.length > 0 ? props.menuList.map((element, index) => (
        <Table.Tr className={'w-full cursor-pointer '} key={Math.random()}>
            <Table.Td className={'text-center '} onClick={() => props.onClickItem(element)}>
                <h1 className={'w-auto'}>{element.name}</h1>
            </Table.Td>
            <Table.Td>
                <div className={'w-min'}>
                    <Switch color={'yellow'} defaultChecked={element.id === idMenuActive} onChange={(v) => {
                        setActiveMenuRestaurants(element.id, (response) => {
                            props.onChangeStatus(response)
                        })
                    }}/>
                </div>
            </Table.Td>
            <Table.Td>
                <div className={'w-full flex items-end justify-end pr-5 gap-x-10'}>

                    <GenericImg src={isLoading.get(element.id) ? loader : editIcon} className={'w-5 h-5 flex  '}
                                onCLick={() => {
                                    props.onEdit(element)
                                }}/>
                    <GenericImg src={isLoading.get(element.id) ? loader : pdfImage} className={'w-5 h-5 flex  '}
                                onCLick={() => {
                                    if (!isLoading.get(element.id)) {
                                        var tmpMap1: Map<string, boolean> = new Map(isLoading)
                                        tmpMap1.set(element.id, true)
                                        setIsLoading(tmpMap1)
                                        getMenuPDF(element.id, (response) => {
                                            var tmpMap2: Map<string, boolean> = new Map(isLoading)
                                            tmpMap2.set(element.id, false)
                                            setIsLoading(tmpMap2)
                                            window.open(response.PDFMenu, '_blank', 'noopener,noreferrer')

                                        })
                                    }
                                }}/>


                    <GenericImg src={trashIcon} className={'w-5 h-5 flex  '} onCLick={() => {
                        showWarningNotification(`Sicuro di voler eliminare  ${element.name}?`, () => {
                            deleteMenu(element.id!, (response) => {
                                if (response.status === 200) {
                                    showSuccessNotification('Menu', 'Eliminato con successo', 2000)
                                    window.location.reload()
                                } else {
                                    showFailNotification('Errore', 'Riprova più tardi', 2000)
                                }

                            })
                        })
                    }}/>
                </div>

            </Table.Td>
        </Table.Tr>

    )) : null;

    if (isMobile) {
        return (
        <div className={'w-auto h-auto mt-10'}>
            <Table.ScrollContainer minWidth={'auto'}>
                <Table striped highlightOnHover withTableBorder withRowBorders={false}>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th style={{ fontSize: '12px' }} className={'text-center'}>NOME MENU</Table.Th>
                            <Table.Th style={{ fontSize: '12px' }} className={'text-center'}>ATTIVO</Table.Th>
                            <Table.Th></Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody key={Math.random()}>{rows}</Table.Tbody>
                </Table>
            </Table.ScrollContainer>
        </div>
    )

}else{
        return (
            <div className={'mt-10'}>
                <Table.ScrollContainer minWidth={'auto'}>
                    <Table striped highlightOnHover withTableBorder withRowBorders={false}>
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th className={'text-center'}>NOME MENU</Table.Th>
                                <Table.Th>ATTIVO</Table.Th>
                                <Table.Th></Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody key={Math.random()}>{rows}</Table.Tbody>
                    </Table>
                </Table.ScrollContainer>
            </div>
        )
    }}
