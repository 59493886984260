import {Tabs} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {SelectTypeFromPath} from "../../types/SelectTypeFromPath";


export default function SelectComponentFromPath(props:{listItem:SelectTypeFromPath[]}){
    const [path, setPath] = useState<string>(window.location.pathname);
    const location = useLocation();
    useEffect(() => {
        setPath(window.location.pathname)
    }, [location]);



    var navigate = useNavigate()
    return(
        <div>
            <Tabs value={path}>
                <Tabs.List >
                    {props.listItem.map((item)=>(
                        <Tabs.Tab key={item.path} defaultChecked={true} className={'font-regular'} onClick={() => {
                            localStorage.removeItem('ricerca')
                            dispatchEvent(new Event('deleteRicerca'))
                            navigate(item.path)
                        }} value={item.path}>
                            {item.title}
                        </Tabs.Tab>
                    ))}
                </Tabs.List>


            </Tabs>
        </div>
    )
}
