import {BackgroundImage} from "@mantine/core";
import testImage from "../../assets/welcome/image.png";
import ItemInfoComponent from "./ItemInfoComponent";
import {isMobile} from "react-device-detect";

export default function InfoComponentLeft(props:{onclick:(subtitle:string)=>void}) {
    var arrayListInfoComponent:{position:{top:string, left:string}, info:{title:string, subtitle?:string, line2subtitle?:string}}[] = []

        if(isMobile){
            arrayListInfoComponent =    [
                {position:{left:'16%', top:'10.8%'},info:{title:'Es. Ordini', subtitle:'il servizio', line2subtitle:'ordini che usi'}},
                {position:{left:'56.5%', top:'10.2%'},info:{title:'Es. Ricerca', subtitle:'il servizio per far ', line2subtitle: 'trovare il tuo locale'}},
                {position:{left:'73%', top:'27.5%'},info:{ title:'Es. Menu', subtitle: 'il servizio menu ', line2subtitle: 'digitale che usi'}},
                {position:{left:'78%', top:'45%'},info:{ title:'Es. Prenotazioni', subtitle: 'il servizio che usi', line2subtitle: 'per le prenotazioni'}},
                {position:{left:'73.5%', top:'62%'},info:{ title:'Es. gestionale', subtitle: 'il servizio che usi', line2subtitle: 'per la gestione della tua attività'}},
                {position:{left:'55.5%', top:'79.5%'},info:{title:'Es. Asporto', subtitle: 'il servio che usi', line2subtitle: 'per l`asporto/domicilio'}},
            ]
        }else {
            arrayListInfoComponent = [
                {position:{left:'19.5%', top:'16.2%'},info:{title:'Es. Ordini', subtitle:'il servizio', line2subtitle:'ordini che usi'}},
                {position:{left:'54%', top:'16.2%'},info:{title:'Es. Ricerca', subtitle: '  il servizio per', line2subtitle: 'cercare il locale'}},
                {position:{left:'69.5%', top:'32%'},info:{ title:'Es. Menu', subtitle: 'il servizio menu ', line2subtitle: 'digitale che usi'}},
                {position:{left:'72.2%', top:'54.5%'},info:{ title:'Es. Prenotazioni', subtitle: 'il servizio che usi', line2subtitle: 'per le prenotazioni'}},
                {position:{left:'61%', top:'74.5%'},info:{ title:'Es. gestionale', subtitle: 'il servizio che usi', line2subtitle: 'per la gestione '}},
                {position:{left:'18.5%', top:'76.9%'},info:{title:'Es. Asporto', subtitle: 'il servio che usi per', line2subtitle: 'asporto/domicilio'}},
            ]
        }




    if (isMobile) {
        return (
            <div className={'w-[300px] h-[300px] '}>
                <BackgroundImage
                    className="w-full h-full bg-contain bg-no-repeat relative"
                    src={testImage}
                >
                    {arrayListInfoComponent.map((item) => (
                        <ItemInfoComponent key={item.info.title} position={{left: item.position.left, top: item.position.top}} info={{
                            title: item.info.title,
                            subtitle: item.info.subtitle,
                            line2subtitle: item.info.line2subtitle
                        }} onclick={(subtitle) => {
                            props.onclick(subtitle)
                        }}/>
                    ))}

                </BackgroundImage>

            </div>
        )
    } else {
        return (
            <div className={'w-[650px] h-[650px] overflow-x-auto  flex justify-around '}>
                <BackgroundImage
                    className="w-full  h-full bg-contain bg-no-repeat relative"
                    src={testImage}
                >
                    {arrayListInfoComponent.map((item) => (
                        <ItemInfoComponent key={item.info.title} position={{left: item.position.left, top: item.position.top}} info={{
                            title: item.info.title,
                            subtitle: item.info.subtitle,
                            line2subtitle: item.info.line2subtitle
                        }} onclick={(subtitle) => {
                            props.onclick(subtitle)
                        }}/>
                    ))}

                </BackgroundImage>

        </div>
    )
}
}
