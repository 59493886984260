import React, {useRef, useState} from 'react';
import {Coordinates, Cropper, CropperPreview, CropperPreviewRef, CropperRef} from "react-advanced-cropper";
import 'react-advanced-cropper/dist/style.css';
import CustomButton from "../../CustomButton";

export default function MobileCropImg(props:{file:File,onCrop:(cropped:string)=>void, aspect:number}) {
    const [image, setImage] = useState(
        URL.createObjectURL(props.file)
    );
    const previewRef = useRef<CropperPreviewRef>(null);
    const cropperRef = useRef<CropperRef>(null);
    const onChange = (cropper: CropperRef) => {
        console.log(cropper.getCoordinates(), cropper.getCanvas());
    };
    const [coordinates, setCoordinates] = useState<Coordinates | null>(null);

    const onCrop = () => {
        if (cropperRef.current) {
            setCoordinates(cropperRef.current.getCoordinates());

           props.onCrop(cropperRef.current.getCanvas()?.toDataURL("image/jpeg",0.7)!);
        }
    };
    const onUpdate = (cropper: CropperRef) => {
        previewRef.current?.update(cropper);
    };



    return (
        <div className={'w-full h-auto '}>
            <div className={'w-full h-[600px]'}>
                <Cropper
                    ref={cropperRef}
                    src={image}
                    onChange={onChange}
                    onUpdate={onUpdate}
                    checkOrientation={true}
                    className={'cropper'}
                    stencilProps={{
                        aspectRatio: props.aspect,
                        movable:true,
                        resizable: true,
                        grid: true,
                    }}

                />
            </div>

            <div className={'w-full items-center justify-center  flex'}>
            <CustomButton enable={true} isLoading={false} onClick={()=>{
                onCrop()
            }} title={'Fine'}/>
            </div>

        </div>
    );
}
