enum StatusEnumRequest {
    UPGRADE = 'MIGLIORAMENTI',
    SUGGESTION = 'SUGGERIMENTI',
    OTHER = 'ALTRO',
    DEFAULT = ''
}

namespace StatusEnumRequest {
    export function retrieveFromString(str: string): StatusEnumRequest {
        if (str === 'MIGLIORAMENTI') {
            return StatusEnumRequest.UPGRADE
        } else if (str === 'SUGGERIMENTI') {
            return StatusEnumRequest.SUGGESTION
        } else if (str === 'ALTRO') {
            return StatusEnumRequest.OTHER
        } else {
            return StatusEnumRequest.DEFAULT
        }
    }

    export function getRequestEntries(): StatusEnumRequest[] {
        return [StatusEnumRequest.UPGRADE, StatusEnumRequest.SUGGESTION, StatusEnumRequest.OTHER, StatusEnumRequest.DEFAULT]
    }

}

export {
    StatusEnumRequest
}
