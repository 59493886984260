import CustomTextInput from "../../../components/generic/CustomTextInput";
import {useEffect, useState} from "react";
import DrawerCropComponent from "../../../components/generic/cropper/DrawerCropComponent";
import {Drawer, NumberInput, Select} from "@mantine/core";
import {ProductType} from "../../../types/ProductType";
import GenericMultiselect from "../../../components/generic/GenericMultiselect";
import {getMenu, getPriceType} from "../../../network/controllers/admin/MenuController";
import placeholder from '../../../assets/insert-image.svg'
import KitchenDrawerComponent from "../KitchenDrawerComponent";
import {KitchenType} from "../../../types/KitchenType";
import {getLicenceFromLicenseId} from "../../../network/controllers/admin/AdminController";
import {getKitchens} from "../../../network/controllers/admin/KitchenController";
import {isMobile} from "react-device-detect";


export default function StepProductInfo(props: {
    isMenuSelect: boolean,
    product: ProductType,
    onDataSet: (product: ProductType | null) => void,
}) {
    const [product, setProduct] = useState<ProductType>(props.product);
    const [menu, setMenu] = useState<{ label: any, value: string }[]>([]);
    const [kitchenMultiSelect, setKitchenMultiSelect] = useState<{ label: any, value: string }[]>();
    const [isKitchenEnable, setIsKitchenEnable] = useState<boolean>(false);
    const [kitchenSelected, setKitchenSelected] = useState<KitchenType>();
    const [statusMenu, setStatusMenu] = useState({open: false, content: <div></div>});
    const [priceTypeList, setPriceTypeList] = useState<string[]>([]);
    useEffect(() => {
        if (product.nameProduct !== '' && product.priceOriginal !== 0 && product.photoPath !== '' && product.idMenus && product.idMenus.length > 0) {
            if (props.isMenuSelect) {
                if (product.idMenus && product.idMenus?.length > 0) {
                    if (product.priceType !== undefined) {
                        props.onDataSet(product)
                    } else {
                        props.onDataSet({...product, priceType: 'singolo'})
                    }

                }
            } else {
                if (product.priceType !== undefined) {
                    props.onDataSet(product)
                } else {
                    props.onDataSet({...product, priceType: 'singolo'})
                }
            }

        } else {
            props.onDataSet(null)
        }
    }, [product, props.product, kitchenSelected]);

    useEffect(() => {
        getMenu((menu) => {
            var tmpArr: any[] = []
            menu.map((menu) => {
                tmpArr.push({value: menu.id, label: menu.name})
            })
            setMenu(tmpArr);
        })
        getLicenceFromLicenseId((license) => {
            if (license.namePackage !== 'BASE') {
                setIsKitchenEnable(true)
                getKitchens((response) => {
                    var tmpArr: any[] = [{value: 'default', label: 'Aggiungi nuova cucina'}];
                    response.map((kitchen) => {
                        if (kitchen.id === props.product.idKitchen) {
                            setKitchenSelected(kitchen)
                            setProduct({...product, idKitchen: kitchen.id})
                        }
                        tmpArr.push({value: JSON.stringify(kitchen), label: kitchen.kitchenName})
                    })
                    setKitchenMultiSelect(tmpArr)
                })
            }
        })
        getPriceType((response) => {

            setPriceTypeList(response)
        })
    }, []);


    if (isMobile) {
        return (
            <div className={'w-full h-full flex items-center justify-center  px-4 pt-10 '}>
                <Drawer
                    position={"bottom"}
                    size={'full'}
                    styles={{
                        content: {backgroundColor: 'black'},
                        header: {backgroundColor: 'black'}
                    }}
                    zIndex={40}
                    opened={statusMenu.open} onClose={() => {
                    setStatusMenu({...statusMenu, open: false})
                }}>

                    {statusMenu.content}
                </Drawer>
                <div className={'w-full h-auto flex flex-col'}>
                    <div className={'w-full flex flex-col '}>
                        <CustomTextInput isBlack={true}
                                         customDimensions={'w-full h-auto'}
                                         defaultValue={product.nameProduct}
                                         onChange={(name) => {setProduct({...product, nameProduct: name})                                         }}
                                         label="Nome Prodotto"
                                        />
                        <div className={'w-full h-auto flex flex-col '}>
                            <div className={'w-full flex h-auto '}>
                                <NumberInput
                                    allowDecimal={true}
                                    hideControls={true}
                                    radius="md"
                                    placeholder={'0'}
                                    variant={'unstyled'}
                                    styles={{
                                        root: {width: '100%'},
                                        wrapper: {width: '100%'},
                                        label: {padding: '5px', color: 'black'},
                                        input: {padding: '10px', height: 20, backgroundColor: '#F2F2F2A8'}
                                    }}
                                    value={product.priceOriginal === 0 ? undefined : product.priceOriginal}
                                    label={'Prezzo'}
                                    onChange={(value) => {
                                        setProduct({...product, priceOriginal: value as number})
                                    }}/>
                            </div>
                            <div className={'w-auto h-auto flex flex-col mt-3'}>
                                <Select
                                    label="Seleziona tipologia prezzo"
                                    placeholder="es. al kg"
                                    variant={'unstyled'}
                                    value={product.priceType}
                                    data={priceTypeList}
                                    classNames={{
                                        root: 'w-full mt-2 ',
                                        wrapper: 'w-full h-full',
                                        input: 'w-full h-full px-5 bg-[#F2F2F2A8] rounded-lg'
                                    }}
                                    onChange={(value) => {
                                        if (value) setProduct({...product, priceType: value})
                                    }}

                                />


                            </div>

                        </div>

                        {isKitchenEnable ?
                            <div className={'w-full h-auto flex flex-col items-center justify-cente bg-orange-500  '}>
                                <div className={'w-full  h-full flex '}>
                                    {
                                        <GenericMultiselect key={Math.random()}
                                                            defaultValue={kitchenSelected !== undefined ? [JSON.stringify(kitchenSelected)] : undefined}
                                                            maxValues={1} label={'Cucine'}
                                                            data={kitchenMultiSelect ? kitchenMultiSelect : []}
                                                            placeholder={'Seleziona Cucina'}
                                                            onSelect={(list) => {
                                                                if (list.length > 0) {
                                                                    if (list[0] === 'default') {
                                                                        setStatusMenu({
                                                                            open: true,
                                                                            content: <KitchenDrawerComponent
                                                                                kitchenList={(response) => {
                                                                                    var tmpArr: any[] = []
                                                                                    response.map((kitchen) => {
                                                                                        tmpArr.push({
                                                                                            value: JSON.stringify(kitchen),
                                                                                            label: kitchen.kitchenName
                                                                                        })
                                                                                    })
                                                                                    setKitchenMultiSelect(tmpArr)
                                                                                    setStatusMenu({
                                                                                        ...statusMenu,
                                                                                        open: false
                                                                                    })
                                                                                }}/>
                                                                        })
                                                                    } else {
                                                                        setProduct({
                                                                            ...product,
                                                                            idKitchen: JSON.parse(list[0]).id
                                                                        })
                                                                        setKitchenSelected(JSON.parse(list[0]))
                                                                    }
                                                                } else {
                                                                    setKitchenSelected(undefined)
                                                                }
                                                            }}/>
                                    }
                                </div>
                            </div> : null}
                    </div>
                    <div className={'w-full h-full flex gap-x-4 mt-10 items-center justify-center'}>
                        <DrawerCropComponent onCroppedFinish={(imageSrc) => {
                            setProduct({...product, photoPath: imageSrc})
                        }} contentHtml={<div
                            className={'w-[300px] h-[300px] bg-[#F7F7F7]   border-[1px] p-6  rounded-3xl'}>
                            <img alt={''} className={`h-[250px] w-[250px] rounded-xl`}
                                 src={product.photoPath ? product.photoPath : placeholder}/>
                        </div>} aspect={4 / 4}/>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={'w-full h-full flex items-center justify-center p-20 '}>
                <Drawer
                    position={"right"}
                    size={'40%'}
                    styles={{
                        content: {backgroundColor: 'black'},
                        header: {backgroundColor: 'black'}
                    }}
                    zIndex={40}
                    opened={statusMenu.open} onClose={() => {
                    setStatusMenu({...statusMenu, open: false})
                }}>

                    {statusMenu.content}
                </Drawer>
                <div className={'w-full  flex '}>
                    <div className={'w-full flex flex-col gap-y-4 px-20 items-center justify-center'}>
                        <CustomTextInput isBlack={true} customDimensions={'w-full h-[80px]'}
                                         defaultValue={product.nameProduct} onChange={(name) => {
                            setProduct({...product, nameProduct: name})
                        }} placeholder={'Nome prodotto'}/>
                        <div className={'w-full flex '}>
                            <div className={'w-2/3 flex h-[80px] '}>
                                <NumberInput
                                    allowDecimal={true}
                                    hideControls={true}
                                    radius="md"
                                    placeholder={'0'}
                                    variant={'unstyled'}
                                    styles={{
                                        root: {width: '100%'},
                                        wrapper: {width: '100%'},
                                        label: {padding: '5px', color: 'black'},
                                        input: {padding: '10px', height: 80, backgroundColor: '#F2F2F2A8'}
                                    }}
                                    value={product.priceOriginal === 0 ? undefined : product.priceOriginal}
                                    label={'Prezzo'}
                                    onChange={(value) => {
                                        setProduct({...product, priceOriginal: value as number})
                                    }}/>
                            </div>
                            <div className={'w-1/3 h-[80px] flex '}>
                                <Select
                                    label="Seleziona tipologia prezzo"
                                    placeholder="es. al kg"
                                    variant={'unstyled'}
                                    value={product.priceType}
                                    data={priceTypeList}
                                    classNames={{
                                        root: 'w-full mt-2 ml-1',
                                        wrapper: 'w-full h-full',
                                        input: 'w-full h-full px-5 bg-[#F2F2F2A8] rounded-lg'
                                    }}
                                    onChange={(value) => {
                                        if (value) setProduct({...product, priceType: value})
                                    }}

                                />


                            </div>

                        </div>

                        {isKitchenEnable ?
                            <div className={'w-full h-[80px] flex flex-col items-center justify-center    '}>
                                <div className={'w-full  h-full flex '}>
                                    {
                                        <GenericMultiselect key={Math.random()}
                                                            defaultValue={kitchenSelected !== undefined ? [JSON.stringify(kitchenSelected)] : undefined}
                                                            maxValues={1} label={'Cucine'}
                                                            data={kitchenMultiSelect ? kitchenMultiSelect : []}
                                                            placeholder={'Seleziona Cucina'}
                                                            onSelect={(list) => {
                                                                if (list.length > 0) {
                                                                    if (list[0] === 'default') {
                                                                        setStatusMenu({
                                                                            open: true,
                                                                            content: <KitchenDrawerComponent
                                                                                kitchenList={(response) => {
                                                                                    var tmpArr: any[] = []
                                                                                    response.map((kitchen) => {
                                                                                        tmpArr.push({
                                                                                            value: JSON.stringify(kitchen),
                                                                                            label: kitchen.kitchenName
                                                                                        })
                                                                                    })
                                                                                    setKitchenMultiSelect(tmpArr)
                                                                                    setStatusMenu({
                                                                                        ...statusMenu,
                                                                                        open: false
                                                                                    })
                                                                                }}/>
                                                                        })
                                                                    } else {
                                                                        setProduct({
                                                                            ...product,
                                                                            idKitchen: JSON.parse(list[0]).id
                                                                        })
                                                                        setKitchenSelected(JSON.parse(list[0]))
                                                                    }
                                                                } else {
                                                                    setKitchenSelected(undefined)
                                                                }
                                                            }}/>
                                    }
                                </div>
                            </div> : null}
                    </div>
                    <div className={'w-full h-full flex gap-x-4 items-center justify-center'}>
                        <DrawerCropComponent onCroppedFinish={(imageSrc) => {
                            setProduct({...product, photoPath: imageSrc})
                        }} contentHtml={<div
                            className={'w-[300px] h-[300px] bg-[#F7F7F7]   border-[1px] p-6  rounded-3xl'}>
                            <img alt={''} className={`h-[250px] w-[250px] rounded-xl`}
                                 src={product.photoPath ? product.photoPath : placeholder}/>
                        </div>} aspect={4 / 4}/>
                    </div>
                </div>
            </div>
        )
    }
}
