import {makeRequest} from "../RestAdapter";
import {HttpMethodsEnum} from "../HttpMethodsEnum";
import {LocalType} from "../../types/LocalType";
import {showFailNotification} from "../../utils/NotificationUtils";




export function getLocalIsInShowcase(lat:any,long:any,distance:number, inShowcase:boolean,onFinish:(response: LocalType[]) => void) {
    makeRequest<LocalType[]>(`/users/restaurants/listRestaurants`, HttpMethodsEnum.POST,{lat:lat,long:long,distanceFromLatLongInMeters:distance, inShowcase:inShowcase}).then((res) => {
        if (res.isSuccessfull()){
            onFinish(res.data?res.data:[])
        }else {
            dispatchEvent(new CustomEvent("loader", {detail: {isLoading: false}}));
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }
    })
}

export function checkRestaurants(placeId:string,onFinish:(response: LocalType) => void, localNotFound:()=>void) {
    makeRequest<LocalType>(`/users/restaurants/place/${placeId}`, HttpMethodsEnum.GET).then((res) => {
        if (res.status === 404){
            localNotFound()
        }else {
            if (res.data !== null) {
                onFinish(res.data)
            } else {
                showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
            }
        }


    })
}
