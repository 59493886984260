import {useEffect, useState} from "react";
import {LocalType} from "../types/LocalType";
import {Grid, LoadingOverlay} from "@mantine/core";
import {getFavourites} from "../network/controllers/RestaurantsControllers";
import LocalListComponent from "../components/LocalListComponent";
import {isMobile} from "react-device-detect";

export default function FavouriteRestaurantPage() {
    const [local, setLocal] = useState<LocalType[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        dispatchEvent(new CustomEvent("loader", {detail: {isLoading: true}}));
        getFavourites((local) => {
            dispatchEvent(new CustomEvent("loader", {detail: {isLoading: false}}));
            setLocal(local || []);
            setIsLoading(false);
        });
    }, [reload]);

    const handleReload = () => {
        setReload(!reload);
    };

    if (isLoading) {
        return <LoadingOverlay visible/>;
    }


    if (isMobile) {

        return (
            <div className="w-full h-full flex flex-col items-center justify-center pt-[170px] pb-20 px-1">
                <h1 className="font-poppins-bold text-black text-3xl w-full items-start text-center  pl-5">
                    I Miei Locali Preferiti
                </h1>
                <div className="w-full h-full mt-10">
                    {local.length === 0 ? (
                        <div className="w-full h-full flex ">
                            <h1 className="w-full h-screen flex text-center font-poppins-bold text-black text-xl pt-48">Non
                                hai ancora aggiunto nessun locale ai tuoi preferiti</h1>
                        </div>
                    ) : (
                        <Grid gutter={20}>
                            {local.map((item) => (
                                <Grid.Col className="w-full" span={{base: 12, xs: 12, md: 6, xl: 4}} key={item.id}>
                                    <div className="w-full">
                                        <LocalListComponent
                                            reload={handleReload}
                                            clickable={true}
                                            local={item}
                                            showTags={false}
                                        />
                                    </div>
                                </Grid.Col>
                            ))}
                        </Grid>
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <div className="w-full h-screen flex flex-col items-center justify-center pt-[170px] pb-20 px-1">
                <h1 className="font-poppins-bold text-black text-3xl w-full items-start  pl-5">
                    I Miei Locali Preferiti
                </h1>
                <div className="w-full h-full mt-10">
                    {local.length === 0 ? (
                        <div className="w-full h-full flex items-center justify-center">
                            <h1 className="font-poppins-bold text-black text-3xl ` ">Non hai ancora aggiunto nessun
                                locale ai tuoi preferiti</h1>
                        </div>
                    ) : (
                        <Grid gutter={20}>
                            {local.map((item) => (
                                <Grid.Col className="w-full" span={{base: 12, xs: 12, md: 6, xl: 4}} key={item.id}>
                                    <div className="w-full">
                                        <LocalListComponent
                                            reload={handleReload}
                                            clickable={true}
                                            local={item}
                                            showTags={false}
                                        />
                                    </div>
                                </Grid.Col>
                            ))}
                        </Grid>
                    )}
                </div>
            </div>
        );
    }
}
