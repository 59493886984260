import {Menu, rem} from '@mantine/core';
import {
    IconCirclePlus,
    IconEdit,
    IconTrash,
    IconArrowsLeftRight, IconMenu, IconDots
} from '@tabler/icons-react';
import {CategoryType} from "../types/CategoryType";
import {useNavigate} from "react-router-dom";
import {GetProductResponseType} from "../types/GetProductResponseType";
import {showWarningNotification} from "../utils/NotificationUtils";
import {deleteProductFromCategory, deleteProducts} from "../network/controllers/admin/ProductController";

export default function MenuProduct(props: {onClickElement:(product:GetProductResponseType)=>void,onListChange:()=>void,listSize:number, product?: GetProductResponseType, category?:CategoryType }) {
    var navigate = useNavigate()
    return (
        <Menu shadow="md" width={200} position={'bottom-end'} transitionProps={{duration: 150}}>
            <Menu.Target>
                <IconDots/>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item  onClick={() => {
                   props.onClickElement(props.product!)
                }} leftSection={<IconEdit style={{width: rem(14), height: rem(14)}}/>}>
                    {`Modifica ${props.product?.nameProduct}`}
                </Menu.Item>
                <Menu.Item style={{color: 'red'}}   onClick={() => {
                    showWarningNotification(`Sicuro di voler eliminare ${props.product?.nameProduct} dalla categoria ${props.category?.categoryName}?`,()=>{
                        deleteProductFromCategory(props.product?.id!,props.category?.id!,(response)=>{
                            props.onListChange()
                        })
                    })
                }} leftSection={<IconEdit style={{color:'#f30404', width: rem(14), height: rem(14)}}/>}>
                    {`Rimuovi ${props.product?.nameProduct} dalla categoria ${props.category?.categoryName}`}
                </Menu.Item>
                <Menu.Item style={{color: 'red'}}  onClick={() => {
                    showWarningNotification(`Sicuro di voler eliminare ${props.product?.nameProduct}?`,()=>{
                        deleteProducts(props.product?.id!,props.category?.id!,()=>{
                            props.onListChange()
                        })
                    })
                }} leftSection={<IconTrash style={{color:'#f30404',width: rem(14), height: rem(14)}}/>}>
                    {`Elimina ${props.product?.nameProduct}`}
                </Menu.Item>

            </Menu.Dropdown>
        </Menu>
    );
}