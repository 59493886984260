import recipebg from '../assets/recipe-bg-icon.svg';
import logo from '../assets/logo-menus.webp';
import CustomButton from "./CustomButton";
import {Divider, TextInput} from "@mantine/core";
import React from "react";

export default function RepiceComponent() {
    const days: Array<string> = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'];
    const months: Array<string> = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];

    const today: Date = new Date();
    const date: string = `${days[today.getDay()]}, ${today.getDate()} ${months[today.getMonth()]} ${today.getFullYear()} - ${today.getHours()
        .toString().padStart(2, '0')}:${today.getMinutes().toString().padStart(2, '0')}:${today.getSeconds().toString().padStart(2,
        '0')}`;

    let item = [
        {name: 'Nome piatto', price: '€ 10'}, {name: 'Nome piatto', price: '€ 20'}, {
            name: 'Nome piatto',
            price: '€ 30'
        }, {name: 'Nome piatto', price: '€ 40'}, {name: 'Nome piatto', price: '€ 50'}, {
            name: 'Nome piatto',
            price: '€ 60'
        }, {name: 'Nome piatto', price: '€ 70'}, {name: 'Nome piatto', price: '€ 80'}, {
            name: 'Nome piatto',
            price: '€ 90'
        }, {name: 'Nome piatto', price: '€ 100'}, {name: 'Nome piatto', price: '€ 110'}, {
            name: 'Nome piatto',
            price: '€ 120'
        }, {name: 'Nome piatto', price: '€ 130'}, {name: 'Nome piatto', price: '€ 140'}, {
            name: 'Nome piatto',
            price: '€ 150'
        }, {name: 'Nome piatto', price: '€ 160'}, {name: 'Nome piatto', price: '€ 170'}, {
            name: 'Nome piatto',
            price: '€ 180'
        }, {name: 'Nome piatto', price: '€ 190'}, {name: 'Nome piatto', price: '€ 200'},
    ];

    const total = item.reduce((acc, curr) => acc + Number(curr.price.replace(/\D/g, '')), 0);

    return (


        <div className='w-full h-[80%]'>

            <div
                className='flex flex-col w-[70%] h-[90%] ml-16 mt-8'
                style={{
                    backgroundImage: `url(${recipebg})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                }}
            >
                <div className={'flex flex-col  items-center pt-6 w-full h-auto'}>
                    <img src={logo} className={'w-[70%] h-auto bg-black'}/>
                    <div>
                        <span className={'text-xs font-roboto-mono-regular'}>{date}</span>
                    </div>
                </div>

                <div className={'flex flex-col w-auto h-[250px] overflow-y-auto scrollbar-hide mt-5'}>
                    {item.map(item => (
                        <>
                            <Divider color='dark' my='sm' variant='dashed' style={{margin: '12px'}}/>
                            <div className={'flex justify-between pl-7 pr-7'}>
                                <h1 className={'text-xs font-roboto-mono-regular opacity-70'}> 1X {item.name}</h1>
                                <h1 className={'text-xs font-roboto-mono-regular opacity-70'}>{item.price}</h1>
                            </div>
                        </>
                    ))}
                </div>


                <div className={'flex flex-col w-full h-[140px] gap-y-3'}>

                    <Divider color='dark' my='sm' variant='dashed'/>


                    <div className={'flex w-full h-auto justify-between pl-5 pr-7'}>
                        <h1 className={'text-xs font-roboto-mono-regular'}> Totale </h1>
                        <h1 className={'text-xs font-roboto-mono-regular'}>€ {total}</h1>
                    </div>

                    <div className={'flex w-full h-auto justify-between pl-5 pr-5'}>
                        <h1 className={'text-xs font-roboto-mono-regular'}> Sconto </h1>
                        <div className={'flex h-auto w-[60px] '}>
                            <TextInput size={'xs'} placeholder="€ 0,00"/>
                        </div>
                    </div>

                    <div className={'flex w-full h-auto justify-between pl-5 pr-7 mb-8'}>
                        <h1 className={'text-xl font-roboto-mono-bold'}> Tot. </h1>
                        <h1 className={'text-xl font-roboto-mono-bold'}>€ </h1>
                    </div>


                </div>


            </div>
            <div className='flex flex-col w-full h-auto pt-8 justify-center items-center pb-8  gap-y-4'>
                <CustomButton title='Stampa preconto' onClick={() => void 0} enable={true} isLoading={false}
                              customStyles={'w-[160px] h-[30px]'}/>
                <CustomButton title='Chiudi tavolo' onClick={() => void 0} enable={true} isLoading={false}
                              customStyles={'w-[160px] h-[30px]'}/>
            </div>
        </div>
    )
}
