import {HttpMethodsEnum} from "./HttpMethodsEnum";
import {GetRestaurantResponseType} from "../types/GetRestaurantResponseType";
import {LoginResponseType} from "../types/LoginResponseType";

const baseUrl = process.env["REACT_APP_BASE_URL_REST"];

function createRequestOptions(requestMethod: string, headersMap: Headers | null = null, body: any = null, abortSignal: AbortSignal | null = null, isFormData: boolean): RequestInit {
    const headersRequest = new Headers()
    const restaurantData = JSON.parse((localStorage.getItem('selectedRestaurant') as string)) as GetRestaurantResponseType
    var userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType
    if (restaurantData) {
        headersRequest.append('restaurant-id', restaurantData.id)
    }
    if (userData){
     headersRequest.append('user-id',userData.id)
    }
    if (isFormData) {
        headersRequest.append('Content-Type', 'multipart/form-data')
    } else {
        headersRequest.append("Content-Type", `application/json`);
    }


    if (headersMap != null) {
        headersMap?.forEach((v:any, k:any) => {
            headersRequest.append(k, v)
        })
    }
    return {
        method: requestMethod,
        headers: headersRequest,
        body: isFormData ? body : body !== null ? JSON.stringify(body) : null,
        signal: abortSignal

    }
}

class ApiResponseWrapper<T> {
    constructor(
        public data: T | null = null,
        public status: number = 0,
        public headers: Headers = new Headers()
    ) {
    }

    isSuccessfull(): boolean {
        return this.status >= 200 && this.status < 300;
    }

}

async function makeRequest<T>(url: string, requestMethod: HttpMethodsEnum, body: any = null, headersMap: Headers | null = null, abortSignal: AbortSignal | null = null, isFormData = false, isNobodyResponse?: boolean) {
    const response = await fetch(
        baseUrl + url,
        createRequestOptions(requestMethod, headersMap, body, abortSignal, isFormData)
    )
    const headers = new Headers(response.headers)

    try {
        const data = isNobodyResponse ? response : await response.json()
        return new ApiResponseWrapper<T>(data, response.status, headers)
    } catch (error) {
        return new ApiResponseWrapper<T>(null, response.status, headers)
    }
}

export {
    makeRequest
}
