import loader from '../assets/loader-white.svg'


export default function CustomButton(props: {
    bgColor?: string,
    title: string,
    onClick: () => void,
    enable: boolean,
    isLoading: boolean,
    small?: boolean,
    customStyles?: string
}) {

    return (
        props.small ?
            <div style={{backgroundColor: props.bgColor}}
                 className={`${props.enable ? 'bg-opacity-100' : `bg-opacity-50`} ${!props.isLoading && props.enable ? 'hover:scale-110 duration-700 pointer-events-auto cursor-pointer ' : 'pointer-events-none'} ${props.bgColor ? `` : 'bg-yellow'} rounded-xl w-[175px] h-[3rem] flex flex-col justify-center items-center px-1`}
                 onClick={() => {
                     props.onClick()
                 }}>
                {props.isLoading ? <img alt={''} className={'w-[25px] h-[25px]'} src={loader}/> :
                    <p className={`font-poppins-bold text-[14px] w-full text-center`}>{props.title}</p>}
            </div> :
            <div style={{backgroundColor: props.bgColor}}
                 className={`${props.enable ? 'bg-opacity-100' : `bg-opacity-50`} ${!props.isLoading && props.enable ? 'hover:scale-110 duration-700 pointer-events-auto cursor-pointer' : 'pointer-events-none'} bg-yellow  rounded-xl ${props.customStyles ? props.customStyles : 'w-[350px]'} h-[4rem] flex flex-col justify-center items-center`}
                 onClick={() => {
                     props.onClick()
                 }}>
                {props.isLoading ? <img alt={''} className={'w-[50px] h-[50px]'} src={loader}/> :
                    <p className={`font-poppins-bold text-[15px] w-full text-center`}>{props.title}</p>}
            </div>
    )
}



