import React, {useEffect, useState} from "react";
import CustomButton from "../../components/CustomButton";
import {Divider, Input} from "@mantine/core";
import confirm from "../../assets/confirm-icon.svg";
import closeIcon from "../../assets/close-icon.svg";
import {showSuccessNotification} from "../../utils/NotificationUtils";
import loadingIcon from '../../assets/loader-white.svg'
import {createTurn, getTurns} from "../../network/controllers/admin/TurnController";
import {TurnType} from "../../types/TurnType";
import TurnAdminTable from "./tables/TurnAdminTable";
import {LoginResponseType} from "../../types/LoginResponseType";
import {useNavigate} from "react-router-dom";


export default function TurnsAdminPage() {
    const [addTurn, setAddTurn] = useState<boolean>(false);
    const [newTurn, setNewTurn] = useState('');
    const [turnsList, setTurnsList] = useState<TurnType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    var userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType
    var navigate = useNavigate();

    useEffect(() => {
        if (userData && userData.role.includes('ADMIN')) {
            getTurns((response) => {
                setTurnsList(response)
            })
        } else {
            navigate('/welcome')
        }
    }, []);


    return (
        <div className={'w-full h-full  flex-col px-20 pt-10 '}>
            <div className={'w-full flex justify-between'}>
                <h1 className={'w-auto text-3xl font-poppins-bold my-5'}>Turni</h1>
                <CustomButton small={true} title={!addTurn ? 'Aggiungi Turno' : 'Annulla'} onClick={() => {
                    setAddTurn(!addTurn)
                }} enable={true} isLoading={false}/>
            </div>
            <h1 className={'w-auto font-poppins-bold my-5'}>NOME TURNO</h1>
            {addTurn ?
                <div className={'w-full flex my-10 items-center '}>
                    <Input type={'text'}
                           className={'w-[250px] h-full bg-white text-text-button-add font-poppins-regular outline-none '}
                           placeholder={'Nome Cucina'} onChange={(event) => {
                        setNewTurn(event.currentTarget.value.charAt(0).toUpperCase() + event.currentTarget.value.slice(1))
                    }}/>
                    <div className={'w-[100px] flex ml-10 gap-x-3'}>
                        <img className={'cursor-pointer w-6 h-6'} alt={''} src={isLoading ? loadingIcon : confirm}
                             onClick={() => {
                                 setIsLoading(true)
                                 createTurn({
                                     idLocal: JSON.parse(localStorage.getItem('selectedRestaurant') as string).id,
                                     nameTurn: newTurn
                                 }, (response) => {
                                     setIsLoading(false)
                                     setAddTurn(false)
                                     showSuccessNotification('Turni', `${newTurn} creato con successo`, 2000)
                                     setTurnsList(response)
                                     setNewTurn('')
                                 })
                             }}/>
                        <img className={'cursor-pointer w-6 h-6'} alt={''} src={isLoading ? loadingIcon : closeIcon}
                             onClick={() => {
                                 setAddTurn(false)
                             }}/>
                    </div>
                </div>

                : null
            }

            {turnsList.length > 0 ? turnsList.map((turn, index) => (
                <div className={`py-5 bg-opacity-90 rounded-md px-2`} key={index}>
                    <TurnAdminTable turn={turn} onTurnUpdate={(turnList) => {
                        setTurnsList(turnList)
                    }} onClickItem={() => {
                    }}/>
                    <Divider color={'yellow'}/>
                </div>)) : null}


        </div>
    )
}
