import React from 'react';
import { isMobile } from 'react-device-detect';

interface ChatMessageProps {
    message: string | React.ReactNode;  // Allow both string and React nodes
    isUser: boolean;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ message, isUser }) => {
    const commonClasses = 'p-3 rounded-t-2xl w-max text-black shadow shadow-gray-500';
    const userClasses = isUser ? 'rounded-bl-2xl bg-yellow' : 'rounded-br-2xl bg-yellow';

    const messageClasses = `${commonClasses} ${userClasses} ${isMobile ? 'max-w-[80%] text-sm' : 'max-w-xs text-base'}`;

    return (
        <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} my-3`}>
            <div className={messageClasses}>
                {message}
            </div>
        </div>
    );
};

export default ChatMessage;
