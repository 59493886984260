import React from "react";
import {Navigate, useRoutes} from 'react-router-dom';
import AddRestaurantSellerPage from "../pages/seller/add-restaurant/AddRestaurantSellerPage";



export default function RouteLocalRegistration() {

    return useRoutes([
        {
            path: '/registrati',
            element: <AddRestaurantSellerPage isRenew={false}/>,
            children: []
        },{
            path: '/rinnova',
            element: <AddRestaurantSellerPage isRenew={true}/>,
            children: []
        },


        {
            path: '*', element: <Navigate to='/restaurant/registrati'/>
        }

    ])
}
