enum StatusPrinterEnum {
    WORKING = 'LAVORAZIONE',
    SENDED = 'SPEDITO',
    COMPLETE = 'COMPLETO',
    DEFAULT = ''
}

namespace StatusPrinterEnum {
    export function retrieveFromString(str: string): StatusPrinterEnum {
        if (str === 'LAVORAZIONE') {
            return StatusPrinterEnum.WORKING;
        } else if (str === 'SPEDITO') {
            return StatusPrinterEnum.SENDED;
        } else if (str === 'COMPLETO') {
            return StatusPrinterEnum.COMPLETE;
        } else {
            return StatusPrinterEnum.DEFAULT;
        }
    }

    export function getRequestEntries(): StatusPrinterEnum[] {
        return [StatusPrinterEnum.WORKING, StatusPrinterEnum.SENDED, StatusPrinterEnum.COMPLETE, StatusPrinterEnum.DEFAULT];
    }
}

export { StatusPrinterEnum };
