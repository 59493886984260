import {useNavigate} from "react-router-dom";
import {LoginResponseType} from "../../types/LoginResponseType";
import {useEffect} from "react";
import RouteLocalRegistration from "../../routing/RouteLocalRegistration";


export default function RootLocalPage() {
    var userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType
    var navigate = useNavigate()
    return (
        <div className={'h-full'}>
            <div className={'w-full h-full px-2 pb-10 '}>
                <RouteLocalRegistration/>
            </div>
        </div>

    )

}
