import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import { SelectTypeFromPath } from "../types/SelectTypeFromPath";
import ImageLogoMenus from "./ImageLogoMenus";
import MenuMyAccount from "./MenuMyAccount";
import MenuChangePosition from "./MenuChangePosition";
import GoogleAddressSearch from "./GoogleAddressSearch";
import { checkRestaurants } from "../network/controllers/WecomePageController";
import { showFailNotification } from "../utils/NotificationUtils";
import { getDimension } from "../utils/Utility";

export default function HeaderComponent() {
    const location = useLocation();
    const [parametriRicerca, setParametriRicerca] = useState(localStorage.getItem('ricerca') !== null ? JSON.parse(localStorage.getItem('ricerca') as string) : {
        category: 'Tutti',
        txtricerca: ''
    });

    const [value] = useDebounce(parametriRicerca, 500);
    const [popOverStatus, setPopOverStatus] = useState(false);
    const navigate = useNavigate();
    const listSelect: SelectTypeFromPath[] = [];
    listSelect.push({ path: '/home', title: 'HOME' });
    listSelect.push({ path: '/home/mappa', title: 'MAPPA' });

    useEffect(() => {
        localStorage.setItem('ricerca', JSON.stringify(parametriRicerca));
        dispatchEvent(new Event("storage"));
    }, [value]);

    useEffect(() => {
        window.removeEventListener('deleteRicerca', () => {});
        window.addEventListener('deleteRicerca', () => {
            setParametriRicerca({ category: 'Tutti', txtricerca: '' });
        });
    }, []);

    const [dimension, setDimension] = useState(false);
    useEffect(() => {
        getDimension(640, (res) => {
            setDimension(res);
        });
    }, []);

    const isRestrictedPage = location.pathname.includes('/detail') ||
        location.pathname.includes('/prenotazioni') ||
        location.pathname.includes('/favourite');

    return (
        <div className={' '}>
            <header className={`h-[85px] bg-black w-full flex items-center shadow  justify-center px-5 absolute z-50 `}>
                <div className={'w-full flex justify-between gap-x-10 items-center '}>
                    <div className={'w-[300px] p-2 '}>
                        <ImageLogoMenus />
                    </div>
                    <div className={'flex flex-col w-full h-full'}>
                        <div className={`absolute top-2  2xl:left-[300px] xl:left-[250px] lg:left-[220px] md:left-[200px]  ${dimension ? 'right-[280px] left-[100px]' : 'xl:right-[290px] lg:right-[280px] md:right-[280px] '}`}>
                            <GoogleAddressSearch
                                searchSuggestions={true}
                                defaultOpen={false}
                                setNameTextInput={'name'}
                                inputDimensions={'h-[70px]'}
                                classname={'h-[70px]'}
                                inputPlaceholder={'Cerca su MaiSazio...'}
                                onPlaceSelect={(place) => {
                                    checkRestaurants(place.place_id, (local) => {
                                        localStorage.setItem('selectedRestaurantClient', JSON.stringify(local));
                                        if (window.location.pathname.includes('/detail')) {
                                            window.location.reload()
                                        }else{
                                            navigate(`/detail`);
                                        }

                                    }, () => {
                                        showFailNotification('OPSS', 'Questo locale non fa parte di MaiSazio', 2000);
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className={'w-auto h-auto flex items-center justify-end '}>
                        <div className={'w-full flex mr-2 gap-x-1 items-center'}>
                        {!isRestrictedPage && <MenuChangePosition />}
                        <MenuMyAccount />
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}
