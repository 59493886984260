import React, {useEffect, useState} from "react";
import {AddRestaurantRequest, License} from "../../../types/AddRestaurantRequest";
import {Divider, Group, Stepper} from "@mantine/core";
import SearchClientDataForm from "./SearchClientDataForm";
import CustomButton from "../../../components/CustomButton";
import RestaurantDataForm from "./RestaurantDataForm";
import SubscritionPlanPage from "../../SubscritionPlanPage";
import {
    renewRestaurantSubscription,
    restaurantRegistration,
    stripeRegistration
} from "../../../network/controllers/RestaurantsControllers";
import {LoginResponseType} from "../../../types/LoginResponseType";
import {checkUser} from "../../../network/controllers/seller/GenericSellerController";
import {notificationSuccess} from "../../../utils/NotificationUtils";
import {useNavigate} from "react-router-dom";
import {isMobile} from "react-device-detect";
import LocalListComponent from "../../../components/LocalListComponent";
import CheckoutformRenew from "../../../components/CheckoutformRenew";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {getLicenceFromLicenseId} from "../../../network/controllers/admin/AdminController";
import {makeRequest} from "../../../network/RestAdapter";
import {HttpMethodsEnum} from "../../../network/HttpMethodsEnum";
import {LicenseType} from "../../../types/LicenseType";
import {formatStringDuration} from "../../../utils/StringUtils";
import LicenseRecapComponent from "../../../components/LicenseRecapComponent";
import {PackageType} from "../../../types/PackageType";


export interface OpeningHours {
    openNow: boolean
    periods: null
    weekdayText: string[]
}

const stripePromise = loadStripe(process.env["REACT_APP_STRIPE_PRIVATE_KEY"] as string);
export default function AddRestaurantSellerPage(props: { isRenew: boolean }) {

    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 4 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
    const [nextBtnStates, setNextBtnState] = useState({isEnable: false, isLoading: false})
    var userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType
    const [loading, setLoading] = useState(false)
    const [stripeId, setStripeId] = useState<string>()
    const navigate = useNavigate()
    const [dataStripe, setDataStripe] = useState({
        clientSecret: '',
        idStripe: ''
    });
    const [newLicense, setNewLicense] = useState<License>()
    const [newPack, setNewPack] = useState<PackageType>()
    const [dataToSend, setDataToSend] = useState<AddRestaurantRequest>({
        addressComponents: [],
        clientUserDto: {
            address: "aaaa",
            cf: "",
            city: "b",
            civicNumber: "1",
            email: "",
            idSocial: "",
            name: 'dd',
            nameSocial: "",
            phone: "dddddddddd",
            social: false,
            state: "italia",
            surname: "dd",
            zipCode: "11111"
        },
        companyName: "",
        stripeId:'',
        contactEmail: "",
        description: "",
        foto: "",
        geometry: undefined,
        idClient: "",
        idLicenseType: "",
        inShowcase: false,
        kitchenVisible: false,
        legalForm: "",
        nameRestaurant: "",
        openingHours: undefined,
        phoneNumber: "",
        placeId: "",
        priceLevel: 0,
        rating: 0,
        review: [],
        subscriptionActive: false,
        tags: [],
        vatNumber: "",
        webSite: "",
        seller: 'gicod',
        idPackage: '',
    })


    useEffect(() => {
        if (userData) {
            checkUser(userData.email, (user) => {
                setDataToSend({
                    ...dataToSend, clientUserDto: {
                        ...dataToSend.clientUserDto,
                        address: user.data.address,
                        cf: user.data.cf,
                        city: user.data.city,
                        civicNumber: user.data.civicNumber,
                        email: user.data.email,
                        name: user.data.name,
                        phone: user.data.phone,
                        state: user.data.state,
                        surname: user.data.surname,
                        zipCode: user.data.zipCode,
                    }
                })
            })
        }
    }, []);


    window.onpopstate = () => {
        navigate("/welcome");
    }

    return (
        <div className='flex flex-col items-center mx-2 '>
            <div className={'w-full flex flex-col gap-y-6'}>
                <Stepper color={'yellow'} orientation={isMobile ? 'vertical' : 'horizontal'}
                         className={`mt-10 ${isMobile ? 'px-1 pb-' : 'px-10'}`} active={active}>
                    <Stepper.Step description="Selezione Pacchetti">
                        <SubscritionPlanPage isRenew={props.isRenew} allowNestStep={() => {
                            nextStep()
                        }} onLicenseSelect={(pack, license, allowNextStep) => {
                            if (allowNextStep) {
                                console.log('license', license)
                                setNewLicense(license[0])
                                setNewPack(pack)
                                setDataToSend({...dataToSend, idLicenseType: license[0].id, idPackage: pack!.id})
                            }
                            setNextBtnState({...nextBtnStates, isEnable: allowNextStep})

                        }} isRestaurantAdding={true}/>
                    </Stepper.Step>
                    <Stepper.Step description="Dati Locale">
                        {props.isRenew ? <div className={'w-full h-auto '}>
                            <LicenseRecapComponent license={newLicense!!} imagePack={newPack?.image!!} namePack={newPack?.name!!} />
                        </div> : <RestaurantDataForm data={dataToSend} allowNext={(allowed, localData) => {
                            if (allowed) {
                                var tmpArray: OpeningHours = {
                                    openNow: false,
                                    periods: null,
                                    weekdayText: []
                                }
                                if (localData?.hours) {
                                    Array.from(Object.entries(localData.hours!)).map((item: [string, any]) => {
                                        tmpArray.weekdayText.push(item[1])
                                    })
                                }
                                setDataToSend({
                                    ...dataToSend, companyName: localData?.companyName!,
                                    nameRestaurant: localData?.nameRestaurant!,
                                    legalForm: localData?.legalForm!,
                                    contactEmail: localData?.contactEmail!,
                                    vatNumber: localData?.vatNumber!,
                                    phoneNumber: localData?.phoneNumber!,
                                    webSite: localData?.webSite!,
                                    foto: localData?.foto!,
                                    inShowcase: localData?.isInShowcase!,
                                    kitchenVisible: localData?.kitchenVisible!,
                                    description: localData?.description!,
                                    //da qui campi google
                                    review: localData?.review, //non mostra
                                    rating: localData?.rating,//non mostra
                                    priceLevel: localData?.priceLevel,//non mostra
                                    placeId: localData?.place_id, //non mostra
                                    openingHours: localData?.hours ? tmpArray : localData?.openingHours,
                                    geometry: localData?.geometry, //non mostra
                                    addressComponents: localData?.addressComponent,
                                })
                            }
                            setNextBtnState({...nextBtnStates, isEnable: allowed})
                        }}/>}
                    </Stepper.Step>
                    {userData ? null : <Stepper.Step description="Dati Cliente">
                        <SearchClientDataForm readonly={true} allowNext={(allowed, client) => {
                            if (allowed) {
                                setDataToSend({...dataToSend, clientUserDto: client})
                            }
                            setNextBtnState({...nextBtnStates, isEnable: allowed})
                        }} data={dataToSend}/>

                    </Stepper.Step>}
                    <Stepper.Step description="Pagamento">
                        {newLicense?.price === 0 ?
                            <div className={'flex flex-col w-full h-full justify-center items-center'}>
                                <div className={'w-full h-full flex flex-col  items-center pt-44 '}>
                                    <div className="flex flex-col gap-y-4">
                                        <p className={'w-full h-auto text-4xl font-poppins-bold text-center  '}>{'Benvenuto su MENUS ' + dataToSend.nameRestaurant + '!'}</p>
                                        <p className={'w-full h-auto text-2xl font-poppins-bold text-center pb-32'}>Il
                                            nostro viaggio inizia qui...</p>
                                    </div>
                                </div>
                                <div className={'w-full h-full flex items-center justify-center'}>
                                    <CustomButton title={"Attiva periodo di prova"} onClick={() => {
                                        makeRequest<Map<string, string>>(`/users/restaurants/enableFreeTrial/${stripeId?stripeId:JSON.parse(localStorage.getItem('selectedRestaurant')!).stripeId}`, HttpMethodsEnum.GET).then((res) => {
                                            // @ts-ignore
                                            if (res.isSuccessfull()) {
                                                if (userData && userData.role.includes('ADMIN')) {
                                                    navigate('/admin')
                                                } else {
                                                    navigate('/welcome')
                                                }
                                                window.location.reload()
                                            }
                                        })
                                    }} enable={true} isLoading={false}/>
                                </div>
                            </div> : stripeId? <Elements stripe={stripePromise} options={dataStripe}>


                                <div
                                    className={`${isMobile ? 'w-full h-full flex flex-col justify-center items-center' : 'w-full h-full flex justify-center items-center my-5 gap-x-5'} `}>

                                    <div
                                        className={`${isMobile ? 'w-full h-full border-2 rounded-xl items-center justify-center gap-y-5 p-5 border-black shadow-2xl' : 'w-[30%] h-full flex flex-col  border-2 rounded-xl items-center justify-center gap-y-2 p-5 border-black shadow-2xl'} `}>
                                            <h1 className="font-poppins-bold text-2xl text-center">RIEPILOGO
                                                PAGAMENTO</h1>
                                        <h2  className={`${isMobile ? 'font-poppins-semi-bold text-lg  text-center ':'font-poppins-semi-bold text-xl whitespace-normal'}`}>

                                                il tuo nuovo piano di abbonamento comprende
                                            </h2>
                                            <h1 className={`${isMobile? 'text-center font-poppins-bold text-xl whitespace-break-spaces':'font-poppins-bold whitespace-normal text-xl'}`}>
                                                {'durata abbonamento - ' + (newLicense?.duration ? formatStringDuration(newLicense.duration) : 'N/A')}
                                            </h1>

                                            <div className="flex w-full justify-between items-center mt-10">
                                                <h1 className="font-poppins-bold text-xl">{newLicense?.name}</h1>
                                                <h1 className="font-poppins-semi-bold text-2xl">
                                                    {'€' + (newLicense?.price ? newLicense.price.toFixed(2) : 'N/A')}
                                                </h1>
                                            </div>

                                            <div className="flex w-full justify-between items-center">
                                                <h1 className="font-poppins-bold text-xl">IVA (22%)</h1>
                                                <h1 className="font-poppins-semi-bold text-2xl mb-3">
                                                    {'€' + (newLicense?.price ? (newLicense.price * 0.22).toFixed(2) : 'N/A')}
                                                </h1>
                                            </div>

                                            <Divider size="md" color={'dark'} />

                                            <div className="flex w-full justify-between items-center mt-3">
                                                <h1 className="font-poppins-bold text-xl">Totale</h1>
                                                <h1 className="font-poppins-semi-bold text-2xl">
                                                    {'€' + (newLicense?.price ? (newLicense.price + newLicense.price * 0.22).toFixed(2) : 'N/A')}
                                                </h1>
                                            </div>
                                        </div>



                                    <div className={`${isMobile ? 'w-full h-full shadow-lg p-2 rounded-xl border-2 border-black mt-3':'w-[70%] h-full flex  rounded-xl shadow-2xl'}`}>
                                            <CheckoutformRenew
                                                idLicense={newLicense?.id!!}
                                                idPackage={dataToSend.idPackage}
                                                idStripe={stripeId ? stripeId : JSON.parse(localStorage.getItem('selectedRestaurant')!).stripeId}
                                            />
                                        </div>
                                    </div>

                            </Elements>:null}


                    </Stepper.Step>

                </Stepper>
                {active !== 0 ?
                    <Group className={` ${isMobile ? 'px-5 pt-30' : 'px-20 pt-20'} mb-8`} justify="space-between"
                           mt="xl">
                        <CustomButton title={"Indietro"} onClick={() => {
                            if (active === 0) {
                                navigate('/welcome')
                            } else {
                                prevStep()
                            }

                        }} enable={true} isLoading={false}/>
                        {userData && active===2?null:!userData && active===3?null:
                        <CustomButton title={"Avanti"} onClick={() => {
                            if (userData ? active === 1 : active === 2) {
                                setLoading(true)
                                if (props.isRenew) {
                                        setLoading(false)
                                            var obj = {
                                                ammountCent: (newLicense?.price! + (newLicense?.price! * 22)/100)*100 ,
                                                dataExtra: {},
                                                description: newLicense?.description == null ? '' : newLicense?.description,
                                                stripeId: stripeId?stripeId:JSON.parse(localStorage.getItem('selectedRestaurant')!).stripeId,
                                                nameRestaurant: dataToSend.nameRestaurant
                                            }

                                            if (newLicense?.price !== 0) {
                                                makeRequest<Map<string, string>>('/users/restaurants/client_secret', HttpMethodsEnum.POST, obj).then((res) => {
                                                    setStripeId(JSON.parse(localStorage.getItem('selectedRestaurant')!).stripeId)
                                                    // @ts-ignore
                                                    setDataStripe({clientSecret: res.data.clientSecret, idStripe: res.data.idStripe});
                                                    nextStep()
                                                })
                                            } else {

                                                nextStep()
                                            }
                                } else {
                                    stripeRegistration(dataToSend,(response)=>{
                                        setLoading(false)

                                        setStripeId(response.data.stripeId)
                                        var obj = {
                                            ammountCent: (newLicense?.price! + (newLicense?.price! * 22)/100)*100 ,
                                            dataExtra: {},
                                            description: newLicense?.description == null ? '' : newLicense?.description,
                                            stripeId: response.data.stripeId,
                                            nameRestaurant: dataToSend.nameRestaurant
                                        }
                                        if (newLicense?.price !== 0) {
                                            makeRequest<Map<string, string>>('/users/restaurants/client_secret', HttpMethodsEnum.POST, obj).then((res) => {
                                                // @ts-ignore
                                                setDataStripe({clientSecret: res.data.clientSecret, idStripe: res.data.idStripe});
                                                // @ts-ignore
                                                sessionStorage.setItem('registrationData',JSON.stringify({...dataToSend, stripeId:res.data.idStripe}))
                                                nextStep()
                                            })
                                        } else {
                                            nextStep()
                                        }
                                    })

                                }

                            } else {
                                nextStep()
                            }
                        }} enable={nextBtnStates.isEnable} isLoading={loading}/>}
                    </Group> : null}
            </div>

        </div>

    );


}
