import React, { useState } from 'react';
import { TextInput, Button } from '@mantine/core';

interface ChatInputProps {
    onSend: (message: string) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSend }) => {
    const [message, setMessage] = useState('');

    const handleSend = () => {
        if (message.trim() !== '') {
            onSend(message);
            setMessage('');
        }
    };

    return (
        <div className="flex items-center p-4 bg-gray-200 rounded-b-2xl">
            <TextInput
                className="flex-grow mr-2 rounded-2xl"
                value={message}
                onKeyPress={(k)=>{
                    if (k.code==='Enter'){
                        setMessage(k.currentTarget.value)
                        handleSend()
                    }
                }}
                onChange={(e) => setMessage(e.currentTarget.value)}
                placeholder="Scrivi il tuo messaggio..."
            />
            <Button
                variant="gradient"
                gradient={{ from: '#FFCC00', to: 'yellow', deg: 150 }}
                onClick={handleSend}
            >
             Invio
            </Button>
        </div>

    );
};

export default ChatInput;
