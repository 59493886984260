import {HttpMethodsEnum} from "../HttpMethodsEnum";
import {makeRequest} from "../RestAdapter";
import {RegistrationRequestType} from "../../types/RegistrationRequestType";
import {LoginResponseType} from "../../types/LoginResponseType";
import {ChangeTmpPassRequestType} from "../../types/ChangeTmpPassRequestType";
import {showFailNotification, showSuccessNotification} from "../../utils/NotificationUtils";


export function registrationClient(request: RegistrationRequestType, onFinish: (response: any) => void) {
    makeRequest(`/users/user/client`, HttpMethodsEnum.POST, request).then((res) => {
        onFinish(res.data)

    })
}

export function changeUserData(userId: string, request: RegistrationRequestType, onFinish: (response: LoginResponseType | null) => void) {
    makeRequest<LoginResponseType>(`/users/user/client-update/${userId}`, HttpMethodsEnum.PUT, request).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data)
            showSuccessNotification('Registrazione', 'avvenuta con successo controlla la tua casella di posta', 3000)
        } else {
            onFinish(null)
            // @ts-ignore
            showFailNotification('Errore', res.data.message, 3000)
        }


    })
}


export function login(headers: Headers, onFinish: (response: LoginResponseType | null) => void) {
    makeRequest<LoginResponseType>(`/users/login`, HttpMethodsEnum.POST, null, headers).then((res) => {
        if (res.data !== null && res.status === 200) {
            onFinish(res.data)
        } else {
            onFinish(null)
            // @ts-ignore
            showFailNotification('Errore', res.data.message, 3000)
        }
    })
}

export function loginPreprod(authSHA:string, onFinish: (response: any) => void) {
    makeRequest<LoginResponseType>(`/users/login/${authSHA}`, HttpMethodsEnum.POST).then((res) => {
        if (res.status === 200) {
            onFinish(res.data)
        } else {
            // @ts-ignore
            showFailNotification('Errore', res.data.message, 3000)
        }
    })
}


export function changeTmpPass(request: ChangeTmpPassRequestType, onFinish: (response: any) => void) {
    makeRequest<any>(`/users/password/change`, HttpMethodsEnum.PUT, request).then((res) => {
        onFinish(res)

    })
}


export function getUserInfo(email: string, onFinish: (response: LoginResponseType | null) => void) {
    makeRequest<LoginResponseType>(`/users/user/${email}`, HttpMethodsEnum.GET).then((res) => {
        if (res.isSuccessfull()) onFinish(res.data)

    })
}


export function recoveryPass(request: string, onFinish: (response: any) => void) {
    makeRequest<any>(`/users/password/recovery?email=${request}`, HttpMethodsEnum.PUT).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res)
            showSuccessNotification('Succes', 'Controlla la tua casella di posta', 3000)
        } else {
            onFinish(null)
            showFailNotification('Errore', res.data.message, 3000)
        }
    })
}















