import ImageLogoMenus from "../../components/ImageLogoMenus";
import RestaurantSelect from "./RestaurantSelect";

export default function HeaderAdminComponent() {

    return (
        <div>

            <header
                className={`h-[85px] bg-black w-full flex items-center shadow  justify-center px-10 absolute`}>
                <div className={'w-full flex gap-x-10 items-center justify-between'}>

                    <div className={'flex items-center gap-x-3'}>
                        <div className={'w-[450px] '}>
                            <ImageLogoMenus/>
                        </div>
                    </div>

                    <RestaurantSelect/>

                </div>

            </header>

        </div>
    )
}
