import iconService from "../assets/service-icon-detail.svg";
import {LocalType} from "../types/LocalType";

export default function ServiceDetailPage(props: { local: LocalType }) {
    return (
        <div className={'w-full '}>
            <div className={'w-full h-14 bg-black p-2 rounded-xl pl-2'}>
                <div className={'flex items-center h-full'}>
                    <img src={iconService} className={'w-8'}/>
                    <h1 className={'font-bold text-amber-400 text-lg ml-2'}>Servizi</h1>

                </div>
            </div>
            <div className={'w-full flex flex-col  justify-center  px-4 pt-4'}>
                <div className={'gap-y-5 flex flex-col'}>
                    {props.local ? props.local.tags.map((item) => (
                        <div key={item.id} className={'flex items-center gap-x-3'}>
                            <div className={'w-1 h-1 bg-black rounded-full'}></div>
                            <h1 className={'font-poppins-semi-bold'}>{item.tag}</h1>
                        </div>
                    )) : null}
                </div>
            </div>
        </div>
    )
}
