import React, {useEffect, useState} from "react";
import {Drawer, TextInput} from "@mantine/core";
import {PrinterType} from "../types/PrinterType";
import {ReactComponent as CustomLoaderIcon} from "../assets/loader-white.svg";
import {showFailNotification} from "../utils/NotificationUtils";
import {getPrintersByUser} from "../network/controllers/PrinterController";
import PrinterDrawerContent from "../components/PrinterDrawerContent";

import CustomButton from "../components/CustomButton";
import {useNavigate} from "react-router-dom";
import {isMobile} from "react-device-detect";
import PrinterAdminTable from "./admin/PrinterAdminTable"; // import useNavigate hook

export default function PrinterPage() {
    const [printerSelected, setPrinterSelected] = useState<PrinterType | null>(null);
    const [printerList, setPrinterList] = useState<PrinterType[]>([]);
    const [originalPrinterList, setOriginalPrinterList] = useState<PrinterType[]>([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // initialize navigate

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userData') as string);
        if (!user || !user.id) {
            showFailNotification('Errore', 'Utente non trovato', 3000);
            setLoading(false);
            return;
        }

        setLoading(true);
        getPrintersByUser(user.id, (response) => {
            setLoading(false);
            setPrinterList(response);
            setOriginalPrinterList(response);
        });
    }, []);

    useEffect(() => {
        if (searchTerm === '') {
            setPrinterList(originalPrinterList);
        } else {
            const tmpPrinters: PrinterType[] = originalPrinterList.filter((p: PrinterType) =>
                p.printerName.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setPrinterList(tmpPrinters);
        }
    }, [searchTerm]);


if (isMobile){
    return (
        <div className="w-full h-full flex-col gap-y-3 pt-[130px] ">
            <Drawer
                position={"bottom"}
                size={"100%"}
                styles={{
                    content: {backgroundColor: 'black'},
                    header: {backgroundColor: 'black'}
                }}
                zIndex={40}
                opened={openDrawer}
                onClose={() => {
                    setPrinterSelected(null);
                    setOpenDrawer(false);
                }}
            >
                <PrinterDrawerContent printerType={printerSelected!}/>
            </Drawer>
            <div className="w-full h-auto flex flex-col">
                <div className="rounded-md w-full h-full mx-2">
                    <TextInput
                        classNames={{
                            label: 'labelStyleBlack',
                            input: 'inputStyle',
                            wrapper: 'wrapperStyle'
                        }}
                        label={"Cerca stampante"}
                        placeholder={"Cerca..."}
                        value={searchTerm}
                        onChange={(res) => {
                            setSearchTerm(res.currentTarget.value);
                        }}
                    />
                </div>

                <div className="w-full flex justify-between mt-5 ">
                    <div className=" w-auto flex justify-items-center flex-col">
                        <span className="w-5 h-5 bg-lime-400 rounded-full border-[1px] border-white"></span>
                        <p className="font-poppins-regular text-sm">SPEDITO</p>
                    </div>
                    <div className="w-auto flex justify-items-center flex-col">
                        <span className="w-5 h-5 bg-amber-400 rounded-full border-[1px] border-white"></span>
                        <p className=" font-poppins-regular text-sm">IN LAVORAZIONE</p>
                    </div>
                    <div className="w-auto flex justify-items-center flex-col">
                        <span className="w-5 h-5 bg-blue-900 rounded-full border-[1px] border-white"></span>
                        <p className=" font-poppins-regular text-sm">COMPLETATO</p>
                    </div>
                </div>
            </div>

            {loading ? (
                <div className="w-full flex justify-center items-center h-full">
                    <CustomLoaderIcon width="120" height="120"/>
                </div>
            ) : (
                <PrinterAdminTable printersList={printerList}
                                   onClickItem={(printer) => {
                                       setPrinterSelected(printer);
                                       setOpenDrawer(true);
                                   }}
                />
            )}

            <div className="w-full h-auto justify-center items-center mt-32">
                <CustomButton title={'Vai allo Shop'} onClick={() => navigate('/admin/shop')}
                              enable={true} isLoading={false} customStyles={' mt- justify-center'}/>
            </div>
        </div>
    );
}else{
    return (
        <div className="w-full h-full flex-col px-20  relative pt-[130px]">
            <Drawer
                position={"right"}
                size={"50%"}
                styles={{
                    content: {backgroundColor: 'black'},
                    header: {backgroundColor: 'black'}
                }}
                zIndex={40}
                opened={openDrawer}
                onClose={() => {
                    setPrinterSelected(null);
                    setOpenDrawer(false);
                }}
            >
                <PrinterDrawerContent printerType={printerSelected!}/>
            </Drawer>
            <div className="w-full flex justify-between">
                <div className="rounded-md w-[300px] h-full">
                    <TextInput
                        classNames={{
                            label: 'labelStyleBlack',
                            input: 'inputStyle',
                            wrapper: 'wrapperStyle'
                        }}
                        label={"Cerca stampante"}
                        placeholder={"Cerca..."}
                        value={searchTerm}
                        onChange={(res) => {
                            setSearchTerm(res.currentTarget.value);
                        }}
                    />
                </div>

                <div className="flex items-center justify-end text-center w-full h-auto space-x-4">
                    <div className="flex items-center">
                        <span className="w-5 h-5 bg-lime-400 rounded-full border-[1px] border-white"></span>
                        <p className="ml-2 font-poppins-regular">SPEDITO</p>
                    </div>
                    <div className="flex items-center">
                        <span className="w-5 h-5 bg-amber-400 rounded-full border-[1px] border-white"></span>
                        <p className="ml-2 font-poppins-regular">IN LAVORAZIONE</p>
                    </div>
                    <div className="flex items-center">
                        <span className="w-5 h-5 bg-blue-900 rounded-full border-[1px] border-white"></span>
                        <p className="ml-2 font-poppins-regular">COMPLETATO</p>
                    </div>
                </div>
            </div>

            {loading ? (
                <div className="w-full flex justify-center items-center h-full">
                    <CustomLoaderIcon width="120" height="120"/>
                </div>
            ) : (
                <PrinterAdminTable printersList={printerList}
                                   onClickItem={(printer) => {
                                       setPrinterSelected(printer);
                                       setOpenDrawer(true);
                                   }}
                />
            )}

            <div className="fixed bottom-10 right-10">
                <CustomButton title={'Vai allo Shop'} onClick={() => navigate('/admin/shop')}
                              enable={true} isLoading={false}/>
            </div>
        </div>
    );
}}
