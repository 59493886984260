import {useEffect, useRef, useState} from "react";
import {Loader} from "@googlemaps/js-api-loader";
import {TextInput} from "@mantine/core";
import {getPlaceIds} from "../network/controllers/RestaurantsControllers";


let placesApiClient: google.maps.PlacesLibrary | undefined;
export default function GoogleAddressSearch(props: {
    classname?: string,
    setNameTextInput: string,
    value?:string
    label?: string,
    width?: string,
    height?: string
    defaultOpen?: boolean,
    inputDimensions?: string
    suggestionDimensions?: string
    inputPlaceholder?: string
    isBlack?: boolean,
    filterSuggestions?: boolean,
    searchSuggestions?: boolean,
    onPlaceSelect: (place: any) => void
    white?:boolean
    required ? : boolean
}) {

    const [value, setValue] = useState(props.value?props.value:"");
    const [fetchingPredictions, setFetchingPredictions] = useState(false);
    const [fetchingDetails, setFetchingDetails] = useState(false);
    const [suggestions, setSuggestions] = useState<any[]>([]);
    const sessionTokenRef = useRef<google.maps.places.AutocompleteSessionToken>();
    const timeoutRef = useRef<NodeJS.Timeout>();
    async function getGoogleMapsPlacesApiClient(): Promise<google.maps.PlacesLibrary> {
        if (placesApiClient) {
            return placesApiClient;
        }
        const loader = new Loader({
            apiKey: 'AIzaSyDe8XjD2lGk6YeJEYcfaakoyoPzi-zkk3U',
            version: "weekly",
        });
        placesApiClient = await loader.importLibrary("places");
        return placesApiClient;
    }
    const [placeIdList,setPlaceIdList] = useState<string[]>([]);


    useEffect(() => {
        if (props.filterSuggestions || props.searchSuggestions){
            getPlaceIds((response)=>{
                setPlaceIdList(response);
            })
        }
    }, []);

    const handleSuggestionSelected = async (suggestion: any) => {
        setSuggestions([]);

        const places = await getGoogleMapsPlacesApiClient();

        const sessionToken = sessionTokenRef.current;
        sessionTokenRef.current = undefined;

        setFetchingDetails(true);

        // @see https://developers.google.com/maps/documentation/javascript/places
        new places.PlacesService(
            document.getElementById(
                "googlemaps-attribution-container"
            ) as HTMLDivElement
        ).getDetails(
            {
                placeId: suggestion.place_id,
                fields: [
                    // @see https://developers.google.com/maps/documentation/javascript/place-data-fields
                    'address_components',
                    "formatted_address",
                    "name",
                    "place_id",
                    "geometry.location",
                    'rating',
                    'review',
                    'website',
                    'price_level',
                    'opening_hours',
                    'formatted_phone_number'
                ],
                sessionToken,
            },
            (place: any, status: any) => {
                setFetchingDetails(false);
                if (status === places.PlacesServiceStatus.OK) {
                    props.onPlaceSelect(place)

                    if (props.setNameTextInput === 'name') {
                        setValue(place.name);
                    }
                    if (props.setNameTextInput === 'formatted_address') {
                        setValue(place.formatted_address);
                    }

                }
            }
        );
    };
    const handleChange = (value: string) => {
        const newValue = value
        setValue(newValue);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        if (!newValue || newValue.trim().length <= 3) {
            setSuggestions([]);
            return;
        }

        timeoutRef.current = setTimeout(async () => {
            const places = await getGoogleMapsPlacesApiClient();

            if (!sessionTokenRef.current) {
                sessionTokenRef.current = new places.AutocompleteSessionToken();
            }

            setFetchingPredictions(true);
            // @see https://developers.google.com/maps/documentation/javascript/place-autocomplete
            new places.AutocompleteService().getPlacePredictions(
                {

                    input: newValue,
                    sessionToken: sessionTokenRef.current,
                },
                (predictions: any, status: any) => {

                    setFetchingPredictions(false);
                    if (status === places.PlacesServiceStatus.ZERO_RESULTS) {
                        setSuggestions([]);
                        return;
                    }
                    if (status !== places.PlacesServiceStatus.OK || !predictions) {
                        alert(status);
                        return;
                    }

                    setSuggestions(predictions);
                }
            );
        }, 350);
    };
    return (
        <div className={`w-full flex flex-col h-auto items-center justify-center  ` + props.classname}>
            {props.label?<div className={'w-full justify-start'}>
                <div className={'flex'}>
                <p className={props.isBlack ? 'labelStyleBlack' : 'labelStyle'}>{props.label}</p>
                    {props.required? <p className={'text-red-700 ml-1'}>*</p>:null}
                </div>
                </div>:null}
            <div className={'w-full'}>
                <div className={` rounded-md   ${props.inputDimensions !== '' ? props.inputDimensions : 'w-full'}`}>
                    <TextInput
                        classNames={{
                            input: 'inputStyle',
                            wrapper: props.white?'wrapperStyleWhite':'wrapperStyle'
                        }}
                        readOnly={false}
                        value={value}
                        placeholder={props.inputPlaceholder ? props.inputPlaceholder : ''}
                        onChange={(res) => {
                            handleChange(res.currentTarget.value)
                        }}
                    />
                </div>
            </div>

            <div className={`${props.suggestionDimensions ? props.suggestionDimensions : 'w-full max-h-[150px]'} ${props.defaultOpen !== undefined && !props.defaultOpen ? 'h-auto max-h-[200px]' : 'h-[200px]'} flex rounded-2xl flex-col overflow-auto mt-1`}>
                {
                    suggestions.map((item) =>(
                        <div className={`w-full bg-[#D9D9D9] ${props.searchSuggestions && !placeIdList.includes(item.place_id)?'hidden':''} hover:bg-yellow p-2 cursor-pointer `}>
                            <h1 className={`${props.filterSuggestions ? placeIdList.includes(item.place_id) ? 'text-gray-400' : '' : ''} w-full whitespace-nowrap font-poppins-semi-bold  truncate  `}
                               onClick={() => {
                                   if (props.filterSuggestions) {
                                       if (!placeIdList.includes(item.place_id)) {
                                           handleSuggestionSelected(item)
                                       }
                                   } else {
                                       handleSuggestionSelected(item)
                                   }

                               }}>{props.filterSuggestions ? placeIdList.includes(item.place_id) ? item.description + `  (Locale Già Registrato)` : item.description : item.description}</h1>
                        </div>
                    ))
                }
            </div>

            <div id="googlemaps-attribution-container"></div>
        </div>
    )
}

