import CustomTextInput from "../components/generic/CustomTextInput";
import {useEffect, useState} from "react";
import imgBg from "../assets/foto-mulincianeddi.webp";
import {BackgroundImage, Checkbox} from "@mantine/core";
import ImageLogoMenus from "../components/ImageLogoMenus";
import CustomButton from "../components/CustomButton";
import {useNavigate} from "react-router-dom";
import {RegistrationRequestType} from "../types/RegistrationRequestType";
import {registrationClient} from "../network/controllers/LoginApiController";
import GoogleAddressSearch from "../components/GoogleAddressSearch";
import {isMobile} from "react-device-detect";
import {validateEmail} from "../utils/StringUtils";
import {showSuccessNotification} from "../utils/NotificationUtils";
import {UbendaRequestType} from "../types/UbendaRequestType";
import {isTablet} from "../utils/WindowDimensionUtils";
import logo from "../assets/logo-menus.webp";
import logoMobile from "../assets/logoMobile.svg";






export default function RegistrationPage() {
    const [loading, setLoading] = useState(false);
    const [registrationData, setRegistrationData] = useState<RegistrationRequestType>({
        address: "",
        cf: "",
        city: "",
        civicNumber: "",
        email: "",
        idSocial: "",
        name: "",
        nameSocial: "",
        phone: "",
        social: false,
        state: "",
        surname: "",
        zipCode: ""
    })
    const [allowPermission, setAllowPermission] = useState<boolean>(false)
    const [allowNewsLetter, setAllowNewsLetter] = useState<boolean>(false)

    const [ip, setIp] = useState('');

    useEffect(() => {
        const fetchIp = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                setIp(data.ip);
            } catch (error) {
                console.error('Errore nel recuperare l\'indirizzo IP', error);
            }
        };

        fetchIp();
    }, []);

    function retrieveAddressData(addressData: any) {
        var tmpdata = registrationData
        addressData.forEach((item: any) => {
            if (item.types.includes('route')) {
                tmpdata.address = item.long_name
            }
            if (item.types.includes('locality')) {
                tmpdata.city = item.long_name
            }
            if (item.types.includes('country')) {
                tmpdata.state = item.long_name
            }
            if (item.types.includes('postal_code')) {
                tmpdata.zipCode = item.long_name
            }
            if (item.types.includes('street_number')) {
                tmpdata.civicNumber = item.long_name
            }
            setRegistrationData({
                ...registrationData,
                address: tmpdata.address,
                city: tmpdata.city,
                state: tmpdata.state,
                zipCode: tmpdata.zipCode,
                civicNumber: tmpdata.civicNumber,
            })


        })
    }

    var navigate = useNavigate();

    if (isMobile) {
        return (
            <BackgroundImage className={'w-auto h-screen flex item-center justify-center'}
                             src={imgBg}>
                <div className={'w-auto h-full flex justify-center '}>
                    <div className={`w-auto h-full flex flex-col'`}>
                        <div className={'w-auto h-auto flex flex-col items-center gap-y-5 '}>
                            <div className={'w-auto h-auto'}>
                                <img onClick={() => {
                                    navigate('/')
                                }}
                                     className={`w-auto h-[85px] cursor-pointer py-3`}
                                     alt={logo}
                                     src={logo + `?timestamp=${new Date().getMilliseconds()}`}/>
                            </div>
                            <CustomTextInput customDimensions={'w-[350px] h-[50px]'} label={'Nome'} isPassword={false}
                                             defaultValue={registrationData.name}
                                             onChange={(value) =>
                                                 setRegistrationData({...registrationData, name: value})
                                             }/>

                            <CustomTextInput customDimensions={'w-[350px]  h-[50px]'} label={'Cognome'}
                                             defaultValue={registrationData.surname}
                                             onChange={(value) =>
                                                 setRegistrationData({...registrationData, surname: value})
                                             }/>
                            <CustomTextInput customDimensions={'w-[350px]  h-[50px]'} label={'Email'}
                                             defaultValue={registrationData.email}
                                             onChange={(value) =>
                                                 setRegistrationData({...registrationData, email: value})
                                             }/>

                            <CustomTextInput customDimensions={'w-[350px]  h-[50px]'} label={'Telefono'}
                                             defaultValue={registrationData.phone}
                                             onChange={(value) =>
                                                 setRegistrationData({...registrationData, phone: value})
                                             }/>

                            <div className={'w-full mt-2 '}>
                                <GoogleAddressSearch defaultOpen={false}
                                                     inputDimensions={'w-[350px]  h-[50px]'}
                                                     suggestionDimensions={'w-[350px] '}
                                                     setNameTextInput={'formatted_address'} width={'100px'}
                                                     label={'Indirizzo'}
                                                     onPlaceSelect={(v) => {
                                                         retrieveAddressData(v.address_components)

                                                     }}/>

                            </div>

                            <div
                                className={'w-full flex flex-col gap-y-2 bg-[#F2F2F2A8] rounded-xl p-2 items-start pl-4 py-5'}>
                                <div className={'w-full flex  '}>
                                    <div className={'w-auto flex '}>
                                        <Checkbox color={'#FFCC00'} checked={allowPermission} onChange={() => {
                                            setAllowPermission(!allowPermission)
                                        }}/>
                                        <a className={'w-full font-poppins-bold ml-2 underline text-black'}
                                           href="https://www.iubenda.com/termini-e-condizioni/86809145" target="_blank">Accetto
                                            termini e condizioni d'uso </a>
                                    </div>

                                </div>

                                <div className={'w-full flex mt-2  '}>
                                    <div className={'w-auto flex '}>
                                        <Checkbox color={'#FFCC00'} checked={allowNewsLetter} onChange={() => {
                                            setAllowNewsLetter(!allowNewsLetter)
                                        }}/>
                                        <a className={'w-full font-poppins-semi-bold ml-2 '}>Iscriviti alla
                                            Newsletter</a>
                                    </div>

                                </div>
                            </div>


                            <div className={''}>
                                <CustomButton title={'Registrati'} onClick={() => {
                                    setLoading(true)
                                    registrationClient(registrationData, (response) => {
                                        showSuccessNotification('Registrazione', 'avvenuta con successo controlla la tua casella di posta', 3000)
                                        if (response) {
                                            var header = new Headers()
                                            var request: UbendaRequestType = {
                                                ip_address: ip,
                                                legal_notices: [{
                                                    identifier: 'privacy_policy'
                                                }],
                                                preferences: {
                                                    newsletter: allowNewsLetter
                                                },
                                                subject: {
                                                    id: response.id,
                                                    email: registrationData.email,
                                                    verified: true,
                                                    first_name: registrationData.name,
                                                    last_name: registrationData.surname,
                                                }

                                            }
                                            header.append('ApiKey', '8RB9wcfzUIpMMfuamGAW7a5bU8pUHamw')
                                            header.append('Content-Type', 'application/json')
                                            fetch('https://consent.iubenda.com/consent', {
                                                method: "POST",
                                                body: JSON.stringify(request),
                                                headers: header
                                            }).then(async (res) => {
                                                if (res.status === 200) {

                                                } else {
                                                }
                                            });
                                            setTimeout(() => {
                                                navigate('/login')
                                            }, 2500)
                                        }


                                    })
                                }}
                                              enable={registrationData.phone !== '' && registrationData.name !== '' && registrationData.surname !== '' && validateEmail(registrationData.email) && registrationData.address !== '' && allowPermission}
                                              isLoading={loading}/>

                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
        );
    } else {
        return (
            <BackgroundImage className={'w-full h-full overflow-y-auto'} src={imgBg}>
                <div className={'w-full h-full flex  justify-end'}>
                    <div className={`w-[500px] h-full flex flex-col py-20 mr-20`}>
                        <div className={'w-full flex flex-col items-center gap-y-6'}>
                            <div className={'w-full max-h-[100px] flex justify-center items-center '}>
                                <ImageLogoMenus/>
                            </div>
                            <h1 className={'text-white text-3xl font-poppins-semi-bold'}>{'Registrati!'}</h1>
                            <CustomTextInput required={true} customDimensions={'w-full h-[60px]'} label={'Nome'} isPassword={false}
                                             defaultValue={registrationData.name}
                                             onChange={(value) =>
                                                 setRegistrationData({...registrationData, name: value})
                                             }/>

                            <CustomTextInput required={true} customDimensions={'w-full h-[60px]'} label={'Cognome'}
                                             defaultValue={registrationData.surname}
                                             onChange={(value) =>
                                                 setRegistrationData({...registrationData, surname: value})
                                             }/>
                            <CustomTextInput required={true} customDimensions={' w-full h-[60px]'} label={'Email'}
                                             defaultValue={registrationData.email}
                                             onChange={(value) =>
                                                 setRegistrationData({...registrationData, email: value})
                                             }/>

                            <CustomTextInput required={true} customDimensions={'w-full h-[60px]'} label={'Telefono'}
                                             defaultValue={registrationData.phone}
                                             onChange={(value) =>
                                                 setRegistrationData({...registrationData, phone: value})
                                             }/>

                            <div className={'w-full mt-1'}>
                                <GoogleAddressSearch defaultOpen={false}
                                                 required={true}
                                                 inputDimensions={`w-full h-[60px] `}
                                                 setNameTextInput={'formatted_address'} width={'100px'}
                                                 label={'Indirizzo'}
                                                 onPlaceSelect={(v) => {
                                                     retrieveAddressData(v.address_components)

                                                 }}/>

                            </div>
                            <div
                                className={'w-full flex flex-col gap-y-2 bg-[#F2F2F2A8] rounded-xl p-2 items-start pl-4 py-5'}>
                                <div className={'w-full flex  '}>
                                    <div className={'w-auto flex '}>
                                        <Checkbox color={'#FFCC00'} checked={allowPermission} onChange={() => {
                                            setAllowPermission(!allowPermission)
                                        }}/>
                                        <a className={'w-full font-poppins-bold ml-2 underline text-black'}
                                           href="https://www.iubenda.com/termini-e-condizioni/86809145" target="_blank">Accetto
                                            termini e condizioni d'uso </a>
                                    </div>

                                </div>

                                <div className={'w-full flex mt-2  '}>
                                    <div className={'w-auto flex '}>
                                        <Checkbox color={'#FFCC00'} checked={allowNewsLetter} onChange={() => {
                                            setAllowNewsLetter(!allowNewsLetter)
                                        }}/>
                                        <a className={'w-full font-poppins-semi-bold ml-2 '}>Iscriviti alla
                                            Newsletter</a>
                                    </div>

                                </div>
                            </div>

                            <div className={'mb-5'}>
                                <CustomButton title={'Registrati'} onClick={() => {
                                    setLoading(true)
                                    registrationClient(registrationData, (response) => {
                                        showSuccessNotification('Registrazione', 'avvenuta con successo controlla la tua casella di posta', 3000)
                                        if (response) {
                                            var header = new Headers()
                                            var request: UbendaRequestType = {
                                                ip_address: ip,
                                                legal_notices: [{
                                                    identifier: 'privacy_policy'
                                                }],
                                                preferences: {
                                                    newsletter: allowNewsLetter
                                                },
                                                subject: {
                                                    id: response.id,
                                                    email: registrationData.email,
                                                    verified: true,
                                                    first_name: registrationData.name,
                                                    last_name: registrationData.surname,
                                                }

                                            }
                                            header.append('ApiKey', '8RB9wcfzUIpMMfuamGAW7a5bU8pUHamw')
                                            header.append('Content-Type', 'application/json')
                                            fetch('https://consent.iubenda.com/consent', {
                                                method: "POST",
                                                body: JSON.stringify(request),
                                                headers: header
                                            }).then(async (res) => {
                                                if (res.status === 200) {

                                                } else {
                                                }
                                            });
                                            setTimeout(() => {
                                                navigate('/login')
                                            }, 2500)
                                        }


                                    })
                                }}
                                              enable={registrationData.phone !== '' && registrationData.name !== '' && registrationData.surname !== '' && validateEmail(registrationData.email) && registrationData.address !== '' && allowPermission}
                                              isLoading={loading}/>
                            </div>

                        </div>
                    </div>
                </div>
            </BackgroundImage>
        );

    }
}
