import {Divider} from '@mantine/core';
import RatingComponent from "./RatingComponent";
import React from "react";
import iconArrow from "../assets/arrow-icon-rating.svg";
import {LoginResponseType} from "../types/LoginResponseType";
import {addComment} from "../network/controllers/DetailController";
import {showSuccessNotification} from "../utils/NotificationUtils";

export function RatingTextInput() {
    const [request, setRequest] = React.useState({
        rating: 0,
        comment: '',
        idLocal: JSON.parse(localStorage.getItem('selectedRestaurantClient') as string).id,
        userId: (JSON.parse(localStorage.getItem('userData')!) as LoginResponseType).id
    });

    return (

        <div className="flex h-full w-full border border-black rounded-2xl my-3 ">
            <div className="flex flex-col w-full h-auto">
                <form>
                    <div className="flex flex-col ml-2 p-1">
                        <input value={request.comment} onChange={(event) => {
                            setRequest({...request, comment: event.target.value})
                        }} className={'outline-none p-1'} type="text" id="recensione"
                               placeholder="Lascia qui la tua recensione" required/>
                    </div>
                </form>
                <Divider/>
                <div className='flex-col pt-1 pb-1 pl-2'>
                    <div>
                        <RatingComponent readonly={false} onChangeValue={(value) => {
                            setRequest({...request, rating: value});
                        }}/>
                    </div>
                </div>
            </div>
            <div className={'w-auto pt-7 px-3 '}>
                <img alt={''} className={`${request.rating > 0 && request.comment !== ''? 'opacity-100 duration-500':'opacity-50 duration-500'} w-6 h-auto hover:scale-125 duration-500 cursor-pointer`} src={iconArrow}
                     onClick={() => {
                         if (request.rating > 0 && request.comment !== '') {
                             addComment(request, (response) => {
                                 if (response !== null) {
                                     showSuccessNotification('Commento', 'Aggiunto con successo', 3000)
                                     window.location.reload()
                                 }

                             })
                         }
                     }}/>
            </div>
        </div>

    );
}



