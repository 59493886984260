import {IngredientType} from "../../../types/IngredientType";
import {useEffect, useRef, useState} from "react";
import {getIngredients} from "../../../network/controllers/admin/IngredientsController";
import GenericTooltip from "../../../components/generic/GenericTooltip";
import CustomTextInput from "../../../components/generic/CustomTextInput";
import CustomButton from "../../../components/CustomButton";
import {getAllCategories} from "../../../network/controllers/admin/CategoryController";
import {Drawer, MultiSelect} from "@mantine/core";
import IngredientDrawerContent from "../IngredientDrawerContent";
import {getAllergeni} from "../../../network/controllers/admin/AllergenController";
import ReactDragListView from "react-drag-listview";
import {CategoryType} from "../../../types/CategoryType";
import loader from '../../../assets/loader-white.svg';
import {isMobile} from "react-device-detect";

export default function StepAddIngredient(props: {
    isBack: boolean;
    categoryId: CategoryType;
    product: any;
    onIngredientSelected: (ingredients: IngredientType[]) => void;
    onAllergenSelect: (allergenList: string[]) => void;
    onDescriptionChange: (description: string) => void;
}) {
    const [ingredientList, setIngredientList] = useState<Map<string, IngredientType>>(new Map());
    const [ingredientListMultiselect, setIngredientListMuliSelect] = useState<{ value: any, label: string }[]>([]);
    const [originalIngredientList, setOriginalIngredientList] = useState<Map<string, IngredientType>>(new Map());
    const [selectedIngredients, setSelectedIngredients] = useState<Map<string, IngredientType>>(new Map());
    const [statusMenu, setStatusMenu] = useState({open: false, content: <div></div>});
    const [categoryMultiSelect, setCategoryMultiSelect] = useState<{ label: any, value: string }[]>([]);
    const [allergenList, setAllergenList] = useState<{ label: any, value: string }[]>([]);
    const [selectedAllergeni, setSelectedAllergeni] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const multiSelectRef = useRef(null);

    useEffect(() => {
        setSelectedAllergeni(props.product.allergens);
    }, [props.product.allergens]);

    useEffect(() => {
        props.onIngredientSelected([])
    }, []);

    useEffect(() => {
        if (searchTerm === "") {
            setIngredientList(originalIngredientList);
            if (multiSelectRef.current) {
                // @ts-ignore
                multiSelectRef.current.focus();
            }
        } else {
            const tmpMap = new Map<string, IngredientType>();
            const arr: IngredientType[] = Array.from(originalIngredientList.values()).filter((ingredient) =>
                ingredient.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            if (arr.length > 0) {
                arr.forEach((item) => {
                    tmpMap.set(item.id!, item);
                });
                setIngredientList(tmpMap);
            } else {
                setIngredientList(new Map());
            }
        }
    }, [searchTerm, originalIngredientList]);

    useEffect(() => {
        setLoading(true);
        getIngredients('', (response) => {
            const tmpMap = new Map<string, IngredientType>();
            const tmpSelected = new Map<string, IngredientType>();
            const multiSelectTmp = new Map<string, { value: any, label: string }>();
            if (props.isBack) {
                if (props.product.ingredients.length > 0) {
                    response.forEach((item) => {
                        props.product.ingredients.forEach((ingredient: IngredientType) => {
                            if (ingredient.id === item.id) {
                                tmpSelected.set(ingredient.id!, ingredient);
                            } else {
                                multiSelectTmp.set(item.id!, {label: item.name, value: JSON.stringify(item)});
                                tmpMap.set(item.id!, item);
                            }
                        });
                    });
                    Array.from(tmpSelected.keys()).forEach((id) => {
                        if (multiSelectTmp.has(id)) {
                            multiSelectTmp.delete(id);
                        }
                    });
                } else {
                    response.forEach((item) => {
                        multiSelectTmp.set(item.id!, {label: item.name, value: JSON.stringify(item)});
                        tmpMap.set(item.id!, item);
                    });
                }
                setSelectedIngredients(tmpSelected);
                setIngredientListMuliSelect(Array.from(multiSelectTmp.values()));
                setOriginalIngredientList(tmpMap);
                setLoading(false);
            } else {
                if (props.product.ingredients.length > 0) {
                    response.forEach((item) => {
                        props.product.ingredients.forEach((ingredient: IngredientType) => {
                            if (ingredient.id === item.id) {
                                tmpSelected.set(ingredient.id!, ingredient);
                            } else {
                                multiSelectTmp.set(item.id!, {label: item.name, value: JSON.stringify(item)});
                                tmpMap.set(item.id!, item);
                            }
                        });
                    });
                    Array.from(tmpSelected.keys()).forEach((id) => {
                        if (multiSelectTmp.has(id)) {
                            multiSelectTmp.delete(id);
                        }
                    });
                } else {
                    response.forEach((item) => {
                        multiSelectTmp.set(item.id!, {label: item.name, value: JSON.stringify(item)});
                        tmpMap.set(item.id!, item);
                    });
                }
                setSelectedIngredients(
                    new Map<string, IngredientType>(
                        Array.from(tmpSelected).sort((a: [string, IngredientType], b: [string, IngredientType]) => {
                            const selectedMenu = JSON.parse(localStorage.getItem('selectedMenu') as string);
                            const keyA = `${selectedMenu.id}--${props.categoryId}--${a[0]}--${props.product.id}`;
                            const keyB = `${selectedMenu.id}--${props.categoryId}--${b[0]}--${props.product.id}`;
                            const positionA = new Map(Object.entries(a[1].position)).get(keyA) as number;
                            const positionB = new Map(Object.entries(b[1].position)).get(keyB) as number;
                            return positionA - positionB;
                        })
                    )
                );
                setIngredientListMuliSelect(Array.from(multiSelectTmp.values()));
                setOriginalIngredientList(tmpMap);
                setLoading(false);
            }
        });
        getAllCategories(JSON.parse(localStorage.getItem('selectedRestaurant') as string).id, (response) => {
            const tmpArr: any[] = [];
            response.map((category) => {
                tmpArr.push({value: JSON.stringify(category), label: category.categoryName});
            });
            setCategoryMultiSelect(tmpArr);
        });
        getAllergeni((res) => {
            const tmpArr: any[] = [];
            res.map((allergen) => {
                tmpArr.push({value: allergen.id, label: allergen.name});
            });
            setAllergenList(tmpArr);
        });
    }, [reload]);

    useEffect(() => {
        if (selectedIngredients && Array.from(selectedIngredients.values()).length > 0) {
            props.onIngredientSelected(Array.from(selectedIngredients.values()));
        }
    }, [selectedIngredients]);

    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            // @ts-ignore
            inputRef.current.focus();
        }
    }, [searchTerm]);

    const onDragEnd = (fromIndex: any, toIndex: any) => {
        if (fromIndex !== toIndex) {
            const updatedItems = [...Array.from(selectedIngredients.values())];
            const item = updatedItems.splice(fromIndex, 1)[0];
            updatedItems.splice(toIndex, 0, item);
            const updatedMap = new Map<string, IngredientType>();
            updatedItems.forEach((ingredient, index) => {
                ingredient.position = new Map();
                if (props.product.id !== undefined) {
                    ingredient.position = new Map(Object.entries(ingredient.position)).set(`${JSON.parse(localStorage.getItem('selectedMenu') as string).id}--${props.categoryId}--${ingredient.id}--${props.product.id}`, index + 1);
                } else {
                    ingredient.position = new Map(Object.entries(ingredient.position)).set(`${JSON.parse(localStorage.getItem('selectedMenu') as string).id}--${props.categoryId}--${ingredient.id}`, index + 1);
                }
                updatedMap.set(ingredient.id!, {...ingredient});
            });
            setSelectedIngredients(updatedMap);
        }
    };


    if (isMobile) {

        return (
            <div className={'w-full h-full flex flex-col items-center justify-center'}>
                <Drawer
                    position={"bottom"}
                    size={'full'}
                    styles={{
                        content: {backgroundColor: 'black'},
                        header: {backgroundColor: 'black'}
                    }}
                    zIndex={40}
                    opened={statusMenu.open}
                    onClose={() => {
                        setStatusMenu({...statusMenu, open: false});
                    }}
                >
                    {statusMenu.content}
                </Drawer>
                <div className={'w-full flex flex-col items-center justify-center gap-x-2'}>
                    <h1 className={'text-xl font-poppins-semi-bold'}>{`Stai Creando`}</h1>
                    <h1 className={'text-xl font-poppins-bold'}>{` ${props.product.nameProduct}`}</h1>
                </div>
                <div className={'w-full flex flex-col items-center justify-center gap-x-5 mt-5'}>
                    <div className={'w-full flex flex-col items-center justify-center '}>
                        <div className={'w-full flex items-center justify-start p-2'}>
                            <GenericTooltip
                                txt={'Se ancora non hai registrato un ingrediente , puoi farlo quì!'}
                                innerHtml={
                                    <div className="w-full flex items-center justify-start p-2">
                                        <GenericTooltip
                                            txt="Se ancora non hai registrato un ingrediente, puoi farlo qui!"
                                            innerHtml={
                                                <div
                                                    className="w-full h-auto flex items-center justify-center cursor-pointer"
                                                    onClick={() => {
                                                        setStatusMenu({
                                                            open: true,
                                                            content: (
                                                                <IngredientDrawerContent
                                                                    ingredient={null}
                                                                    data={categoryMultiSelect}
                                                                    onIngredientChange={(ingredientList) => {
                                                                        setStatusMenu({...statusMenu, open: false});
                                                                        setReload(!reload);
                                                                    }}
                                                                />
                                                            ),
                                                        });
                                                    }}
                                                >
                                                    <CustomButton
                                                        title="Crea ingrediente"
                                                        small={true}
                                                        onClick={() => {
                                                        }}
                                                        enable={true}
                                                        isLoading={false}
                                                    />
                                                </div>
                                            }
                                        />
                                    </div>


                                }
                            />
                        </div>

                        <div className={'w-full flex flex-col justify-center'}>
                            <h1 className={'w-full h-auto flex items-center justify-center mb-3'}>Ingredienti
                                Selezionati</h1>
                        </div>

                        <div className={'w-full h-auto flex flex-col items-center justify-center gap-x-4'}>
                            <div
                                className={'w-full h-full flex flex-col items-start border-[1px] border-lightgrey rounded-lg '}
                            >
                                {loading ? (
                                    <img className={'w-20 h-20'} alt={''} src={loader}/>
                                ) : (
                                    <div className={'w-full h-full flex items-center justify-center py-5'}>
                                        <MultiSelect
                                            ref={multiSelectRef}
                                            width={'full'}
                                            label="Cerca un igrediente da aggiungere "
                                            placeholder="Cerca..."
                                            data={ingredientListMultiselect}
                                            value={[]}
                                            searchable
                                            onChange={(prova) => {
                                                var item = JSON.parse(prova[0]) as IngredientType;
                                                const selectedMap = new Map(selectedIngredients);
                                                const tmpPosition = new Map<string, number>();
                                                if (props.product.id !== undefined) {
                                                    tmpPosition.set(
                                                        `${JSON.parse(localStorage.getItem('selectedMenu') as string).id}--${props.categoryId}--${item.id}--${props.product.id}`,
                                                        selectedIngredients.size + 1
                                                    );
                                                    selectedMap.set(item.id!, {...item, position: tmpPosition});
                                                } else {
                                                    tmpPosition.set(
                                                        `${JSON.parse(localStorage.getItem('selectedMenu') as string).id}--${props.categoryId}--${item.id}`,
                                                        selectedIngredients.size + 1
                                                    );
                                                    selectedMap.set(item.id!, {...item, position: tmpPosition});
                                                }
                                                setIngredientList(ingredientList);
                                                setSelectedIngredients(selectedMap);
                                                setSearchTerm('');
                                                var tmpArr = ingredientListMultiselect!.filter((i) => i.label !== item.name);
                                                setIngredientListMuliSelect(tmpArr);
                                            }}
                                        />
                                    </div>
                                )}

                                <div className={'w-full h-auto '}>
                                    <ReactDragListView nodeSelector="div" onDragEnd={onDragEnd}>
                                        {selectedIngredients && Array.from(selectedIngredients).length > 0
                                            ? Array.from(selectedIngredients).map((item, index) => (
                                                <div
                                                    className={`w-full p-1.5 flex gap-x-2 ${
                                                        index % 2 === 0 ? 'bg-[#F2F2F2A8]' : ''
                                                    }`}
                                                    key={item[0]}
                                                >
                                                    <h1>{index + 1}</h1>
                                                    <h1
                                                        className={'font-poppins-regular cursor-pointer'}
                                                        onClick={() => {
                                                            const selectedMap = new Map(selectedIngredients);
                                                            selectedMap.delete(item[0]);
                                                            setIngredientListMuliSelect([
                                                                ...ingredientListMultiselect!,
                                                                {
                                                                    value: JSON.stringify(item[1]),
                                                                    label: item[1].name,
                                                                },
                                                            ]);
                                                            Array.from(selectedMap).forEach((ingredient, index) => {
                                                                const tmpPos = new Map<string, number>();
                                                                Array.from(ingredient[1].position).forEach((value) => {
                                                                    if (props.product.id !== undefined) {
                                                                        if (
                                                                            value[0] ===
                                                                            `${JSON.parse(localStorage.getItem(
                                                                                'selectedMenu'
                                                                            ) as string).id}--${props.categoryId}--${ingredient[1].id}--${props.product.id}`
                                                                        ) {
                                                                            tmpPos.set(
                                                                                `${JSON.parse(localStorage.getItem(
                                                                                    'selectedMenu'
                                                                                ) as string).id}--${props.categoryId}--${item[1].id}--${props.product.id}`,
                                                                                index + 1
                                                                            );
                                                                        } else {
                                                                            tmpPos.set(value[0], value[1]);
                                                                        }
                                                                    } else {
                                                                        if (
                                                                            value[0] ===
                                                                            `${JSON.parse(localStorage.getItem(
                                                                                'selectedMenu'
                                                                            ) as string).id}--${props.categoryId}--${ingredient[1].id}`
                                                                        ) {
                                                                            tmpPos.set(
                                                                                `${JSON.parse(localStorage.getItem(
                                                                                    'selectedMenu'
                                                                                ) as string).id}--${props.categoryId}--${item[1].id}`,
                                                                                index + 1
                                                                            );
                                                                        } else {
                                                                            tmpPos.set(value[0], value[1]);
                                                                        }
                                                                    }
                                                                });
                                                                ingredient[1].position = tmpPos;
                                                            });
                                                            setSelectedIngredients(selectedMap);
                                                        }}
                                                    >
                                                        {item[1].name}
                                                    </h1>
                                                </div>
                                            ))
                                            : null}
                                    </ReactDragListView>
                                </div>
                            </div>
                            <CustomTextInput
                                customDimensions={'h-[100px] w-full my-10'}
                                defaultValue={props.product.description}
                                isBlack={true}
                                label={'Inserisci Eventuale Descrizione'}
                                onChange={(value) => {
                                    props.onDescriptionChange(value);
                                }}
                                placeholder={'Es:Servito con patate...'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {

        return (
            <div className={'w-full h-full flex flex-col items-center justify-center p-20 '}>
                <Drawer
                    position={"right"}
                    size={'40%'}
                    styles={{
                        content: {backgroundColor: 'black'},
                        header: {backgroundColor: 'black'}
                    }}
                    zIndex={40}
                    opened={statusMenu.open}
                    onClose={() => {
                        setStatusMenu({...statusMenu, open: false});
                    }}
                >
                    {statusMenu.content}
                </Drawer>
                <div className={'w-full flex gap-x-2'}>
                    <h1 className={'text-2xl font-poppins-semi-bold'}>{`Stai Creando`}</h1>
                    <h1 className={'text-2xl font-poppins-bold'}>{` ${props.product.nameProduct}`}</h1>
                </div>
                <div className={'w-full flex gap-x-5 mt-5'}>
                    <div className={'w-full flex flex-col items-center justify-center '}>
                        <div className={'w-full flex items-center justify-start p-2'}>
                            <GenericTooltip
                                txt={'Se ancora non hai registrato un ingrediente , puoi farlo quì!'}
                                innerHtml={
                                    <div
                                        className={'w-auto flex gap-x-1 my-1 cursor-pointer'}
                                        onClick={() => {
                                            setStatusMenu({
                                                open: true,
                                                content: (
                                                    <IngredientDrawerContent
                                                        ingredient={null}
                                                        data={categoryMultiSelect}
                                                        onIngredientChange={(ingredientList) => {
                                                            setStatusMenu({...statusMenu, open: false});
                                                            setReload(!reload);
                                                        }}
                                                    />
                                                ),
                                            });
                                        }}
                                    >
                                        <CustomButton
                                            title={'Crea ingrediente'}
                                            small={true}
                                            onClick={() => {
                                            }}
                                            enable={true}
                                            isLoading={false}
                                        />
                                    </div>
                                }
                            />
                        </div>

                        <div className={'w-full flex justify-between '}>
                            <h1 className={'w-full flex text-center'}>Ingredienti Selezionati</h1>
                        </div>

                        <div className={'w-full h-[400px] flex gap-x-4'}>
                            <div
                                className={'w-full h-full flex flex-col gap-y-6 items-start p-5 border-[1px] border-lightgrey rounded-lg '}
                            >
                                {loading ? (
                                    <img className={'w-20 h-20'} alt={''} src={loader}/>
                                ) : (
                                    <div>
                                        <MultiSelect
                                            ref={multiSelectRef}
                                            width={'100%'}
                                            label="Cerca l'ingrediente che vuoi aggiungere al tuo prodotto"
                                            placeholder="Cerca..."
                                            data={ingredientListMultiselect}
                                            value={[]}
                                            searchable
                                            onChange={(prova) => {
                                                var item = JSON.parse(prova[0]) as IngredientType;
                                                const selectedMap = new Map(selectedIngredients);
                                                const tmpPosition = new Map<string, number>();
                                                if (props.product.id !== undefined) {
                                                    tmpPosition.set(
                                                        `${JSON.parse(localStorage.getItem('selectedMenu') as string).id}--${props.categoryId}--${item.id}--${props.product.id}`,
                                                        selectedIngredients.size + 1
                                                    );
                                                    selectedMap.set(item.id!, {...item, position: tmpPosition});
                                                } else {
                                                    tmpPosition.set(
                                                        `${JSON.parse(localStorage.getItem('selectedMenu') as string).id}--${props.categoryId}--${item.id}`,
                                                        selectedIngredients.size + 1
                                                    );
                                                    selectedMap.set(item.id!, {...item, position: tmpPosition});
                                                }
                                                setIngredientList(ingredientList);
                                                setSelectedIngredients(selectedMap);
                                                setSearchTerm('');
                                                var tmpArr = ingredientListMultiselect!.filter((i) => i.label !== item.name);
                                                setIngredientListMuliSelect(tmpArr);
                                            }}
                                        />
                                    </div>
                                )}

                                <div className={'w-full overflow-y-auto max-h-96'}>
                                    <ReactDragListView nodeSelector="div" onDragEnd={onDragEnd}>
                                        {selectedIngredients && Array.from(selectedIngredients).length > 0
                                            ? Array.from(selectedIngredients).map((item, index) => (
                                                <div
                                                    className={`w-full p-1.5 flex gap-x-2 ${
                                                        index % 2 === 0 ? 'bg-[#F2F2F2A8]' : ''
                                                    }`}
                                                    key={item[0]}
                                                >
                                                    <h1>{index + 1}</h1>
                                                    <h1
                                                        className={'font-poppins-regular cursor-pointer'}
                                                        onClick={() => {
                                                            const selectedMap = new Map(selectedIngredients);
                                                            selectedMap.delete(item[0]);
                                                            setIngredientListMuliSelect([
                                                                ...ingredientListMultiselect!,
                                                                {
                                                                    value: JSON.stringify(item[1]),
                                                                    label: item[1].name,
                                                                },
                                                            ]);
                                                            Array.from(selectedMap).forEach((ingredient, index) => {
                                                                const tmpPos = new Map<string, number>();
                                                                Array.from(ingredient[1].position).forEach((value) => {
                                                                    if (props.product.id !== undefined) {
                                                                        if (
                                                                            value[0] ===
                                                                            `${JSON.parse(localStorage.getItem(
                                                                                'selectedMenu'
                                                                            ) as string).id}--${props.categoryId}--${ingredient[1].id}--${props.product.id}`
                                                                        ) {
                                                                            tmpPos.set(
                                                                                `${JSON.parse(localStorage.getItem(
                                                                                    'selectedMenu'
                                                                                ) as string).id}--${props.categoryId}--${item[1].id}--${props.product.id}`,
                                                                                index + 1
                                                                            );
                                                                        } else {
                                                                            tmpPos.set(value[0], value[1]);
                                                                        }
                                                                    } else {
                                                                        if (
                                                                            value[0] ===
                                                                            `${JSON.parse(localStorage.getItem(
                                                                                'selectedMenu'
                                                                            ) as string).id}--${props.categoryId}--${ingredient[1].id}`
                                                                        ) {
                                                                            tmpPos.set(
                                                                                `${JSON.parse(localStorage.getItem(
                                                                                    'selectedMenu'
                                                                                ) as string).id}--${props.categoryId}--${item[1].id}`,
                                                                                index + 1
                                                                            );
                                                                        } else {
                                                                            tmpPos.set(value[0], value[1]);
                                                                        }
                                                                    }
                                                                });
                                                                ingredient[1].position = tmpPos;
                                                            });
                                                            setSelectedIngredients(selectedMap);
                                                        }}
                                                    >
                                                        {item[1].name}
                                                    </h1>
                                                </div>
                                            ))
                                            : null}
                                    </ReactDragListView>
                                </div>
                            </div>
                            <CustomTextInput
                                customDimensions={'h-[150px] w-full text-center'}
                                defaultValue={props.product.description}
                                isBlack={true}
                                label={'Inserisci Eventuale Descrizione'}
                                onChange={(value) => {
                                    props.onDescriptionChange(value);
                                }}
                                placeholder={'Es:Servito con patate...'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
