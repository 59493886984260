import {ComandaOrder, PostCommand} from "../../types/PostCommand";
import {makeRequest} from "../RestAdapter";
import {HttpMethodsEnum} from "../HttpMethodsEnum";
import {showFailNotification} from "../../utils/NotificationUtils";


export function addCommand(request: PostCommand, onFinish: (response: any) => void) {
    makeRequest<any>(`/orders/comanda-order/add-or-update-comanda-order`, HttpMethodsEnum.POST, request).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : [])
        } else {
            onFinish(null)
            showFailNotification('Errore', 'Riprova più tardi', 2000)
        }
    })
}


export function getClientCommad(id: string, onFinish: (response: ComandaOrder[]) => void) {
    makeRequest<ComandaOrder[]>(`/orders/comanda-order/get-comanda-order-from-id/${id}`, HttpMethodsEnum.GET).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : []);
        } else {
            onFinish([]);
        }
    })
}


export function comandaOrderPrint(idComanda: string, onFinish: (response: ComandaOrder[]) => void) {
    makeRequest<ComandaOrder[]>(`/orders/comanda-order/print/${idComanda}`, HttpMethodsEnum.GET).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : []);
        } else {
            onFinish([]);
            showFailNotification('Errore', 'Riprova più tardi', 2000);
        }
    });
}

export function updateOrderPrint(idComanda: string, onFinish: (response: ComandaOrder[]) => void) {
    makeRequest<ComandaOrder[]>(`/orders/comanda-order/update-comanda-status/${idComanda}`, HttpMethodsEnum.PUT).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : []);
        } else {
            onFinish([]);
            showFailNotification('Errore', 'Riprova più tardi', 2000);
        }
    });
}


export function deleteCommand(isAdmin:boolean,request:string[], onFinish: (response: any) => void) {
    makeRequest<any>(`/orders/comanda-order/delete-comanda-orders-by-list?idUser=${isAdmin}`, HttpMethodsEnum.DELETE,request).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : '');
        } else {
            onFinish('');
            showFailNotification('Errore', 'Riprova più tardi', 2000);
        }
    });
}







