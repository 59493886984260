import successIcon from '../../assets/payment/payment-succes-icon.svg';
import failureIcon from '../../assets/payment/payment-failure-icon.svg';
import CustomButton from "../../components/CustomButton";
import React, {useEffect, useState} from 'react';
import {isTablet} from "../../utils/WindowDimensionUtils";
import {useNavigate} from "react-router-dom";
import {makeRequest} from "../../network/RestAdapter";
import {HttpMethodsEnum} from "../../network/HttpMethodsEnum";
import {AddRestaurantRequest} from "../../types/AddRestaurantRequest";
import {restaurantRegistration} from "../../network/controllers/RestaurantsControllers";
import pizza from '../../assets/pizzaLoading.webp'
import logo from '../../assets/logoBlack.svg'
import {isMobile} from "react-device-detect";


export default function ResultPaymentPage() {

    const [paymentSuccess, setPaymentSuccess] = useState<boolean | null>(null);
    var navigate = useNavigate();
    var idIntent = new URLSearchParams(window.location.search).get('payment_intent')
    var idStripe = new URLSearchParams(window.location.search).get('idStripe')
    var idLicense = new URLSearchParams(window.location.search).get('idLicense')
    var idPackage = new URLSearchParams(window.location.search).get('idPackage')
    var localData = JSON.parse(sessionStorage.getItem('registrationData') as string) as AddRestaurantRequest
    const [dots, setDots] = useState('');
    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''));
        }, 500);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {

        if (localData) {
            makeRequest<Map<String, any>>(`/users/restaurants/check-payment-status/${idIntent}/${idStripe}`, HttpMethodsEnum.GET).then((res) => {
                debugger
                if (localData) {
                    debugger
                    restaurantRegistration(localData, (response: any) => {

                        sessionStorage.removeItem('registrationData')
                        // @ts-ignore
                        setPaymentSuccess(res.data.status === 'succeeded')
                    })
                } else {

                    // @ts-ignore
                    setPaymentSuccess(res.data.status === 'succeeded')
                }
            })
        } else {
            makeRequest<Map<String, any>>(`/users/restaurants/check-payment-status/${idIntent}/${idStripe}/${idLicense}/${idPackage}`, HttpMethodsEnum.GET).then((res) => {

                // @ts-ignore
                setTimeout(() => {
                    // @ts-ignore
                    setPaymentSuccess(res.data.status === 'succeeded')
                }, 3000)


            })
        }
    }, []);


    if (isMobile){
        return (
            <div className={'flex flex-col h-full w-full items-center justify-center gap-y-7 p-y'}>
                {paymentSuccess !== null ?
                    <div className={'w-full h-full flex flex-col items-center justify-center'}>
                        <img alt={''} className={'w-[35%]  h-[35%]'} src={paymentSuccess ? successIcon : failureIcon}/>
                        <div className={'flex flex-col w-auto h-auto items-center justify-center bg-white gap-y-2.5'}>
                            <h1 className={`items-center font-poppins-bold mt-5 ${isTablet() ? 'text-4xl' : 'text-2xl'} ${paymentSuccess ? 'text-green-700' : 'text-red-600'}  `}>
                                {paymentSuccess
                                    ? 'Grazie!'
                                    : 'Oh no!'
                                }
                            </h1>
                            <h1 className={'items-center text-xl font-poppins-regular mb-3 p-5 text-center'}>
                                {paymentSuccess
                                    ? 'Il tuo pagamento è stato effettuato con successo!'
                                    : 'Qualcosa è andato storto, riprova il pagamento!'
                                }
                            </h1>
                        </div>

                        <div className={'flex w-auto h-auto mb-15 items-center justify-center mt-3'}>
                            <CustomButton title={'Continua su MaiSazio'} onClick={() => {
                                if (localData) {
                                    navigate('/welcome')
                                } else {
                                    navigate('/admin')

                                }
                            }
                            } enable={true}
                                          isLoading={false}
                                          customStyles={'w-[200px] h-[80px]'}/>
                        </div>
                    </div> :
                    <div className={'w-auto h-auto mb-15 items-center justify-center mt-3 rounded-xl'}>
                        {localData ?
                            <div className="w-full h-full flex flex-col  items-center justify-center   p-10">

                                <img className={'w-[40% h-[40%]'} src={pizza}/>
                                <h2 className={'text-xl font-poppins-semi-bold text-center mt-5'}> La tua registarzione sarà al pass in un
                                    momento... </h2>
                                <h2 className={'font-poppins-semi-bold'}>Grazie dal Team</h2>

                                <img className={'w-[20%] h-auto'} src={logo}/>
                            </div>


                            : <div className="w-full h-full flex flex-col  items-center justify-center   p-10">
                                <img className={'w-[60% h-[60%]'} src={pizza}/>
                                <h2 className={'text-xl font-poppins-semi-bold  text-center mt-5'}> il rinnovo del tuo
                                    abbonamento sarà al pass in un
                                    momento... </h2>
                                <h2 className={'font-poppins-semi-bold'}>Grazie dal Team</h2>
                                <img className={'w-[20%] h-auto'} src={logo}/>
                            </div>
                        }
                    </div>

                }
            </div>
        );
    }else{
    return (
        <div className={'flex flex-col h-full w-full items-center justify-center gap-y-7'}>
            {paymentSuccess !== null ?
                <div className={'w-full h-full flex flex-col items-center justify-center'}>
                    <img alt={''} className={'w-[35%]  h-[35%]'} src={paymentSuccess ? successIcon : failureIcon}/>
                    <div className={'flex flex-col w-auto h-auto items-center justify-center bg-white gap-y-2.5'}>
                        <h1 className={`items-center font-poppins-bold mt-5 ${isTablet() ? 'text-4xl' : 'text-2xl'} ${paymentSuccess ? 'text-green-700' : 'text-red-600'}  `}>
                            {paymentSuccess
                                ? 'Grazie!'
                                : 'Oh no!'
                            }
                        </h1>
                        <h1 className={'items-center text-xl font-poppins-regular mb-3'}>
                            {paymentSuccess
                                ? 'Il tuo pagamento è stato effettuato con successo!'
                                : 'Qualcosa è andato storto, riprova il pagamento!'
                            }
                        </h1>
                    </div>

                    <div className={'flex w-auto h-auto mb-15 items-center justify-center mt-3'}>
                        <CustomButton title={'Continua su MaiSazio'} onClick={() => {
                            if (localData) {
                                navigate('/welcome')
                            } else {
                                navigate('/admin')

                            }
                        }
                        } enable={true}
                                      isLoading={false}
                                      customStyles={'w-[200px] h-[80px]'}/>
                    </div>
                </div> :
                <div className={'w-auto h-auto mb-15 items-center justify-center mt-3 rounded-xl'}>
                    {localData ?
                        <div className="w-full h-full flex flex-col  items-center justify-center   p-10">

                            <img className={'w-[60% h-[60%]'} src={pizza}/>
                            <h2 className={'text-xl font-poppins-semi-bold whitespace-nowrap mt-5'}> La tua registarzione sarà al pass in un
                                momento... </h2>
                            <h2 className={'font-poppins-semi-bold'}>Grazie dal Team</h2>

                            <img className={'w-[20%] h-auto'} src={logo}/>
                        </div>


                        : <div className="w-full h-full flex flex-col  items-center justify-center   p-10">
                            <img className={'w-[60% h-[60%]'} src={pizza}/>
                            <h2 className={'text-xl font-poppins-semi-bold whitespace-nowrap mt-5'}> il rinnovo del tuo
                                abbonamento sarà al pass in un
                                momento... </h2>
                            <h2 className={'font-poppins-semi-bold'}>Grazie dal Team</h2>
                            <img className={'w-[20%] h-auto'} src={logo}/>
                        </div>
                    }
                </div>

            }
        </div>
    );
}
}
