import {CategoryType} from "../types/CategoryType";
import {StatusEnum} from "../enums/StatusEnum";
import placeholderCategory from '../assets/placeolder-categoria.svg'
import {isMobile} from "react-device-detect";

export default function CategoryComponent(props: {
    isSelected?: boolean,
    category: CategoryType,
    onClick: (category: CategoryType) => void
}) {
    return (
        <div className={'w-auto h-full flex flex-col cursor-pointer '} onClick={() => {
            props.onClick(props.category)
        }}>
            <img alt={''}
                 className={`w-[200px] h-[100%] rounded-xl shadow-lg shadow-black`}
                 src={props.category.approvalStatus !== StatusEnum.ACCEPTED ? placeholderCategory : props.category.image}/>
            <div className={'w-full flex justify-center items-center py-1'}>
                <h1 className={'font-poppins-bold whitespace-nowrap truncate pt-3'}>{props.category.categoryName}</h1>
            </div>
        </div>
    )
}
