import {Navigate, useRoutes} from 'react-router-dom';
import MapPage from "../pages/MapPage";
import ListLocalPage from "../pages/ListLocalPage";
import {useEffect} from "react";

export default function RouteLocal() {

    return useRoutes([
        {
            path: '/mappa',
            element: <MapPage/>,
            children: []
        }, {
            path: '/lista',
            element: <ListLocalPage/>,
            children: []
        },
        {
            path: '*', element: <Navigate to='/locali/lista'/>
        }
    ])
}
