import iconHours from '../assets/hours-detail-icon.svg';
import iconComment from '../assets/comment-detail-icon.svg';
import {LocalType} from "../types/LocalType";
import {useEffect, useState} from "react";
import {getComment} from "../network/controllers/DetailController";
import {CommentType} from "../types/CommentType";
import CommentComponent from "../components/CommentComponent";
import {RatingTextInput} from "../components/RatingTextInput";
import {isMobile} from "react-device-detect";
import {isTablet} from "../utils/WindowDimensionUtils";
import {LoginResponseType} from "../types/LoginResponseType";
import ServiceDetailPage from "../components/ServiceDetailPage";
import ServiceDetailPageAdmin from "../components/ServiceDetailPageAdmin";
import {Radio, RadioGroup, Switch, Tooltip} from "@mantine/core";
import {updateReservation, updateDeliveryStatus, syncDataGoogle} from "../network/controllers/RestaurantsControllers";
import {showFailNotification, showSuccessNotification, showWarningNotification} from "../utils/NotificationUtils";
import GenericTooltip from "../components/generic/GenericTooltip";
import receiptYellowIcon from "../assets/receipt-yellow.svg";
import {RestaurantStatusEnum} from "../enums/RestaurantStatusEnum";
import infoIcon from "../assets/info-tooltip-icon.svg"
import CustomButton from "../components/CustomButton";

export default function InfoDetailPage(props: { adminpage: boolean, local: LocalType, reloadLocal?: () => void }) {
    let local: LocalType = props.local;
    const [commentList, setCommentList] = useState<CommentType[]>([]);
    const [selectedService, setSelectedService] = useState(RestaurantStatusEnum.NESSUNO); // Default selected value
    const userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType;
    const [value, setValue] = useState('asporto'); // Default selected value
    const [isStatusReservation, setIsStatusReservation] = useState(local.isReservation);
    const [loadingDataGoogle, setLoadingDataGoogle] = useState(false)
    useEffect(() => {
        getComment(local.id, (listComment) => {
            setCommentList(listComment);
        });
    }, []);

    const serviceOptions = [
        {
            value: RestaurantStatusEnum.ASPORTO.toString(),
            label: 'Attiva Asporto',
            tooltipText: 'Attiva per il cliente la possibilità di ordinare e ritirare il prodotto nel tuo locale'
        },
        {
            value: RestaurantStatusEnum.DELIVERY.toString(),
            label: 'Attiva Asporto e Domicilio',
            tooltipText: 'Attiva per il cliente la possibilità di ordinare e ritirare il prodotto nel tuo locale o richiedere la consegna presso il proprio domicilio'
        },
        {
            value: RestaurantStatusEnum.NESSUNO.toString(),
            label: 'Disattiva Servizi',
            tooltipText: 'Disattiva i Servizi di Asporto e Domicilio'
        }
    ];

    const handleServiceChange = (value: string) => {
        const selectedEnumValue = value as RestaurantStatusEnum;
        setSelectedService(selectedEnumValue);
        updateDeliveryStatus(local.id, selectedEnumValue, (response) => {
            if (response) {
                showSuccessNotification("Successo", "Stato del servizio aggiornato con successo", 2000);
            } else {
                showFailNotification('Errore', 'Riprova più tardi', 2000);
            }
        });
    };
    if (isMobile) {
        return (
            <div className={`w-full h-full mt-5 ${props.adminpage ? '' : 'px-5'}`}>
                <div className={`${props.adminpage ? 'flex-col' : 'flex'} w-full gap-x-2`}>
                    <div className={'w-full'}>
                        <div className={'w-full h-14 bg-black p-2 rounded-xl'}>
                            <div className={'flex items-center h-full '}>
                                <img alt={''} src={iconHours} className={`${isTablet() ? 'w-8' : 'w-8'}`}/>
                                {props.adminpage ? (
                                    <GenericTooltip
                                        txt={'Per modificare gli orari di Apertura/Chiusura modifica i tuoi dati su Google'}
                                        innerHtml={
                                            <h1 className={'font-bold text-amber-400 text-lg ml-2'}>Orari</h1>
                                        }/>
                                ) : (
                                    <h1 className={'font-bold text-amber-400 text-lg ml-2'}>Orari</h1>
                                )}
                            </div>
                        </div>
                        <div className={'w-full h-[620px] overflow-y-auto flex flex-col gap-y-5 pt-4'}>

                            {local ? Array.from(Object.entries(local.hours)).map((item) => (
                                <div className={'w-full flex flex-col'}>
                                    <h1 key={item[0]} className={'font-poppins-semi-bold'}>{item[1].split(': ')[0]}</h1>
                                    {
                                        isTablet() ?
                                            <h1 key={item[0]}
                                                className={'font-poppins-semi-bold'}>{item[1].split(': ')[1]}</h1> :
                                            <div>
                                                <h1 key={item[0]}
                                                    className={'font-poppins-semi-bold'}>{item[1].split(': ')[1].split(',')[0]}</h1>
                                                <h1 key={item[0]}
                                                    className={'font-poppins-semi-bold'}>{item[1].split(': ')[1].split(',')[1]}</h1>
                                            </div>
                                    }

                                </div>
                            )) : null}
                        </div>


                    </div>
                    {props.adminpage ? <ServiceDetailPageAdmin local={local}/> : <ServiceDetailPage local={local}/>}
                    {props.adminpage ? <div className={'w-full mt-10 h-14 bg-black p-2 rounded-xl'}>
                        <div className={'flex items-center h-full justify-between mr-3'}>
                            <div className={'flex justify-center items-center '}>
                                <img alt={''} src={iconHours} className={'w-8'}/>
                                <h1 className={'font-bold text-amber-400 text-lg ml-2 '}>{isStatusReservation ? 'Disabilita Prenotazioni' : 'Abilita Prenotazioni'}</h1>
                            </div>
                            <Switch checked={isStatusReservation} styles={{label: {color: 'white'}}} color={'#FFCC00'}
                                    onChange={(value) => {
                                        if (isStatusReservation) {
                                            showWarningNotification("Sei sicuro di voler disattivare le prenotazioni?", () => {
                                                updateReservation(!isStatusReservation, local.id, () => {
                                                    setIsStatusReservation(!value.target.checked);
                                                    showSuccessNotification("Motifica", "Prenotazione disabilitata con successo", 1000)
                                                })
                                            })
                                        } else {
                                            updateReservation(!isStatusReservation, local.id, () => {
                                                setIsStatusReservation(!isStatusReservation)
                                                showSuccessNotification("Motifica", "Prenotazione abilitato con successo", 1000)
                                            })
                                        }


                                    }}/>
                        </div>
                    </div> : null}
                    {props.adminpage && (
                        <div className={'w-full h-full flex flex-col bg-black p-2 rounded-xl my-5'}>
                            <div className={'flex items-center h-full mx-1'}>
                                <img alt={''} src={receiptYellowIcon} className={`${isTablet() ? 'w-8' : 'w-8'}`}/>
                                <p className={'font-bold text-amber-400 text-lg ml-1'}>Servizio Asporto -
                                    Domicilio</p>
                            </div>
                            <div className={'w-full h-full p-2 flex flex-col'}>
                                <RadioGroup defaultValue={local.isDelivery}
                                            onChange={(value) => handleServiceChange(value)}>
                                    {serviceOptions.map(option => (
                                        <Tooltip key={option.value} label={option.tooltipText} refProp="rootRef">
                                            <Radio
                                                value={option.value}
                                                label={<span style={{
                                                    color: 'white',
                                                    fontSize: '16px'
                                                }}>{option.label}</span>}
                                                mt="md"
                                                color="#FFCC00"
                                            />
                                        </Tooltip>
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                    )}
                    {props.adminpage && (
                        <div className={'flex flex-col w-full items-center justify-center py-10'}>
                            <Tooltip
                                label={
                                    props.local.isLoadDataGoogle ? (
                                        <span>
                    Una volta apportate le modifiche su Google, clicca qui per poter sincronizzare le informazioni su MaiSazio.<br/>
                    <strong>ATTENZIONE!</strong> L'operazione può essere effettuata una sola volta al giorno!
                </span>
                                    ) : (
                                        <span>
                    Operazione già effettuata.<br/>
                    Riprova domani!
                </span>
                                    )
                                }
                            >
                                <div>
                                    <CustomButton
                                        title={'Sincronizza Dati Google'}
                                        onClick={() => {

                                            if (props.local.isLoadDataGoogle) {
                                                showWarningNotification("Sei sicuro di voler sincronizzare i dati?", () => {
                                                    setLoadingDataGoogle(true);
                                                    syncDataGoogle(props.local.id, () => {
                                                        if (props.reloadLocal) {
                                                            setLoadingDataGoogle(false);
                                                            props.reloadLocal();
                                                        }
                                                    });
                                                });
                                            } else {
                                                showFailNotification('Errore', 'Operazione già effettuata. Riprova domani!', 2000);
                                            }
                                        }}
                                        enable={props.local.isLoadDataGoogle}
                                        isLoading={loadingDataGoogle}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                    )}
                </div>

                {!props.adminpage ? <div className={'w-full mt-8'}>
                    <div className={'w-full h-14 bg-black p-2 rounded-xl'}>
                        <div className={'flex items-center h-full'}>
                            <img alt={''} src={iconComment} className={`${isTablet() ? 'w-8' : 'w-6'}`}/>
                            <h1 className={'font-bold text-amber-400 text-lg ml-2'}>Commenti</h1>
                        </div>
                    </div>
                    <div className={'overflow-y-scroll max-h-80 py-10 mt-0.5 '}>
                        <div className={'w-full flex flex-col gap-y-3 px-4 pt-4'}>
                            {
                                commentList?.length > 0 ? commentList?.map((comment: CommentType) => (
                                    <CommentComponent key={comment.id} comment={comment}/>
                                )) : null
                            }
                        </div>
                    </div>
                    <div className='flex justify-between w-full items-center mb-2'>
                        {userData ? <RatingTextInput/> : null}
                    </div>
                </div> : null}
            </div>
        );
    } else {
        return (
            <div className={'w-full h-full mt-5 px-5'}>
                <div className={'flex w-full gap-x-16'}>
                    <div className={'w-full'}>
                        <div className={'w-full h-14 bg-black p-2 rounded-xl'}>
                            <div className={'flex items-center w-full h-full'}>
                                <img alt={''} src={iconHours} className={'w-8'}/>
                                {props.adminpage ? (
                                    <GenericTooltip
                                        txt={'Per modificare gli orari di Apertura/Chiusura modifica i tuoi dati su Google'}
                                        innerHtml={
                                            <h1 className={'font-bold text-amber-400 text-lg ml-2'}>Orari</h1>
                                        }/>
                                ) : (
                                    <h1 className={'font-bold text-amber-400 text-lg ml-2'}>Orari</h1>
                                )}
                            </div>
                        </div>
                        <div className={'w-full  flex flex-col gap-y-7 px-4 pt-4'}>
                            {local ? Array.from(Object.entries(local.hours)).map((item) => (
                                <div key={item[0]} className={'w-full flex flex-col'}>
                                    <h1 className={'font-poppins-semi-bold'}>{item[1].split(': ')[0]}</h1>
                                    <h1 className={'font-poppins-semi-bold'}>{item[1].split(': ')[1]}</h1>
                                </div>)) : null}
                        </div>
                    </div>
                    <div className={'flex flex-col w-full'}>
                        {props.adminpage ? <ServiceDetailPageAdmin local={local}/> : <ServiceDetailPage local={local}/>}
                        {props.adminpage ? <div className={'w-full mt-10 h-14 bg-black p-2 rounded-xl'}>
                            <div className={'flex items-center h-full justify-between mr-3'}>
                                <div className={'flex justify-center items-center '}>
                                    <img alt={''} src={iconHours} className={'w-8'}/>
                                    <h1 className={'font-bold text-amber-400 text-lg ml-2 '}>{isStatusReservation ? 'Disabilita Prenotazioni' : 'Abilita Prenotazioni'}</h1>
                                </div>
                                <Switch checked={isStatusReservation} styles={{label: {color: 'white'}}}
                                        color={'#FFCC00'}
                                        onChange={(value) => {
                                            if (isStatusReservation) {
                                                showWarningNotification("Sei sicuro di voler disattivare le prenotazioni?", () => {
                                                    updateReservation(!isStatusReservation, local.id, () => {
                                                        setIsStatusReservation(!value.target.checked);
                                                        showSuccessNotification("Motifica", "Prenotazione disabilitata con successo", 1000);
                                                    })
                                                })
                                            } else {
                                                showWarningNotification("Sei sicuro di voler abilitare le prenotazioni?", () => {
                                                    updateReservation(!isStatusReservation, local.id, () => {
                                                        setIsStatusReservation(!isStatusReservation);
                                                        showSuccessNotification("Motifica", "Prenotazione abilitata con successo", 1000);
                                                    })
                                                })
                                            }
                                        }}/>
                            </div>
                        </div> : null}
                        {props.adminpage && (
                            <div className={'w-full h-auto flex flex-col bg-black  rounded-xl mt-5'}>
                                <div className={'flex items-center h-full p-4 '}>
                                    <img alt={''} src={receiptYellowIcon} className={`${isTablet() ? 'w-8' : 'w-8'}`}/>
                                    <p className={'font-bold text-amber-400 text-lg '}>Servizio Asporto -
                                        Domicilio</p>
                                </div>
                                <div className={'w-full h-full flex flex-col ml-5 mb-5'}>
                                    <RadioGroup defaultValue={local.isDelivery}
                                                onChange={(value) => handleServiceChange(value)}>
                                        {serviceOptions.map(option => (
                                            <Tooltip key={option.value} label={option.tooltipText} refProp="rootRef">
                                                <Radio

                                                    value={option.value}
                                                    label={<span style={{
                                                        color: 'white',
                                                        fontSize: '16px'
                                                    }}>{option.label}</span>}
                                                    mt="xs"
                                                    color="#FFCC00"
                                                />
                                            </Tooltip>
                                        ))}
                                    </RadioGroup>
                                </div>

                            </div>

                        )}
                        {props.adminpage && (
                        <div className={'flex flex-col w-full items-center justify-center py-10'}>
                            <Tooltip
                                label={
                                    props.local.isLoadDataGoogle ? (
                                        <span>
                    Una volta apportate le modifiche su Google, clicca qui per poter sincronizzare le informazioni su MaiSazio.<br/>
                    <strong>ATTENZIONE!</strong> L'operazione può essere effettuata una sola volta al giorno!
                </span>
                                    ) : (
                                        <span>
                    Operazione già effettuata.<br/>
                    Riprova domani!
                </span>
                                    )
                                }
                            >
                                <div>
                                    <CustomButton
                                        title={'Sincronizza Dati Google'}
                                        onClick={() => {

                                            if (props.local.isLoadDataGoogle) {
                                                showWarningNotification("Sei sicuro di voler sincronizzare i dati?", () => {
                                                    setLoadingDataGoogle(true);
                                                    syncDataGoogle(props.local.id, () => {
                                                        if (props.reloadLocal) {
                                                            setLoadingDataGoogle(false);
                                                            props.reloadLocal();
                                                        }
                                                    });
                                                });
                                            } else {
                                                showFailNotification('Errore', 'Operazione già effettuata. Riprova domani!', 2000);
                                            }
                                        }}
                                        enable={props.local.isLoadDataGoogle}
                                        isLoading={loadingDataGoogle}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                        )}
                    </div>
                </div>
                {!props.adminpage ? <div className={'w-full  mt-8'}>
                    <div className={'w-full h-14 bg-black p-2 rounded-xl'}>
                        <div className={'flex items-center h-full'}>
                            <img alt={''} src={iconComment} className={'w-8'}/>
                            <h1 className={'font-bold text-amber-400 text-lg ml-2 '}>Commenti</h1>
                        </div>
                    </div>
                    <div className={'overflow-y-scroll max-h-96 pt-5'}>
                        <div className={'w-full flex flex-col gap-y-4 px-4 pt-4'}>
                            {
                                commentList?.length > 0 ? commentList?.map((comment: CommentType) => (
                                    <CommentComponent key={comment.id} comment={comment}/>
                                )) : null
                            }
                        </div>
                    </div>
                    <div className='flex justify-between w-full items-center'>
                        {userData && <RatingTextInput/>}
                    </div>
                </div> : null}
            </div>
        );
    }
}
