import React, { useEffect, useState } from "react";
import { getClientCommad } from "../network/controllers/CommandController";
import { showFailNotification } from "../utils/NotificationUtils";
import { LoginResponseType } from "../types/LoginResponseType";
import CommandClientCardComponent from "../components/CommandClientCardComponent";
import {Grid} from "@mantine/core";
import {isMobile} from "react-device-detect";
import {ComandaOrder} from "../types/PostCommand";

export default function CommandPage() {
    const [orders, setOrders] = useState<ComandaOrder[]>([]);
    const userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType;
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        dispatchEvent(new CustomEvent("loader", { detail: { isLoading: true } }));
        getClientCommad(userData.id, (response) => {

            if (response.length > 0) {
                setOrders(response);
            }
            dispatchEvent(new CustomEvent("loader", { detail: { isLoading: false } }));
            setIsLoading(false)
        });
    }, [userData.id,reload]);


    if (isMobile) {
    return (
        <div className="w-full h-screen flex flex-col items-center justify-center pt-[173px] ">
            <p className="font-poppins-bold text-black text-3xl w-full items-start pl-5">
               I Miei Ordini
            </p>
            <div className="w-full h-full overflow-y-auto  mt-2 p-2 ">
                {orders.length === 0 && !isLoading ? (
                    <div className="w-full h-full flex items-center justify-center">
                        <p className="font-poppins-bold text-black text-center text-xl">Non hai nessun ordine da visualizzare</p>
                    </div>
                ) : (
                    <Grid gutter={'auto'} >
                        {orders.map((item) => (
                            <Grid.Col className="w-auto" span={isMobile ? 12 : {base: 12, xs: 6, md: 4, xl: 3}} key={item.id}>
                                <div className="w-full">
                                     <CommandClientCardComponent order={item}/>
                                </div>
                            </Grid.Col>
                        ))}
                    </Grid>
                )}
            </div>
        </div>
    );
}else{
        return (
            <div className="w-full h-screen flex flex-col items-center justify-center pt-[173px] pb-10 ml-5 ">
                <p className="font-poppins-bold text-black text-3xl w-full items-start pl-5">
                    I Miei Ordini
                </p>
                <div className="w-full h-full overflow-y-auto  mt-10 p-5 ">
                    {orders.length === 0 && !isLoading ? (
                        <div className="w-full h-full flex items-center justify-center">
                            <p className="font-poppins-bold text-black text-3xl">Non hai nessun ordine da visualizzare</p>
                        </div>
                    ) : (
                        <Grid gutter={'auto'} >
                            {orders.map((item) => (
                                <Grid.Col className="w-auto" span={isMobile ? 12 : {base: 12, xs: 6, md: 4, xl: 3}} key={item.id}>
                                    <div className="w-full">
                                        <CommandClientCardComponent order={item}/>
                                    </div>
                                </Grid.Col>
                            ))}
                        </Grid>
                    )}
                </div>
            </div>
        );

    }}

















