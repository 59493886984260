import CustomButton from "./CustomButton";
import {useState} from "react";
import {RegistrationRequestType} from "../types/RegistrationRequestType";
import {useNavigate} from "react-router-dom";
import CustomTextInput from "./generic/CustomTextInput";
import GoogleAddressSearch from "./GoogleAddressSearch";
import {changeUserData, registrationClient} from "../network/controllers/LoginApiController";
import {LoginResponseType} from "../types/LoginResponseType";
import {showSuccessNotification} from "../utils/NotificationUtils";


export default function AccountDrawerComponent() {
    const userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType
    const [registrationData, setRegistrationData] = useState<RegistrationRequestType>({
        address: localStorage.getItem('formatted_address')! ,
        cf: "",
        city: "",
        civicNumber: "",
        email: userData.email,
        idSocial: "",
        name: userData.name,
        nameSocial: "",
        phone: userData.phone,
        social: false,
        state: "",
        surname: userData.surname,
        zipCode: ""
    })

    function retrieveAddressData(addressData: any) {
        var tmpdata = registrationData
        addressData.forEach((item: any) => {
            if (item.types.includes('route')) {
                tmpdata.address = item.long_name
            }
            if (item.types.includes('locality')) {
                tmpdata.city = item.long_name
            }
            if (item.types.includes('country')) {
                tmpdata.state = item.long_name
            }
            if (item.types.includes('postal_code')) {
                tmpdata.zipCode = item.long_name
            }
            if (item.types.includes('street_number')) {
                tmpdata.civicNumber = item.long_name
            }
            setRegistrationData({
                ...registrationData,
                address: tmpdata.address,
                city: tmpdata.city,
                state: tmpdata.state,
                zipCode: tmpdata.zipCode,
                civicNumber: tmpdata.civicNumber,
            })


        })
    }

    var navigate = useNavigate();
    return (


        <div className={'w-full h-full flex flex-col justify-center items-center'}>
            <div className={'flex flex-col h-auto w-full items-center gap-y-2 px-8'}>
                <div>
                    <h1 className='text-white font-poppins-bold text-3xl item-left ml-4'>Account</h1>
                </div>
                <CustomTextInput label={'Nome'} isPassword={false} defaultValue={registrationData.name}
                                 onChange={(value) =>
                                     setRegistrationData({...registrationData, name: value})
                                 }/>
                <CustomTextInput label={'Cognome'} defaultValue={registrationData.surname}
                                 onChange={(value) =>
                                     setRegistrationData({...registrationData, surname: value})
                                 }/>
                <CustomTextInput label={'Email'} defaultValue={registrationData.email}
                                 onChange={(value) =>
                                     setRegistrationData({...registrationData, email: value})
                                 }/>
                <CustomTextInput label={'Telefono'} defaultValue={registrationData.phone}
                                 onChange={(value) =>
                                     setRegistrationData({...registrationData, phone: value})
                                 }/>
                <GoogleAddressSearch  inputPlaceholder={registrationData.address} setNameTextInput={'formatted_address'} width={'100px'} label={'Indirizzo'}
                                     onPlaceSelect={(v) => {
                                         retrieveAddressData(v.address_components)
                                     }}/>
                <CustomButton title={'Salva'} onClick={() => {
                    changeUserData(userData.id,registrationData, (response) => {
                        showSuccessNotification('Utente','Aggiornato con successo',3000)
                        localStorage.setItem('userData',JSON.stringify(response))
                        window.location.reload()
                    })
                }} enable={true} isLoading={false}/>
            </div>

        </div>


    );
}

