import {Skeleton} from "@mantine/core";
import {useState} from "react";


export default function GenericImg(props: { src: string | null, className?: string, onCLick?: () => void }) {
    const [isError, setIsError] = useState(false);
    const [loadImage, setLoadImage] = useState(true)
    return (
        <div className={props.className ? props.className : 'w-14 h-14'}>
            <Skeleton visible={loadImage}>
                <picture>
                    <img className={'w-full  h-full  '}
                         onError={(e) => {
                             setIsError(true)
                         }}
                         onLoad={() => {
                             setLoadImage(false)
                         }} onClick={() => {
                        if (props.onCLick !== undefined) {
                            props.onCLick()
                        }
                    }}
                         src={isError ? '' : props.src !== null ? props.src! : ''}
                         alt=""/>
                </picture>
            </Skeleton>
        </div>
    )
}

