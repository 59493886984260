enum StatusEnum {
    ACCEPTED = 'ACCEPTED',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    DONE = 'DONE',
    DEFAULT = ''
}

namespace StatusEnum {
    export function retrieveFromString(str: string): StatusEnum {
        if (str === 'ACCEPTED') {
            return StatusEnum.ACCEPTED
        } else if (str === 'REJECTED') {
            return StatusEnum.REJECTED
        } else if (str === 'PENDING') {
            return StatusEnum.PENDING
        }
        if (str === 'DONE') {
            return StatusEnum.DONE
        } else {
            return StatusEnum.DEFAULT
        }
    }

    export function retrieveValueString(str: string): String {
        if (str === 'ACCEPTED') {
            return 'Accettate'
        } else if (str === 'REJECTED') {
            return 'Rifiutate'
        } else if (str === 'PENDING') {
            return 'In attesa'
        }
        if (str === 'DONE') {
            return 'Completate'
        } else {
            return StatusEnum.DEFAULT
        }
    }

    export function getEntries(): StatusEnum[] {
        return [StatusEnum.ACCEPTED, StatusEnum.REJECTED, StatusEnum.PENDING]
    }

}

export {
    StatusEnum
}
