import {makeRequest} from "../../RestAdapter";
import {HttpMethodsEnum} from "../../HttpMethodsEnum";
import {TableType} from "../../../types/TableType";
import {KitchenType} from "../../../types/KitchenType";


export function getKitchens(onFinish:(response:KitchenType[]) => void) {
    makeRequest<KitchenType[]>(`/restaurants/kitchen/local/${JSON.parse(localStorage.getItem('selectedRestaurant') as string).id}`, HttpMethodsEnum.GET).then((res) => {
        if (res.data!==null){
            onFinish(res.data)
        }
    })
}

export function createKitchen(request:{idLocal:string,kitchenName:string},onFinish:(response: KitchenType[]) => void) {
    makeRequest<KitchenType[]>(`/restaurants/kitchen`, HttpMethodsEnum.POST,request).then((res) => {
        if (res.data!==null){
            onFinish(res.data)
        }
    })
}

export function updateKitchen(request:{id:string,kitchenName:string},onFinish:(response: KitchenType[]) => void) {
    makeRequest<KitchenType[]>(`/restaurants/kitchen/update`, HttpMethodsEnum.PUT,request).then((res) => {
        if (res.data!==null){
            onFinish(res.data)
        }

    })
}

export function deleteKitchen(request:string,onFinish:(response: any) => void) {
    makeRequest<any>(`/restaurants/kitchen/${request}`, HttpMethodsEnum.DELETE).then((res) => {
        if (res.status===200){
            onFinish(res)
        }

    })
}
