import {Divider, MultiSelect, NumberInput, Popover, Select, TagsInput, Tooltip} from "@mantine/core";
import {useEffect, useState} from "react";
import placeProd from '../../assets/placeholder-dish.svg';
import {GetProductResponseType} from "../../types/GetProductResponseType";
import {getDimension} from "../../utils/Utility";
import {StatusEnum} from "../../enums/StatusEnum";
import CustomButton from "../../components/CustomButton";

import closeIcon from '../../assets/add-icon.svg'
import {IngredientType} from "../../types/IngredientType";
import {getAllergeni} from "../../network/controllers/admin/AllergenController";
import {AllergenType} from "../../types/AllergenType";
import GenericTooltip from "../../components/generic/GenericTooltip";
import CustomTextInput from "../../components/generic/CustomTextInput";
import {isMobile} from "react-device-detect";
import {notificationSuccess, showSuccessNotification} from "../../utils/NotificationUtils";
import {useDebounce} from "use-debounce";

export default function ItemProductComponentDelivery(props: {
    ingredientList: { label: string, value: string }[],
    product: GetProductResponseType,
    onProductAdd: (quantity: number, ingredientList: IngredientType[], note: string) => void,

}) {
    const [dimension, setDimension] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [allAllergenList, setAllAllergenList] = useState<AllergenType[]>([]);
    getDimension(1400, (res: boolean) => {
        setDimension(res);
    });
    const [uniqueAllergens, setUniqueAllergens] = useState<Map<string, AllergenType>>();
    const [note, setNote] = useState<string>('');
    const [productAdd, setProductAdd] = useState(false)
    useEffect(() => {
        getAllergeni((allergeniList) => {
            setAllAllergenList(allergeniList)
        })
        var tmpArr: Map<string, AllergenType> = new Map()
        props.product.allergensList.forEach((item) => {
            if (!tmpArr.has(item.id)) {
                tmpArr.set(item.id, item);
            }

        })
        setUniqueAllergens(tmpArr)
    }, []);

    const [ingredients, setIngredients] = useState<IngredientType[]>(props.product.ingredients);


    const [productQuantity, setProductQuantity] = useState<number>(1);

    const [value] = useDebounce(productAdd, 1000);
    useEffect(() => {
        if (productAdd){
            setProductAdd(false)
        }

    }, [value])
    if (isMobile) {
        return (
            <div className={'w-full h-full flex flex-col  '}>
                <div
                    className={`flex flex-col w-full h-full pt-4 items-center justify-center' gap-x-2 `}>
                    <div className={`flex flex-col items-center justify-center w-full h-full `}>
                        <img className={'w-[200px] h-[200px] rounded-xl shadow-[3px_3px_8px_0.5px_rgba(0,0,0,0.3)] '}
                             src={props.product.approvalStatus === StatusEnum.ACCEPTED ? props.product.photoPath : placeProd}/>
                        <div className={'flex w-full h-full flex-col justify-between px-1 '}>
                            <div
                                className={'flex flex-col w-full h-full font-poppins-bold text-xl text-center mt-1 p-3 '}>
                                <div className={'w-full h-auto flex flex-col gap-x-5 '}>
                                    <div className={'w-auto p-2'}>


                                    </div>

                                    <p className={'text-white  text-center whitespace-normal'}>{props.product.nameProduct}</p>
                                    <p className={'text-white whitespace-nowrap'}> € {`${props.product.priceOriginal}  ${props.product.priceType !== 'singolo' ? props.product.priceType : ''}`}</p>
                                </div>


                                <div className={'w-full h-auto min-h-[30px] flex item-center justify-center'}>
                                    <div className={'w-auto h-auto flex flex-col'}>
                                        {ingredients.map((ing) => (
                                            <div
                                                className={'bg-yellow w-auto h-auto  flex gap-x-1 items-center justify-center  m-1 p-1  rounded-2xl'}>
                                                <GenericTooltip txt={ing.name} innerHtml={
                                                    <p className={' text-sm text-black font-poppins-regular truncate'}>
                                                        {ing.name}
                                                    </p>
                                                }
                                                />
                                                <img alt={''} src={closeIcon} className={'w-3 h-3'} onClick={() => {
                                                    setUniqueAllergens(prevState => {
                                                        const updatedListAllergen = new Map(prevState);
                                                        ing.allergenId.forEach((it) => {
                                                            updatedListAllergen.delete(it)
                                                        })
                                                        return updatedListAllergen;
                                                    })
                                                    setIngredients(ingredients.filter(item => item.name !== ing.name));
                                                }}/>
                                            </div>
                                        ))}
                                        <Popover width={300} trapFocus position="bottom" withArrow shadow="md">
                                            <Popover.Target>
                                                <div
                                                    className={'cursor-pointer ml-2 w-6 h-6 bg-yellow mt-1.5 rounded-full flex items-center justify-center hover:scale-125 duration-500'}>
                                                    <p>+</p>
                                                </div>
                                            </Popover.Target>
                                            <Popover.Dropdown>
                                                <Select
                                                    searchable={true}
                                                    size={'md'}
                                                    value={null}
                                                    data={props.ingredientList ? props.ingredientList : []}
                                                    onChange={(e) => {
                                                        if (e) {
                                                            var ing = JSON.parse(e) as IngredientType
                                                            var allergenList: AllergenType[] = []
                                                            ing.allergenId.forEach((allergenId) => {
                                                                allergenList.push(allAllergenList.find(item => item.id === allergenId) as AllergenType)
                                                            });

                                                            setUniqueAllergens(prevState => {
                                                                const updatedListAllergen = new Map(prevState);
                                                                allergenList.forEach((it) => {
                                                                    updatedListAllergen.set(it.id, it)
                                                                })
                                                                return updatedListAllergen;
                                                            })

                                                            setIngredients(prevState => {
                                                                if (!prevState.some(item => item.id === ing.id)) {
                                                                    return [...prevState, ing];
                                                                }
                                                                return prevState;
                                                            });
                                                        }
                                                    }}
                                                />
                                            </Popover.Dropdown>
                                        </Popover>
                                    </div>


                                </div>

                                <div className={'w-full flex min-h-[40px] my-3'}>
                                    {uniqueAllergens && Array.from(uniqueAllergens).length > 0 && (
                                        <div className={'flex w-full h-auto  gap-x-2 flex justify-center '}>
                                            <div className={'flex gap-x-2'}>
                                                {Array.from(uniqueAllergens).map((allergen, index) => {
                                                        return (
                                                            <Tooltip key={index} label={allergen[1].name}>
                                                                <img alt={''} className={'w-7 h-7'}
                                                                     src={allergen[1].b64Image}/>
                                                            </Tooltip>
                                                        )
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>


                                <NumberInput
                                    min={1}
                                    max={99}
                                    error={error}
                                    label='Seleziona Quantità'
                                    styles={{
                                        label: {padding: '5px', color: 'white'},
                                    }}
                                    value={productQuantity}
                                    allowDecimal={false}
                                    onChange={(e) => {
                                        if (e as number <= 99) {
                                            setProductQuantity(e as number);
                                            setError(null)
                                        } else {
                                            setError('Massimo 99 piatti')

                                        }
                                    }}
                                    allowNegative={false}
                                />

                                <div
                                    className={'w-full h-full  flex flex-col justify-center items-center gap-y-5 mt-8 '}>
                                    <CustomTextInput customDimensions={'w-full h-[50px] '} isBlack={true}
                                                     placeholder={'inserisci note aggiuntive....'}
                                                     onChange={(value) => {
                                                         setNote(value)
                                                     }}/>
                                    <CustomButton small={true} title={productAdd ? 'Aggiunto con successo' : 'Aggiungi'}
                                                  onClick={() => {
                                                      if (!productAdd) {
                                                          props.onProductAdd(productQuantity, ingredients, note)
                                                      }
                                                      setProductAdd(!productAdd)
                                                  }} enable={!error && ingredients.length > 0} isLoading={false}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'flex-col mt-4'}>
                    <Divider color={'yellow'} size="sm"/>
                </div>
            </div>
        )
    } else {
        return (
            <div className={'w-full h-full  '}>
                <div
                    className={`flex flex-col w-full h-full pt-4 ${dimension ? 'items-center justify-center' : ''} gap-x-2 `}>
                    <div className={`flex  ${dimension ? 'flex-col items-center justify-center' : ''} w-full h-full `}>
                        <img className={'w-[200px] h-[200px] rounded-xl shadow-[3px_3px_8px_0.5px_rgba(0,0,0,0.3)] '}
                             src={props.product.approvalStatus === StatusEnum.ACCEPTED ? props.product.photoPath : placeProd}/>
                        <div className={'flex w-full h-full flex-col justify-between px-5 '}>
                            <div className={'flex flex-col w-full h-full font-poppins-bold text-xl text-center mt-1'}>
                                <div className={'w-full h-auto flex  items-start gap-x-5'}>
                                    <div className={'w-2/12'}>
                                        <NumberInput
                                            min={1}
                                            max={99}
                                            error={error}
                                            value={productQuantity}
                                            allowDecimal={false}
                                            onChange={(e) => {
                                                if (e as number <= 99) {
                                                    setProductQuantity(e as number);
                                                    setError(null)
                                                } else {
                                                    setError('Massimo 99 piatti')

                                                }
                                            }}
                                            allowNegative={false}
                                        />
                                    </div>

                                    <p className={'text-white pl-2'}>{props.product.nameProduct}</p>
                                    <p className={'text-white whitespace-nowrap'}> € {`${props.product.priceOriginal}  ${props.product.priceType !== 'singolo' ? props.product.priceType : ''}`}</p>
                                </div>
                                <div className={'w-full flex min-h-[40px] my-1'}>
                                    {uniqueAllergens && Array.from(uniqueAllergens).length > 0 && (
                                        <div className={'flex w-full h-auto  gap-x-2 pl-2 '}>
                                            <div className={'flex gap-x-2'}>
                                                {Array.from(uniqueAllergens).map((allergen, index) => {
                                                        return (
                                                            <Tooltip key={index} label={allergen[1].name}>
                                                                <img alt={''} className={'w-7 h-7'}
                                                                     src={allergen[1].b64Image}/>
                                                            </Tooltip>
                                                        )
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className={'w-full h-auto min-h-[30px] flex justify-between my-2 '}>
                                    <div className={'w-auto h-auto grid grid-cols-3'}>
                                        {ingredients.map((ing) => (
                                            <div
                                                className={'bg-yellow w-auto h-auto  flex gap-x-1 items-center justify-center  m-1 p-1  rounded-2xl'}>
                                                <GenericTooltip txt={ing.name} innerHtml={
                                                    <p className={' text-sm text-black font-poppins-regular truncate'}>
                                                        {ing.name}
                                                    </p>
                                                }
                                                />
                                                <img alt={''} src={closeIcon} className={'w-3 h-3'} onClick={() => {
                                                    setUniqueAllergens(prevState => {
                                                        const updatedListAllergen = new Map(prevState);
                                                        ing.allergenId.forEach((it) => {
                                                            updatedListAllergen.delete(it)
                                                        })
                                                        return updatedListAllergen;
                                                    })
                                                    setIngredients(ingredients.filter(item => item.name !== ing.name));
                                                }}/>
                                            </div>
                                        ))}
                                        <Popover width={300} trapFocus position="bottom" withArrow shadow="md">
                                            <Popover.Target>
                                                <div
                                                    className={'cursor-pointer ml-2 w-6 h-6 bg-yellow mt-1.5 rounded-full flex items-center justify-center hover:scale-125 duration-500'}>
                                                    <p>+</p>
                                                </div>
                                            </Popover.Target>
                                            <Popover.Dropdown>
                                                <Select
                                                    searchable={true}
                                                    size={'md'}
                                                    value={null}
                                                    data={props.ingredientList ? props.ingredientList : []}
                                                    onChange={(e) => {
                                                        if (e) {
                                                            var ing = JSON.parse(e) as IngredientType
                                                            var allergenList: AllergenType[] = []
                                                            ing.allergenId.forEach((allergenId) => {
                                                                allergenList.push(allAllergenList.find(item => item.id === allergenId) as AllergenType)
                                                            });

                                                            setUniqueAllergens(prevState => {
                                                                const updatedListAllergen = new Map(prevState);
                                                                allergenList.forEach((it) => {
                                                                    updatedListAllergen.set(it.id, it)
                                                                })
                                                                return updatedListAllergen;
                                                            })

                                                            setIngredients(prevState => {
                                                                if (!prevState.some(item => item.id === ing.id)) {
                                                                    return [...prevState, ing];
                                                                }
                                                                return prevState;
                                                            });
                                                        }
                                                    }}
                                                />
                                            </Popover.Dropdown>
                                        </Popover>
                                    </div>


                                </div>

                                <div className={'w-full h-full  flex justify-between items-end  '}>
                                    <CustomTextInput customDimensions={'w-full h-[50px] pr-20'} isBlack={true}
                                                     placeholder={'inserisci note aggiuntive....'}
                                                     onChange={(value) => {
                                                         setNote(value)
                                                     }}/>
                                    <CustomButton small={true} title={'Aggiungi'} onClick={() => {
                                        props.onProductAdd(productQuantity, ingredients, note)
                                    }} enable={!error && ingredients.length > 0} isLoading={false}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'flex-col mt-4'}>
                    <Divider color={'yellow'} size="sm"/>
                </div>
            </div>
        )


    }
}
