import LocalListComponent from "./LocalListComponent";
import arrow from '../assets/arrowRight.svg';
import arrowBottom from '../assets/arrowBottom.svg';
import { formatStringDuration } from "../utils/StringUtils";
import React, {useState } from "react";
import {isMobile} from "react-device-detect";
import {License} from "../types/AddRestaurantRequest";

export default function LicenseRecapComponent(props:{license:License, imagePack:string, namePack:string}) {
    const [license, setLicence] = useState<License | null>(props.license);





    if (isMobile) {
    return (
        <div className={'w-full h-auto flex flex-col justify-center items-center '}>
            <div className={'w-full h-auto'}>
                <LocalListComponent clickable={false} showTags={true}
                                    local={JSON.parse(localStorage.getItem('selectedRestaurant')!)} />
            </div>
            <div className={'w-[25%] h-auto mt-12'}>
                <img className={'w-full h-full rotate-90'} src={arrowBottom} />
            </div>

                <div className={`w-auto h-auto flex flex-col items-center justify-center p-5 rounded-xl gap-y-2`}>
                    <img alt={''} className='w-[40%] h-auto' src={props.imagePack} />
                    <h1 className={'font-poppins-bold text-3xl'}>{props.namePack}</h1>
                    <h1 className={'text-3xl font-poppins-regular'}>{license?.name}</h1>
                    <h1 className={'font-poppins-semi-bold text-2xl'}>
                        {'€' + license?.price + ' / ' + (license?.duration ? formatStringDuration(license.duration) : 'N/A')}
                    </h1>
                </div>

        </div>
    );
}else {
        return (
        <div className={'w-full h-auto flex justify-center items-center gap-x-5 my-5 mt-32'}>
            <div className={'w-[40%] h-auto'}>
                <LocalListComponent clickable={false} showTags={false}
                                    local={JSON.parse(localStorage.getItem('selectedRestaurant')!)}/>
            </div>
            <div className={'w-[2%] h-auto'}>
                <img className={'w-full h-full'} src={arrow}/>
            </div>

            <div className={`w-auto h-auto flex flex-col items-center justify-center p-5 rounded-xl gap-y-2`}>
                <img alt={''} className='w-auto h-auto' src={props.imagePack}/>
                <h1 className={'font-poppins-bold text-3xl'}>{props.namePack}</h1>
                <h1 className={'text-3xl font-poppins-regular'}>{license?.name}</h1>
                <h1 className={'font-poppins-semi-bold text-2xl'}>
                    {'€' + license?.price + ' / ' + (license?.duration ? formatStringDuration(license.duration) : 'N/A')}
                </h1>
            </div>

        </div>
        )
    }
}



