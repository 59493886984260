import {BackgroundImage} from "@mantine/core";
import testImage from "../../assets/welcome/image1.png";
import ItemInfoComponent from "./ItemInfoComponent";
import {isMobile} from "react-device-detect";

export default function InfoComponentRight(props: { onclick: (subtitle: string) => void }) {
    var arrayListInfoComponent: {
        position: { top: string, left: string },
        info: { title: string, subtitle?: string, line2subtitle?: string }
    }[] = [
        {position: {left: '44%', top: '15%'}, info: {title: 'ORDINI'}},
        {position: {left: '70%', top: '15%'}, info: {title: 'RICERCA'}},
        {position: {left: '82%', top: '32%'}, info: {title: 'MENU'}},
        {position: {left: '82%', top: '55.5%'}, info: {title: 'PRENOTAZIONI'}},
        {position: {left: '74.2%', top: '78.3%'}, info: {title: 'GESTIONALE'}},
        {position: {left: '44%', top: '80%'}, info: {title: 'ASPORTO', subtitle: 'DOMICILIO'}},
    ]

    if (isMobile) {
        return(
            <div className={'w-[300px] h-[150px]'}>
            <BackgroundImage
                className="w-full h-full bg-contain bg-no-repeat relative"
                src={testImage}
            >
                {arrayListInfoComponent.map((item) => (
                    <ItemInfoComponent key={item.info.title} position={{left: item.position.left, top: item.position.top}} info={{
                        title: item.info.title,
                        subtitle: item.info.subtitle,
                        line2subtitle: item.info.line2subtitle
                    }} onclick={(subtitle) => {
                        props.onclick(subtitle)
                    }}/>
                ))}

            </BackgroundImage>

        </div>
        )
    } else {
        return (
            <div className={'w-[900px] h-[600px] overflow-x-auto  flex justify-around '}>
                <BackgroundImage
                    className="w-full  h-full bg-contain bg-no-repeat relative"
                    src={testImage}
                >
                    {arrayListInfoComponent.map((item) => (
                        <ItemInfoComponent key={item.info.title} position={{left: item.position.left, top: item.position.top}} info={{
                            title: item.info.title,
                            subtitle: item.info.subtitle,
                            line2subtitle: item.info.line2subtitle
                        }} onclick={(subtitle) => {
                            props.onclick(subtitle)
                        }}/>
                    ))}

                </BackgroundImage>

            </div>
        )
    }
}
