import {MultiSelect} from "@mantine/core";

export default function GenericMultiselect(props: {
    defaultValue?: any,
    maxValues?: number,
    label: string,
    data: any,
    placeholder?: string
    onSelect: (list: string[]) => void,
    onSearchChange?: (search:string) => void,
    isBlack?: boolean,
    searchable?: boolean
}) {



    return (
        <div className={'w-full'}>
            <MultiSelect
                variant={'unstyled'}
                maxValues={props.maxValues ? props.maxValues : 9999999}
                label={props.label}
                placeholder={props.placeholder}
                contentEditable
                styles={{
                    pillsList: {width: '100%'},
                    pill: {border: '1px', borderColor: 'black', backgroundColor: 'white'},
                    label: {padding: '5px', color: props.isBlack ? 'black' : 'white', fontFamily: 'semi-bold'},
                    inputField: {width: '100%'},
                    input: {width: '100%', height: '100%', backgroundColor: '#F2F2F2A8', outline: 'none'}
                }}
                searchable={props.searchable !== null ? props.searchable : false}
                defaultValue={props.defaultValue}
                size="md"
                radius={'md'}
                data={props.data}
                onSearchChange={(v)=>{
                    if (props.onSearchChange) {
                        props.onSearchChange(v)
                    }
                }}
                onChange={(e) => {
                    props.onSelect(e)
                }}
            />
        </div>

    )
}
