import {CommentType} from "../types/CommentType";
import RatingComponent from "./RatingComponent";
import iconUtente from "../assets/utente-icon.svg"

export default function CommentComponent(props: { comment: CommentType }) {


    return (
        <div className={'w-auto h-auto flex flex-row'}>
            <div
                className={'flex size-[50px] min-w-[50px] items-center justify-center mr-3 mt-3 bg-black rounded-[50%]'}>
                <img src={iconUtente}/>
            </div>
            <div className={'w-full flex flex-col bg-lightgrey font-poppins-regular rounded-t-xl rounded-br-xl p-7'}
            >
                <h2>{props.comment.user !== null ? props.comment.user.name + ' ' + props.comment.user.surname : props.comment.comment.split('|||')[0]}</h2>
                <RatingComponent readonly={true} value={props.comment.rating} onChangeValue={() => {
                }}/>
                <h1>{props.comment.user !== null ? props.comment.comment : props.comment.comment.split('|||')[1]}</h1>
            </div>
        </div>
    );
}
