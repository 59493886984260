import {useState} from 'react';
import {Transition} from '@mantine/core';
import placeholder from "../assets/placeolder-ristorante.svg";
import {PrinterShopType} from "../types/PrinterType";
import CustomButton from "./CustomButton";

export default function PrinterShopCardComponentMobile(props: { printer: PrinterShopType ,onBuyButtonClick:()=>void}) {
    const [hovered, setHovered] = useState(false);

    return (
        <div
            className={`w-[300px] ${hovered?'h-min  duration-1000 rounded-2xl':'h-48 duration-1000 '} flex flex-col bg-black rounded-t-2xl shadow-lg transition-transform  hover:scale-105 my-10 `}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}>
            <img
                className={`h-48 w-full object-cover rounded-t-2xl transition-transform duration-500  ${hovered ? 'rotate-3' : 'rotate-0'}`}
                src={props.printer.photopath64}
                alt="Placeholder"
            />
            <div className="overflow-hidden transition-all duration-500 ease-in-out">
                <div className="w-full flex flex-col gap-y-4 p-6">
                    <Transition
                        mounted={hovered}
                        duration={1000}
                        timingFunction="ease">
                        {(styles) => (
                            <>
                                <div className={'w-full flex items-center justify-center'} style={styles}>
                                    <CustomButton small={true} enable={true} isLoading={false} onClick={() => {
                                        props.onBuyButtonClick()
                                    }} title={props.printer.typePurchase === 'PURCHASE' ? 'Acquista' : 'Noleggia'}/>
                                </div>
                                <div style={styles} className="w-full flex flex-col ">
                                    <p className="text-amber-400 text-lg font-bold text-center">Modello:</p>
                                    <p className="text-amber-400 text-lg text-center">
                                        <strong>{props.printer.name}</strong></p>
                                </div>
                                <div style={styles} className="w-full flex justify-between items-center">
                                    <p className="text-amber-400 text-lg font-bold">Prezzo:</p>
                                    <p className="text-amber-400 text-lg">{`${props.printer.price} € ${props.printer.typePurchase === 'PURCHASE' ? '' : 'al mese'} `}</p>
                                </div>
                                <div style={styles} className="w-full">
                                    <p className="text-amber-400 text-lg font-bold">Descrizione:</p>
                                    <p className="text-amber-400 text-sm mt-2 leading-relaxed">{props.printer.description}</p>
                                </div>
                                <div style={styles} className="w-full">
                                    <p className="text-amber-400 text-lg font-bold">Specifiche:</p>
                                    <p className="text-amber-400 text-sm mt-2 leading-relaxed">{props.printer.specifiche}</p>
                                </div>


                            </>
                        )}
                    </Transition>

                </div>

            </div>

            {!hovered && (
                <h1 className={'w-full h-full bg-black rounded-b-lg flex justify-center items-center text-xs p-1 text-amber-400'}>
                    {props.printer.name}
                </h1>
            )}

        </div>
    );
}
