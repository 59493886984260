import {isMobile} from "react-device-detect";

export default function ItemInfoComponent(props:{position:{top:string, left:string}, info:{title:string, subtitle?:string, line2subtitle?:string}, onclick:(subtitle:string)=>void}) {

    return (
        <div
            onClick={()=>{props.onclick(props.info.title)}}
            className="absolute  flex flex-col"
            style={{top: props.position.top, left: props.position.left}} // Posiziona il div in modo preciso
        >
            {isMobile ?
                <p className="font-poppins-bold cursor-pointer hover:underline text-[8px] leading-tight">{props.info.title} <br/>
                    <span
                        className={'font-poppins-regular text-[7px]'}>{props.info.subtitle ? props.info.subtitle : ''}</span>
                    <br/> <span
                        className={'font-poppins-regular text-[7px]'}>{props.info.line2subtitle ? props.info.line2subtitle : ''}</span>
                </p> :
                <p className="font-poppins-bold cursor-pointer hover:underline text-[14px] leading-tight">{props.info.title} <br/>
                    <span
                        className={'font-poppins-regular text-xs'}>{props.info.subtitle ? props.info.subtitle : ''}</span>
                    <br/> <span
                        className={'font-poppins-regular text-xs'}>{props.info.line2subtitle ? props.info.line2subtitle : ''}</span>
                </p>}

        </div>
    )
}
