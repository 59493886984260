import {LoginResponseType} from "../types/LoginResponseType";
import React, {useEffect, useState} from "react";
import {getReservationClient} from "../network/controllers/admin/ReservationController";
import {ReservationResponseType} from "../types/ReservationResponseType";
import {useNavigate} from "react-router-dom";
import HeaderReservationFilter from "../components/HeaderReservationFilter";
import AccordionReservation from "../components/AccordionReservation";
import {StatusEnum} from "../enums/StatusEnum";
import {addAlpha} from "../utils/Utility";
import {isMobile} from "react-device-detect";
import {isTablet} from "../utils/WindowDimensionUtils";


export default function MyReservationPage() {
    const userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType;
    var navigate = useNavigate()

    const [value, setValue] = useState<{ date: Date, status: StatusEnum }>({
        date: new Date(),
        status: StatusEnum.PENDING
    });
    const [reload, setReload] = useState<boolean>(false);
    const [dataReservation, setDataReservation] = useState<Map<string, ReservationResponseType[]>>(new Map());

    useEffect(() => {
        if (userData === null) {
            navigate("/login")
        } else {
            getReservationClient(userData.email, value.status, value.date, (res) => {
                var tmp = new Map<string, ReservationResponseType[]>()
                Object.entries(res).forEach((ite) => {
                    tmp.set(ite[0], ite[1])
                })
                setDataReservation(tmp)
            })
        }

    }, [value, reload]);



    if (isMobile){
        return (
            <div className="pt-[150px] w-full h-screen flex flex-col">
                <HeaderReservationFilter reload={() => {
                    setReload(!reload)
                }} isAdmin={false} onValueChange={(res) => {
                    setValue(res)
                }}/>
                {dataReservation.size > 0 ?
                    <AccordionReservation isAdmin={false} dataReservation={dataReservation} reload={() => {
                        setReload(!reload)
                    }}/> :
                    <div className="flex-grow flex items-center justify-center">
                        <h1 className={`font-poppins-bold text-black ${isMobile && !isTablet()?'text-xl px-5 text-center':'text-3xl'} `}>{`Non hai prenotazioni ${StatusEnum.retrieveValueString(value.status)} per la data selezionata`}</h1>
                    </div>
                }
            </div>
        )

    }
    return (
        <div className="pt-[150px] w-full h-screen flex flex-col">
            <HeaderReservationFilter reload={() => {
                setReload(!reload)
            }} isAdmin={false} onValueChange={(res) => {
                setValue(res)
            }}/>
            {dataReservation.size > 0 ?
                <AccordionReservation isAdmin={false} dataReservation={dataReservation} reload={() => {
                    setReload(!reload)
                }}/> :
                <div className="flex-grow flex items-center justify-center">
                    <h1 className={`font-poppins-bold text-black ${isMobile && !isTablet()?'text-xl px-5 text-center':'text-3xl'} `}>{`Non hai prenotazioni ${StatusEnum.retrieveValueString(value.status)} per la data selezionata`}</h1>
                </div>
            }
        </div>
    )
}
