import SubscriptionPlanComponent from "../components/SubscriptionPlanComponent";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getPackages } from "../network/controllers/admin/AdminController";
import { PackageType } from "../types/PackageType";
import { getDimension } from "../utils/Utility";
import { License } from "../types/AddRestaurantRequest";
import { isMobile } from "react-device-detect";

export default function SubscritionPlanPage(props: {
    isRenew: boolean;
    allowNestStep?: () => void
    isRestaurantAdding: boolean,
    onLicenseSelect?: (pack: PackageType | undefined, license: License[], allowNextStep: boolean) => void
}) {
    const [packageList, setPackageList] = useState<PackageType[]>([]);
    const [dimension, setDimension] = useState(false)
    const [check, setCheck] = useState<boolean>(false)
    getDimension(1300, (res) => {
        setDimension(res)
    })
    var navigate = useNavigate();

    const [locationKeys, setLocationKeys] = useState([])


    useEffect(() => {
        getPackages((packages) => {
            var tmpPackArr: PackageType[] = []
            if (packages.length > 0) {
                // @ts-ignore
                tmpPackArr.push(packages.find((item) => item.name === 'BASE'))
                // @ts-ignore
                tmpPackArr.push(packages.find((item) => item.name === 'PRO'))
                // @ts-ignore
                tmpPackArr.push(packages.find((item) => item.name === 'PREMIUM-SMART'))
            }
            setPackageList(tmpPackArr)

        })
    }, []);
    const [licenseList, setLicenseList] = useState<Map<string, License>>(new Map());
    const [selectedPack, setSelectedPack] = useState<PackageType | undefined>(undefined);
    useEffect(() => {
        if (props.onLicenseSelect) {
            if (licenseList.size === 1 && selectedPack !== undefined) {
                props.onLicenseSelect(selectedPack, Array.from(licenseList.values()), true)
            } else {
                props.onLicenseSelect(selectedPack, [], false)
            }
        }
    }, [licenseList, check]);

    if (isMobile) {
        return (
            <div className={`w-full h-full flex flex-col  items-center justify-center overflow-y-auto`}>
                <h1 className={'w-full h-full text-center text-xl font-poppins-bold whitespace-normal mt-24 mb-5'}>Seleziona il piano d'abbonamento adatto a te!</h1>
                <div
                    className={`w-full h-full flex flex-col items-center gap-y-16`}>
                    {packageList.length > 0 ? packageList.map((pack) => (
                        <SubscriptionPlanComponent allowNextStep={() => {
                            if (props.allowNestStep) {
                                props.allowNestStep()
                            }
                        }}
                                                   selectedLicense={licenseList.get(pack.id)}
                                                   licenseList={Array.from(licenseList.values())}
                                                   onLicenseSelect={(license) => {
                                                       if (props.onLicenseSelect && license) {
                                                           var map = new Map();
                                                           map.set(pack.id, license)
                                                           setLicenseList(map)
                                                           setSelectedPack(pack)
                                                       }
                                                   }} isRestaurantAdding={props.isRestaurantAdding} key={Math.random()}
                                                   pack={pack}/>
                    )) : null}
                </div>
                <div className={'h-12 b-0'}>

                </div>
            </div>
        );
    } else {
        return (
            <div className={`w-full h-full flex flex-col items-center justify-center   absolute z-50`}>
                <h1 className={'text-center text-3xl font-poppins-bold'}>Seleziona il piano d'abbonamento adatto a
                    te!</h1>
                <div
                    className={`w-full h-full ${dimension ? 'flex-col items-center justify-center' : 'flex justify-center overflow-x-auto'} gap-x-5 py-10`}>
                    {packageList.length > 0 ? packageList.map((pack) => (
                        <SubscriptionPlanComponent allowNextStep={() => {
                            if (props.allowNestStep) {
                                props.allowNestStep()
                            }
                        }}
                                                   selectedLicense={licenseList.get(pack.id)}
                                                   licenseList={props.isRenew?Array.from(licenseList.values()).filter((license)=> license.price!==0):Array.from(licenseList.values())}
                                                   onLicenseSelect={(license) => {
                                                       if (props.onLicenseSelect && license) {
                                                           console.log(license)
                                                           var map = new Map();
                                                           map.set(pack.id, license)
                                                           setLicenseList(map)
                                                           setSelectedPack(pack)
                                                       }
                                                   }} isRestaurantAdding={props.isRestaurantAdding} key={Math.random()}
                                                   pack={pack} />
                    )) : null}
                </div>

            </div>
        )
    }
}
