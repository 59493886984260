import {ComandaOrder, PostCommand} from "../types/PostCommand";
import {Divider, Popover} from "@mantine/core";
import CustomButton from "./CustomButton";
import React, {useEffect, useState} from "react";
import {addCommand, comandaOrderPrint, deleteCommand, updateOrderPrint} from "../network/controllers/CommandController";
import {RestaurantStatusEnum} from "../enums/RestaurantStatusEnum";
import {showWarningNotification} from "../utils/NotificationUtils";
import GenericTooltip from "./generic/GenericTooltip";
import editIcon from "../assets/edit-icon.svg";
import {TimeInput} from "@mantine/dates";
import {isMobile} from "react-device-detect";
import confirmIcon from "../assets/confirm-icon.svg";

export default function CommandAdminCardComponent(props: { order: ComandaOrder }) {
    const totalPrice = props.order.dishes.reduce((acc, dish) => acc + dish.price, 0);

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}`;
    };

    function formatDateTimeWithoutTimezone(dateStr: string): string {
        // Parse the input date string to a Date object
        const date = new Date(dateStr);

        // Extract the date part
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');

        // Extract the time part
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

        // Format the datetime string as YYYY-MM-DDTHH:MM:SS.mmm
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }

    const [dateTimeData, setDateTimeData] = useState<Date | null>(new Date());


    const handlePrintClick = () => {
        comandaOrderPrint(props.order.id, (response) => {
            if (response.length > 0) {
                console.log("Order printed successfully:", response);
            } else {
                console.log("Failed to print order");
            }
        });
    };

    return (
        <div className={'w-auto mx-4 rounded-2xl border-t-4 shadow-lg shadow-black'}>
            <div className={'w-full h-full'}>
                <div key={props.order.id} className="w-full h-full order-details p-5 my-5">
                    <p className={`w-full h-full bg-black rounded-2xl text-center font-poppins-bold text-2xl text-amber-400`}>{props.order.type === RestaurantStatusEnum.DELIVERY ? 'DOMICILIO' : props.order.type}</p>
                    <p className={'font-poppins-regular mt-5'}><strong>Creato
                        il:</strong> {formatDate(props.order.booking_date)}</p>
                    <div className={'w-full flex items-center'}>
                        <p className={`font-poppins-regular `}><strong>Orario consegna ordine:</strong> {formatDateTimeWithoutTimezone(props.order.delivery)}</p>
                        {props.order.status!=="COMPLETA"?<Popover width={250} position="bottom" withArrow shadow="md">
                            <Popover.Target>
                                <img className={`cursor-pointer ${isMobile ? 'w-3 h-3' : 'w-5 h-5'} ml-2`} alt={''}
                                     src={editIcon}/>
                            </Popover.Target>
                            <Popover.Dropdown>
                                <TimeInput
                                    rightSection={
                                        <img className={'p-1'} alt={''} src={confirmIcon} onClick={() => {
                                            var date=new Date(dateTimeData!)
                                            if (dateTimeData?.getUTCHours()===22){
                                                date.setDate(date.getDate()+1)
                                            }
                                            var request: PostCommand = {
                                                id:props.order.id!,
                                                address: props.order.address,
                                                delivery: date.toISOString()!,
                                                dishes: props.order.dishes,
                                                email:props.order.email,
                                                idLocal:props.order.restaurantDto.id,
                                                idUser: props.order.idUser!,
                                                name: props.order.name,
                                                phoneNumber: props.order.phoneNumber,
                                                surname: props.order.surname,
                                                type: props.order.type,
                                            }
                                                addCommand(request,()=>{
                                                    window.location.reload()
                                                })
                                        }}/>
                                    }
                                    className={' mt-4 mx-4 cursor-pointer'}
                                    classNames={{
                                        input: 'my-4 cursor-pointer',
                                        label: 'text-black font-poppins-regular text-sm'
                                    }}
                                    label={'Scegli il nuovo orario'}
                                    onChange={(event) => {
                                        if (event.target.value && event.target.value.split(":").length === 2 && event.target.value.split(":")[1].length === 2) {
                                            setDateTimeData(prevState => {
                                                var date = new Date(prevState!)
                                                var value = event.target.value.split(":").map(Number);
                                                date.setHours(value[0] + 2);
                                                date.setMinutes(value[1]);
                                                date.setSeconds(0);
                                                date.setMilliseconds(0);
                                                date.setFullYear(prevState?.getFullYear() as number, prevState?.getMonth(), prevState?.getDate())
                                                return date
                                            });
                                        }
                                    }}/>
                            </Popover.Dropdown>
                        </Popover>:null}

                    </div>

                    <p className={'font-poppins-regular'}>
                        <strong>Cliente:</strong> {props.order.name} {props.order.surname}</p>
                    <p className={'font-poppins-regular mb-5'}
                       style={{
                           visibility: props.order.type === 'DELIVERY' ? 'visible' : 'hidden',
                           height: 'auto',
                           marginBottom: '1rem'
                       }}><strong>Indirizzo:</strong> {props.order.address || "\u00A0"}
                    </p>
                    <Divider color={'dark'} size={'lg'}/>
                    <p className={'w-full h-auto font-poppins-regular text-lg text-center py-2'}><strong>I MIEI
                        ORDINI</strong></p>
                    <ul className="h-64 overflow-y-auto">
                        {props.order.dishes.map((dish, index) => (
                            <li key={index} className="flex justify-between">
                                <div className="flex flex-col mt-2">
                                    <p className="font-poppins-regular mt-2">
                                        <strong>{dish.quantity} X {dish.name}</strong></p>
                                    <p className="font-poppins-regular mr-10">{dish.ingredient && dish.ingredient.length > 0 ? dish.ingredient.join(', ') : 'Nessun ingrediente'}</p>
                                </div>
                                <p className="font-poppins-regular whitespace-nowrap mt-6">
                                    <strong>€ {dish.price}</strong></p>
                            </li>
                        ))}
                    </ul>
                    <Divider color={'dark'} size={'lg'}/>
                    <div className={'flex justify-between mt-5'}>
                        <p className={'font-poppins-regular'}><strong>Totale</strong></p>
                        <p className={'font-poppins-regular'}><strong>€ {totalPrice}</strong></p>
                    </div>
                    <div className={`${props.order.status!=="COMPLETA"?'visible':'invisible'} w-full h-full flex gap-x-3 p-4 justify-center items-center`}>
                        <CustomButton title={'Stampa'} onClick={handlePrintClick} enable={true} isLoading={false}
                                      bgColor={'#FFCC00'} small={true}/>
                        <GenericTooltip innerHtml={
                            <div
                                className={` bg-opacity-100  hover:scale-110 duration-700 pointer-events-auto cursor-pointer bg-yellow rounded-xl w-[175px] h-[3rem] flex flex-col justify-center items-center px-1`}
                                onClick={() => {
                                    showWarningNotification(`Sei sicuro di voler eliminare l ordine di ${props.order.name} ${props.order.surname}?`, () => {
                                        deleteCommand(true, [props.order.id], (response) => {
                                            window.location.reload();
                                        })
                                    })
                                }}>

                                <p className={`font-poppins-bold text-[14px] w-full text-center`}>{'Elimina Comanda'}</p>
                            </div>

                        } txt={'Clicca per annullare e eliminare la comanda '}
                        />
                        <GenericTooltip innerHtml={
                            <div
                                className={` bg-opacity-100  hover:scale-110 duration-700 pointer-events-auto cursor-pointer bg-yellow rounded-xl w-[175px] h-[3rem] flex flex-col justify-center items-center px-1`}
                                onClick={() => {
                                    showWarningNotification(`Sei sicuro di voler completare l ordine di ${props.order.name} ${props.order.surname}?`, () => {
                                        updateOrderPrint(props.order.id, () => {
                                            window.location.reload();
                                        })
                                    })
                                }}>

                                <p className={`font-poppins-bold text-[14px] w-full text-center`}>{'Completa Comanda'}</p>
                            </div>

                        } txt={'Clicca per completare e nascondere la comanda '}
                        />

                    </div>
                </div>
            </div>
        </div>
    );
}
