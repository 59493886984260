import {Grid} from "@mantine/core";
import roundTableIconGreen from "../assets/round-table-icon-green.svg";
import roundTableIconRed from "../assets/round-table-icon-red.svg";

export default function TableCashDeskComponent() {
    var arrayMok = [roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen, roundTableIconRed, roundTableIconGreen]
    return (
        <div className={'w-[98%] h-full overflow-auto mt-[30px]'}>

            <Grid className={'w-auto h-full overflow-auto'}>
                {arrayMok.map((item) => (
                    <Grid.Col span={{base: 12, md: 3, lg: 2}}>Tavolo X
                        <img src={item}/>
                    </Grid.Col>
                ))}
            </Grid>
        </div>
    )
}

