import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Divider, Popover, Slider} from "@mantine/core";
import {isTablet} from "../utils/WindowDimensionUtils";
import CustomButton from "./CustomButton";
import {LoginResponseType} from "../types/LoginResponseType";
import iconMenu from "../assets/menu-icon-admin.svg";
import GoogleAddressSearch from "./GoogleAddressSearch";
import {isMobile} from "react-device-detect";

export default function MenuMyAccountMobile() {
    const [popOverStatus, setPopOverStatus] = useState(false)
    let navigate = useNavigate()
    var userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType
    const [dataSearch, setDataSearch] = useState<{
        geometry: any,
        radius: number
    }>(() => {
        const storedData = JSON.parse(localStorage.getItem('geoData') as string);
        return {
            geometry: storedData?.geometry ?? null,
            radius: storedData?.radius >= 1 ? storedData.radius : 1 // Ensure minimum radius is 1
        };
    });

    useEffect(() => {
        localStorage.setItem('geoData', JSON.stringify(dataSearch))
    }, [dataSearch])

    return (
        <Popover opened={popOverStatus} width={300} position="bottom-end" withArrow shadow="md" onOpen={() => {
            setPopOverStatus(true)
        }} onClose={() => setPopOverStatus(false)}>
            <Popover.Target>
                <img alt={''} src={iconMenu} onClick={()=>{setPopOverStatus(true)}}/>
            </Popover.Target>
            <Popover.Dropdown classNames={{dropdown: 'dropDownLogin'}}>
                <div className={' flex flex-col items-center py-2'}>
                    {!userData ?
                        <div>
                            <div className={'w-full flex items-center justify-center'}>
                                {<CustomButton title={'Accedi'} small={true} onClick={() => {
                                    localStorage.setItem('path', window.location.pathname)
                                    navigate('/login')
                                }} enable={true} isLoading={false}/>}
                            </div>
                            <div className={'flex  mt-3 mb-8'}>
                                <h1 className={`${isMobile && !isTablet() ? 'text-[12px]' : ''} mr-1 font-poppins-regular`}>Non
                                    sei ancora iscritto?</h1>
                                <h1 className={`${isMobile && !isTablet() ? 'text-[12px]' : ''} underline font-poppins-regular text-color-link cursor-pointer hover:scale-105 duration-300`}
                                   onClick={() => {
                                       navigate('/registration')
                                   }}>Iscriviti qui</h1>
                            </div>
                        </div> : null}


                    <div className={'w-full flex flex-col px-5  gap-y-1.5'}>
                        <h1 className={'font-bold text-lg mr-1  font-poppins-bold '}>Il mio account</h1>

                        {/*<h1 className={`${isMobile && !isTablet()?'text-[12px]':''} font-poppins-regular mr-1 hover:text-color-link hover:underline duration-200 cursor-pointer`}
                           onClick={() => {
                               setPopOverStatus(false)
                               navigate('/profilo/ordini')
                           }}>Dashboard</h1>

                        <h1 className={`${isMobile && !isTablet()?'text-[12px]':''} font-poppins-regular mr-1 hover:text-color-link hover:underline duration-200 cursor-pointer`}
                           onClick={() => {
                               setPopOverStatus(false)
                               navigate('/profilo/ordini')

                           }}>Impostazioni</h1>






                        <h1 className={`${isMobile && !isTablet()?'text-[12px]':''} font-poppins-regular mr-1 hover:text-color-link hover:underline duration-200 cursor-pointer`}
                           onClick={() => {
                               setPopOverStatus(false)
                               navigate('/profilo/ordini')

                           }}>I Miei Piatti Preferiti</h1>*/}





                        {userData ?
                            <h1 className={`${isMobile && !isTablet() ? 'text-[12px]' : ''} font-poppins-regular mr-1 hover:text-color-link hover:underline duration-200 cursor-pointer`}
                                onClick={() => {
                                    setPopOverStatus(false)
                                    navigate('/favourite')

                                }}>I Miei Ristoranti Preferiti</h1> : null}

                        {userData ?
                            <h1 className={`${isMobile && !isTablet() ? 'text-[12px]' : ''} font-poppins-regular mr-1 hover:text-color-link hover:underline duration-200 cursor-pointer`}
                                onClick={() => {
                                    setPopOverStatus(false)
                                    navigate('/prenotazioni')

                                }}>Le Mie Prenotazioni</h1> : null}

                        {userData?<h1 className={`${isMobile && !isTablet() ? 'text-[12px]' : ''} font-poppins-regular mr-1 hover:text-color-link hover:underline duration-200 cursor-pointer`}
                                      onClick={() => {
                                          setPopOverStatus(false)
                                          navigate('/commandClient')

                                      }}>I Miei Ordini</h1>:null}

                        {userData && userData.role.includes('ADMIN') ?
                            <h1 className={`${isMobile && !isTablet() ? 'text-[12px]' : ''} font-poppins-regular mr-1 hover:text-color-link hover:underline duration-200 cursor-pointer`}
                                onClick={() => {
                                    setPopOverStatus(false)
                                    window.open('/admin')

                                }}>Admin</h1> : null}


                        {userData ? <div onClick={() => {
                            var geoData = localStorage.getItem('geoData')
                            var formattedAddress = localStorage.getItem('formatted_address')
                            localStorage.clear()
                            localStorage.setItem('geoData', geoData!)
                            localStorage.setItem('formatted_address', formattedAddress!)
                            window.location.reload()
                        }}><h1
                            className={`${isMobile && !isTablet() ? 'text-[12px]' : ''} font-poppins-regular mr-1 hover:text-color-link hover:underline duration-200 cursor-pointer`}>Logout</h1>
                        </div> : null}

                        <Divider my="sm" variant={'dashed'} color={'dark'}/>
                    </div>

                    <div className={'w-full flex flex-col items-center py-5 gap-y-7'}>
                        <div className={'flex flex-col justify-center w-full items-center gap-y-2'}>

                            <div className={'flex gap-x-3'}>
                                <h1 className={'font-poppins-semi-bold text-xs'}>con un raggio di:</h1>
                                <h1 className={'font-poppins-semi-bold text-xs'}>{dataSearch.radius}KM</h1>
                            </div>
                        </div>
                        <Slider
                            className={'w-full'}
                            max={50}
                            min={1} // Set minimum radius to 1
                            defaultValue={dataSearch.radius}
                            color="yellow"
                            marks={[
                                {value: 10, label: ''},
                                {value: 20, label: ''},
                                {value: 30, label: ''},
                                {value: 40, label: ''},
                            ]}

                            onChangeEnd={(value) => {
                                setDataSearch({...dataSearch, radius: value})
                                window.location.reload()

                            }}
                        />
                        <div className={'w-full flex flex-col items-center justify-center'}>
                            <h1 className={'font-poppins-semi-bold text-xs'}>Stai cercando:</h1>
                            <GoogleAddressSearch
                                inputPlaceholder={localStorage.getItem('closedGeodata')!==null && localStorage.getItem('formatted_address')?(localStorage.getItem('formatted_address') as string).replace('\"', '').replace('\"', ''):'Via Pitagora ,98051 Barcellona Pozzo di Gotto'}
                                setNameTextInput={'formatted_address'} defaultOpen={false}
                                onPlaceSelect={(value) => {
                                    setDataSearch({...dataSearch, geometry: value.geometry.location})
                                    localStorage.setItem('formatted_address', JSON.stringify(value.formatted_address))
                                    window.location.reload()

                                }}/>
                        </div>
                    </div>
                </div>
            </Popover.Dropdown>
        </Popover>
    )
}
