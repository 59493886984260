import {Rating} from '@mantine/core';
import {MantineSize} from "@mantine/core/lib/core";

export default function RatingComponent(props: {
    readonly: boolean,
    size?: MantineSize,
    value?: number,
    onChangeValue: (value: number) => void
}) {

    return <div className={'flex items-center '}>
        <Rating size={props.size} onChange={(value) => {
            props.onChangeValue(value)
        }} className={''} fractions={2} defaultValue={props.value} count={5} readOnly={props.readonly}/>
    </div>
}
