import {TextInput, PasswordInput} from '@mantine/core';
import passwordVisible from '../../assets/passwordViss.svg'
import passwordHide from '../../assets/passordHide.svg'
import {useEffect, useState} from "react";

export default function CustomTextInput({
                                            customDimensions = '',
                                            isPassword = false,
                                            defaultValue = '',
                                            label = "",
                                            white=false,
                                            placeholder = "",
                                            description = "",
                                            readOnly  = false,
                                            required  = false,
                                            onChange = (text: string) => {
                                            },
                                            isBlack = false,
                                            onEnterPress = () => {
                                            }
                                        }) {

    const [passwordVisibleStatus, setPasswordVisibleStatus] = useState(false)

    const [value, setValue] = useState(defaultValue)


    useEffect(() => {
        setValue(defaultValue)
        console.log('defaultValue', defaultValue)
    }, [defaultValue]);
    return (
        <div className={` rounded-md   ${customDimensions !== '' ? customDimensions : 'w-full'}`} onKeyDown={(e) => {
        }}>
            {isPassword ? <PasswordInput
                    onKeyPress={(e) => {
                        if (e.code === 'Enter') {
                            onEnterPress()
                        }
                    }}
                    rightSection={<img alt={''} onClick={() => {
                        setPasswordVisibleStatus(!passwordVisibleStatus)
                    }} className={'mr-10'} src={passwordVisibleStatus ? passwordVisible : passwordHide}/>}
                    label={label}
                    value={value}

                    visible={passwordVisibleStatus}
                    classNames={{
                        label: isBlack ? 'labelStyleBlack' : 'labelStyle',
                        input: 'inputStyle',
                        wrapper: white?'wrapperStyleWhite':'wrapperStyle'
                    }}
                    description={description}
                    placeholder={placeholder}
                    onChange={(res) => {
                        setValue(res.currentTarget.value)
                        onChange(res.currentTarget.value)
                    }}
                /> :
                <TextInput required={required}
                    onKeyPress={(e) => {
                        if (e.code === 'Enter') {
                            onEnterPress()
                        }
                    }}
                    classNames={{
                        label: isBlack ? 'labelStyleBlack' : 'labelStyle',
                        input: 'inputStyle',
                        wrapper: white?'wrapperStyleWhite':'wrapperStyle'
                    }}
                    label={label}
                    readOnly={readOnly}
                           value={value}
                    description={description}
                    placeholder={placeholder}
                    onChange={(res) => {
                        onChange(res.currentTarget.value)
                    }}
                />}
        </div>


    )
}
