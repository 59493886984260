function checkInclusion(baseString: string, includedString: string): boolean {
    return baseString.toLowerCase().includes(includedString.toLowerCase())
}


function searchData<T>(elementsList: any[], keyList: string[], searchText: string): T[] {
    let tmpList: T[] = []

    elementsList.forEach(item => {
        let boolsList: boolean[] = []
        keyList.forEach(key => {
            boolsList.push(checkInclusion(item[key], searchText))
        })
        if (boolsList.includes(true)) {
            tmpList.push(item)
        }
    })

    return tmpList
}


export function searchItem<T>(txt: string, list: T[], keyList: string[]): T[] {
    if (txt.length === 0) {
        return list
    } else {
        return searchData<T>(list, keyList, txt)
    }
}

export function validateEmail(email: string) {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
}

export function validatePassword(password: string) {
    const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!-@#\$%\^&\*])");
    return regex.test(password);
}


export function formatStringDuration(duration: string): string {
    var tmpString = ''
    var time = parseInt(duration.split('-')[0]!)
    var period = duration.split('-')[1]
    if (period === 'y') {
        tmpString = time > 1 ? time + ' Anni' : time + '   Anno'
    } else if (period === 'm') {
        tmpString = time > 1 ? time + '    Mesi' : time + '    Mese'
    } else if (period === 'w') {
        tmpString = time > 1 ? time + '    Settimane' : time + '    Settimana'
    }else if (period === 'd') {
        tmpString = time > 1 ? time + '    Giorni' : time + '    Giorno'
    }
    return tmpString
}


