import { Divider, Tooltip } from "@mantine/core";
import { GetProductResponseType } from "../types/GetProductResponseType";
import {useEffect, useState} from "react";
import { getDimension } from "../utils/Utility";
import { StatusEnum } from "../enums/StatusEnum";
import placeProd from '../assets/placeholder-dish.svg';
import potIcon from "../assets/pot-icon.png";
import divider from "../assets/divider.png";

export default function QrItemComponent(props: { product: GetProductResponseType }) {
    let ingredients = props.product.ingredients.map(ingredient => ingredient.name).join(', ');
    let uniqueAllergens = Array.from(new Set(props.product.allergensList.map(item => item.b64Image)));
    let description = props.product.description;
    const [dimension, setDimension] = useState(false);


    useEffect(() => {
        getDimension(1400, (res: boolean) => {
            setDimension(res);
        });
    }, []);

    return (
        <div className={'flex flex-col w-full overflow-y-auto '}>
            <div className={`flex flex-col w-full h-full pt-2 ${dimension ? 'items-center justify-center' : ''} gap-x-2`}>
                <div className={`flex flex-col items-center justify-center ${dimension ? 'flex-col items-center justify-center' : ''} w-full`}>
                    <img className={'w-[280px] h-[280px] rounded-xl shadow-2xl'}
                         src={props.product.approvalStatus === StatusEnum.ACCEPTED ? props.product.photoPath : placeProd}
                         alt={props.product.nameProduct} />
                    <div className={'flex w-full flex-col justify-between px-5 pt-3'}>
                        <div className={'flex flex-col w-full h-auto font-poppins-bold text-xl text-center mt-1'}>
                            <div className={'w-full h-auto flex justify-center items-center'}>
                                <h1 className={'pl-2 bg-'}>{props.product.nameProduct}</h1>
                            </div>
                            <h1 className={'whitespace-nowrap'}> € {props.product.priceOriginal}</h1>
                        </div>
                        <div className="flex flex-col w-full h-full items-center justify-center gap-x-2 pl-2 pt-3">
                            <img className="h-7 w-auto mx-auto" src={potIcon} alt="Pot Icon" />
                            <h1 className={'w-auto h-full text-sm font-poppins-semi-bold whitespace-normal text-center pl-2 pt-2'}>
                                {ingredients}
                            </h1>
                        </div>
                        {description && (
                            <>
                                <img className={'w-[20%] h-[20%] items-center justify-center mx-auto'} src={divider} alt="Divider" />
                                <div className="flex flex-row w-full h-full items-center justify-center gap-x-2 pl-2">
                                    <h1 className="w-[70%] h-full text-gray-600 font-poppins-regular text-center text-sm whitespace-normal">
                                        {description}
                                    </h1>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {uniqueAllergens.length > 0 && (
                <div className={'flex w-full h-auto gap-x-2 py-5 pl-8'}>
                    <h1 className={'font-poppins-semi-bold text-sm'}>Allergeni:</h1>
                    <div className={'flex gap-x-2'}>
                        {uniqueAllergens.map((image, index) => {
                            let allergen = props.product.allergensList.find(a => a.b64Image === image);
                            if (allergen) {
                                return (
                                    <Tooltip key={index} label={allergen.name}>
                                        <img className={'w-6'} src={allergen.b64Image} alt={allergen.name} />
                                    </Tooltip>
                                )
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </div>
            )}
            <div className={'flex-col mx-8'}>
                <Divider color={'yellow'} size="md" />
            </div>
        </div>
    );
}


