import CustomButton from "../CustomButton";
import React from "react";
import {ImageResponseCard} from "./ChatWindow";
import {useNavigate} from "react-router-dom";

export default function ChatCardReservationRecap(props: {
    cardData: ImageResponseCard;
    onSend: (value: string) => void;
    onClose: () => void,

}) {
    return (
        <div className="w-full h-auto shadow-2xl bg-yellow rounded-2xl  mt-2 flex flex-col gap-y-2">
            <img className="rounded-xl p-2" alt={props.cardData.title} src={props.cardData.imageUrl}/>
            <div className={'w-full h-auto bg-white p-2 rounded-b-2xl'}>
                {
                    props.cardData.title.split('Cg1!').map((item) => (
                        <p className={'font-poppins-semi-bold'}>{item}</p>
                    ))
                }
                {props.cardData.subtitle.split('Cg1!').map((item, index) => (
                    <p key={index} className="font-poppins-semi-bold">{item}</p>
                ))}
                <div className="w-full flex justify-center gap-x-3 my-2 r">
                    {props.cardData.buttons && props.cardData.buttons.length > 0 ? props.cardData.buttons.map((button, index) => (
                        <CustomButton
                            key={index}
                            small={true}
                            title={button.text}
                            enable={true}
                            isLoading={false}
                            onClick={() => {
                                    props.onSend(button.value);

                            }}
                        />
                    )) : null}
                </div>
            </div>

        </div>
    );
}
