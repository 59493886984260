import {useEffect, useState} from "react";
import {ClientType} from "../../../types/ClientType";
import CustomTextInput from "../../../components/generic/CustomTextInput";
import {checkUser} from "../../../network/controllers/seller/GenericSellerController";
import GoogleAddressSearch from "../../../components/GoogleAddressSearch";
import {isMobile} from "react-device-detect";
import {isTablet} from "../../../utils/WindowDimensionUtils";
import {Checkbox} from "@mantine/core";

export default function SearchClientDataForm(props: {
    data: any, readonly: boolean
    allowNext: (allowed: boolean, data: any | null) => void
}) {
    const [data, setData] = useState(props.data);
    const [clientData, setClientData] = useState<ClientType>({
        address: "",
        auth: "",
        cf: "",
        city: "",
        civicNumber: "",
        email: "",
        id: "",
        idLocal: undefined,
        idOrder: undefined,
        idSocial: "",
        idTable: undefined,
        inTheTable: false,
        name: "",
        nameSocial: "",
        phone: "",
        role: [],
        social: false,
        state: "",
        surname: "",
        systemDevice: undefined,
        temporaryPassword: false,
        tokensFirebase: [],
        zipCode: ""
    });

    function retrieveAddressData(addressData: any) {
        var tmpdata = clientData
        addressData.forEach((item: any) => {
            if (item.types.includes('route')) {
                tmpdata.address = item.long_name
            }
            if (item.types.includes('locality')) {
                tmpdata.city = item.long_name
            }
            if (item.types.includes('country')) {
                tmpdata.state = item.long_name
            }
            if (item.types.includes('postal_code')) {
                tmpdata.zipCode = item.long_name
            }
            if (item.types.includes('street_number')) {
                tmpdata.civicNumber = item.long_name
            }
            setClientData({
                ...clientData,
                address: tmpdata.address,
                city: tmpdata.city,
                state: tmpdata.state,
                zipCode: tmpdata.zipCode,
                civicNumber: tmpdata.civicNumber,
            })


        })
    }

    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [allowPermission, setAllowPermission] = useState<boolean>(false)
    const [allowNewsLetter, setAllowNewsLetter] = useState<boolean>(false)


    useEffect(() => {
        if (clientData.name !== '' &&
            clientData.surname !== '' &&
            clientData.email !== '' &&
            clientData.phone !== '' &&
            clientData.phone.length === 10 &&
            clientData.address !== '' &&
            clientData.civicNumber !== '' &&
            clientData.city !== '' &&
            clientData.zipCode !== '' &&
            clientData.state !== '' &&
            clientData.cf !== '' &&
            allowPermission) {
            props.allowNext(true, clientData)
        } else {
            props.allowNext(false, null)
        }
    }, [clientData,allowPermission]);


    const handleUpdate = (field: string, value: string | string[] | any | Date) => {
        setClientData({
            ...clientData,
            [field]: value
        })
    }


    if (isMobile) {
        return (
            <div>
                <div className={'w-full h-auto  flex flex-col '}>
                    <div className={'w-full flex flex-col '}>
                        <CustomTextInput required={true} isBlack={true} readOnly={readOnly}
                                         defaultValue={clientData.name}
                                         label={'Nome'}
                                         onChange={(value) => {
                                             handleUpdate('name', value)
                                         }}/>
                        <CustomTextInput required={true} isBlack={true} readOnly={readOnly}
                                         defaultValue={clientData.surname}
                                         label={'Cognome'}
                                         onChange={(value) => {
                                             handleUpdate('surname', value)
                                         }}/>
                        <CustomTextInput required={true} isBlack={true} readOnly={readOnly}
                                         defaultValue={clientData.email}
                                         label={'Email'}
                                         onChange={(value) => {
                                             handleUpdate('email', value)
                                         }}/>
                        <CustomTextInput required={true} isBlack={true} readOnly={readOnly}
                                         defaultValue={clientData.phone}
                                         label={'Telefono'}
                                         onChange={(value) => {
                                             handleUpdate('phone', value)
                                         }}/>
                        <CustomTextInput required={true} isBlack={true} readOnly={readOnly} defaultValue={clientData.cf}
                                         label={'C.F.'}
                                         onChange={(value) => {
                                             handleUpdate('cf', value)
                                         }}/>


                    </div>

                    <div className={'w-full flex gap-x-3'}>
                        <GoogleAddressSearch
                            defaultOpen={false}
                            required={true}
                            isBlack={true}
                            inputDimensions={'w-full h-[60px]'}
                            setNameTextInput={'formatted_address'} width={'100px'}
                            label={'Indirizzo (completo di città e numero civico)'}
                            suggestionDimensions={'w-full '}
                            onPlaceSelect={(v) => {
                                retrieveAddressData(v.address_components)
                            }}/>

                    </div>
                    <div
                        className={'w-auto flex flex-col justify-start gap-y-2 bg-[#F2F2F2A8] rounded-xl p-2  py-5 mt-10'}>

                        <div className={'w-auto flex items-center '}>
                            <Checkbox color={'#FFCC00'} checked={allowPermission} onChange={() => {
                                setAllowPermission(!allowPermission)
                            }}/>
                            <a className={'w-auto font-poppins-bold ml-2 underline text-black'}
                               href="https://www.iubenda.com/termini-e-condizioni/86809145" target="_blank">Accetto
                                termini e condizioni d'uso </a>
                        </div>

                        <div className={'w-auto flex '}>
                            <Checkbox color={'#FFCC00'} checked={allowNewsLetter} onChange={() => {
                                setAllowNewsLetter(!allowNewsLetter)
                            }}/>
                            <a className={'w-auto font-poppins-semi-bold ml-2 '}>Iscriviti alla
                                Newsletter</a>
                        </div>


                    </div>
                </div>

            </div>
        )
    } else {
        return (
            <div>
                <div className={'w-full flex flex-col '}>
                    <div className={'w-full flex gap-x-3 mt-10'}>
                        <CustomTextInput required={true} isBlack={true} readOnly={readOnly}
                                         defaultValue={clientData.name} label={'Nome'}
                                         onChange={(value) => {
                                             handleUpdate('name', value)
                                         }}/>
                        <CustomTextInput required={true} isBlack={true} readOnly={readOnly}
                                         defaultValue={clientData.surname}
                                         label={'Cognome'}
                                         onChange={(value) => {
                                             handleUpdate('surname', value)
                                         }}/>
                    </div>
                    <div className={'w-full flex gap-x-3 mt-10'}>
                        <CustomTextInput required={true} isBlack={true} readOnly={readOnly}
                                         defaultValue={clientData.email} label={'email'}
                                         onChange={(value) => {
                                             handleUpdate('email', value)
                                         }}/>
                        <CustomTextInput required={true} isBlack={true} readOnly={readOnly}
                                         defaultValue={clientData.phone}
                                         label={'Telefono'}
                                         onChange={(value) => {
                                             handleUpdate('phone', value)
                                         }}/>


                    </div>

                    <div className={'w-full flex gap-x-3 mt-10'}>
                        <GoogleAddressSearch
                            required={true}
                            isBlack={true}
                            inputDimensions={'w-full h-[60px]'}
                            setNameTextInput={'formatted_address'} width={'100px'}
                            label={'Indirizzo (completo di città e numero civico)'}
                            suggestionDimensions={'w-full '}
                            onPlaceSelect={(v) => {
                                retrieveAddressData(v.address_components)
                            }}/>
                        <CustomTextInput required={true} customDimensions={'h-[60px] w-full'} isBlack={true} readOnly={readOnly}
                                         defaultValue={clientData.cf} label={'C.F.'}
                                         onChange={(value) => {
                                             handleUpdate('cf', value)
                                         }}/>
                    </div>
                </div>
                <div className={'w-full flex items-center justify-center '}>
                    <div className={'w-auto px-20 flex-col  items-center justify-center  bg-[#F2F2F2A8] rounded-xl p-2  py-5'}>
                        <div className={'w-auto flex items-center my-3 '}>
                            <Checkbox color={'#FFCC00'} checked={allowPermission} onChange={() => {
                                setAllowPermission(!allowPermission)
                            }}/>
                            <a className={'w-auto font-poppins-bold ml-2 underline text-black'}
                               href="https://www.iubenda.com/termini-e-condizioni/86809145" target="_blank">Accetto
                                termini e condizioni d'uso </a>
                        </div>

                        <div className={'w-auto flex '}>
                            <Checkbox color={'#FFCC00'} checked={allowNewsLetter} onChange={() => {
                                setAllowNewsLetter(!allowNewsLetter)
                            }}/>
                            <a className={'w-auto font-poppins-semi-bold ml-2 '}>Iscriviti alla
                                Newsletter</a>
                        </div>

                    </div>


                </div>


            </div>
        )
    }
}
