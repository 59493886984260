//icone array gestione locali
import tavoliIcon from '../assets/menu-icons/icon-tables.svg'
import prodottiIcon from '../assets/menu-icons/icona-prodotti.svg'
import ingredientiIcon from '../assets/menu-icons/ingredients-icon.svg'
import cucineIcon from '../assets/menu-icons/icona-cucine.svg'
import turniIcon from '../assets/menu-icons/icona-turni.svg'
import personaleIcon from '../assets/menu-icons/icona-personale.svg'
import prenotazioniIcon from '../assets/menu-icons/icona-prenotazioni.svg'
import gestMenuIcon from '../assets/menu-icons/icona-gest-menu.svg'
import listIcon from '../assets/menu-icons/icona-liste.svg'


//icone array ordini
import storicoOrdiniIcon from '../assets/menu-icons/icona-storico-ordini.svg'
import ordiniCorrentiIcon from '../assets/menu-icons/icona-ordini-correnti.svg'
import shopicon from '../assets/shop-icon.svg'


//icone array il mio account
import dashboardIcon from '../assets/menu-icons/icona-dashboard.svg'
import abbonamentiIcon from '../assets/menu-icons/icona-abbonamenti.svg'
import impostazioniIcon from '../assets/menu-icons/icona-impostazioni.svg'
import richiesteIcon from '../assets/menu-icons/icona-richieste.svg'


//icone seller
import statsIcon from '../assets/menu-icons/stats-icon.svg'
import printerIcon from '../assets/menu-icons/printer.svg'
import commandIcon from '../assets/menu-icons/receipt.svg'

import addRestaurantIcon from '../assets/menu-icons/add-restaurant-icon.svg'
import restaurantIcon from '../assets/menu-icons/restaurant-icon.svg'
import bankIcon from '../assets/menu-icons/bank-icon.svg'
import MenuAdminPage from "../pages/admin/MenuAdminPage";

import TableAdminPage from "../pages/admin/TableAdminPage";
import ReservationAdminPage from "../pages/admin/ReservationAdminPage";
import TurnsAdminPage from "../pages/admin/TurnsAdminPage";
import SettingsAdminPage from "../pages/admin/SettingsAdminPage";
import ProductAdminStepperPage from "../pages/admin/product/ProductAdminStepperPage";
import SubscritionPlanPage from "../pages/SubscritionPlanPage";
import DashboardAdminPage from "../pages/admin/DashboardAdminPage";
import ProductAdminPage from "../pages/admin/product/ProductAdminPage";
import MyLocalPage from "../pages/MyLocalPage";
import RequestAdminPage from "../pages/admin/RequestAdminPage";
import {PackEnum} from "../enums/PackEnum";
import IngredientsAdminPage from "../pages/admin/IngredientsAdminPage";
import KitchenAdminPage from "../pages/admin/KitchenAdminPage";

import PrinterPage from "../pages/PrinterPage";
import CommandAdminPage from "../pages/CommandAdminPage";
import ShopPage from "../pages/ShopPage";



export var routeListArray = [{
    path: '/menu',
    element: <MenuAdminPage/>,
    children: [],
    pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
},
    {
        path: '/ingredienti',
        element: <IngredientsAdminPage/>,
        children: [],
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    },
    {
        path: '/comande',
        element: <CommandAdminPage/>,
        children: [],
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    }, {
        path: '/stampanti',
        element: <PrinterPage/>,
        children: [],
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    },{
        path: '/shop',
        element: <ShopPage/>,
        children: [],
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    },
    {
        path: '/cucine',
        element: <KitchenAdminPage/>,
        children: [],
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    },
    {
        path: '/tavoli',
        element: <TableAdminPage/>,
        children: [],
        pack: [PackEnum.PRO, PackEnum.PREMIUMSMART]
    }, {
        path: '/prenotazioni',
        element: <ReservationAdminPage/>,
        children: [],
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    },
    {
        path: '/turni',
        element: <TurnsAdminPage/>,
        children: [],
        pack: [PackEnum.PRO, PackEnum.PREMIUMSMART]
    },
    {
        path: '/impostazioni',
        element: <SettingsAdminPage/>,
        children: [],
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    },
    {
        path: '/prodotti/aggiunta',
        element: <ProductAdminStepperPage/>,
        children: [],
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    }, {
        path: '/prodotti',
        element: <ProductAdminPage/>,
        children: [],
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    }, {
        path: '/abbonamenti',
        element: <SubscritionPlanPage isRenew={false} isRestaurantAdding={false}/>,
        children: [],
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]

    },
    {
        path: '/dashboard',
        element: <DashboardAdminPage/>,
        children: [],
        pack: [PackEnum.PRO, PackEnum.PREMIUMSMART]
    },
    {
        path: '/reservation',
        element: <ReservationAdminPage/>,
        children: [],
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    }, {
        path: '/richieste',
        element: <RequestAdminPage/>,
        children: [],
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    },
    {
        path: '/ilmiolocale',
        element: <MyLocalPage/>,
        children: [],
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    },]


export var gestioneLocaliArray = [
    {
        img: prenotazioniIcon,
        txt: 'Il mio Ristorante',
        path: '/admin/ilmiolocale',
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    },
    {
        img: prenotazioniIcon,
        txt: 'Prenotazioni',
        path: '/admin/reservation',
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    },
    {
        img: gestMenuIcon,
        txt: 'Gestione Menu',
        path: '/admin/menu',
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    },
    {
        img: listIcon,
        txt: 'Ingredienti',
        path: '/admin/ingredienti',
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    },
    {
        img: printerIcon,
        txt: 'Stampanti',
        path: '/admin/stampanti',
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    },{
        img: commandIcon,
        txt: 'Comande',
        path: '/admin/comande',
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    }, {
        img:shopicon ,
        txt: 'Shop',
        path: '/admin/shop',
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    },{
        img: listIcon,
        txt: 'Cucine',
        path: '/admin/cucine',
        pack: [PackEnum.PREMIUMSMART]
    },
    {
        img: tavoliIcon,
        txt: 'Tavoli',
        path: '/admin/tavoli',
        pack: [PackEnum.PRO, PackEnum.PREMIUMSMART]
    },
    {
        img: turniIcon,
        txt: 'Turni',
        path: '/admin/turni',
        pack: [PackEnum.PRO, PackEnum.PREMIUMSMART]
    }, {
        img: personaleIcon,
        txt: 'Personale',
        path: '/admin/personale',
        pack: [PackEnum.PRO, PackEnum.PREMIUMSMART]
    }
]

export var ordiniArray = [
    {
        img: storicoOrdiniIcon,
        txt: 'Storico Ordini',
        path: '/admin/ordiniStorico',
        pack: [PackEnum.PRO, PackEnum.PREMIUMSMART]
    }, {
        img: ordiniCorrentiIcon,
        txt: 'Ordini Correnti',
        path: '/admin/ordiniCorrenti',
        pack: [PackEnum.PRO, PackEnum.PREMIUMSMART]
    }
]


export var myAccountArray = [
    {
        img: dashboardIcon,
        txt: 'Dashboard',
        path: '/admin/dashboard',
        pack: ['PRO', 'PREMIUM']
    }, /*{
        img: abbonamentiIcon,
        txt: 'Abbonamenti',
        path: '/admin/abbonamenti',
        pack:[PackEnum.BASE,PackEnum.PRO,PackEnum.PREMIUMSMART]
    },*/{
        img: impostazioniIcon,
        txt: 'Impostazioni',
        path: '/admin/impostazioni',
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    }, {
        img: richiesteIcon,
        txt: 'Richieste',
        path: '/admin/richieste',
        pack: [PackEnum.BASE, PackEnum.PRO, PackEnum.PREMIUMSMART]
    }
]

export var sellerMenuArray = [
    {
        img: statsIcon,
        txt: 'Statistiche',
        path: '/seller/statistiche',

    }, {
        img: personaleIcon,
        txt: 'Lista Clienti',
        path: '/seller/clienti'
    }, {
        img: addRestaurantIcon,
        txt: 'Aggiungi Ristorante',
        path: '/seller/addRestaurant'
    }, {
        img: restaurantIcon,
        txt: 'Lista Ristoranti',
        path: '/seller/ristoranti'
    }, {
        img: bankIcon,
        txt: 'Storico Guadagni',
        path: '/seller/guadagni'
    }
]
