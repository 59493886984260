import GenericImg from "../../components/generic/GenericImg";
import {useNavigate} from "react-router-dom";

export default function MenuGroupItem(props: {
    title: { img: string, txt: string },
    subItems: { img: string, txt: string, path: string }[],
    onMenuItemClick?: () => void // Aggiungiamo la callback opzionale
}) {
    const navigate = useNavigate();

    return (
        <div className={'w-full flex flex-col items-center justify-start'}>
            <div className={'w-full flex items-center justify-start'}>
                <GenericImg className={'w-5 h-5 mr-2'} src={props.title.img}/>
                <h1 className={`text-white uppercase font-poppins-bold`}>{props.title.txt}</h1>
            </div>
            {props.subItems.map((item) => (
                <div key={item.path} className={'w-full flex items-center justify-start ml-20 my-2 hover:scale-110'}
                     onClick={() => {
                         navigate(item.path);
                         if (props.onMenuItemClick) {
                             props.onMenuItemClick(); // Chiude il drawer se la funzione è definita
                         }
                     }}>
                    <GenericImg className={'w-5 h-5 mr-2'} src={item.img+`?timestamp=${new Date().getMilliseconds()}`}/>
                    <h1 className={`font-poppins-regular cursor-pointer ${window.location.pathname.includes(item.path) ? 'text-yellow' : 'text-white'}`}>{item.txt}</h1>
                </div>
            ))}
        </div>
    )
}
