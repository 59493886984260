import StatisticDashAdminComponent from "../../components/StatisticDashAdminComponent";

export default function DashboardAdminPage() {
  return (
    <div className={'flex w-full h-auto gap-x-2.5'}>
      <div
        className={'flex flex-col flex w-[400px] h-[300px]items-start bg-black border rounded-2xl gap-y-4 p-7 shadow-2xl'}>
        <h1 className={'text-white font-poppins-regular'}>Nome :</h1>
        <h1 className={'text-white font-poppins-regular'}>Indirizzo :</h1>
        <h1 className={'text-white font-poppins-regular'}>Cap :</h1>
        <h1 className={'text-white font-poppins-regular'}> Città :</h1>
        <h1 className={'text-white font-poppins-regular'}>P. I.V.A. :</h1>
        <h1 className={'text-white font-poppins-regular'}>Telefono :</h1>
        <h1 className={'text-white font-poppins-regular'}>Sito Web :</h1>
      </div>
      <StatisticDashAdminComponent/>
      <div
        className={'flex flex-col w-[400px] h-[300px]justify-items-start items-start bg-black border rounded-2xl gap-y-2 pt-7 pl-7 shadow-2xl'}>
        <h1 className={'text-red-500 font-poppins-regular text-center'}>Sostituire con componente calendario Manuel</h1>
      </div>
    </div>
  )
}
