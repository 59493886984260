import HeaderComponent from "../components/HeaderComponent";

import RouteHome from "../routing/RouteHome";
import {Divider} from "@mantine/core";
import {addAlpha} from "../utils/Utility";
import {useNavigate} from "react-router-dom";
import GenericImg from "../components/generic/GenericImg";
import mapIconEnable from "../assets/map-icon-enable.svg";
import mapIconDisable from "../assets/map-icon-disable.svg";
import listIconEnable from "../assets/list-icon-enable.svg";
import listIconDisable from "../assets/list-icon-disable.svg";
import {isMobile} from 'react-device-detect';
import {isTablet} from "../utils/WindowDimensionUtils";
import HeaderMobileComponent from "../components/HeaderMobileComponent";
import {useEffect} from "react";


export default function HomePage() {
    var navigate = useNavigate();
    var path = window.location.pathname

    return (
        <div className={'w-full h-auto'}>
            <div className={'fixed w-full h-auto z-40'}>
                {isMobile && !isTablet() ? <HeaderMobileComponent/> : <HeaderComponent/>}

                <div className={'w-full h-auto '}>
                    <div
                        className={'w-full mt-[85px] h-auto    bg-transparent absolute z-30 flex flex-col items-center justify-center'}>
                        <div style={{backgroundColor: addAlpha('#494949', 1)}}
                             className={`  w-96    h-10 rounded-b-[40px] flex justify-center`}>
                            <div
                                className={'w-full h-full  rounded-bl-[40px] flex flex-col justify-center items-center cursor-pointer'}
                                onClick={() => {
                                    navigate('/welcome')
                                }}>
                                <h1 className={`${path.includes('welcome') ? 'text-amber-400' : 'text-white'} text-lg font-poppins-bold`}>HOME</h1>
                            </div>

                            <Divider orientation='vertical'/>
                            <div
                                className={'w-full h-full  rounded-br-[40px] cursor-pointer flex flex-col items-center justify-center'}
                                onClick={() => {
                                    navigate('/locali')
                                }}>
                                <h1 className={`${path.includes('locali') ? 'text-amber-400' : 'text-white'} text-lg font-poppins-bold`}>LOCALI</h1>
                            </div>
                        </div>

                    </div>
                </div>

                <div
                    className={`w-full  h-auto  ${path.includes('locali') ? 'mt-[125px]' : 'mt-[90px]'} duration-500 bg-transparent absolute z-20 flex flex-col items-center justify-center`}>
                    <div style={{backgroundColor: addAlpha('#494949', 0.8)}}
                         className={`  w-52 h-auto   rounded-b-[40px]   flex justify-center `}>
                        <div className={'w-full h-full    py-1   flex flex-col justify-center items-center cursor-pointer'}
                             onClick={() => {
                                 if (!window.location.pathname.includes("mappa")) {
                                     dispatchEvent(new CustomEvent("loader", {detail: {isLoading: true}}));
                                 }
                                 navigate('/locali/mappa')
                             }}>
                            <GenericImg className={'w-5 h-5'}
                                        src={path.includes('mappa') ? mapIconEnable : mapIconDisable}/>
                        </div>

                        <Divider orientation='vertical'/>
                        <div className={'w-full h-full py-1 cursor-pointer flex flex-col items-center justify-center'}
                             onClick={() => {
                                 navigate('/locali/lista')
                             }}>
                            <GenericImg className={'w-5 h-5'}
                                        src={path.includes('lista') ? listIconEnable : listIconDisable}/>
                        </div>
                    </div>
                </div>
            </div>
                <div className={''}>
                    <RouteHome/>
                </div>

        </div>
    )
}
