import {Grid} from "@mantine/core";

import {isMobile} from "react-device-detect";
import {CategoryType} from "../../types/CategoryType";
import {useEffect, useState} from "react";
import {getCategories} from "../../network/controllers/admin/CategoryController";
import CategoryDeliveryItem from "./CategoryDeliveryItem";

export default function CategoryDeliveryComponentAdmin(props: { categorySelected: (category: CategoryType) => void }) {
    const [categoryList, setCategoryList] = useState<CategoryType[]>([])

    useEffect(() => {
        getCategories(JSON.parse(localStorage.getItem('selectedRestaurant') as string).id, (list) => {
            setCategoryList(list)
        },)
    }, [])
    return (
        <div className={'w-full h-auto'}>
            <div className={'w-full h-14 bg-black p-2 rounded-xl'}>
                <div className={'flex items-center justify-center h-full '}>
                    <p className={'font-bold text-amber-400 text-lg  '}>{categoryList.length>0?'Seleziona Categoria':'Nessuna Categoria'}</p>
                </div>
            </div>
            <div className={'w-full max-w-[100%] h-full  flex flex-col px-4 pt-8 overflow-x-hidden '}>
                <Grid>
                    {categoryList.length>0?categoryList.map((item) => (
                        <Grid.Col span={isMobile ? 6 : {base: 12, xs: 6, md: 4, xl: 3}}>
                            <CategoryDeliveryItem  category={item} onClick={(sel) => {
                                props.categorySelected(sel)
                            }}/>
                        </Grid.Col>
                    )):null}
                </Grid>
            </div>
        </div>
    );
}
