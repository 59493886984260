
import {ReservationResponseType} from "../../../types/ReservationResponseType";
import {makeRequest} from "../../RestAdapter";
import {HttpMethodsEnum} from "../../HttpMethodsEnum";
import {showFailNotification} from "../../../utils/NotificationUtils";
import {StatusEnumRequest} from "../../../enums/StatusRequestEnum";
import {GetRequestType, RequestType} from "../../../types/RequestType";

export function getRequestFromType(status:StatusEnumRequest,  onFinish:(response: Map<string,GetRequestType[]>) => void) {
    makeRequest<Map<string, GetRequestType[]>>(`/restaurants/richieste/${JSON.parse(localStorage.getItem('selectedRestaurant') as string).id}?typeRequest=${status}`, HttpMethodsEnum.GET).then((res) => {
        if (res.data) {
            onFinish(res.data)
        }else {
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }

    })
}


export function createRequest(request:RequestType,  onFinish:(response: Map<string, GetRequestType[]>) => void) {
    makeRequest<Map<string, GetRequestType[]>>(`/restaurants/richieste`, HttpMethodsEnum.POST,request).then((res) => {
        if (res.data) {
            onFinish(res.data)
        }else {
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }

    })
}