import successIcon from '../../assets/payment/payment-succes-icon.svg';
import failureIcon from '../../assets/payment/payment-failure-icon.svg';
import CustomButton from "../../components/CustomButton";
import React, {useEffect, useState} from 'react';
import {isTablet} from "../../utils/WindowDimensionUtils";
import {useNavigate} from "react-router-dom";
import {makeRequest} from "../../network/RestAdapter";
import {HttpMethodsEnum} from "../../network/HttpMethodsEnum";
import {PrinterShopType} from "../../types/PrinterType";
import {isMobile} from "react-device-detect";

var decodeBase64 = localStorage.getItem("shopData")?JSON.parse(atob(localStorage.getItem("shopData") as string)):null
export default function ResultShopPaymentPage() {

    useEffect(() => {
        if (!decodeBase64){
            navigate('/welcome')
        }
    }, []);

    const [paymentSuccess, setPaymentSuccess] = useState<boolean | null>(null);
    var navigate = useNavigate();
    var idIntent = new URLSearchParams(window.location.search).get('payment_intent')
    var idStripe = new URLSearchParams(window.location.search).get('idStripe')
    localStorage.removeItem("shopData");
    useEffect(() => {
        makeRequest<Map<String, any>>(`/users/restaurants/check-payment-status/${idIntent}/${idStripe}`, HttpMethodsEnum.GET).then((res) => {
            // @ts-ignore
            setPaymentSuccess(res.data.status === 'succeeded')
        })
    }, []);
    useEffect(() => {
        if (paymentSuccess) {
            var request={
                print:decodeBase64.printerData,
                spedizione:decodeBase64.datiDiSpedizione,
                fatturazione:decodeBase64.datiDiFatturazione
            }
            makeRequest<Map<String, any>>(`/users/shop`, HttpMethodsEnum.POST,request).then((res) => {})
        }
    }, [paymentSuccess]);


    if (isMobile){
    return (
        <div className={' h-full w-full flex flex-col items-center justify-center '}>
            {paymentSuccess !== null ? <div>
                <img className={'w-auto h-auto flex items-center justify-center p-10'} src={paymentSuccess ? successIcon : failureIcon}/>
                <div className={'w-auto h-auto  flex flex-col items-center justify-center bg-white gap-y-2.5'}>
                    <p className={`w-auto h-auto flex text-center font-poppins-bold ${isTablet() ? 'text-4xl' : 'text-2xl'} ${paymentSuccess ? 'text-green-700' : 'text-red-600'}  `}>
                        {paymentSuccess
                            ? `Grazie per aver comprato la stampante ${decodeBase64.printerData.name}!`
                            : 'Oh no!'
                        }
                    </p>
                    <p className={'h-auto w-auto flex text-center text-xl font-poppins-regular'}>
                        {paymentSuccess
                            ? 'Il tuo pagamento è stato effettuato con successo!'
                            : 'Qualcosa è andato storto, riprova il pagamento!'
                        }
                    </p>
                </div>

                <div className={'flex w-auto h-auto mb-15 items-center justify-center mt-3'}>
                    <CustomButton title={'Continua su MaiSazio'} small={true} onClick={() => navigate('/welcome')} enable={true}
                                  isLoading={false}
                                  customStyles={'w-[200px] h-[80px]'}/>
                </div>
            </div> : null}
        </div>
    );
}else{
        return (
            <div className={'flex flex-col h-full w-full items-center justify-center gap-y-7'}>
                {paymentSuccess !== null ? <div>
                    <img className={'w-[50%] h-[50%]'} src={paymentSuccess ? successIcon : failureIcon}/>
                    <div className={'flex flex-col w-auto h-auto items-center justify-center bg-white gap-y-2.5'}>
                        <p className={`items-center font-poppins-bold ${isTablet() ? 'text-4xl' : 'text-2xl'} ${paymentSuccess ? 'text-green-700' : 'text-red-600'}  `}>
                            {paymentSuccess
                                ? `Grazie per aver comprato la stampante ${decodeBase64.printerData.name}!`
                                : 'Oh no!'
                            }
                        </p>
                        <p className={'items-center text-xl font-poppins-regular'}>
                            {paymentSuccess
                                ? 'Il tuo pagamento è stato effettuato con successo!'
                                : 'Qualcosa è andato storto, riprova il pagamento!'
                            }
                        </p>
                    </div>

                    <div className={'flex w-auto h-auto mb-15 items-center justify-center mt-3'}>
                        <CustomButton title={'Continua su MaiSazio'} onClick={() => navigate('/welcome')} enable={true}
                                      isLoading={false}
                                      customStyles={'w-[200px] h-[80px]'}/>
                    </div>
                </div> : null}
            </div>
        );

    }}
