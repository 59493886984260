


enum PackEnum {
    BASE='BASE',
    PRO='PRO',
    PREMIUMSMART='PREMIUM-SMART',
    DEFAULT=''
}

namespace PackEnum{
    export function retrieveFromString(str: string): PackEnum{
        if (str === 'BASE'){
            return PackEnum.BASE
        }else if (str === 'PRO'){
            return PackEnum.PRO
        }else if(str === 'PREMIUM-SMART'){
            return PackEnum.PREMIUMSMART
        }
        else {
            return PackEnum.DEFAULT
        }
    }

    export function getEntries():PackEnum[]{
        return [PackEnum.BASE,PackEnum.PRO,PackEnum.PREMIUMSMART,PackEnum.DEFAULT]
    }

}

export {
    PackEnum
}
