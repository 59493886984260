import edit from '../../../assets/edit-icon.svg'
import trashIcon from '../../../assets/delete-icon.svg'
import {useState} from "react";
import {Input} from "@mantine/core";
import confirm from "../../../assets/confirm-icon.svg";
import closeIcon from "../../../assets/close-icon.svg";
import {showSuccessNotification, showWarningNotification} from "../../../utils/NotificationUtils";
import loadingIcon from '../../../assets/loader-white.svg'
import {KitchenType} from "../../../types/KitchenType";
import {deleteKitchen, updateKitchen} from "../../../network/controllers/admin/KitchenController";


export default function KitchenAdminTable(props: {
    kitchen: KitchenType,
    onClickItem: (element: any) => void,
    onKitchenUpdate: (kitchenList: KitchenType[]) => void
}) {
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [newKitchen, setNewKitchen] = useState(props.kitchen.kitchenName);
    const [isLoading, setIsLoading] = useState(false);


    return (
        <div className={' flex gap-x-20 '}>
            {
                !isEditing ? <div className={'w-full flex justify-between gap-x-20 '}>
                        <h1 className={'w-auto font-poppins-regular my-1'}>{props.kitchen.kitchenName}</h1>
                        <div className={'w-[100px] flex gap-x-5'}>
                            <div className={'w-5 h-5 cursor-pointer'}>
                                <img alt={''} className={'w-5 h-5'} src={isLoading ? loadingIcon : edit} onClick={() => {
                                    setIsEditing(true)
                                }}/>
                            </div>

                            <div className={'w-5 h-5 cursor-pointer'}>
                                <img alt={''} src={isLoading ? loadingIcon : trashIcon} onClick={() => {
                                    showWarningNotification(`Sicuro di voler eliminare  ${props.kitchen.kitchenName}?`, () => {
                                        setIsLoading(true);
                                        deleteKitchen(props.kitchen.id, (response) => {
                                            window.location.reload()
                                        })
                                    })
                                }}/>
                            </div>

                        </div>

                    </div> :
                    <div className={'w-full flex justify-between gap-x-20 '}>
                        <Input variant={'unstyled'} type={'text'} value={newKitchen}
                               className={'w-[250px] h-full bg-white text-text-button-add font-poppins-regular outline-none '}
                               placeholder={'Nome Tavolo'} onChange={(event) => {
                            setNewKitchen(event.currentTarget.value.charAt(0).toUpperCase() + event.currentTarget.value.slice(1))

                        }}/>
                        <div className={'w-[100px] flex gap-x-5'}>
                            <img alt={''} className={'w-6 h-6 cursor-pointer'} src={isLoading ? loadingIcon : closeIcon}
                                 onClick={() => {
                                     setIsEditing(false)
                                 }}/>

                            <img alt={''} className={'w-6 h-6 cursor-pointer'} src={isLoading ? loadingIcon : confirm}
                                 onClick={() => {
                                     setIsEditing(true)
                                     updateKitchen({id: props.kitchen.id, kitchenName: newKitchen}, (response) => {
                                         setIsLoading(false)
                                         showSuccessNotification('Tavolo', `${newKitchen} modificato con successo`, 2000)
                                         setIsEditing(false)
                                         props.onKitchenUpdate(response)
                                     })
                                 }}/>
                        </div>

                    </div>
            }

        </div>
    )
}
