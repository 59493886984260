import {showNotification} from "../components/Notification";


export const SuccessNotification = new CustomEvent('showSuccessNotification', {
    detail: {
        title: '',
        text: '',
        time: 0
    }
})
export const FailNotification = new CustomEvent('showFailNotification', {
    detail: {
        title: '',
        text: '',
        time: 0
    }
})

export const WarningNotification = new CustomEvent('showWarningNotification', {
    detail: {
        title: '',
        text: '',
        warningYes: () => {
        }
    }
})
export const hideNotification = new CustomEvent('hideNotification')

export function notificationSuccess(title: string, text: string, time: number) {
    return (showNotification('success', title, text, time))
}

export function showSuccessNotification(title: string, text: string, time: number) {
    SuccessNotification.detail.title = title
    SuccessNotification.detail.text = text
    SuccessNotification.detail.time = time
    document.dispatchEvent(SuccessNotification)

}

export function notificationFail(title: string, text: string, time: number) {
    return (showNotification('fail', title, text, time))
}

export function showFailNotification(title: string, text: string, time: number) {
    FailNotification.detail.title = title
    FailNotification.detail.text = text
    FailNotification.detail.time = time
    document.dispatchEvent(FailNotification)
}

export function notificationWarning(title: string, onYes: () => void) {
    return (showNotification('warning', title, undefined, undefined, onYes))
}

export function showWarningNotification(title: string, onYes: () => void) {
    WarningNotification.detail.title = title
    WarningNotification.detail.warningYes = onYes
    document.dispatchEvent(WarningNotification)
}
