import CustomTextInput from "../../components/generic/CustomTextInput";
import DrawerCropComponent from "../../components/generic/cropper/DrawerCropComponent";
import CustomButton from "../../components/CustomButton";
import {addCategory, updateCategory} from "../../network/controllers/admin/CategoryController";
import {CategoryType, MenuType} from "../../types/CategoryType";
import React, {useEffect, useState} from "react";
import {getCategoryNotPresent} from "../../network/controllers/admin/ProductController";
import GenericTagInput from "../../components/generic/GenericTagInput";
import placeholder from "../../assets/insert-image.svg";
import {Textarea} from "@mantine/core";
import {isMobile} from "react-device-detect";


export default function CategoryDrawerComponent(props: {
    category: CategoryType | null,
    mapSize: number,
    onCategoryCreated?: (categotyList: CategoryType[] | null) => void
}) {

    const [newCategory, setNewCategory] = useState<CategoryType>(props.category ? props.category : {
            categoryName: "",
            idLocal: JSON.parse(localStorage.getItem('selectedRestaurant') as string).id,
            image: "",
            idMenus: [(JSON.parse(localStorage.getItem('selectedMenu')!) as MenuType).id],
            positionCategory: new Map<string, number>(),
            description: ''
        }
    );
    const [updating, setUpdating] = useState<boolean>(false);
    const [menu, setMenu] = useState<{ label: any, value: string }[]>([]);

    useEffect(() => {
        if (props.category === null) {
            getCategoryNotPresent((cat) => {
                var tmpArr: { label: any, value: string }[] = []
                cat.map((item) => {
                    tmpArr.push({value: JSON.stringify(item), label: item.categoryName})
                })
                setMenu(tmpArr);
            })
        }
    }, []);


    const [loading, setIsLoading] = useState(false);


    if (isMobile){

    return (
        <div className={'w-full h-full py-20 flex flex-col'}>
            <CustomTextInput label={'Nome Categoria'} isBlack={false} defaultValue={newCategory.categoryName}
                             onChange={(v) => {
                                 setNewCategory({...newCategory, categoryName: v});
                             }}/>


            <div className={'w-full flex  items-center  justify-center mt-5 '}>


                <Textarea
                    classNames={{
                        root: 'w-full p-1',
                        label: 'text-white font-poppins-semi-bold',
                        wrapper: 'bg-[#F2F2F2A8] rounded-xl p-2'
                    }}
                    styles={{
                        input: {
                            '::placeholder': {
                                color: 'black',
                            }
                        }
                    }}
                    defaultValue={newCategory.description}
                    autosize
                    maxRows={5}
                    minRows={5}
                    label={'Note Aggiuntive (Opzionale)'}
                    variant={'unstyled'}
                    placeholder={'Note'}
                    onChange={(value) => {
                        setNewCategory({...newCategory, description: value.currentTarget.value})
                    }}
                />

            </div>

            <div className={'w-full flex flex-col gap-y-6 my-10 items-center justify-start'}>
                <DrawerCropComponent onCroppedFinish={(imageSrc) => {
                    setNewCategory({...newCategory, image: imageSrc})
                }} contentHtml={<div
                    className={'w-[200px] h-[200px] bg-yellow   border-[1px] p-3  rounded-3xl'}>
                    <img alt={''} className={`h-[175px] w-[175px]  rounded-xl`}
                         src={newCategory.image ? newCategory.image : placeholder}/>
                </div>} aspect={4 / 4}/>

                <CustomButton title={'Conferma'} onClick={() => {
                    setIsLoading(true)
                    if (updating || props.category) {
                        var tmpMenu: string[] = []
                        if (props.category) {
                            props.category.idMenus.forEach((menu) => {
                                tmpMenu.push((menu as MenuType).id)
                            })
                        }
                        updateCategory({
                            id: newCategory.id!,
                            categoryName: newCategory.categoryName,
                            image: newCategory.image,
                            description:newCategory.description,
                            idMenus: props.category ? tmpMenu : newCategory.idMenus as string[],
                            positionCategory: newCategory.positionCategory,
                        }, props.mapSize, (response) => {
                            window.location.reload()
                        })
                    } else {
                        addCategory(newCategory, props.mapSize, (response) => {
                            setIsLoading(false)
                            window.location.reload()

                        })
                    }

                }} enable={newCategory.categoryName !== '' && newCategory.image !== ''} isLoading={loading}/>
            </div>

        </div>
    )

}else{
        return (
            <div className={'w-full h-full py-20 flex flex-col'}>
                <CustomTextInput label={'Nome Categoria'} isBlack={false} defaultValue={newCategory.categoryName}
                                 onChange={(v) => {
                                     setNewCategory({...newCategory, categoryName: v});
                                 }}/>


                <div className={'w-full flex  items-center  justify-center mt-5 '}>


                    <Textarea
                        classNames={{
                            root: 'w-full p-1',
                            label: 'text-white font-poppins-semi-bold',
                            wrapper: 'bg-[#F2F2F2A8] rounded-xl p-2'
                        }}
                        styles={{
                            input: {
                                '::placeholder': {
                                    color: 'black',
                                }
                            }
                        }}
                        defaultValue={newCategory.description}
                        autosize
                        maxRows={5}
                        minRows={5}
                        label={'Note Aggiuntive (Opzionale)'}
                        variant={'unstyled'}
                        placeholder={'Note'}
                        onChange={(value) => {
                            setNewCategory({...newCategory, description: value.currentTarget.value})
                        }}
                    />

                </div>

                <div className={'w-full flex flex-col gap-y-6 my-10 items-center justify-start'}>
                    <DrawerCropComponent onCroppedFinish={(imageSrc) => {
                        setNewCategory({...newCategory, image: imageSrc})
                    }} contentHtml={<div
                        className={'w-[200px] h-[200px] bg-yellow   border-[1px] p-3  rounded-3xl'}>
                        <img alt={''} className={`h-[175px] w-[175px]  rounded-xl`}
                             src={newCategory.image ? newCategory.image : placeholder}/>
                    </div>} aspect={4 / 4}/>

                    <CustomButton title={'Conferma'} onClick={() => {
                        setIsLoading(true)
                        if (updating || props.category) {
                            var tmpMenu: string[] = []
                            if (props.category) {
                                props.category.idMenus.forEach((menu) => {
                                    tmpMenu.push((menu as MenuType).id)
                                })
                            }
                            updateCategory({
                                id: newCategory.id!,
                                categoryName: newCategory.categoryName,
                                image: newCategory.image,
                                description:newCategory.description,
                                idMenus: props.category ? tmpMenu : newCategory.idMenus as string[],
                                positionCategory: newCategory.positionCategory,
                            }, props.mapSize, (response) => {
                                window.location.reload()
                            })
                        } else {
                            addCategory(newCategory, props.mapSize, (response) => {
                                setIsLoading(false)
                                window.location.reload()

                            })
                        }

                    }} enable={newCategory.categoryName !== '' && newCategory.image !== ''} isLoading={loading}/>
                </div>

            </div>
        )
    }}
