import {makeRequest} from "../../RestAdapter";
import {ClientType} from "../../../types/ClientType";
import {HttpMethodsEnum} from "../../HttpMethodsEnum";
import {TypeLicenseAndPlan} from "../../../types/TypeLicenseAndPlan";

export function checkUser(email:string, onFinish: (user: any) => void) {
    makeRequest<ClientType>(`/users/user/checkClient/${email}`, HttpMethodsEnum.GET).then((res) => {
        onFinish(res)
    })
}
export function getTypeLicensesList(onFinish: (typeLicenseList: TypeLicenseAndPlan[]) => void) {
    makeRequest<TypeLicenseAndPlan[]>("/restaurants/type-license/all", HttpMethodsEnum.GET).then((res) => {
        if (res.data !== null) {
            onFinish(res.data)
        }
    })
}