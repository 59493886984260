import info1 from '../../assets/welcome/info1.webp'
import {isMobile} from "react-device-detect";
import React, {useEffect, useRef, useState} from "react";
import InfoComponentLeft from "./InfoComponentLeft";
import InfoComponentRight from "./InfoComponentRight";
import ilMioLocaleIcon from "../../assets/welcome/il-tuo-locale.webp"
import ilMioLocaleMaisazioIcon from "../../assets/welcome/il-mio-locale-maisazio.webp"
import prenotazioniIcon from "../../assets/welcome/prenotazioni-icon.webp"
import gestionaleIcon from "../../assets/welcome/gestionale-icon.webp"
import asportoIcon from "../../assets/welcome/asporto-icon.webp"
import ricercaIcon from "../../assets/welcome/ricerca-icon.webp"
import ordiniIcon from "../../assets/welcome/ordini-icon.webp"
import menuIcon from "../../assets/welcome/menu-icon.webp"
import ReactPlayer from "react-player";
import GenericImg from "../generic/GenericImg";
import {getDimension} from "../../utils/Utility";

export default function InfoWelcome() {
    const targetDivRefOrdini = useRef(null);
    const targetDivRefRicerca = useRef(null);
    const targetDivRefMenu = useRef(null);
    const targetDivRefPrenotazioni = useRef(null);
    const targetDivRefGestionale = useRef(null);
    const targetDivRefAsporto = useRef(null);
    const [dimension, setDimension] = useState(window.innerWidth < 1650)


    useEffect(() => {
        getDimension(1650, (res)=>{
            console.log(res);
            setDimension(res)
        })
    },[])


    const handleScrollToDiv = (subtitle: string) => {

        if (subtitle.includes('ordini')) {
            // @ts-ignore
            targetDivRefOrdini.current.scrollIntoView({
                behavior: 'smooth', // Scroll fluido
                block: 'center', // Posiziona l'elemento all'inizio della viewport
            });
        }
        if (subtitle.includes('ricerca')) {
            // @ts-ignore
            targetDivRefRicerca.current.scrollIntoView({
                behavior: 'smooth', // Scroll fluido
                block: 'center', // Posiziona l'elemento all'inizio della viewport
            });
        }
        if (subtitle.includes('menu')) {
            // @ts-ignore
            targetDivRefMenu.current.scrollIntoView({
                behavior: 'smooth', // Scroll fluido
                block: 'center', // Posiziona l'elemento all'inizio della viewport
            });
        }
        if (subtitle.includes('prenotazioni')) {
            // @ts-ignore
            targetDivRefPrenotazioni.current.scrollIntoView({
                behavior: 'smooth', // Scroll fluido
                block: 'center', // Posiziona l'elemento all'inizio della viewport
            });
        }
        if (subtitle.includes('gestionale')) {
            // @ts-ignore
            targetDivRefGestionale.current.scrollIntoView({
                behavior: 'smooth', // Scroll fluido
                block: 'center', // Posiziona l'elemento all'inizio della viewport
            });
        }
        if (subtitle.includes('asporto')) {
            // @ts-ignore
            targetDivRefAsporto.current.scrollIntoView({
                behavior: 'smooth', // Scroll fluido
                block: 'center', // Posiziona l'elemento all'inizio della viewport
            });
        }


    };
    if (isMobile) {
        return (
            <div>
                <div className={'flex flex-col w-full h-full bg-white pt-16 items-center px-5 overflow-x-hidden'}>
                    <div className={'flex flex-col justify-center gap-x-5 px-5 items-center'}>
                        <p className={'text-xl w-full font-poppins-bold items-center justify-center text-center '}>TUTTI I SERVIZI DI CUI HAI BISOGNO <br/> <span
                            className={'text-3xl font-poppins-regular'}>IN UN UNICA</span> <span
                            className={'font-poppins-bold text-3xl'}> SOLUZIONE</span> <br/> <span className={'text-5xl font-poppins-bold text-[#ffcc00]'}>gratuitamente</span></p>


                        <GenericImg className={'w-[50%] mt-10'} src={ilMioLocaleIcon}/>
                        <div className={'w-full flex flex-col mt-10'}>
                            <div className={'flex justify-around items-start'}>
                                <div onClick={() => {
                                    handleScrollToDiv('prenotazioni')
                                }} className={'flex flex-col w-1/3 items-center justify-center '}>
                                    <GenericImg className={'w-12  '} src={prenotazioniIcon}/>
                                    <h1 className={'font-poppins-regular text-xs mt-1 text-center leading-none'}><span className={'font-poppins-bold text-sm'}>Es. Prenotazioni</span> <br/>il servizio che <br/>
                                        usi per le <br/>
                                        prenotazioni</h1>
                                </div>

                                <div  onClick={() => {
                                    handleScrollToDiv('gestionale')
                                }} className={'flex flex-col w-1/3 items-center justify-center '}>
                                    <GenericImg className={'w-12 '} src={gestionaleIcon}/>
                                    <h1 className={'font-poppins-regular text-xs mt-1 text-center leading-none'}><span className={'font-poppins-bold text-sm'}>Es. Gestionale</span> <br/>il servizio che <br/>
                                        usi per la <br/>
                                        gestione</h1>
                                </div>
                                <div  onClick={() => {
                                    handleScrollToDiv('asporto')
                                }} className={'flex flex-col w-1/3 items-center justify-center'}>
                                    <GenericImg className={'w-12 '} src={asportoIcon}/>
                                    <h1 className={'font-poppins-regular text-xs mt-1 text-center leading-none'}><span className={'font-poppins-bold text-sm'}>Es. Asporto</span> <br/>il servizio che usi<br/>
                                        per asporto e<br/>
                                        domicilio</h1>
                                </div>

                            </div>

                            <div className={'flex justify-around mt-5 items-start '}>
                                <div  onClick={() => {
                                    handleScrollToDiv('ricerca')
                                }} className={'flex flex-col w-1/3 items-center justify-center '}>
                                    <GenericImg className={'w-12  '} src={ricercaIcon}/>
                                    <h1 className={'font-poppins-regular text-xs mt-1 text-center leading-none'}><span className={'font-poppins-bold text-sm'}>Es. Ricerca</span> <br/>il servizio<br/>
                                        usi per far <br/>
                                        trovare <br/>il tuo locale</h1>
                                </div>

                                <div  onClick={() => {
                                    handleScrollToDiv('ordini')
                                }} className={'flex flex-col w-1/3 items-center justify-center '}>
                                    <GenericImg className={'w-12 '} src={ordiniIcon}/>
                                    <h1 className={'font-poppins-regular text-xs mt-1 text-center leading-none'}><span className={'font-poppins-bold text-sm'}>Es. Ordini</span> <br/>il servizio ordini <br/>
                                        che usi<br/></h1>
                                </div>
                                <div  onClick={() => {
                                    handleScrollToDiv('menu')
                                }} className={'flex flex-col w-1/3 items-center justify-center'}>
                                    <GenericImg className={'w-12 '} src={menuIcon}/>
                                    <h1 className={'font-poppins-regular text-xs mt-1  text-center leading-none'}><span className={'font-poppins-bold text-sm'}>Es. Menu</span> <br/>il servizio menù <br/>
                                        digitale che usi<br/></h1>
                                </div>

                            </div>


                        </div>

                        <GenericImg className={'w-[50%] mt-10 '} src={ilMioLocaleMaisazioIcon}/>
                        <div className={'w-full flex flex-col mt-10'}>
                            <div className={'flex justify-around items-start '}>
                                <div onClick={() => {
                                    handleScrollToDiv('prenotazioni')
                                }} className={'flex flex-col w-1/3 items-center justify-center '}>
                                    <GenericImg className={'w-12  '} src={prenotazioniIcon}/>
                                    <h1 className={'font-poppins-regular text-xs mt-2 text-center leading-none hover:underline'}><span className={'font-poppins-bold text-sm'}>PRENOTAZIONI</span></h1>
                                </div>

                                <div onClick={() => {
                                    handleScrollToDiv('gestionale')
                                }} className={'flex flex-col w-1/3 items-center justify-center'}>
                                    <GenericImg className={'w-12 '} src={gestionaleIcon}/>
                                    <h1 className={'font-poppins-regular text-xs mt-2 text-center leading-none'}><span className={'font-poppins-bold text-sm'}>GESTIONALE</span></h1>
                                </div>
                                <div onClick={() => {
                                    handleScrollToDiv('asporto')
                                }} className={'flex flex-col w-1/3 items-center justify-center'}>
                                    <GenericImg className={'w-12 '} src={asportoIcon}/>
                                    <h1 className={'font-poppins-regular text-xs mt-2 text-center leading-none'}><span className={'font-poppins-bold text-sm'}>ASPORTO E <br/> DOMICILIO</span></h1>
                                </div>

                            </div>

                            <div className={'flex justify-around mt-5 items-start'}>
                                <div onClick={() => {
                                    handleScrollToDiv('ricerca')
                                }} className={'flex flex-col w-1/3 items-center justify-center'}>
                                    <GenericImg className={'w-12  '} src={ricercaIcon}/>
                                    <h1 className={'font-poppins-regular text-xs mt-2 text-center leading-none'}><span className={'font-poppins-bold text-sm'}>RICERCA</span></h1>
                                </div>

                                <div onClick={() => {
                                    handleScrollToDiv('ordini')
                                }} className={'flex flex-col w-1/3 items-center justify-center'}>
                                    <GenericImg className={'w-12 '} src={ordiniIcon}/>
                                    <h1 className={'font-poppins-regular text-xs mt-2 text-center leading-none'}><span className={'font-poppins-bold text-sm'}>ORDINI</span></h1>
                                </div>
                                <div onClick={() => {
                                    handleScrollToDiv('menu')
                                }} className={'flex flex-col w-1/3 items-center justify-center'}>
                                    <GenericImg className={'w-12 '} src={menuIcon}/>
                                    <h1 className={'font-poppins-regular text-xs mt-2 text-center leading-none'}><span className={'font-poppins-bold text-sm'}>MENÙ</span></h1>
                                </div>

                            </div>


                        </div>
                    </div>

                    <div className={'flex flex-col w-full h-full gap-y-40 px-5 pt-12 items-center '}>

                        {/*1*/}
                        <div ref={targetDivRefOrdini} className={' flex  flex-col w-full  items-center pt-20'}>
                            <div className={'w-full'}>
                                <ReactPlayer
                                    url={`${process.env.PUBLIC_URL}/video/ordini/ordini.mp4`}
                                    playing
                                    loop
                                    muted
                                    playsinline={true}
                                    controls={false} // Nascondi i controlli
                                    width="100%"
                                    height="100%"

                                />
                            </div>
                            <div
                                className={'flex  flex-col w-full h-full justify-center items-center text-center '}>
                                <h1 className={'font-poppins-bold text-4xl w-full text-center'}><span
                                    style={{color: '#FFCC00'}}>Ordini</span> in totale autonomia
                                </h1>
                                <h2 className={'font-poppins-semi-bold text-sm'}>Ordinare al tavolo diventa
                                    un’esperienza completamente fluida e moderna! I clienti hanno la possibilità di
                                    inviare i propri ordini
                                    direttamente dal loro smartphone, semplicemente scansionando il QR code presente sul
                                    tavolo, senza la necessità di attendere il cameriere. Se preferiscono, anche il
                                    cameriere può
                                    prendere le ordinazioni dal proprio dispositivo mobile, garantendo massima
                                    flessibilità al servizio. Tutti gli ordini vengono gestiti in tempo reale attraverso
                                    una sezione dedicata,
                                    permettendo di monitorare e organizzare le comande con facilità. Questo sistema
                                    migliora l’efficienza del servizio, riduce i tempi di attesa e ottimizza
                                    l’esperienza complessiva del
                                    cliente.
                                </h2>
                            </div>
                        </div>

                        {/*2*/}
                        <div ref={targetDivRefRicerca} className={' flex  flex-col w-full  items-center '}>

                            <div className={'w-full'}>
                                <ReactPlayer
                                    url={`${process.env.PUBLIC_URL}/video/ricerca/ricerca.mp4`}
                                    playing
                                    loop
                                    playsinline={true}
                                    muted
                                    controls={false} // Nascondi i controlli
                                    width="100%"
                                    height="100%"

                                />
                            </div>
                            <div
                                className={'flex  flex-col w-full h-full justify-center items-center text-center  '}>
                                <h1 className={'font-poppins-bold text-4xl w-full text-center'}><span
                                    style={{color: '#FFCC00'}}>Trova </span>
                                    e Fatti Trovare
                                </h1>
                                <h2 className={'font-poppins-semi-bold text-sm'}>Fai conoscere il tuo locale a un
                                    pubblico sempre più ampio grazie al nostro servizio di ricerca e recensioni! Come
                                    proprietario, avrai la
                                    possibilità di creare una scheda dettagliata della tua attività, arricchita da foto,
                                    descrizioni e recensioni dei clienti, che aiuteranno le persone a trovarti con
                                    facilità e a
                                    scoprire tutto ciò che offri. La visibilità online del tuo locale sarà ottimizzata,
                                    permettendo ai clienti di trovarti rapidamente.
                                    Inoltre, con ChefBot, il nostro assistente virtuale, gli utenti possono cercare e
                                    trovare il tuo locale in modo ancora più semplice. ChefBot fornisce suggerimenti
                                    personalizzati in
                                    base alle preferenze, indicazioni precise e informazioni aggiornate su orari, menù e
                                    altro ancora. Con questo sistema intelligente, far emergere il tuo locale non è mai
                                    stato così
                                    facile!
                                </h2>
                            </div>

                        </div>

                        {/*3*/}
                        <div ref={targetDivRefMenu} className={' flex flex-col w-full  items-center '}>

                            <div className={'w-full'}>
                                <ReactPlayer
                                    url={`${process.env.PUBLIC_URL}/video/menu/menu.mp4`}
                                    playing
                                    loop
                                    muted
                                    playsinline={true}
                                    controls={false} // Nascondi i controlli
                                    width="100%"
                                    height="100%"

                                />
                            </div>

                            <div
                                className={'flex  flex-col w-full h-full justify-center items-center '}>
                                <h1 className={'font-poppins-bold text-4xl w-full text-center'}><span
                                    style={{color: '#FFCC00'}}>Menù</span> Dinamico e <span
                                    style={{color: '#FFCC00'}}></span>Sempre Pronto </h1>
                                <h2 className={'font-poppins-semi-bold text-sm text-center'}>Un menù da gustare con gli occhi e
                                    gestire con un tocco! Con MaiSazio, la gestione del tuo menù diventa semplice e
                                    dinamica. Il QR code sul
                                    tavolo resta sempre lo stesso, ma puoi aggiornare i contenuti in tempo reale dalla
                                    tua sezione dedicata, attivando, disattivando o modificando i piatti in modo facile
                                    e veloce. Hai
                                    finito una portata? Rimuovila istantaneamente senza dover rifare l’intero menù.

                                    Inoltre, il menù può essere visualizzato in anteprima dai clienti prima ancora che
                                    arrivino nel locale, aumentando e migliorando la loro esperienza. E per chi
                                    preferisce il formato cartaceo, puoi esportare il menù in PDF e stamparlo, così da
                                    poterlo consegnare direttamente ai clienti. Con MaiSazio, offri un servizio
                                    completo, deliziando i
                                    tuoi clienti con immagini appetitose e informazioni dettagliate su ingredienti,
                                    allergie e intolleranze, tutto personalizzabile per un controllo totale.
                                </h2>
                            </div>

                        </div>

                        {/*4*/}
                        <div ref={targetDivRefPrenotazioni} className={' flex flex-col w-full  items-center '}>
                            <div className={'w-full'}>
                                <ReactPlayer
                                    url={`${process.env.PUBLIC_URL}/video/prenotazioni/prenotazioni.mp4`}
                                    playing
                                    loop
                                    muted
                                    playsinline={true}
                                    controls={false} // Nascondi i controlli
                                    width="100%"
                                    height="100%"

                                />
                            </div>
                            <div
                                className={'flex  flex-col w-full h-full justify-center items-center '}>
                                <h1 className={'font-poppins-bold text-4xl w-full text-center'}><span
                                    style={{color: '#FFCC00'}}>Prenotazioni </span> Semplici, Gestione Efficiente
                                </h1>
                                <h2 className={'font-poppins-semi-bold text-sm w-full text-center'}>Gestisci le
                                    prenotazioni dei tavoli in modo semplice ed efficiente, grazie alla nostra sezione
                                    dedicata, accessibile sia
                                    tramite sito web che app mobile. I clienti possono prenotare un tavolo inserendo
                                    tutte le informazioni necessarie: numero di persone, nome, cognome, email e
                                    eventuali note aggiuntive
                                    per richieste particolari. Le prenotazioni vengono automaticamente organizzate per
                                    fasce orarie e giorni, offrendoti una panoramica completa della disponibilità dei
                                    posti nel tuo
                                    locale, facilitando la gestione del flusso di clienti.

                                    Da questa sezione, potrai visualizzare tutte le richieste in tempo reale e gestirle
                                    in base alle tue esigenze. Potrai accettare, modificare o rifiutare le prenotazioni
                                    con un solo
                                    clic, assicurando una risposta rapida e un servizio impeccabile. Inoltre,
                                    l’organizzazione per fasce orarie ti permette di ottimizzare la disposizione dei
                                    tavoli e massimizzare
                                    l’efficienza del tuo locale, offrendo ai clienti un’esperienza fluida e ben
                                    pianificata.
                                </h2>
                            </div>


                        </div>

                        {/*5*/}
                        <div ref={targetDivRefGestionale} className={' flex flex-col w-full  items-center '}>
                            <div className={'w-full'}>
                                <ReactPlayer
                                    url={`${process.env.PUBLIC_URL}/video/gestionale/gestionale.mp4`}
                                    playing
                                    loop
                                    muted
                                    playsinline={true}
                                    controls={false} // Nascondi i controlli
                                    width="100%"
                                    height="100%"

                                />
                            </div>
                            <div
                                className={'flex  flex-col w-full h-full justify-center items-center '}>
                                <h1 className={'font-poppins-bold text-4xl w-full text-center'}>
                                    <span
                                        style={{color: '#FFCC00'}}>Gestione</span> Completa del Tuo Locale
                                </h1>
                                <h2 className={'font-poppins-semi-bold text-sm text-center'}>Gestisci ogni aspetto del tuo
                                    ristorante, bar o attività di ristorazione in modo semplice e completo con il nostro
                                    gestionale. Dalla gestione
                                    degli ingredienti e delle scorte, alla creazione e personalizzazione del menù, fino
                                    al monitoraggio dei piatti più popolari tra i clienti, hai tutto sotto controllo in
                                    un’unica
                                    piattaforma. Tieni traccia delle vendite, organizza i turni del personale e gestisci
                                    le prenotazioni con facilità. Il nostro sistema ti permette di ottimizzare ogni fase
                                    operativa del
                                    tuo locale, migliorando l’efficienza e garantendo un’esperienza fluida sia per te
                                    che per i tuoi clienti.
                                </h2>
                            </div>

                        </div>

                        {/*6*/}
                        <div ref={targetDivRefAsporto} className={' flex flex-col  w-full  items-center '}>
                            <div className={'w-full'}>
                                <ReactPlayer
                                    url={`${process.env.PUBLIC_URL}/video/asporto/asporto.mp4`}
                                    playing
                                    loop
                                    muted
                                    playsinline={true}
                                    controls={false} // Nascondi i controlli
                                    width="100%"
                                    height="100%"

                                />
                            </div>

                            <div
                                className={'flex  flex-col w-full h-full justify-center items-center '}>
                                <h1 className={'font-poppins-bold w-full text-4xl text-center'}> <span
                                    style={{color: '#FFCC00'}}>Asporto</span> e Domicilio<span
                                    style={{color: '#FFCC00'}}></span>
                                </h1>
                                <h2 className={'font-poppins-semi-bold text-sm w-full text-center'}>Abilita la
                                    possibilità per i tuoi clienti di ordinare comodamente da casa, scegliendo tra
                                    asporto o consegna a domicilio! Grazie alla nostra piattaforma, puoi gestire ogni
                                    aspetto delle comande in una sezione dedicata. I clienti possono ordinare facilmente
                                    tramite sito web o app, personalizzando i piatti secondo le loro preferenze: possono
                                    modificare gli ingredienti e il prezzo verrà ricalcolato automaticamente in base
                                    alle modifiche apportate.

                                    Ogni ordine viene stampato automaticamente in cucina, garantendo una preparazione
                                    rapida e precisa. Inoltre, i clienti possono aggiungere note speciali alle comande,
                                    come richieste su intolleranze, allergie o specifiche di cottura. Puoi anche
                                    monitorare in tempo reale le statistiche sui piatti più ordinati, ottimizzando il
                                    menù in base alle preferenze dei tuoi clienti.

                                    Con la nostra soluzione, gestisci le consegne, l’asporto e ogni dettaglio della
                                    preparazione, tutto da un’unica piattaforma che semplifica l’operatività del tuo
                                    locale e migliora l’esperienza del cliente.
                                </h2>
                            </div>

                        </div>


                    </div>
                </div>

                <img className={'w-full h-full pt-12 bg-white'} src={info1}/>
            </div>
        )

    } else {
        return (
            <div>
                <div className={'flex flex-col w-full h-full bg-white px-20 pt-20'}>
                    <div className={'w-full flex   justify-center text-center '}>
                        <p className={'text-5xl font-poppins-bold leading-tight'}>TUTTI I SERVIZI DI CUI HAI
                            BISOGNO <br/> <span className={'text-7xl font-poppins-regular'}>IN UN UNICA</span> <span
                                className={'font-poppins-bold text-7xl'}> SOLUZIONE</span> <br/> <span
                                className={'text-8xl font-poppins-bold text-[#ffcc00]'}> gratuitamente !</span></p>

                    </div>
                    <div
                        className={`w-full ${dimension ? 'flex-col' : ''} h-min flex justify-around items-center mt-20`}>
                        <InfoComponentLeft onclick={(subtitle) => {
                            handleScrollToDiv(subtitle.toLowerCase())
                        }}/>
                        <InfoComponentRight onclick={(subtitle) => {
                            handleScrollToDiv(subtitle.toLowerCase())
                        }}/>
                    </div>
                    <div className={'flex flex-col  w-full h-full mt-20  gap-y-80'}>


                        {/*1*/}
                        <div ref={targetDivRefOrdini} className={' flex  w-full  items-center '}>
                            <div className={'w-[2000px] '}>
                                <ReactPlayer
                                    url={`${process.env.PUBLIC_URL}/video/ordini/ordini.mp4`}
                                    playing
                                    loop
                                    muted
                                    controls={false} // Nascondi i controlli
                                    width="100%"
                                    height="100%"

                                />
                            </div>
                            <div
                                className={'flex  flex-col w-full h-full justify-center items-center text-left '}>
                                <h1 className={'font-poppins-bold text-5xl w-full text-left'}><span
                                    style={{color: '#FFCC00'}}>Ordini</span> in totale autonomia
                                </h1>
                                <h2 className={'font-poppins-semi-bold text-md'}>Ordinare al tavolo diventa un’esperienza completamente fluida e moderna! I clienti hanno la possibilità di inviare i propri ordini
                                    direttamente dal loro smartphone, semplicemente scansionando il QR code presente sul tavolo, senza la necessità di attendere il cameriere. Se preferiscono, anche il cameriere può
                                    prendere le ordinazioni dal proprio dispositivo mobile, garantendo massima flessibilità al servizio. Tutti gli ordini vengono gestiti in tempo reale attraverso una sezione dedicata,
                                    permettendo di monitorare e organizzare le comande con facilità. Questo sistema migliora l’efficienza del servizio, riduce i tempi di attesa e ottimizza l’esperienza complessiva del
                                    cliente.
                                </h2>
                            </div>
                        </div>

                        {/*2*/}
                        <div ref={targetDivRefRicerca} className={' flex  w-full  items-center '}>
                            <div
                                className={'flex  flex-col w-full h-full justify-center items-center text-right  '}>
                                <h1 className={'font-poppins-bold w-full text-5xl'}> <span
                                    style={{color: '#FFCC00'}}>Trova </span>
                                    e Fatti Trovare
                                </h1>
                                <h2 className={'font-poppins-semi-bold text-md'}> Fai conoscere il tuo locale a un pubblico sempre più ampio grazie al nostro servizio di ricerca e recensioni! Come proprietario, avrai la
                                    possibilità di creare una scheda dettagliata della tua attività, arricchita da foto, descrizioni e recensioni dei clienti, che aiuteranno le persone a trovarti con facilità e a
                                    scoprire tutto ciò che offri. La visibilità online del tuo locale sarà ottimizzata, permettendo ai clienti di trovarti rapidamente.
                                    Inoltre, con ChefBot, il nostro assistente virtuale, gli utenti possono cercare e trovare il tuo locale in modo ancora più semplice. ChefBot fornisce suggerimenti personalizzati in
                                    base alle preferenze, indicazioni precise e informazioni aggiornate su orari, menù e altro ancora. Con questo sistema intelligente, far emergere il tuo locale non è mai stato così
                                    facile!
                                </h2>
                            </div>
                            <div className={'w-[2000px] '}>
                                <ReactPlayer
                                    url={`${process.env.PUBLIC_URL}/video/ricerca/ricerca.mp4`}
                                    playing
                                    loop
                                    muted
                                    controls={false} // Nascondi i controlli
                                    width="100%"
                                    height="100%"

                                />
                            </div>
                        </div>

                        {/*3*/}
                        <div ref={targetDivRefMenu} className={' flex  w-full  items-center '}>

                            <div className={'w-[2000px] '}>
                                <ReactPlayer
                                    url={`${process.env.PUBLIC_URL}/video/menu/menu.mp4`}
                                    playing
                                    loop
                                    muted
                                    controls={false} // Nascondi i controlli
                                    width="100%"
                                    height="100%"

                                />
                            </div>

                            <div
                                className={'flex  flex-col w-full h-full justify-center items-center '}>
                                <h1 className={'font-poppins-bold text-5xl w-full text-left'}> <span
                                    style={{color: '#FFCC00'}}>Menù</span> Dinamico e <span
                                    style={{color: '#FFCC00'}}></span>Sempre Pronto </h1>
                                <h2 className={'font-poppins-semi-bold text-md'}>Un menù da gustare con gli occhi e gestire con un tocco! Con MaiSazio, la gestione del tuo menù diventa semplice e dinamica. Il QR code sul
                                    tavolo resta sempre lo stesso, ma puoi aggiornare i contenuti in tempo reale dalla tua sezione dedicata, attivando, disattivando o modificando i piatti in modo facile e veloce. Hai
                                    finito una portata? Rimuovila istantaneamente senza dover rifare l’intero menù.

                                    Inoltre, il menù può essere visualizzato in anteprima dai clienti prima ancora che arrivino nel locale, aumentando e migliorando la loro esperienza. E per chi
                                    preferisce il formato cartaceo, puoi esportare il menù in PDF e stamparlo, così da poterlo consegnare direttamente ai clienti. Con MaiSazio, offri un servizio completo, deliziando i
                                    tuoi clienti con immagini appetitose e informazioni dettagliate su ingredienti, allergie e intolleranze, tutto personalizzabile per un controllo totale.
                                </h2>
                            </div>

                        </div>

                        {/*4*/}
                        <div ref={targetDivRefPrenotazioni} className={' flex  w-full  items-center '}>
                            <div
                                className={'flex  flex-col w-full h-full justify-center items-center '}>
                                <h1 className={'font-poppins-bold text-5xl w-full text-right'}><span
                                    style={{color: '#FFCC00'}}>Prenotazioni </span> Semplici, Gestione Efficiente
                                </h1>
                                <h2 className={'font-poppins-semi-bold text-md w-full text-right'}>Gestisci le prenotazioni dei tavoli in modo semplice ed efficiente, grazie alla nostra sezione dedicata, accessibile sia
                                    tramite sito web che app mobile. I clienti possono prenotare un tavolo inserendo tutte le informazioni necessarie: numero di persone, nome, cognome, email e eventuali note aggiuntive
                                    per richieste particolari. Le prenotazioni vengono automaticamente organizzate per fasce orarie e giorni, offrendoti una panoramica completa della disponibilità dei posti nel tuo
                                    locale, facilitando la gestione del flusso di clienti.

                                    Da questa sezione, potrai visualizzare tutte le richieste in tempo reale e gestirle in base alle tue esigenze. Potrai accettare, modificare o rifiutare le prenotazioni con un solo
                                    clic, assicurando una risposta rapida e un servizio impeccabile. Inoltre, l’organizzazione per fasce orarie ti permette di ottimizzare la disposizione dei tavoli e massimizzare
                                    l’efficienza del tuo locale, offrendo ai clienti un’esperienza fluida e ben pianificata.
                                </h2>
                            </div>
                            <div className={'w-[2000px] '}>
                                <ReactPlayer
                                    url={`${process.env.PUBLIC_URL}/video/prenotazioni/prenotazioni.mp4`}
                                    playing
                                    loop
                                    muted
                                    controls={false} // Nascondi i controlli
                                    width="100%"
                                    height="100%"

                                />
                            </div>

                        </div>


                        {/*5*/}
                        <div ref={targetDivRefGestionale} className={' flex  w-full  items-center '}>
                            <div className={'w-[2000px] '}>
                                <ReactPlayer
                                    url={`${process.env.PUBLIC_URL}/video/gestionale/gestionale.mp4`}
                                    playing
                                    loop
                                    muted
                                    controls={false} // Nascondi i controlli
                                    width="100%"
                                    height="100%"

                                />
                            </div>
                            <div
                                className={'flex  flex-col w-full h-full justify-center items-center '}>
                                <h1 className={'font-poppins-bold text-5xl w-full text-left'}>
                                    <span
                                        style={{color: '#FFCC00'}}>Gestione</span> Completa del Tuo Locale
                                </h1>
                                <h2 className={'font-poppins-semi-bold text-md'}>Gestisci ogni aspetto del tuo ristorante, bar o attività di ristorazione in modo semplice e completo con il nostro gestionale. Dalla gestione
                                    degli ingredienti e delle scorte, alla creazione e personalizzazione del menù, fino al monitoraggio dei piatti più popolari tra i clienti, hai tutto sotto controllo in un’unica
                                    piattaforma. Tieni traccia delle vendite, organizza i turni del personale e gestisci le prenotazioni con facilità. Il nostro sistema ti permette di ottimizzare ogni fase operativa del
                                    tuo locale, migliorando l’efficienza e garantendo un’esperienza fluida sia per te che per i tuoi clienti.
                                </h2>
                            </div>

                        </div>

                        {/*6*/}
                        <div ref={targetDivRefAsporto} className={' flex  w-full  items-center '}>

                            <div
                                className={'flex  flex-col w-full h-full justify-center items-center '}>
                                <h1 className={'font-poppins-bold w-full text-5xl text-right'}> <span
                                    style={{color: '#FFCC00'}}>Asporto</span> e Domicilio<span
                                    style={{color: '#FFCC00'}}></span>
                                </h1>
                                <h2 className={'font-poppins-semi-bold text-md w-full text-right'}>Abilita la possibilità per i tuoi clienti di ordinare comodamente da casa, scegliendo tra asporto o consegna a domicilio! Grazie alla nostra piattaforma, puoi gestire ogni aspetto delle comande in una sezione dedicata. I clienti possono ordinare facilmente tramite sito web o app, personalizzando i piatti secondo le loro preferenze: possono modificare gli ingredienti e il prezzo verrà ricalcolato automaticamente in base alle modifiche apportate.

                                    Ogni ordine viene stampato automaticamente in cucina, garantendo una preparazione rapida e precisa. Inoltre, i clienti possono aggiungere note speciali alle comande, come richieste su intolleranze, allergie o specifiche di cottura. Puoi anche monitorare in tempo reale le statistiche sui piatti più ordinati, ottimizzando il menù in base alle preferenze dei tuoi clienti.

                                    Con la nostra soluzione, gestisci le consegne, l’asporto e ogni dettaglio della preparazione, tutto da un’unica piattaforma che semplifica l’operatività del tuo locale e migliora l’esperienza del cliente.
                                </h2>
                            </div>
                            <div className={'w-[2000px] '}>
                                <ReactPlayer
                                    url={`${process.env.PUBLIC_URL}/video/asporto/asporto.mp4`}
                                    playing
                                    loop
                                    muted
                                    controls={false} // Nascondi i controlli
                                    width="100%"
                                    height="100%"

                                />
                            </div>
                        </div>


                    </div>

                </div>

                <img className={'w-full h-full bg-white'} src={info1}/>

            </div>
        )

    }
}
