import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDebounce} from 'use-debounce';
import {SelectTypeFromPath} from "../types/SelectTypeFromPath";
import ImageLogoMenus from "./ImageLogoMenus";
import GoogleAddressSearch from "./GoogleAddressSearch";
import {checkRestaurants} from "../network/controllers/WecomePageController";
import {showFailNotification} from "../utils/NotificationUtils";
import {getDimension} from "../utils/Utility";
import MenuMyAccountMobile from "./MenuMyAccountMobile";

export default function HeaderMobileComponent() {
    const [parametriRicerca, setParametriRicerca] = useState(localStorage.getItem('ricerca') !== null ? JSON.parse(localStorage.getItem('ricerca') as string) : {
        category: 'Tutti',
        txtricerca: ''
    })

    const [value] = useDebounce(parametriRicerca, 500);
    const [popOverStatus, setPopOverStatus] = useState(false)
    var navigate = useNavigate()
    var listSelect: SelectTypeFromPath[] = []
    listSelect.push({path: '/home', title: 'HOME'})
    listSelect.push({path: '/home/mappa', title: 'MAPPA'})

    useEffect(() => {
        localStorage.setItem('ricerca', JSON.stringify(parametriRicerca))
        dispatchEvent(new Event("storage"))
    }, [value]);

    useEffect(() => {
        window.removeEventListener('deleteRicerca', () => {
        })
        window.addEventListener('deleteRicerca', () => {
            setParametriRicerca({category: 'Tutti', txtricerca: ''})

        })
    }, []);
    const [dimension, setDimension] = useState(false)
    getDimension(640, (res) => {
        setDimension(res)
    })
    return (
        <div className={' '}>

            <header className={`h-[85px] bg-black w-full flex items-center shadow  justify-center absolute z-50 `}>
                <div className={'w-full flex justify-between  items-center gap-x-10 '}>
                    <div className={'w-auto h-full ml-2'}>
                        <ImageLogoMenus/>
                    </div>
                    <div className={'flex w-full h-full  justify-center'}>
                        <div
                            className={`absolute top-4  left-16 right-16`}>
                            <GoogleAddressSearch defaultOpen={false} setNameTextInput={'name'}
                                                 inputDimensions={'h-[50px]'} classname={'w'}
                                                 inputPlaceholder={'Cerca su MaiSazio...'}
                                                 onPlaceSelect={(place) => {

                                                     checkRestaurants(place.place_id, (local) => {
                                                         localStorage.setItem('selectedRestaurantClient', JSON.stringify(local))
                                                         if (window.location.pathname.includes('/detail')) {
                                                             window.location.reload()
                                                         }else{
                                                             navigate(`/detail`);
                                                         }
                                                     }, () => {
                                                         showFailNotification('OPSS', 'Questo locale non fa parte di MaiSazio', 2000)

                                                     })
                                                 }}/>
                        </div>
                    </div>
                    <div className={'w-10  h-auto mr-5'}>
                        <MenuMyAccountMobile/>
                    </div>
                </div>
            </header>

        </div>
    )
}
