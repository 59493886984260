import { LexRuntimeV2Client, RecognizeTextCommand } from '@aws-sdk/client-lex-runtime-v2';
// Configura il client AWS Lex
const lexClient = new LexRuntimeV2Client({
    region: 'eu-west-2',  // Sostituisci con la tua regione AWS

    credentials: {
        accessKeyId: 'AKIA2PL425AZMWQRKJFA',
        secretAccessKey: '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',
    },
});

// Funzione per inviare messaggi ad AWS Lex
export const sendMessageToLex = async (message: string, sessionId: string) => {  // Aggiunto sessionId come parametro
    const params = {
        botId: process.env["REACT_APP_BOT_ID"],  // Sostituisci con l'ID del tuo chatbot Amazon Lex
        botAliasId: 'TSTALIASID',  // Sostituisci con l'ID dell'alias del tuo chatbot
        localeId: 'it_IT',  // Imposta la lingua del tuo chatbot
        sessionId: sessionId,  // Usa il sessionId passato come argomento
        text: message,  // Il messaggio da inviare al chatbot
    };
    const command = new RecognizeTextCommand(params);
    const response = await lexClient.send(command);
    return response;
};
