import {makeRequest} from "../RestAdapter";
import {HttpMethodsEnum} from "../HttpMethodsEnum";
import {CommentType} from "../../types/CommentType";
import {GetProductResponseType} from "../../types/GetProductResponseType";
import {showFailNotification} from "../../utils/NotificationUtils";

export function getComment(idLocal:string,onFinish:(response: CommentType[]) => void) {
    makeRequest<CommentType[]>(`/users/comment?idLocal=${idLocal}`, HttpMethodsEnum.GET).then((res) => {
        if (res.data!==null){
            onFinish(res.data)
        }

    })
}

export function addComment(request:{rating: number, comment: string, idLocal: string,userId:string},onFinish:(response: CommentType[]|null) => void) {
    makeRequest<CommentType[]>(`/users/comment`, HttpMethodsEnum.POST,request).then((res) => {
        if (res.isSuccessfull()){
            onFinish(res.data)
        }else{
            // @ts-ignore
            showFailNotification('Errore',res.data.message,2000)
        }
    })
}

export function getProductList(idLocal:string,idCategory:string,onFinish:(response: GetProductResponseType[]) => void) {
    makeRequest<GetProductResponseType[]>(`/restaurants/products/search?idLocal=${idLocal}&idCategory=${idCategory}`, HttpMethodsEnum.GET).then((res) => {
        if (res.data!==null){
            onFinish(res.data)
        }
    })
}
