import React from 'react';
import {Button, Container, Group, Text, ThemeIcon,} from '@mantine/core';
import {
    IconBrandFacebook,
    IconBrandInstagram,
    IconMail,
    IconMapPin,
    IconPhone
} from "@tabler/icons-react";
import {isMobile} from "react-device-detect";
import logo from '../../assets/logo-gcod.jpg'


export default function Footer  ()  {
    var mapsURI = 'https://www.google.com/maps/search/?api=1&query=ViaCairoli316-98051BarcellonaP.G.(ME)';
    var faceURI = 'https://www.facebook.com/profile.php?id=61560385255726';
    var instaURI = 'https://www.instagram.com/maisazio_app/';
    return (
        <footer className="bg-black text-white py-8 b-0 l-0 ">
            <Container>
                <div className="grid grid-cols-1 md:grid-cols-6 m-1  ">
                    {/* Logo e descrizione */}
                    <div className={'mt-4 mb-3'}>
                        <img src={logo} alt="Logo" className="h-10 mb-4"/>
                    </div>

                    {/* Link utili */}
                    <div className={'my-1'}>
                        <Text className="font-poppins-semi-bold mt-4 mb-3">Link utili</Text>
                        <ul className="">
                            <li><a href="https://www.gicod.it/" target="_blank"
                                   className="hover:underline font-poppins-regular text-xs">Chi
                                siamo</a></li>
                            <li><a href="https://www.gicod.it/f-a-q-1" target="_blank"
                                   className="hover:underline font-poppins-regular text-xs ">Contattaci</a></li>

                            <li><a href="https://www.iubenda.com/termini-e-condizioni/86809145" target="_blank"
                                   className="hover:underline font-poppins-regular text-xs ">Termini e condizioni</a></li>

                            <li><a href="https://www.iubenda.com/privacy-policy/86809145/cookie-policy" target="_blank"
                                   className="hover:underline font-poppins-regular text-xs ">Cookie Policy</a></li>

                            <li><a href="https://www.iubenda.com/privacy-policy/86809145" target="_blank"
                                   className="hover:underline font-poppins-regular text-xs ">Policy Privacy</a></li>
                        </ul>
                    </div>

                    {/* Contatti */}
                    <div className={`${isMobile ? '' : 'col-span-3'} my-1`}>
                        <Text className="font-poppins-semi-bold  mt-4 mb-3">Contatti</Text>
                        <div className="space-y-2">
                            <Group>
                                <ThemeIcon style={{background: "#FFCC00", color: 'black'}}>
                                    <IconPhone size={18}/>
                                </ThemeIcon>
                                <a href={"tel:3773421322"} target="_blank"
                                   className={`hover:underline font-poppins-regular  text-xs`}>39
                                    377 34 21</a>
                            </Group>
                            <Group>
                                <ThemeIcon style={{background: "#FFCC00", color: 'black'}}>
                                    <IconMail size={18}/>
                                </ThemeIcon>
                                <a href={"mailto:info@gicod.it"} target="_blank"
                                   className={`hover:underline font-poppins-regular  text-xs `}>info@gicod.it</a>
                            </Group>
                            <Group>
                                <ThemeIcon style={{background: "#FFCC00", color: 'black'}}>
                                    <IconMapPin size={18}/>
                                </ThemeIcon>
                                <a href={mapsURI} target="_blank"
                                   className={`hover:underline font-poppins-regular  text-xs `}>Via
                                    Cairoli 316 - 98051 Barcellona P.G. (ME)</a>

                            </Group>
                        </div>
                    </div>



                    {/* Link social */}
                    <div className={'my-1'}>
                        <Text className="font-poppins-semi-bold  mt-4 mb-3">Link Social</Text>
                        <div className="space-y-2 ">
                            <Group>
                                <a href="https://www.facebook.com/profile.php?id=61560385255726" target="_blank"
                                   className="hover:underline font-poppins-regular flex items-center justify-center">
                                    <ThemeIcon style={{background: "#FFCC00", color: 'black'}}>
                                        <IconBrandFacebook className={'mr-0.5'} size={18} onClick={() => {
                                        }}/>
                                    </ThemeIcon>
                                    <a href={faceURI} target="_blank"
                                       className={`hover:underline font-poppins-regular text-xs ml-3 `}>Facebook</a>
                                </a>

                            </Group>
                            <Group>
                                <a href="https://www.instagram.com/maisazio_app/" target="_blank"
                                   className="hover:underline font-poppins-regular flex items-center justify-center">
                                    <ThemeIcon style={{background: "#FFCC00", color: 'black'}}>
                                        <IconBrandInstagram size={18} onClick={() => {
                                        }}/>
                                    </ThemeIcon>
                                    <a href={instaURI} target="_blank"
                                       className={`hover:underline font-poppins-regular  text-xs ml-3  `}>Instagram</a>


                                </a>

                            </Group>
                        </div>
                    </div>
                </div>

                <div className="border-t border-gray-700 mt-8 pt-4 text-center">
                <Text className={'font-poppins-semi-bold  text-xs text-amber-400'}>© 2023 by GiCod Srl. Tutti i
                        diritti riservati.</Text>
                    <p className={'w-full text-white text-end font-poppins-regular text-xs'}>{'v.' + process.env.REACT_APP_VERSION}</p>

                </div>

            </Container>
        </footer>
    );
};


