import {Divider, Tooltip} from "@mantine/core";
import {useState} from "react";
import {GetProductResponseType} from "../types/GetProductResponseType";
import {StatusEnum} from "../enums/StatusEnum";
import placeProd from '../assets/placeholder-dish.svg';
import potIcon from "../assets/pot-icon.png";
import divider from "../assets/divider.png";
import {getDimension} from "../utils/Utility";
import {isMobile} from "react-device-detect";

export default function ItemProductComponent(props: { product: GetProductResponseType }) {
    let ingredients = props.product.ingredients.map(ingredient => ingredient.name).join(', ');
    let description = props.product.description;
    const [dimension, setDimension] = useState(false);
    getDimension(1400, (res: boolean) => {
        setDimension(res);
    });

    // Use a set to track unique allergens
    const uniqueAllergens = Array.from(new Set(props.product.allergensList.map(item => item.name)));


if (isMobile) {
        return (
            <div className={'w-full h-auto flex flex-col '}>
                <div
                    className={`w-full h-full  flex flex-col mt-4 items-center justify-center gap-x-2 `}>

                    <Divider color={'yellow'} size={'md'} className={' w-full h-full px-2 py-12'}/>
                    <div
                        className={`w-full h-full flex flex-col items-center justify-center`}>
                        <img className={'w-full h-auto rounded-xl shadow-[3px_3px_8px_0.5px_rgba(0,0,0,0.3)]'}
                             src={props.product.approvalStatus === StatusEnum.ACCEPTED ? props.product.photoPath : placeProd}/>
                        <div className={'flex w-full flex-col gap-y-3 '}>
                            <div
                                className={' w-full h-auto flex flex-col font-poppins-bold text-xl text-center mt-1'}>
                                <div className={'w-full h-auto flex justify-center items-center'}>
                                    <h1 className={'pl-2'}>{props.product.nameProduct}</h1>
                                </div>
                                <h1 className={'w-full h-auto flex items-center justify-center whitespace-nowrap '}> € {`${props.product.priceOriginal}  ${props.product.priceType!=='singolo'?props.product.priceType:''}`}</h1>
                            </div>
                            <div className="flex flex-col w-full h-full items-center justify-center gap-x-2 mt-3">
                                {ingredients!==''?<img className="h-7 w-auto mx-auto" src={potIcon}/>:null}
                                <h1 className={'w-full h-full text-sm font-poppins-semi-bold whitespace-normal text-center pt-2'}>{ingredients}</h1>
                            </div>
                            {description && (
                                <>
                                    <img className={'w-[20%] h-[20%] items-center justify-center mx-auto'}
                                         src={divider}/>
                                    <div
                                        className="flex flex-row w-full h-full items-center justify-center gap-x-2 pl-2 ">
                                        <h1 className="w-[70%] h-full text-gray-600 font-poppins-regular text-center text-sm whitespace-normal">{description}</h1>
                                    </div>
                                </>
                            )}
                            <div className={'w-full h-auto flex justify-start '}>
                            </div>
                        </div>
                    </div>
                </div>
                {uniqueAllergens.length > 0 && (
                    <div className={'flex w-full h-auto  items-center justify-center gap-x-2 pt-4 '}>
                        <h1 className={'font-poppins-semi-bold text-sm'}>Allergeni:</h1>
                        <div className={'flex gap-x-2'}>
                            {uniqueAllergens.map((allergenName, index) => {
                                let allergen = props.product.allergensList.find(a => a.name === allergenName);
                                if (allergen) {
                                    return (
                                        // @ts-ignore
                                        <Tooltip key={index} label={allergen.name}>
                                            <img className={'w-6'} src={allergen.b64Image}/>
                                        </Tooltip>
                                    )
                                }
                            })}
                        </div>
                    </div>
                )}
                <div className={'flex-col my-2'}>
                    <Divider color={'yellow'} size="md"/>
                </div>
            </div>
        )
}else{
        return (
            <div className={'w-full overflow-y-auto '}>
                <div
                    className={`flex flex-col w-full h-full pt-4items-center justify-center gap-x-2 `}>
                    <div
                        className={`flex items-center justify-center  ${dimension ? 'flex-col items-center justify-center' : ''} w-full `}>
                        <img className={'w-[250px] h-[250px] rounded-xl shadow-[3px_3px_8px_0.5px_rgba(0,0,0,0.3)] '}
                             src={props.product.approvalStatus === StatusEnum.ACCEPTED ? props.product.photoPath : placeProd}/>
                        <div className={'flex w-full flex-col justify-between px-5 '}>
                            <div
                                className={'flex flex-col w-full h-auto font-poppins-bold text-xl text-center mt-1'}>
                                <div className={'w-full h-auto flex justify-center items-center'}>
                                    <h1 className={'pl-2'}>{props.product.nameProduct}</h1>
                                </div>
                                <h1 className={'whitespace-nowrap'}> € {`${props.product.priceOriginal}  ${props.product.priceType!=='singolo'?props.product.priceType:''}`}</h1>
                            </div>
                            <div className="flex flex-col w-full h-full items-center justify-center gap-x-2 pl-2 pt-3">
                                {ingredients!==''?<img className="h-7 w-auto mx-auto" src={potIcon}/>:null}
                                <h1 className={'w-auto h-full text-sm font-poppins-semi-bold whitespace-normal text-center pl-2 pt-2'}>{ingredients}</h1>
                            </div>
                            {description && (
                                <>
                                    <img className={'w-[20%] h-[20%] items-center justify-center mx-auto'}
                                         src={divider}/>
                                    <div
                                        className="flex flex-row w-full h-full items-center justify-center gap-x-2 pl-2 ">
                                        <h1 className="w-[70%] h-full text-gray-600 font-poppins-regular text-center text-sm whitespace-normal">{description}</h1>
                                    </div>
                                </>
                            )}
                            <div className={'w-full h-auto flex justify-start '}>
                            </div>
                        </div>
                    </div>
                </div>
                {uniqueAllergens.length > 0 && (
                    <div className={'flex w-full h-auto gap-x-2 pt-4 '}>
                        <h1 className={'font-poppins-semi-bold text-sm'}>Allergeni:</h1>
                        <div className={'flex gap-x-2'}>
                            {uniqueAllergens.map((allergenName, index) => {
                                let allergen = props.product.allergensList.find(a => a.name === allergenName);
                                if (allergen) {
                                    return (
                                        // @ts-ignore
                                        <Tooltip key={index} label={allergen.name}>
                                            <img className={'w-6'} src={allergen.b64Image}/>
                                        </Tooltip>
                                    )
                                }
                            })}
                        </div>
                    </div>
                )}
                <div className={'flex-col mt-4'}>
                    <Divider color={'yellow'} size="md"/>
                </div>
            </div>
        )
    }}
