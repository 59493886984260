import {ComandaOrder} from "../types/PostCommand";
import {Divider} from "@mantine/core";
import logo from "../assets/logo-menus.svg"
import CustomButton from "./CustomButton";
import {deleteCommand} from "../network/controllers/CommandController";
import React from "react";
import {showWarningNotification} from "../utils/NotificationUtils";

export default function CommandClientCardComponent(props: { order: ComandaOrder }) {
    const totalPrice = props.order.dishes.reduce((acc, dish) => acc + dish.price, 0);

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}`;
    };

    function formatDateTimeWithoutTimezone(dateStr: string): string {
        // Parse the input date string to a Date object
        const date = new Date(dateStr);

        // Extract the date part
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');

        // Extract the time part
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

        // Format the datetime string as YYYY-MM-DDTHH:MM:SS.mmm
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }

    const checkDeliveryTime = () => {
        const deliveryTime = new Date(props.order.delivery);
        const currentTime = new Date();
        // @ts-ignore
        const timeDifference = deliveryTime - currentTime;
        const fortyFiveMinutesInMs = 45 * 60 * 1000;
        return timeDifference >= fortyFiveMinutesInMs
    };
    return (
        <div className={'w-auto h-[700px] mx-5 my-5 rounded-2xl border-t-4 shadow-xl shadow-black'}>
            <div className={'w-full h-[600px] overflow-y-auto flex-col justify-between'}>
                <div key={props.order.id} className="w-full h-min order-details p-7 mb-5">
                    <p className={'w-full h-full bg-black rounded-2xl  text text-center justify-center items-center font-poppins-bold text-2xl text-amber-400'}> {props.order.type}</p>
                    <p className={'font-poppins-regular text-center text-xl my-5'}>
                        <strong>{props.order.restaurantDto.nameRestaurant}</strong></p>
                    <Divider color={'dark'} size={'lg'}/>
                    <p className={'font-poppins-regular mt-5'}><strong>Creato il:
                        :</strong> {formatDate(props.order.booking_date)}</p>
                    <p className={'font-poppins-regular'}><strong>Consegna
                        prevista:</strong> {formatDateTimeWithoutTimezone(props.order.delivery)}</p>

                    <p
                        className={'font-poppins-regular mb-5'}
                        style={{
                            visibility: props.order.type === 'DELIVERY' ? 'visible' : 'hidden',
                            height: 'auto',
                            marginBottom: '1rem'
                        }}
                    >
                        <strong>Indirizzo:</strong> {props.order.address || "\u00A0"}
                    </p>

                    <Divider color={'dark'} size={'lg'}/>

                    <p className={'w-full h-auto font-poppins-regular text-lg text-center py-3 '}><strong> I MIEI
                        ORDINI </strong></p>

                    <ul className="h-64 overflow-y-auto py-3 ">
                        {props.order.dishes.map((dish, index) => (
                            <li key={index} className="flex justify-between">
                                <div className="flex flex-col mt-2">
                                    <p className="font-poppins-regular  mt-2">
                                        <strong>{dish.quantity} X {dish.name}</strong>
                                    </p>
                                    <p className="font-poppins-regular mr-10">
                                        {dish.ingredient && dish.ingredient.length > 0 ? dish.ingredient.join(', ') : 'Nessun ingrediente'}
                                    </p>
                                </div>

                                <p className="font-poppins-regular whitespace-nowrap mt-6">
                                    <strong> € {dish.price}</strong>
                                </p>
                            </li>
                        ))}
                    </ul>

                    <Divider color={'dark'} size={'lg'}/>

                    <div className={'flex justify-between mt-5'}>
                        <p className={'font-poppins-regular'}><strong>Totale</strong></p>
                        <p className={'font-poppins-regular'}><strong>€ {totalPrice}</strong></p>
                    </div>
                </div>
            </div>

            <div
                className={`w-full  ${checkDeliveryTime() ? 'visible' : 'invisible'} flex gap-x-3 p-4 justify-center items-center`}>
                <CustomButton title={'Elimina Comanda'} onClick={() => {
                    showWarningNotification('Sicuro di voler eliminare questo ordine?', () => {
                        deleteCommand(false, [props.order.id], (response) => {
                            window.location.reload();
                        })
                    })

                }} enable={true} isLoading={false} small={true}/>
            </div>
        </div>
    );
}
