import React, {useEffect, useState} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import {makeRequest} from "../network/RestAdapter";
import {HttpMethodsEnum} from "../network/HttpMethodsEnum";
import CustomButton from "./CustomButton";
import {useNavigate} from "react-router-dom";
import {BackgroundImage} from "@mantine/core";
import iconPay from "../assets/payment/payment.jpg"
import iconPay2 from "../assets/payment/wll-payment.jpg"
import ImageLogoMenus from "./ImageLogoMenus";
import {LoginResponseType} from "../types/LoginResponseType";
import {isMobile} from "react-device-detect";
import CheckoutformRenew from "./CheckoutformRenew";


const stripePromise = loadStripe(process.env["REACT_APP_STRIPE_PRIVATE_KEY"] as string);
var userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType
const PaymentPage = () => {
    const [clientSecret, setClientSecret] = useState({
        clientSecret: '',
        idStripe: ''
    });
    const [isLoading, setIsLoading] = useState(false);


    var data64Fromurl = new URLSearchParams(window.location.search).get('data')
    var licenseID= new URLSearchParams(window.location.search).get('license') as string
    var packID= new URLSearchParams(window.location.search).get('package') as string
    var decodeBase64 = atob(data64Fromurl as string)
    var navigate = useNavigate()
    useEffect(() => {
        if (JSON.parse(decodeBase64).ammountCent !== 0) {
            makeRequest<Map<string, string>>('/users/restaurants/client_secret', HttpMethodsEnum.POST, JSON.parse(decodeBase64)).then((res) => {
                // @ts-ignore
                setClientSecret({clientSecret: res.data.clientSecret, idStripe: res.data.idStripe});
            })
        }
    }, []);
    // @ts-ignore
    if (JSON.parse(decodeBase64).ammountCent === 0) {
        if (isMobile) {
            return (
                <div>
                    <header
                        className={`h-[85px] bg-black w-full flex items-center`}>
                        <div className={'w-full flex gap-x-10 items-center '}>
                            <div className={'w-auto ml-10'}>
                                <ImageLogoMenus/>
                            </div>
                        </div>
                    </header>
                    <div className={' w-full h-full flex flex-col items-center justify-start '}>
                        <div className={'w-full h-full flex flex-col justify-center items-center mt-32 p-5'}>
                            <div className="flex flex-col gap-y-4">
                                <p className={'w-full h-auto text-4xl font-poppins-bold text-center'}>{'Benvenuto su MENUS ' + JSON.parse(decodeBase64).nameRestaurant + '!'}</p>
                                <p className={'w-full h-auto text-2xl font-poppins-bold text-center'}>Il nostro viaggio
                                    inizia qui...</p>
                            </div>
                        </div>
                        <div className={'w-full h-full flex item-center justify-center mt-32'}>
                            <CustomButton small={true} title={"Attiva periodo di prova"} onClick={() => {
                                makeRequest<Map<string, string>>(`/users/restaurants/enableFreeTrial/${JSON.parse(decodeBase64).stripeId}`, HttpMethodsEnum.GET).then((res) => {
                                    // @ts-ignore
                                    if (res.isSuccessfull()) {
                                        if (userData && userData.role.includes('ADMIN')) {
                                            navigate('/admin')
                                        } else {
                                            navigate('/welcome')
                                        }
                                        window.location.reload()
                                    }
                                })
                            }} enable={true} isLoading={isLoading}/>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <header
                        className={`h-[85px] bg-black w-full flex items-center shadow  justify-center px-10 absolute z-50 `}>
                        <div className={'w-full flex gap-x-10 items-center '}>
                            <div className={'w-[220px]'}>
                                <ImageLogoMenus/>
                            </div>
                        </div>
                    </header>

                    <BackgroundImage className=" w-screen h-screen no-repeat " src={iconPay}>
                        <div className={'flex flex-col w-auto h-auto justify-start pt-64  pl-48 '}>
                            <div className={'w-full h-full flex flex-col justify-center items-start'}>
                                <div className="flex flex-col gap-y-4">
                                    <p className={'w-full h-auto text-4xl font-poppins-bold text-center  '}>{'Benvenuto su MENUS ' + JSON.parse(decodeBase64).nameRestaurant + '!'}</p>
                                    <p className={'w-full h-auto text-2xl font-poppins-bold text-center pb-32'}>Il
                                        nostro viaggio inizia qui...</p>
                                </div>
                            </div>
                            <div className={'w-full h-full pl-44'}>
                                <CustomButton title={"Attiva periodo di prova"} onClick={() => {
                                    makeRequest<Map<string, string>>(`/users/restaurants/enableFreeTrial/${JSON.parse(decodeBase64).stripeId}`, HttpMethodsEnum.GET).then((res) => {
                                        // @ts-ignore
                                        if (res.isSuccessfull()) {
                                            if (userData && userData.role.includes('ADMIN')) {
                                                navigate('/admin')
                                            } else {
                                                navigate('/welcome')
                                            }
                                            window.location.reload()
                                        }
                                    })
                                }} enable={true} isLoading={isLoading}/>
                            </div>
                        </div>
                    </BackgroundImage>
                </div>)

        }
    } else {
        if (isMobile) {
            return (
                <div>
                    <header
                        className={`h-[85px] bg-black w-full flex items-center shadow  justify-center px-10 absolute z-50 `}>
                        <div className={'w-full flex gap-x-10 items-center '}>
                            <div className={'w-[220px] '}>
                                <ImageLogoMenus/>
                            </div>
                        </div>
                    </header>

                    <BackgroundImage className=" w-screen h-screen no-repeat  " src={iconPay2}>
                        <div className={'bg-gradient-to-b from-black from-15% to-transparent to-95%'}>
                            <div className={'h-auto p-32'}>
                                {clientSecret.clientSecret !== '' ? <Elements stripe={stripePromise} options={
                                    clientSecret}>
                                    <CheckoutformRenew idLicense={licenseID} idPackage={packID}  idStripe={clientSecret.idStripe}/>
                                </Elements> : null}
                            </div>
                        </div>
                    </BackgroundImage>
                </div>

                /*        <div className={'p-32'}>
                            {clientSecret.clientSecret !== '' ? <Elements stripe={stripePromise} options={
                                clientSecret}>
                                <CheckoutForm idStripe={clientSecret.idStripe}/>
                            </Elements> : null}
                        </div>*/
            );
        } else {
            return (
                <div>
                    <header
                        className={`h-[85px] bg-black w-full flex items-center shadow  justify-center px-10 absolute z-50 `}>
                        <div className={'w-full flex gap-x-10 items-center '}>
                            <div className={'w-[220px] '}>
                                <ImageLogoMenus/>
                            </div>
                        </div>
                    </header>

                    <BackgroundImage className=" w-screen h-screen no-repeat  " src={iconPay2}>
                        <div className={'bg-gradient-to-b from-black from-15% to-transparent to-95%'}>
                            <div className={'h-auto p-32'}>
                                {clientSecret.clientSecret !== '' ? <Elements stripe={stripePromise} options={
                                    clientSecret}>
                                    <CheckoutformRenew idLicense={licenseID} idPackage={packID}  idStripe={clientSecret.idStripe}/>
                                </Elements> : null}
                            </div>
                        </div>
                    </BackgroundImage>
                </div>
            )
        }
    }
};

export default PaymentPage;


