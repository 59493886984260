import {Checkbox, Divider, Switch, Table} from "@mantine/core";
import GenericImg from "../../../components/generic/GenericImg";
import {StatusEnum} from "../../../enums/StatusEnum";
import placeholder from '../../../assets/placeolder-categoria.svg';
import {GetProductResponseType} from "../../../types/GetProductResponseType";
import {changeProductStatus, updateProductPosition} from "../../../network/controllers/admin/ProductController";
import React, {useEffect, useState} from "react";
import {getMenu} from "../../../network/controllers/admin/MenuController";
import {getLicenceFromLicenseId} from "../../../network/controllers/admin/AdminController";
import ReactDragListView from "react-drag-listview";
import arrowImage from '../../../assets/arrowSelection.svg'

import {CategoryType} from "../../../types/CategoryType";
import MenuProduct from "../../../components/MenuProduct";
import {isMobile} from "react-device-detect";

export default function ProductCategoryTable(props: {
    category: CategoryType
    productList: GetProductResponseType[],
    onClickItem: (product: GetProductResponseType) => void,
    onReload: () => void
}) {

    useEffect(() => {
        getMenu((menu) => {
            setMenuList(menu)
        })
    }, []);

    function retrieveStatus(status: string): string {
        if (status === StatusEnum.ACCEPTED) {
            return 'border-lime-400'
        } else if (status === StatusEnum.REJECTED) {
            return 'border-red-600'
        } else if (status === StatusEnum.PENDING) {
            return 'border-yellow'
        } else {
            return ''
        }
    }

    const [selectedRows, setSelectedRows] = useState<Map<string, GetProductResponseType>>(new Map());
    const [menuList, setMenuList] = useState<any[]>([]);
    const [isKitchenEnable, setIsKitchenEnable] = useState<boolean>(false);

    function addElement(element: GetProductResponseType, isChecked: boolean) {
        var tmpMap = new Map<string, GetProductResponseType>(selectedRows);
        var tmpElement = element;
        if (isChecked) {
            tmpElement.menusList!.push(localStorage.getItem('menuId') as string);
        } else {
            tmpElement.menusList = tmpElement.menusList!.filter((value) => value !== localStorage.getItem('menuId') as string);
        }
        if (selectedRows.get(element.id) === undefined) {
            tmpMap.set(element.id, tmpElement);
        } else {
            tmpMap.delete(element.id);
        }
        setSelectedRows(tmpMap);
    }

    useEffect(() => {
        getLicenceFromLicenseId((license) => {
            if (license.namePackage !== 'BASE') {
                setIsKitchenEnable(true);
            }
        });
    }, []);

    useEffect(() => {
        var listProductSelected: GetProductResponseType[] = [];
        selectedRows.forEach((item) => {
            listProductSelected.push(item);
        });
        // props.elementSelected(listProductSelected)
    }, [selectedRows]);

    const [items, setItems] = useState(props.productList.sort());

    const onDragEnd = (fromIndex: any, toIndex: any) => {
        const updatedItems = [...items];
        const item = updatedItems.splice(fromIndex, 1)[0];
        updatedItems.splice(toIndex, 0, item);
        updatedItems.forEach((product, index) => {
            product.position[`${JSON.parse(localStorage.getItem('selectedMenu') as string).id}--${props.category.id}`] = index + 1
        })
        setItems(updatedItems)
        updateProductPosition(updatedItems, (response) => {

        })
        setItems(updatedItems);
    };

    const rows = items.length > 0 ? items.map((element, index) => (
        <Table.Tr className={'w-full cursor-pointer drag-handle'} key={element.id}>
            <Table.Td className={'w-10'}>
                <img alt={''} src={arrowImage}/>
            </Table.Td>
            <Table.Td onClick={() => {
            }}>
                <div className={'w-full flex justify-start p-2'}>
                    <img alt={''} src={element.photoPath}
                         className={`w-[100px] h-[100px] rounded-lg border-[3.5px] ${retrieveStatus(element.approvalStatus)} p-0.5`}/>
                </div>
            </Table.Td>
            <Table.Td>
                <h1>{element.nameProduct}</h1>
            </Table.Td>
            {isKitchenEnable ? <Table.Td>
                <h1>{element.nameKitchen}</h1>
            </Table.Td> : null}
            <Table.Td>
                {element.ingredients.map((ingredient, i) => (
                    <h1 key={i}>{ingredient.name}</h1>
                ))}
            </Table.Td>
            <Table.Td>
                <h1>{element.priceOriginal + ' €  ' + element.priceType}</h1>
            </Table.Td>
            <Table.Td>
                <Switch color={'yellow'} defaultChecked={element.isAvailable} onChange={(v) => {
                    changeProductStatus(element.id, v.currentTarget.checked, (res) => {
                    });
                }}/>
            </Table.Td>
            <Table.Td>
                <MenuProduct onClickElement={(product) => {
                    props.onClickItem(product)
                }} listSize={props.productList.length} product={element} category={props.category} onListChange={() => {
                    props.onReload()
                }}/>
            </Table.Td>
        </Table.Tr>
    )) : null;

    if (isMobile) {
        return (
            <div className="w-full h-auto flex-row">
                <ReactDragListView
                    nodeSelector="tr"
                    handleSelector=".drag-handle"
                    onDragEnd={onDragEnd}
                >
                    <Table.ScrollContainer minWidth="auto">
                        <Table striped highlightOnHover withTableBorder withRowBorders={false}>
                            <Table.Tbody>
                                {items.map((element, index) => (
                                    <Table.Tr key={element.id} className="w-full h-auto flex flex-col p-5">
                                        <MenuProduct
                                                onClickElement={(product) => {
                                                    props.onClickItem(product)
                                                }}
                                                listSize={props.productList.length}
                                                product={element}
                                                category={props.category}
                                                onListChange={() => {
                                                    props.onReload()
                                                }}
                                            />

                                        <Table.Td className={'w-full h-auto flex justify-center items-center pb-3'}
                                                  style={{fontSize: '10px'}}>
                                            <img alt={''} src={element.photoPath}
                                                 className={`w-[80px] h-[80px] rounded-lg border-[3.5px] ${retrieveStatus(element.approvalStatus)} p-0.5`}/>
                                        </Table.Td>
                                        <Table.Th style={{fontSize: '11px'}}><strong>NOME PRODOTTO</strong></Table.Th>
                                        <Table.Td style={{fontSize: '12px'}}>{element.nameProduct}</Table.Td>
                                        {isKitchenEnable &&
                                            <Table.Td style={{fontSize: '11px'}}>{element.nameKitchen}</Table.Td>}
                                        <Table.Th style={{fontSize: '10px'}}>INGREDIENTI</Table.Th>
                                        <Table.Td
                                            style={{fontSize: '12px'}}>{element.ingredients.map((ingredient, i) => (
                                            <h1 key={i}>{ingredient.name}</h1>
                                        ))}</Table.Td>
                                        <Table.Th style={{fontSize: '11px'}}>PREZZO</Table.Th>
                                        <Table.Td
                                            style={{fontSize: '12px'}}>{element.priceOriginal + ' €  ' + element.priceType}</Table.Td>
                                        <Table.Th style={{fontSize: '12px'}}>ATTIVO</Table.Th>
                                        <Table.Td style={{fontSize: '11px'}}>
                                            <Switch color={'yellow'} defaultChecked={element.isAvailable}
                                                    onChange={(v) => {
                                                        changeProductStatus(element.id, v.currentTarget.checked, (res) => {
                                                        });
                                                    }}/>
                                        </Table.Td>
                                        <Divider className={'mx-5 my-5'} size={'xs'} color={'#FFCC00'}></Divider>
                                    </Table.Tr>
                                ))}
                            </Table.Tbody>
                        </Table>
                    </Table.ScrollContainer>
                </ReactDragListView>
            </div>
        )
            ;
    } else {
        return (
            <div className={'mt-2'}>
                <ReactDragListView
                    nodeSelector="tr"
                    handleSelector=".drag-handle"
                    onDragEnd={onDragEnd}>
                    <Table.ScrollContainer minWidth={'auto'}>
                        <Table striped highlightOnHover withTableBorder withRowBorders={false}>
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th></Table.Th>
                                    <Table.Th>IMMAGINE</Table.Th>

                                    {isKitchenEnable ? <Table.Th>CUCINA</Table.Th> : null}
                                    <Table.Th>INGREDIENTI</Table.Th>
                                    <Table.Th>PREZZO</Table.Th>
                                    <Table.Th>ATTIVO</Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {rows}
                            </Table.Tbody>
                        </Table>
                    </Table.ScrollContainer>
                </ReactDragListView>
            </div>
        )
    }
}
