import {StatusEnumRequest} from "../../enums/StatusRequestEnum";
import addIcon from "../add-icon.svg";
import React, {useState} from "react";
import {Textarea} from "@mantine/core";
import CustomButton from "../../components/CustomButton";
import {GetRequestType, RequestType} from "../../types/RequestType";
import {LoginResponseType} from "../../types/LoginResponseType";
import {LocalType} from "../../types/LocalType";
import {createRequest} from "../../network/controllers/admin/RequestController";
import {isMobile} from "react-device-detect";

export default function AddRequestComponent(props: { title: string, onReload: (response:Map<string,GetRequestType[]>) => void }) {
    var userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType
    var local = JSON.parse(localStorage.getItem('selectedRestaurant') as string) as LocalType

    const [addRequest, setAddRequest] = useState(false)

    const [request, setRequest] = useState<RequestType>({
        emailClient: userData.email,
        idLocal: local.id,
        messageClient: "",
        nameClient: userData.name,
        nameLocal: local.nameRestaurant,
        phoneClient: userData.phone,
        response: "",
        surnameClient: userData.surname,
        typeRequest: StatusEnumRequest.retrieveFromString(props.title)
    })
    if (isMobile){
    return (
        <div className={'flex w-full h-full flex-col p-2 '}>
            <div
                className={`w-full flex justify-center bg-yellow  ${addRequest ? 'rounded-t-2xl duration-200  ' : 'rounded-2xl duration-1000 delay-[900ms]'}`}>
                <div className={'w-full flex items-center justify-between p-2'}>
                    <h1 className={'font-poppins-semi-bold text-lg text-black bg-yellow'}>{props.title}</h1>
                    <img alt={''} className={'w-5 h-5'} src={addIcon} onClick={() => (
                        setAddRequest(!addRequest)
                    )}/>
                </div>
            </div>
            <div
                className={`${addRequest ? 'h-[400px] visible ' : 'h-[0px] invisible '} duration-1000 delay-[250ms] w-full  bg-black flex flex-col items-center justify-center rounded-b-2xl `}>
                <div
                    className={`w-full h-auto flex items-center justify-center mt-2 ${addRequest ? 'opacity-100  duration-500 delay-500' : 'opacity-0 duration-100 '}`}>
                    <h1 className={`w-full font-poppins-bold text-white text-center`}>DATA RICHIESTA</h1>
                    <h1 className={`w-full font-poppins-regular text-white text-center`}>{new Date().toLocaleDateString()}</h1>
                </div>
                <div
                    className={`w-full flex mt-5 ${addRequest ? 'opacity-100 duration-[2500ms] ' : 'opacity-0 duration-700 '} `}>
                    <Textarea classNames={{
                        root: 'w-full p-1',
                        label: 'text-white font-poppins-bold'
                    }} autosize maxRows={5} minRows={5} label={'MESSAGGIO'} variant={'filled'}
                              placeholder={'inserisci il messaggio che vuoi inviare'} onChange={(ev) => {
                        setRequest({...request,messageClient:ev.currentTarget.value})
                    }}/>
                </div>
                <div
                    className={`w-full mt-2 flex items-center justify-center ${addRequest ? 'opacity-100 duration-[2500ms] delay-[250ms] ' : 'opacity-0 duration-200 '}  `}>
                    <CustomButton title={'Invia'} small={true} onClick={() => {
                        createRequest(request,(response)=>{
                            var tmp = new Map<string, GetRequestType[]>()
                            Object.entries(response).forEach((ite) => {
                                tmp.set(ite[0], ite[1])
                            })
                            setAddRequest(false)
                            props.onReload(tmp)
                        })
                    }} enable={request.messageClient!==''} isLoading={false}/>
                </div>

            </div>
        </div>
    )
}else{
        return (
            <div className={'flex w-full h-full flex-col p-4 '}>
                <div
                    className={`w-full flex justify-center bg-yellow  ${addRequest ? 'rounded-t-2xl duration-200  ' : 'rounded-2xl duration-1000 delay-[900ms]'}`}>
                    <div className={'w-full flex items-center justify-center gap-x-10'}>
                        <h1 className={'font-poppins-semi-bold text-lg text-black text-center  bg-yellow'}>{props.title}</h1>
                        <img alt={''} className={'w-5 h-5 cursor-pointer'} src={addIcon} onClick={() => (
                            setAddRequest(!addRequest)
                        )}/>
                    </div>
                </div>
                <div
                    className={`${addRequest ? 'h-[400px] visible ' : 'h-[0px] invisible '} duration-1000 delay-[250ms] w-full  bg-black flex flex-col items-center justify-center rounded-b-2xl `}>
                    <div
                        className={`w-full flex items-center justify-center mt-5 ${addRequest ? 'opacity-100  duration-500 delay-500' : 'opacity-0 duration-100 '}`}>
                        <h1 className={`w-full font-poppins-bold text-white text-center`}>DATA RICHIESTA</h1>
                        <h1 className={`w-full font-poppins-regular text-white text-center`}>{new Date().toLocaleDateString()}</h1>
                    </div>
                    <div
                        className={`w-full flex mt-5 ${addRequest ? 'opacity-100 duration-[2500ms] ' : 'opacity-0 duration-700 '} `}>
                        <Textarea classNames={{
                            root: 'w-full p-1',
                            label: 'text-white font-poppins-bold'
                        }} autosize maxRows={5} minRows={5} label={'MESSAGGIO'} variant={'filled'}
                                  placeholder={'inserisci il messaggio che vuoi inviare'} onChange={(ev) => {
                            setRequest({...request,messageClient:ev.currentTarget.value})
                        }}/>
                    </div>
                    <div
                        className={`w-full mt-5 flex items-center justify-center ${addRequest ? 'opacity-100 duration-[2500ms] delay-[250ms] ' : 'opacity-0 duration-200 '}  `}>
                        <CustomButton title={'Invia'} onClick={() => {
                            createRequest(request,(response)=>{
                                var tmp = new Map<string, GetRequestType[]>()
                                Object.entries(response).forEach((ite) => {
                                    tmp.set(ite[0], ite[1])
                                })
                                setAddRequest(false)
                                props.onReload(tmp)
                            })
                        }} enable={request.messageClient!==''} isLoading={false}/>
                    </div>

                </div>
            </div>
        )
    }}
