import React from "react";

export default function ButtonGroup(props: {
    label: string,
    content: string[],
    selected: string,
    onClick: (buttonElement: string) => void
}) {
    return (
        <>

            <h1 className={'text-white font-poppins-regular text-base leading-6 mt-7 ms-4 buttonGroup-label'}>{props.label}</h1>
            <div className={'flex flex-row justify-start mx-5 mt-4 gap-3 flex-wrap'}>

                {props.content.map((buttonElement, index) => (

                    // @ts-ignore
                    <div
                        className={`button-box border-solid border-[1px] w-[65px] rounded-[0.375rem] hover:bg-yellow bg-opacity-40 ${index === parseInt(props.selected, 10) - 1 && 'bg-yellow opacity-50'}`}
                        onClick={() => {
                            props.onClick(buttonElement)
                        }} key={index}><h1
                        className={'text-white text-center font-poppins-regular text-base px-6 py-3'}>{buttonElement}</h1></div>
                ))}
            </div>
        </>
    )
}
