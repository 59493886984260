import {useEffect, useState} from "react";
import imgBg from "../assets/foto-mulincianeddi.webp";
import {BackgroundImage} from "@mantine/core";
import CustomButton from "../components/CustomButton";
import {useNavigate} from "react-router-dom";
import {changeTmpPass, login, recoveryPass} from "../network/controllers/LoginApiController";
import {LoginResponseType} from "../types/LoginResponseType";
import {showFailNotification} from "../utils/NotificationUtils";
import {Buffer} from "buffer";
import {isMobile} from "react-device-detect";
import {isTablet} from "../utils/WindowDimensionUtils";
import {validateEmail, validatePassword} from "../utils/StringUtils";
import CustomTextInputValue from "../components/generic/CustomTextInputValue";
import logo from "../assets/logo-menus.webp";

export default function LoginPage() {
    const [loginResponse, setLoginResponse] = useState<LoginResponseType>()
    const [loginData, setLoginData] = useState({username: '', password: ''})
    const [changePassData, setChangePassData] = useState({pass: '', confirmPass: ''})
    const [loading, setLoading] = useState<boolean>(false);
    const [isRecovering, setIsRecovering] = useState(false);
    const [isTmpPassword, setIsTmpPassword] = useState(false);
    var navigate = useNavigate();
    var header = new Headers()

    function startLogin() {
        const auth = Buffer.from(loginData.username.trim() + ':' + Buffer.from(loginData.password.trim()).toString('base64')).toString('base64');
        header.append('auth', auth);
        header.append('systemDevice', 'web')
        header.append('firebaseToken', '')
        login(header, (response) => {
            setLoading(false)
            if (response) {
                if (response.temporaryPassword) {
                    setLoginResponse(response)
                    setChangePassData({confirmPass: '', pass: ''})
                    setIsTmpPassword(true)
                } else {
                    localStorage.setItem('userData', JSON.stringify(response))
                    navigate('/welcome')
                }
            }

        })

    }

    var userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType
    useEffect(() => {
        if (userData) {
            navigate('/welcome')
        }
    }, []);


    function changeTmpPassword() {
        const auth = Buffer.from(loginData.username.trim() + ':' + Buffer.from(changePassData.confirmPass.trim()).toString('base64')).toString('base64');
        changeTmpPass({id: loginResponse!.id, newAuth: auth}, (response) => {
            setLoading(false)
            if (response.status === 200) {
                setIsTmpPassword(false)
                setLoginData({username: '', password: ''})
                setChangePassData({confirmPass: '', pass: ''})
            } else {
                showFailNotification('Errore', response.data.message, 3000);
            }
        })
    }


    return (
        <BackgroundImage className={'w-full h-screen '} src={imgBg}>
            <div className={'w-full h-full flex justify-end items-center'}>
                <div
                    className={`w-auto h-full flex flex-col items-center justify-center py-20 ${isMobile ? 'mx-auto' : 'mr-20'}`}>
                    <div className={'flex flex-col items-center gap-y-2'}>
                        <div className={'w-[300px] max-h-[100px]'}>
                            <img onClick={() => {
                                navigate('/')
                            }} className={`w-full h-[85px] cursor-pointer`}
                                 alt={logo}
                                 src={logo + `?timestamp=${new Date().getMilliseconds()}`}/>
                        </div>
                        <h1 className={`text-white text-2xl font-bold `}>{isRecovering ? 'Recupero Password' : isTmpPassword ? 'Password Temporanea' : 'Benvenuto'}</h1>
                        {!isTmpPassword ?
                            <div
                                className={`${isMobile && !isTablet() ? 'w-[350px]' : 'w-[500px]'} flex flex-col gap-y-6`}>
                                <CustomTextInputValue label={'E-mail'} customDimensions={'h-[70px]'}
                                                      isPassword={false}
                                                      defaultValue={loginData.username}
                                                      onChange={(username) =>
                                                          setLoginData({...loginData, username: username.trim()})
                                                      }/>
                                {isRecovering ? null :
                                    <CustomTextInputValue label={'Password'} customDimensions={'h-[70px]'}
                                                          isPassword={true}
                                                          defaultValue={loginData.password}
                                                          onChange={(pass) =>
                                                              setLoginData({...loginData, password: pass})
                                                          } onEnterPress={() => {
                                        if (validateEmail(loginData.username) && validatePassword(loginData.password)) {
                                            startLogin()
                                        }
                                    }}/>
                                }
                            </div> :
                            <div
                                className={`${isMobile && !isTablet() ? 'w-[400px]' : 'w-[500px]'} flex flex-col gap-y-6`}>
                                <CustomTextInputValue label={'Nuova Password  '} customDimensions={'h-[70px]'}
                                                      isPassword={true}
                                                      defaultValue={changePassData.pass}
                                                      onChange={(value) =>
                                                          setChangePassData({...changePassData, pass: value})
                                                      }/>
                                {isRecovering ? null :
                                    <CustomTextInputValue label={'Conferma nuova Password'}
                                                          customDimensions={'h-[70px]'}
                                                          isPassword={true}
                                                          defaultValue={changePassData.confirmPass}
                                                          onChange={(pass) =>
                                                              setChangePassData({...changePassData, confirmPass: pass})
                                                          } onEnterPress={() => {
                                        if (changePassData.pass.trim() === changePassData.confirmPass.trim() && validatePassword(changePassData.confirmPass.trim())) {
                                            changeTmpPassword()
                                        }
                                    }}/>
                                }

                                <h1 className={'w-full text-white text-sm whitespace-normal font-poppins-regular text-center pt-3'}>Attenzione! La nuova password deve contenere: Almeno 8 caratteri, una maiuscola e un carattere spaciale</h1>
                            </div>
                        }

                        <div className={`w-full flex justify-end px-5 my-5 ${isTmpPassword ? 'invisible' : 'visible'}`}>
                            {isRecovering ?
                                <h1 className={'cursor-pointer text-white font-poppins-regular'} onClick={() => {
                                    setIsRecovering(false)
                                }}>Torna alla login</h1> :
                                <h1 className={'cursor-pointer text-yellow font-poppins-regular underline'} onClick={() => {
                                    setIsRecovering(true)
                                }}>Recupera Password</h1>
                            }
                        </div>
                        <CustomButton title={isTmpPassword || isRecovering ? 'Conferma' : 'Accedi'} onClick={() => {
                            setLoading(true)
                            if (isTmpPassword) {
                                changeTmpPassword()
                            } else if (isRecovering) {
                                recoveryPass(loginData.username.trim(), (res) => {
                                    if (res) {
                                        setIsRecovering(false)
                                        setLoginData({username: '', password: ''})
                                    }
                                    setLoading(false)
                                })
                            } else {
                                startLogin()
                            }
                        }}
                                      enable={isRecovering ? validateEmail(loginData.username.trim()) : !isTmpPassword ? validateEmail(loginData.username.trim()) && validatePassword(loginData.password.trim()) : changePassData.pass.trim() === changePassData.confirmPass.trim() && validatePassword(changePassData.confirmPass.trim())}
                                      isLoading={loading}/>

                        <div className={'w-full flex  items-center justify-center'}>
                            <h1 className={'text-white cursor-pointer font-poppins-regular '} onClick={() => {
                                navigate('/registration')
                            }}>Non sei ancora registrato?</h1>
                            <h1 className={'text-yellow underline cursor-pointer font-poppins-regular ml-1 '}
                               onClick={() => {
                                   navigate('/registration')
                               }}> Registrati qui</h1>
                        </div>
                    </div>
                </div>
            </div>
        </BackgroundImage>
    );
}
