import {makeRequest} from "../../RestAdapter";
import {HttpMethodsEnum} from "../../HttpMethodsEnum";
import {showFailNotification} from "../../../utils/NotificationUtils";
import {ReservationResponseType} from "../../../types/ReservationResponseType";
import {StatusEnum} from "../../../enums/StatusEnum";
import {ReservationSendUpdate} from "../../../types/ReservationSendUpdate";
import {GetUserReservationAdmin} from "../../../types/GetUsersReservationAdmin";

export function getReservationRestaurants(status:StatusEnum, date:Date, onFinish:(response: Map<string, ReservationResponseType[]>) => void) {

    makeRequest<Map<string, ReservationResponseType[]>>(`/restaurants/reservations/day?from=${date.toISOString()}&status=${status}`, HttpMethodsEnum.GET).then((res) => {
        if (res.data) {
            onFinish(res.data)
        }else {
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }

    })
}

export function checkReservation( reservationCode:string, onFinish:(response:any) => void) {
    makeRequest<any>(`/restaurants/reservations/done/${reservationCode}`, HttpMethodsEnum.PUT).then((res) => {
        if (res.status === 200) {
            onFinish(res.data)
        }else {
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }

    })
}




export function deleteReservation(idReservation:string, onFinish: ()=> void){
    makeRequest<any>(`/restaurants/reservations/cancel-reservation/${idReservation}`, HttpMethodsEnum.DELETE).then((res) => {
        if (res.status === 200) {
            onFinish()
        }else {
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }

    })
}

export function getReservationClient(emailClient:string, status:StatusEnum, date:Date, onFinish: (response: Map<string, ReservationResponseType[]> )=> void){
    makeRequest<Map<string, ReservationResponseType[]>>(`/restaurants/reservations/user/${emailClient}?status=${status}&from=${date.toISOString()}`, HttpMethodsEnum.GET).then((res) => {
        if (res.data) {
            onFinish(res.data)
        }else {
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }

    })
}


export function sendResponseAdmin(reservatuinUpdate:ReservationSendUpdate, onFinish:(data:any)=>void){
    makeRequest('/restaurants/reservations', HttpMethodsEnum.PUT, reservatuinUpdate).then((res)=>{
        if (res.status === 200) {
            onFinish(res.data)
        }else {
            showFailNotification('Attenzione', 'Si è verificato un errore, riprova più tardi', 3000)
        }
    })
}
export function searchUserReservation(phone:string, onFinish:(data:GetUserReservationAdmin[])=>void){
    makeRequest<GetUserReservationAdmin[]>(`/restaurants/telephone-book/get-telephone-book?phone=${phone}`, HttpMethodsEnum.GET).then((res)=>{
        if (res.isSuccessfull()) {
            onFinish(res.data?res.data:[])
        }else {
            onFinish([])
        }
    })
}
