import {Popover, PopoverDropdown} from "@mantine/core";
import {StatusEnum} from "../enums/StatusEnum";
import React, {useEffect, useState} from "react";
import Calendar from "./reservation/Calendar";
import ReservationStepper from "./reservation/Steeper";
import CustomButton from "./CustomButton";
import {isMobile} from "react-device-detect";


export default function HeaderRequestFilter(props:{reload:()=>void,isAdmin:boolean,onValueChange:(value:{date:Date, status:StatusEnum})=>void}) {

    const [value, setValue] = useState<{date:Date, status:StatusEnum}>({date:new Date(), status:StatusEnum.PENDING});
    const [reservationCode, setReservationCode] = useState('')
    useEffect(() => {
        props.onValueChange(value)
    }, [value]);

   if (isMobile) {
    return (
        <div className={'w-full h-auto flex flex-col '}>
        <div className={'w-full h-full'}>
            <h1 className={'font-poppins-semi-bold text-center text-2xl'}> Richieste</h1>
        </div>

    </div>)
}else{
       return (
           <div className={'w-full flex justify-between h-32 px-5'}>
               <div className={'w-auto h-full  flex items-center'}>
                   <h1 className={'font-poppins-semi-bold text-4xl'}> Richieste</h1>
               </div>
               <div className={'w-full h-full  flex justify-end items-center gap-x-10'}>



                   {props.isAdmin ?
                       <div className={'w-auto h-full items-center justify-center flex flex-col mr-10'}>

                           <Popover>
                               {/*  <Popover.Target>
                        <div>
                            <CustomButton title={'Aggiungi Prenotazione'} onClick={() => {
                            }} enable={true}  isLoading={false}/>
                        </div>
                    </Popover.Target>*/}
                               <PopoverDropdown>
                                   <div className={'bg-black'}>
                                       <ReservationStepper isAdmin={true}/>
                                   </div>
                               </PopoverDropdown>
                           </Popover>

                       </div>:null }

               </div>
           </div>)

   }}
