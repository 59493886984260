import React, {useEffect, useState} from "react";
import {StatusEnum} from "../../enums/StatusEnum";
import {LocalType} from "../../types/LocalType";
import {GetRequestType} from "../../types/RequestType";
import {getRequestFromType} from "../../network/controllers/admin/RequestController";
import {StatusEnumRequest} from "../../enums/StatusRequestEnum";
import HeaderRequestFilter from "../../components/HeaderRequestFilter";
import AccordionRequest from "../../components/AccordionRequest";
import AddRequestComponent from "../../assets/request/AddRequestComponent";
import {isMobile} from "react-device-detect";

export default function RequestAdminPage() {
    var local = JSON.parse(localStorage.getItem('selectedRestaurant') as string) as LocalType
    const [reload, setReload] = useState<boolean>(false);
    const [dataRequestUpdate, setDataRequestUpdate] = useState<Map<string, GetRequestType[]>>(new Map());
    const [dataRequestSuggestion, setDataRequestSuggestion] = useState<Map<string, GetRequestType[]>>(new Map());
    const [dataRequestOther, setDataRequestOther] = useState<Map<string, GetRequestType[]>>(new Map());
    const [value, setValue] = useState<{ date: Date, status: StatusEnum }>({
        date: new Date(),
        status: StatusEnum.PENDING
    });


    useEffect(() => {
        getRequestFromType(StatusEnumRequest.UPGRADE, (res) => {
            var tmp = new Map<string, GetRequestType[]>()
            Object.entries(res).forEach((ite) => {
                tmp.set(ite[0], ite[1])
            })

            setDataRequestUpdate(tmp)
        })
        getRequestFromType(StatusEnumRequest.SUGGESTION, (res) => {
            var tmp = new Map<string, GetRequestType[]>()
            Object.entries(res).forEach((ite) => {
                tmp.set(ite[0], ite[1])
            })
            setDataRequestSuggestion(tmp)
        })
        getRequestFromType(StatusEnumRequest.OTHER, (res) => {
            var tmp = new Map<string, GetRequestType[]>()
            Object.entries(res).forEach((ite) => {
                tmp.set(ite[0], ite[1])
            })
            setDataRequestOther(tmp)
        })

    }, [value, reload]);


    if (isMobile) {
        return (
            <div className={'flex w-full h-full flex-col pt-[130px] '}>
                <div className={'w-full h-auto'}>

                    <HeaderRequestFilter reload={() => {
                        setReload(!reload)
                    }} isAdmin={true} onValueChange={(value) => {
                        setValue(value)
                    }}/>


                </div>
                <div className={'w-full flex flex-col mt-5 gap-y-5 '}>
                    <div className={'w-full auto flex flex-col'}>
                        <AddRequestComponent title={StatusEnumRequest.UPGRADE} onReload={(map) => {
                            setDataRequestUpdate(map)
                        }}/>
                        {dataRequestUpdate.size > 0 ?
                            <AccordionRequest isAdmin={true} data={dataRequestUpdate}/> : null}
                    </div>
                    <div className={'w-full h-min flex flex-col'}>
                        <AddRequestComponent title={StatusEnumRequest.SUGGESTION} onReload={(map) => {
                            setDataRequestSuggestion(map)
                        }}/>
                        {dataRequestSuggestion.size > 0 ?
                            <AccordionRequest isAdmin={true} data={dataRequestSuggestion}/> : null}
                    </div>
                    <div className={'w-full h-min flex flex-col'}>
                        <AddRequestComponent title={StatusEnumRequest.OTHER} onReload={(map) => {
                            setDataRequestOther(map)
                        }}/>
                        {dataRequestOther.size > 0 ? <AccordionRequest isAdmin={true} data={dataRequestOther}/> : null}
                    </div>


                </div>
            </div>
        )
    }else{
        return (
            <div className={'flex w-full h-full flex-col pt-[130px]'}>
                <div className={'w-full h-auto'}>

                    <HeaderRequestFilter reload={() => {
                        setReload(!reload)
                    }} isAdmin={true} onValueChange={(value) => {
                        setValue(value)
                    }}/>


                </div>
                <div className={'flex w-full justify-around'}>
                    <div className={'w-full h-min flex flex-col'}>
                        <AddRequestComponent title={StatusEnumRequest.UPGRADE} onReload={(map) => {
                            setDataRequestUpdate(map)
                        }}/>
                        {dataRequestUpdate.size > 0 ?
                            <AccordionRequest isAdmin={true} data={dataRequestUpdate}/> : null}
                    </div>
                    <div className={'w-full h-min flex flex-col'}>
                        <AddRequestComponent title={StatusEnumRequest.SUGGESTION} onReload={(map) => {
                            setDataRequestSuggestion(map)
                        }}/>
                        {dataRequestSuggestion.size > 0 ?
                            <AccordionRequest isAdmin={true} data={dataRequestSuggestion}/> : null}
                    </div>
                    <div className={'w-full h-min flex flex-col'}>
                        <AddRequestComponent title={StatusEnumRequest.OTHER} onReload={(map) => {
                            setDataRequestOther(map)
                        }}/>
                        {dataRequestOther.size > 0 ? <AccordionRequest isAdmin={true} data={dataRequestOther}/> : null}
                    </div>


                </div>
            </div>
        )
    }}
