import {Checkbox, Table} from "@mantine/core";
import GenericImg from "../../../components/generic/GenericImg";
import {StatusEnum} from "../../../enums/StatusEnum";
import placeholder from '../../../assets/placeolder-categoria.svg'
import {GetProductResponseType} from "../../../types/GetProductResponseType";
import {
    addListProdCategoryMenu,
    getProductAdminSelect
} from "../../../network/controllers/admin/ProductController";
import React, {useEffect, useState} from "react";
import {getMenu} from "../../../network/controllers/admin/MenuController";
import {getLicenceFromLicenseId} from "../../../network/controllers/admin/AdminController";
import CustomButton from "../../../components/CustomButton";
import {CategoryType} from "../../../types/CategoryType";
import {isMobile} from "react-device-detect";


export default function ProductAdminTable(props: {
    listSize: number
    categoryId: string
    onClickItem: (product: GetProductResponseType) => void
    elementSelected: (ptoductList: GetProductResponseType) => void
    onChangeStatus: (productList: GetProductResponseType[]) => void
}) {


    var path = window.location.pathname
    const [selectedRows, setSelectedRows] = useState<Map<string, GetProductResponseType>>(new Map());
    const [menuList, setMenuList] = useState<any[]>([]);
    const [isKitchenEnable, setIsKitchenEnable] = useState<boolean>(false);
    const [productList, setProductList] = useState<GetProductResponseType[]>([]);

    function addElement(element: GetProductResponseType, isCheked: boolean) {
        var tmpMap = new Map<string, GetProductResponseType>(selectedRows)
        var tmpElement = element
        if (isCheked) {
            tmpElement.menusList!.push(localStorage.getItem('menuId') as string)
        } else {
            tmpElement.menusList = tmpElement.menusList!.filter((value, index, array) => value !== localStorage.getItem('menuId') as string)
        }
        if (selectedRows.get(element.id) === undefined) {
            tmpMap.set(element.id, tmpElement);
        } else {
            tmpMap.delete(element.id)
        }
        setSelectedRows(tmpMap)
    }


    function retrieveStatus(product:GetProductResponseType): string {
        if (product.approvalStatus === StatusEnum.ACCEPTED) {
            return 'border-lime-400'
        } else if (product.approvalStatus === StatusEnum.REJECTED) {
            return 'border-red-600'
        } else if (product.approvalStatus === StatusEnum.PENDING) {
            return 'border-yellow'
        } else {
            return ''
        }
    }

    useEffect(() => {
        getMenu((menu) => {
            setMenuList(menu)
        })
        getProductAdminSelect((response) => {
            setProductList(response)
        })
    }, []);

    useEffect(() => {
        getLicenceFromLicenseId((license) => {
            if (license.namePackage !== 'BASE') {
                setIsKitchenEnable(true)
            }
        })
    }, []);

    useEffect(() => {
        var listProductSelected: GetProductResponseType[] = []
        selectedRows.forEach((item) => {
            listProductSelected.push(item)
        })
        //props.elementSelected(listProductSelected)
    }, [selectedRows]);

    const [items, setItems] = useState(productList.sort());

    const onDragEnd = (fromIndex: any, toIndex: any) => {
        const updatedItems = [...items];
        const item = updatedItems.splice(fromIndex, 1)[0];

    };


    const rows = productList.length > 0 ? productList.map((element, index) => (
        <Table.Tr className={'w-full cursor-pointer '} key={Math.random()}>
            <Table.Td className={path.includes('/liste/') ? 'hidden' : 'visible'}>
                <div className={'w-full flex justify-center'}>
                    <Checkbox
                        color={'#FFCC00'}
                        iconColor={'#000000'}
                        aria-label="Select row"
                        defaultChecked={element.menusList?.includes(localStorage.getItem('menuId') as string)}
                        onChange={(event) => {
                            addElement(element, event.currentTarget.checked)
                        }}
                    />
                </div>
            </Table.Td>
            <Table.Td onClick={() => {
                props.onClickItem(element)
            }}>
                <div className={'w-full flex justify-start p-2'}>
                    <img alt={''} src={element.photoPath}
                         className={`w-[100px] h-[100px] rounded-lg border-[3.5px] ${retrieveStatus(element)} p-0.5`}/>
                </div>
            </Table.Td>
            <Table.Td onClick={() => props.onClickItem(element)}>
                <h1>{element.nameProduct}</h1>
            </Table.Td>
            {isKitchenEnable ? <Table.Td onClick={() => props.onClickItem(element)}>
                <h1>{element.nameKitchen}</h1>
            </Table.Td> : null}
            <Table.Td onClick={() => props.onClickItem(element)}>
                {element.ingredients.map((ingredient) => (
                    <h1>{ingredient.name}</h1>
                ))}
            </Table.Td>
            <Table.Td onClick={() => props.onClickItem(element)}>
                {element.menusList ? element.menusList.map((menu) => (
                    menuList.map((element) => (
                        element.id === menu && element.id !== JSON.parse(localStorage.getItem('selectedMenu') as string).id ?
                            <h1>{element.name}</h1> : null
                    ))
                )) : ''}
            </Table.Td>
            <Table.Td onClick={() => props.onClickItem(element)}>
                {element.nameCategories ? element.nameCategories.map((category) => (
                    <h1>{category.categoryName}</h1>
                )) : ''}
            </Table.Td>
            <Table.Td onClick={() => props.onClickItem(element)}>
                <h1>{element.priceOriginal + ' €'}</h1>
            </Table.Td>
        </Table.Tr>

    )) : null;


    if (isMobile) {
        return (
            <div>
                <div className={'w-full h-auto flex flex-col items-center py-10 '}>
                    <CustomButton title={'Seleziona'} onClick={() => {
                        var tmpReq = {
                            listProdID: Array.from(selectedRows.keys()),
                            idMenu: JSON.parse(localStorage.getItem('selectedMenu') as string).id,
                            idCategory: props.categoryId
                        }
                        addListProdCategoryMenu({
                            listProdId: tmpReq.listProdID,
                            idMenu: tmpReq.idMenu,
                            idCategory: tmpReq.idCategory
                        }, (response) => {
                            window.location.reload()
                        })
                    }} enable={selectedRows.size > 0} isLoading={false}/>
                </div>
                <div className={'w-auto h-auto flex flex-col bg-white'}>
                    <div className={'w-full flex items-center justify-center gap-x-5 py-5'}>
                        <div className={'flex flex-col items-center justify-center'}>
                            <img alt={''} src={placeholder}
                                 className={`w-[50px] h-[50px] rounded-lg border-[3.5px] border-lime-400 p-0.5`}/>
                            <h1 className={'w-full font-poppins-regular mx-1'}>Approvata</h1>
                        </div>
                        <div className={'flex flex-col items-center justify-center'}>
                            <img alt={''} src={placeholder}
                                 className={`w-[50px] h-[50px] rounded-lg border-[3.5px] border-red-600 p-0.5`}/>
                            <h1 className={'w-full font-poppins-regular mx-1'}>Rifiutata</h1>
                        </div>
                        <div className={'flex flex-col items-center justify-center'}>
                            <img alt={''} src={placeholder}
                                 className={`w-[50px] h-[50px] rounded-lg border-[3.5px] border-yellow p-0.5`}/>
                            <h1 className={'w-full font-poppins-regular mx-1'}>In Attesa</h1>
                        </div>
                    </div>
                    <div className={'w-auto h-auto flex flex-col'}>
                        {productList.length > 0 ? productList.map((element, index) => (
                            <div className={'card w-full p-4 border mb-4 flex flex-col'} key={Math.random()}>

                                <div className="card-body flex flex-col">
                                    <div className="flex items-center">
                                        <div className="w-auto">
                                            <img alt={''} src={element.photoPath}
                                                 className={`w-[100px] h-[100px] rounded-lg border-[3.5px] ${retrieveStatus(element)} p-0.5`}/>
                                        </div>
                                        <div className="w-3/4 pl-4">
                                            <h2 style={{fontSize: '10px'}}>NOME PRODOTTO: {element.nameProduct}</h2>
                                            {isKitchenEnable && <h1>CUCINA: {element.nameKitchen}</h1>}
                                            <h1 style={{fontSize: '10px'}}>INGREDIENTI: {element.ingredients.map((ingredient) => ingredient.name).join(', ')}</h1>
                                            <h1 style={{fontSize: '10px'}}>MENU: {element.menusList ? element.menusList.map((menu) => (
                                                menuList.map((el) => (
                                                    el.id === menu && el.id !== JSON.parse(localStorage.getItem('selectedMenu') as string).id ?
                                                        el.name : null
                                                ))
                                            )).join(', ') : ''}</h1>
                                            <h1 style={{fontSize: '10px'}}>CATEGORIE: {element.nameCategories ? element.nameCategories.map((category) => category.categoryName).join(', ') : ''}</h1>
                                            <h1 style={{fontSize: '10px'}}>PREZZO: {element.priceOriginal + ' €'}</h1>
                                        </div>

                                    </div>
                                    <div
                                        className={`w-full h-auto flex justify-end items-center card-header ${path.includes('/liste/') ? 'hidden' : 'visible'}`}>
                                        <Checkbox
                                            size={'md'}
                                            aria-label="Select row"
                                            defaultChecked={element.menusList?.includes(localStorage.getItem('menuId') as string)}
                                            onChange={(event) => {
                                                addElement(element, event.currentTarget.checked)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )) : null}
                    </div>
                </div>
            </div>
        )

    } else {
        return (

            <div className={'mt-10'}>
                <div className={'w-full flex items-center justify-end pr-20 py-5'}>
                    <CustomButton title={'Seleziona'} onClick={() => {
                        var tmpReq = {
                            listProdID: Array.from(selectedRows.keys()),
                            idMenu: JSON.parse(localStorage.getItem('selectedMenu') as string).id,
                            idCategory: props.categoryId
                        }
                        addListProdCategoryMenu({
                            listProdId: tmpReq.listProdID,
                            idMenu: tmpReq.idMenu,
                            idCategory: tmpReq.idCategory
                        }, (response) => {
                            window.location.reload()
                        })
                    }} enable={selectedRows.size > 0} isLoading={false}/>
                </div>
                <div className={'w-full bg-white'}>
                    <div className={'w-full flex items-center justify-center gap-x-5 py-5'}>
                        <div className={'flex items-center justify-center'}>
                            <img alt={''} src={placeholder}
                                 className={`w-[50px] h-[50px] rounded-lg border-[3.5px] border-lime-400 p-0.5`}/>
                            <h1 className={'w-full font-poppins-regular mx-1'}>Approvata</h1>
                        </div>
                        <div className={'flex items-center justify-center'}>
                            <img alt={''} src={placeholder}
                                 className={`w-[50px] h-[50px] rounded-lg border-[3.5px] border-red-600 p-0.5`}/>
                            <h1 className={'w-full font-poppins-regular mx-1'}>Rifiutata</h1>
                        </div>
                        <div className={'flex items-center justify-center'}>
                            <img alt={''} src={placeholder}
                                 className={`w-[50px] h-[50px] rounded-lg border-[3.5px] border-yellow p-0.5`}/>
                            <h1 className={'w-full font-poppins-regular mx-1'}>In Attesa</h1>
                        </div>


                    </div>
                    <Table.ScrollContainer minWidth={'100%'}>
                        <Table striped highlightOnHover withTableBorder withRowBorders={false}>
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th className={path.includes('/liste/') ? 'hidden' : 'visible'}></Table.Th>
                                    <Table.Th>IMMAGINE</Table.Th>
                                    <Table.Th>NOME PRODOTTO</Table.Th>
                                    {isKitchenEnable ? <Table.Th>CUCINA</Table.Th> : null}
                                    <Table.Th>INGREDIENTI</Table.Th>
                                    <Table.Th>MENU</Table.Th>
                                    <Table.Th>CATEGORIE</Table.Th>
                                    <Table.Th>PREZZO</Table.Th>
                                </Table.Tr>
                            </Table.Thead>

                            <Table.Tbody key={Math.random()}>
                                {rows}
                            </Table.Tbody>
                        </Table>
                    </Table.ScrollContainer>
                </div>


            </div>
        )
    }}
