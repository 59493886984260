import React, {useEffect} from "react";
import {Navigate, useRoutes} from 'react-router-dom';

export default function RouteAdmin(props: { routeList: any[] }) {

    const [route, setRoute] = React.useState<any[]>(props.routeList);

    useEffect(() => {
        setRoute([...route, {
            path: '*', element: <Navigate to='/admin/ilmiolocale'/>
        }])
    }, []);
    return useRoutes(route)
}
