import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Popover} from "@mantine/core";
import {isTablet} from "../utils/WindowDimensionUtils";
import GenericImg from "./generic/GenericImg";
import iconArrow from "../assets/arrow-icon.svg";
import CustomButton from "./CustomButton";
import {LoginResponseType} from "../types/LoginResponseType";
import hambIcon from "../assets/ham-reg.webp"


export default function MenuMyAccount() {
    const [popOverStatus, setPopOverStatus] = useState(false)
    let navigate = useNavigate()

    const [userData,setUserData]= useState<LoginResponseType>(JSON.parse(localStorage.getItem('userData')!) as LoginResponseType)
    window.addEventListener('userInfo', (e) => {
        // @ts-ignore
        setUserData(JSON.parse(localStorage.getItem('userData')!) as LoginResponseType)
    })

    return (
        <Popover opened={popOverStatus} width={400} position="bottom-end" withArrow shadow="xl"  onOpen={() => {
            setPopOverStatus(true)
        }} onClose={() => setPopOverStatus(false)}>
            <Popover.Target>
                <div  onClick={()=>{setPopOverStatus(true)}}
                    className={`pr-4 flex items-start w-auto cursor-pointer border-[1px] border-transparent ${popOverStatus ? 'scale-110' : ''} hover:scale-110 duration-300  ml-1  `}>
                    <h1 className={` ${isTablet() ? 'text-xs' : ''} text-center whitespace-nowrap  text-amber-400 font-poppins-bold`}>{`${userData ? `Benvenuto ${userData.name}` : 'Accedi al tuo account'}`}</h1>
                    <GenericImg className={`${popOverStatus ? 'rotate-90' : 'rotate-0'} w-min duration-500 h-min`}
                                src={iconArrow}/>
                </div>
            </Popover.Target>
            <Popover.Dropdown classNames={{dropdown: 'dropDownLogin'}}>
                <div className={' flex flex-col items-center py-5'}>
                    {!userData ?
                        <div>
                            <div className={'w-full flex items-center justify-center'}>
                                {<CustomButton title={'Accedi'} small={true} onClick={() => {
                                    localStorage.setItem('path', window.location.pathname)
                                    navigate('/login')
                                }} enable={true} isLoading={false}/>}
                            </div>
                            <div className={'w-full flex mt-4 items-center justify-center'}>
                                <h1 className={'font-light mr-1 font-poppins-regular'}>Non sei ancora registrato?</h1>
                                <h1 className={'font-light underline font-poppins-regular text-color-link text-amber-500  cursor-pointer hover:scale-105 duration-300'}
                                   onClick={() => {
                                       navigate('/registration')
                                   }}>Registrati qui</h1>
                            </div>
                            <img className={'w-full h-full px-8'} src={hambIcon}/>
                            <div className={'flex w-full items-center '}>
                                <h1 className={'w-full font-poppins-semi-bold underline text-center  text-color-link cursor-pointer hover:scale-105 duration-300'}
                                   onClick={() => {
                                       navigate('/restaurant')
                                   }}>Registra Gratuitamente il tuo Locale </h1>
                            </div>


                        </div> : !userData.role.includes('ADMIN') ?
                            <CustomButton title={'Registra Gratuitamente il tuo Locale'} small={false} onClick={() => {
                                localStorage.setItem('path', window.location.pathname)
                                navigate('/restaurant')
                            }} enable={true} isLoading={false}/>:null
                    }


                    <div className={'w-full flex flex-col px-5 mt-3  gap-y-2'}>
                        {userData?<h1 className={'font-bold mr-1 text-2xl font-poppins-bold '}>Il mio account</h1>:null}

                        {/* <h1 className={'font-poppins-regular mr-1 hover:text-color-link hover:underline duration-200 cursor-pointer'}
                           onClick={() => {
                               setPopOverStatus(false)
                               navigate('/profilo/ordini')

                           }}>Dashboard</h1>*/}

                        {/* <h1 className={'font-poppins-regular mr-1 hover:text-color-link hover:underline duration-200 cursor-pointer'}
                           onClick={() => {
                               setPopOverStatus(false)
                               navigate('/profilo/ordini')

                           }}>Impostazioni</h1>*/}



                        {userData?
                            <h1 className={'font-poppins-regular mr-1 hover:text-color-link hover:underline duration-200 cursor-pointer'}
                               onClick={() => {
                                   setPopOverStatus(false)
                                   navigate('/favourite')

                               }}>I Miei Ristoranti Preferiti</h1>:null}

                        {/* <h1 className={'font-poppins-regular mr-1 hover:text-color-link hover:underline duration-200 cursor-pointer'}
                           onClick={() => {
                               setPopOverStatus(false)
                               navigate('/profilo/ordini')

                           }}>I Miei Piatti Preferiti</h1>*/}
                        {userData?
                            <h1 className={'font-poppins-regular mr-1 hover:text-color-link hover:underline duration-200 cursor-pointer'}
                               onClick={() => {
                                   setPopOverStatus(false)
                                   navigate('/prenotazioni')

                               }}>Le mie prenotazioni</h1>:null}

                        {userData? <p className={'font-poppins-regular mr-1 hover:text-color-link hover:underline duration-200 cursor-pointer'}
                             onClick={() => {
                                 setPopOverStatus(false)
                                 navigate('/commandClient')

                             }}>I Miei Ordini</p>:null}

                        {userData && userData.role.includes('ADMIN') ?
                            <h1 className={'font-poppins-regular mr-1 hover:text-color-link hover:underline duration-200 cursor-pointer'}
                               onClick={() => {
                                   setPopOverStatus(false)
                                   window.open('/admin')

                               }}>Admin</h1> : null}


                        {/* {userData && userData.role.includes('SELLER')?
                            <h1 className={'font-poppins-regular mr-1 hover:text-color-link hover:underline duration-200 cursor-pointer'}
                               onClick={() => {
                                   setPopOverStatus(false)
                                   window.open('/seller')

                               }}>Commerciale</h1>:null}*/}


                        {userData ? <div onClick={() => {
                            var geoData = localStorage.getItem('geoData')
                            var formattedAddress = localStorage.getItem('formatted_address')
                            localStorage.clear()
                            localStorage.setItem('geoData', geoData!)
                            localStorage.setItem('formatted_address', formattedAddress!)
                            window.location.reload()
                        }}><h1
                            className={'font-poppins-regular mr-1 hover:text-color-link hover:underline duration-200 cursor-pointer'}>Logout</h1>
                        </div> : null}


                    </div>
                </div>
            </Popover.Dropdown>
        </Popover>
    )
}
