import '../css/notification.css'
import close_icon from '../assets/notification/close-icon.svg'
import success_bubbles from '../assets/notification/bubbles.svg'
import fail_bubbles from '../assets/notification/fail-bubbles.svg'
import warning_bubbles from '../assets/notification/warning-bubbles.svg'
import success_icon from '../assets/notification/success-icon.svg'
import fail_icon from '../assets/notification/fail-icon.svg'
import warning_icon from '../assets/notification/warning-icon.svg'
import check_background from '../assets/notification/check-backgroud.svg'
import React, {useEffect} from "react";
import {hideNotification} from "../utils/NotificationUtils";
import {isMobile} from "react-device-detect";

export default function Notification(props: {
    type: String,
    title: String,
    text?: String,
    time?: number,
    onYes?: () => void
}) {
    useEffect(() => {
        if (props.time) {
            const timeout = setTimeout(() => {
                document.dispatchEvent(hideNotification)
            }, props.time);
            return () => clearTimeout(timeout);
        }
    }, [props.time]);
    return (
        <div className={`notification-container fixed bottom-5 flex
         ${isMobile ? `h-[110px] ml-2 ${props.type === 'warning' ? 'w-[300px]' : 'w-[238px]'}` : `h-[170px] ml-5 ${props.type === 'warning' ? 'w-[566px]' : 'w-[438px]'}`} `}>
            {/*Notification*/}
            <div className={`ml-5 flex flex-row flex-wrap items-center justify-center z-10 absolute 
            ${isMobile ? 'size-[35px]' : 'size-[78px]'}`}>
                <img className={`check-background ${props.type}`} src={check_background} alt={''}/>
                {/*Icon*/}
                <img className={`absolute mb-1  ${props.type === 'fail' ? 'size-[30%]' : 'size-[35%]'} `}
                     src={props.type === 'success' ? success_icon : props.type === 'warning' ? warning_icon : fail_icon}
                     alt={''}/>
            </div>
            <div className={`text-white rounded-[32px] w-full overflow-hidden mt-auto flex flex-row 
             ${isMobile ? `h-[90px]` : `h-[132px]`} notification_${props.type}`}>

                {/*Bubbles */}
                <img className={`mt-auto ${isMobile ? 'size-8' : 'size-16'} ${props.type}`}
                     src={props.type === 'success' ? success_bubbles : props.type === 'warning' ? warning_bubbles : fail_bubbles}
                     alt={''}/>

                {/*MESAGGE*/}
                <div className={`flex flex-col w-full ${isMobile ? 'ml-2' : 'ml-7'}`}>
                    {/*Title*/}
                    <h1 className={`${isMobile ? `mt-4 ${props.type === 'warning' ? 'text-sm' : 'text-lg'} ` : `mt-5 ${props.type === 'warning' ? `${props.title.length >= 50 ? 'text-lg' : 'text-2xl'}` : 'text-[34px]'}`}`}>{props.title}</h1>
                    {/*Text*/}
                    <h1 className={`${isMobile ? 'text-xs' : 'text-sm'}`}>{props.text}</h1>
                    {props.type === 'warning' && (
                        <>
                            {/*Buttons si/no */}
                            <div className={'flex flex-row mb-3 mt-auto gap-2 text-black justify-self-center mx-auto'}>

                                <div
                                    className={`button content-center text-center rounded-[50%] hover:cursor-pointer ${isMobile ? 'size-[25px] text-[12px]' : 'size-[50px] text-xl'} `}
                                    onClick={() => {
                                        if (props.onYes !== undefined) {
                                            props.onYes()
                                            document.dispatchEvent(hideNotification)
                                        }
                                    }}>
                                    <h1>SI</h1>
                                </div>

                                <div
                                    className={`button content-center text-center rounded-[50%] hover:cursor-pointer ${isMobile ? 'size-[25px] text-[12px]' : 'size-[50px] text-xl'}`}
                                    onClick={() => {
                                        document.dispatchEvent(hideNotification)
                                    }}>
                                    <h1>NO</h1>
                                </div>

                            </div>
                        </>
                    )}
                </div>
                {/*Close X*/}
                <img
                    className={`flex flex-col ${isMobile ? 'size-[15px] mr-4' : 'size-[20px] mr-8'}  mt-6 ml-auto hover:cursor-pointer`}
                    src={close_icon} onClick={() => {
                    document.dispatchEvent(hideNotification)
                }} alt={''}/>
            </div>
        </div>
    )

}

export function showNotification(type: string, title: string, text?: string, time?: number, warningYes?: () => void) {
    return <Notification type={type} title={title} time={time} text={text} onYes={warningYes}/>
}
