import {makeRequest} from "../../RestAdapter";
import {HttpMethodsEnum} from "../../HttpMethodsEnum";
import {GetRestaurantResponseType} from "../../../types/GetRestaurantResponseType";
import {TagType} from "../../../types/TagType";
import {LicenseType} from "../../../types/LicenseType";
import {PackageType} from "../../../types/PackageType";
import {showFailNotification} from "../../../utils/NotificationUtils";

export function getRestaurants(onFinish:(response: GetRestaurantResponseType[]) => void) {
   makeRequest<GetRestaurantResponseType[]>(`/users/restaurants/${JSON.parse(localStorage.getItem('userData')!).id}`, HttpMethodsEnum.GET).then((res) => {
        if (res.isSuccessfull()){
            if (res.data && res.data.length > 0) {
                res.data.forEach(restaurant => {
                    if (restaurant.status==='ACTIVE' && !localStorage.getItem('selectedRestaurant')){
                        localStorage.setItem('selectedRestaurant', JSON.stringify(restaurant));
                    }
                })
            }
            onFinish(res.data?res.data:[])
        }
    })
}

export function getLicenceFromLicenseId(onFinish:(response: LicenseType) => void,restaurantLicense?:string) {
    makeRequest<LicenseType>(`/users/user/getLicenseFromLocal?idLicense=${restaurantLicense?restaurantLicense:JSON.parse(localStorage.getItem('selectedRestaurant')!).licenseActive}`, HttpMethodsEnum.GET).then((res) => {
        if (res.isSuccessfull()){
            onFinish(res.data!)
        }
    })
}

export function setActiveMenuRestaurants(idMenu:string,onFinish:(response: GetRestaurantResponseType) => void) {
    makeRequest<GetRestaurantResponseType>(`/users/restaurants/setMenu?idMenu=${idMenu}&idLocal=${JSON.parse(localStorage.getItem('selectedRestaurant')!).id}`, HttpMethodsEnum.POST).then((res) => {
        if (res.data!==null && res.status===200){
            onFinish(res.data)
        }
    })
}

export function getTag(onFinish:(response: TagType[]) => void) {
    makeRequest<TagType[]>(`/users/restaurants/tag`, HttpMethodsEnum.GET).then((res) => {
        if (res.data!==null && res.status===200){
            onFinish(res.data)
        }
    })
}

export function getPackages(onFinish: (packages: PackageType[]) => void) {
    makeRequest<PackageType[]>("/users/user/getpackages", HttpMethodsEnum.GET).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data?res.data:[])
        } else {
            onFinish([])
           // @ts-ignore
            showFailNotification('Attenzione', res.data.message,1000)
        }
    })
}

