import {Navigate, useRoutes} from 'react-router-dom';
import WelcomePage from "../pages/WelcomePage";
import LocalPage from "../pages/LocalPage";
import DetailRestaurantsPage from "../pages/DetailRestaurantsPage";
import React from "react";
import MyReservationPage from "../pages/MyReservationPage";
import FavouriteRestaurantPage from "../pages/FavouriteRestaurantPage";
import CommandPage from "../pages/CommandPage";


export default function RouteHome() {

    return useRoutes([
        {
            path: '/welcome',
            element: <WelcomePage/>,
            children: []
        }, {
            path: '/locali/*',
            element: <LocalPage/>,
            children: []
        }, {
            path: '/detail/*',
            element: <DetailRestaurantsPage/>,
            children: []
        }, {
            path: '/prenotazioni',
            element: <MyReservationPage/>,
            children: []
        }, {
            path: '/favourite',
            element: <FavouriteRestaurantPage/>,
            children: []
        }, {
            path: '/commandClient',
            element: <CommandPage/>,
            children: []
        },
        {
            path: '*', element: <Navigate to='/welcome'/>
        }
    ])
}
