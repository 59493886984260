import React, {useEffect, useState} from "react";
import CategoryDetailComponent from "../components/CategoryDetailComponent";
import ProductDetailComponent from "../components/ProductDetailComponent";
import {getInfoLocal} from "../network/controllers/admin/CategoryController";
import loadingImg from '../assets/loader-white.svg'

export default function MenuDetailPage() {
    const [contentMenu, setContentMenu] = useState<React.JSX.Element>();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        var categoryComponent = <CategoryDetailComponent categorySelected={(category) => {
            setLoading(true)
            getInfoLocal(JSON.parse(localStorage.getItem('selectedRestaurantClient')!).id, (local)=>{
                setLoading(false)
                setContentMenu(<ProductDetailComponent local={local} categorySelected={category} onBack={() => {
                    setContentMenu(categoryComponent)
                }}/>)
            })
        }}/>
        setContentMenu(categoryComponent)
    },[])


    return (

        <div className={'w-full h-full  mt-5 px-5 flex '}>
            {loading?
                <div className={'w-full h-auto flex items-center justify-center'}>
                    <img alt={''} className={'w-10 h-10'} src={loadingImg}/>
                </div> : contentMenu ? contentMenu:null}
        </div>
    )
}
