import {DatePicker, DatePickerProps} from "@mantine/dates";
import React from "react";

export default function Calendar(props: {
    value: Date | null,
    dayProps?: DatePickerProps['getDayProps'],
    onChange: (value: Date | null) => void
}) {
    return <DatePicker  className={'mx-2 mt-7 flex content-center '} classNames={{
        levelsGroup: 'flex w-full content-center',
        calendarHeader: 'text-white w-full flex-row content-center min-w-full mt-2',
        calendarHeaderLevel: 'customHeaderLevel0 text-xl hover:!bg-transparent !pointer-events-none',
        month: 'mt-4',
        monthRow: 'border-[12px] border-transparent',
        calendarHeaderControl: 'bg-trueGray-500 rounded-full mx-2 hover:!bg-yellow hover:!bg-opacity-50 hover:!rounded-full',
        day: 'text-white text-base font-poppins-bold customDatePicker0 hover:!bg-yellow hover:!bg-opacity-50 hover:!rounded-full',
        weekday: 'text-trueGray-200 uppercase text-xs font-poppins-bold',
    }} maxLevel="month" getDayProps={props.dayProps} value={props.value} weekdayFormat='ddd' monthsListFormat="M"
                       monthLabelFormat='MMMM' locale='it' onChange={(value) => props.onChange(value)} hideOutsideDates
                       firstDayOfWeek={0}/>;
}
