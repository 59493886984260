import HeaderCaskDesk from './admin/HeaderCashDesk'
import RepiceComponent from "../components/RepiceComponent";
import TableCashDeskComponent from "../components/TableCashDeskComponent";
import React from "react";
import {isTablet} from "../utils/WindowDimensionUtils";

export default function CashDeskPage() {
    return (
        <div className={`flex flex-col overflow-hidden ${isTablet() ? '' : 'h-full'}`}>

            <div className={'w-auto'}>
                <HeaderCaskDesk/>
            </div>

            <div className={`flex mt-[85px] overflow-auto ${isTablet() ? '' : ''}`}>
                <div className={'w-9/12 h-full overflow-auto pl-8'}>
                    <h1 style={{position: 'sticky', top: '0', backgroundColor: 'white'}}
                       className={'font-poppins-bold text text-2xl '}>Cassa</h1>
                    <TableCashDeskComponent/>
                </div>

                <div
                    className={`w-3/12 h-[85%] ${isTablet()} border border-black bg-lightgrey rounded-2xl mt-8 mr-8 overflow-hidden`}>
                    <RepiceComponent/>
                </div>
            </div>
        </div>
    )
}
