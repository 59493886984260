import React, {useEffect, useState} from "react";
import {ImageResponseCard} from "./ChatWindow";
import {useNavigate} from "react-router-dom";
import {isMobile} from "react-device-detect";
import iconPrice from "../../assets/icona_euro.svg";
import {LocalType} from "../../types/LocalType";
import GenericImg from "../generic/GenericImg";
import {getDetailRestaurants} from "../../network/controllers/RestaurantsControllers";
import CustomButton from "../CustomButton";
import RatingComponent from "../RatingComponent";

export default function ChatCard(props: {
    cardData: ImageResponseCard;
    onSend: (value: string) => void;
    onClose: () => void,
    onLocalNoReservation: () => void,
}) {
    const navigate = useNavigate();
    const [restaurantData, setRestaurantData] = useState<LocalType | null>(null);
    const [mapsUri, setMapsUri] = useState<string>('');

    useEffect(() => {
        // @ts-ignore
        getDetailRestaurants(props.cardData.buttons[0]?.value.split('/')[1], (response) => {
            setRestaurantData(response);
            setMapsUri(`https://www.google.com/maps/search/?api=1&query=${response.address}, ${response.civicNumber}, ${response.city}`);

        });

    }, [props.cardData]);

    useEffect(() => {
        if (props.cardData.title!==''){
            props.onLocalNoReservation();
        }
    }, []);
    const priceIcons = Array.from({length: restaurantData ? restaurantData.priceLevel : 0}, () => iconPrice);
    if (!restaurantData) {
        return <div>Loading...</div>; // Show a loading placeholder or spinner
    }
    return (
        <div className="w-full h-auto shadow-2xl bg-yellow  rounded-2xl  mt-2 flex flex-col gap-y-2">
            <p className={`font-poppins-semi-bold  mt-2 ml-2 line w-full ${props.cardData.title!==''?'visibile':'hidden'} `}>{props.cardData.title}</p>

            <div className={'w-full h-auto bg-transparent flex p-2 '}>
                <img className="w-[45%] rounded-xl" alt={props.cardData.title} src={props.cardData.imageUrl}/>
                <div className={'w-full flex flex-col gap-y-1 ml-1 truncate'}>
                    <p className={'font-poppins-semi-bold text-sm  whitespace-nowrap truncate'}>{restaurantData?.nameRestaurant}</p>
                    <a className={"flex font-poppins-regular text-sm cursor-pointer underline "}
                       onClick={() => window.open(mapsUri, '_blank')} href="#">
                        {`${restaurantData.address ? `${restaurantData.address} ${restaurantData.civicNumber}` : "\u00A0"}`}
                    </a>
                    <a className="w-min text-sm underline cursor-pointer mt-2 "
                       onClick={() => window.open(`tel:${restaurantData.phoneNumber}`, '_blank')}>
                        {restaurantData.phoneNumber || "©"} </a>

                    {/*
                        <p className={`font-poppins-semi-bold  text-xs  whitespace-nowrap  ${restaurantData.isOpen.isOpen ? 'text-[#069C42]' : 'text-red-500'}`}>{restaurantData.isOpen.nextOpen ? restaurantData.isOpen.nextOpen : ''}</p>*/}

                </div>
            </div>
            <div className={'w-full bg-white p-2 rounded-b-2xl'}>

                <div className={`flex flex-col mt-2 ${isMobile ? 'gap-y-2' : ''}`}>
                    <RatingComponent size={'sm'} value={restaurantData.rating} readonly={true} onChangeValue={() => {
                    }}/>
                    <div className={'w-full flex gap-x-2 mt-2'}>
                        {priceIcons.length > 0
                            ? priceIcons.map((icon, index) => (
                                <GenericImg key={index} className="w-4 h-full" src={icon}/>
                            ))
                            : "\u00A0"
                        }
                    </div>

                </div>

                <div className="w-full flex justify-between mt-2">
                    {props.cardData.buttons && props.cardData.buttons.length > 0 ? props.cardData.buttons.map((button, index) => (
                        button.text === 'Prenota Adesso' && !restaurantData.isReservation ? null : <CustomButton
                            key={index}
                            small={true}
                            title={button.text}
                            enable={true}
                            isLoading={false}
                            onClick={() => {
                                if (button.text === 'Vai al Dettaglio') {
                                    localStorage.setItem('selectedRestaurantClient', JSON.stringify(restaurantData));
                                    if (window.location.pathname.includes('/detail')) {
                                        window.location.reload();
                                        props.onClose();
                                    } else {
                                        navigate(`/detail`);
                                        props.onClose();
                                    }
                                } else {
                                    props.onSend(button.value);
                                }
                            }}/>
                    )) : null}
                </div>
            </div>

        </div>
    );
}
