import {useNavigate} from "react-router-dom";
import {isTablet} from "../utils/WindowDimensionUtils";
import logo from "../assets/logo-menus.webp";
import logoMobile from "../assets/logoMobile.svg";
import {useState} from "react";
import {getDimension} from "../utils/Utility";
import {isMobile} from "react-device-detect";

export default function ImageLogoMenus(props: { dimension?: string, absolute?: boolean }) {
    var navigate = useNavigate();
    const [dimension, setDimension] = useState(false)
    getDimension(768, (res) => {
        setDimension(res)
    })
    return (
        <img onClick={() => {
            dispatchEvent(new CustomEvent("footerVisible", { detail: { isVisible: true } }));
            navigate('/')
        }} className={`w-auto h-[85px] cursor-pointer ${dimension || (isMobile && !isTablet()) ? 'py-5' : 'py-4'}`} alt={logo}
             src={dimension || (isMobile && !isTablet()) ? logoMobile+`?timestamp=${new Date().getMilliseconds()}` : logo+`?timestamp=${new Date().getMilliseconds()}`}/>
    )
}
