import React, {useEffect, useState} from "react";
import CustomButton from "../../components/CustomButton";
import {Input, Tooltip} from "@mantine/core";
import confirm from "../../assets/confirm-icon.svg";
import closeIcon from "../../assets/close-icon.svg";
import {showSuccessNotification} from "../../utils/NotificationUtils";
import loadingIcon from '../../assets/loader-white.svg'
import {createMenu, getMenu, getMenuQr, updateMenu} from "../../network/controllers/admin/MenuController";
import MenuAdminTable from "./tables/MenuAdminTable";
import {useNavigate} from "react-router-dom";
import {MenuType} from "../../types/CategoryType";
import qrImage from "../../assets/qr-icon.svg";
import {isMobile} from "react-device-detect";


export default function MenuAdminPage() {
    const [addMenu, setAddMenu] = useState<boolean>(false);
    const [modifyMenu, setModifyMenu] = useState<boolean>(false);
    const [newMenu, setNewMenu] = useState('');
    const [editableMenu, setEditableMenu] = useState<MenuType | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [menuList, setMenuList] = useState<any[]>([]);
    useEffect(() => {

        setIsLoading(true);
        getMenu((response) => {
            setIsLoading(false)
            setMenuList(response);
        })
    }, []);
    var navigate = useNavigate();

    if (isMobile) {
        return (
            <div className="w-full flex flex-col pt-[130px]">
                <div className="w-full flex items-center justify-center my-8">
                    <h1 className="text-3xl font-bold ">Menu</h1>
                    <Tooltip
                        radius="lg"
                        classNames={{ tooltip: 'font-bold' }}
                        inline={false}
                        position="right-end"
                        label="Scarica qui il menù del tuo Ristorante! Si aggiornerà automaticamente in base al menù attivo!"
                    >
                        <img
                            alt=""
                            src={qrImage}
                            className="w-10 h-10 ml-4"
                            onClick={() => {
                                getMenuQr((response) => {
                                    const base64Data = response.qrMenu;
                                    const binaryString = atob(base64Data);
                                    const len = binaryString.length;
                                    const bytes = new Uint8Array(len);
                                    for (let i = 0; i < len; i++) {
                                        bytes[i] = binaryString.charCodeAt(i);
                                    }
                                    const blob = new Blob([bytes], { type: 'application/octet-stream' });
                                    const link = document.createElement('a');
                                    link.href = URL.createObjectURL(blob);
                                    link.download = `${JSON.parse(localStorage.getItem('selectedRestaurant') as string).nameRestaurant}.png`;
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                });
                            }}
                        />
                    </Tooltip>
                </div>
                <div className="w-full flex justify-between items-center py-3">
                    <h1 className="font-bold my-5">NOME MENU</h1>
                    <CustomButton
                        small
                        title={!addMenu ? 'Aggiungi Menu' : 'Annulla'}
                        onClick={() => {
                            setAddMenu(!addMenu);
                        }}
                        enable={true}
                        isLoading={false}
                    />
                </div>
                {addMenu ? (
                    <div className="w-full flex my-5 items-center">
                        <Input
                            type="text"
                            className="w-auto h-full bg-white text-black font-normal outline-none"
                            placeholder="Nome menu"
                            onChange={(event) => {
                                setNewMenu(event.currentTarget.value.charAt(0).toUpperCase() + event.currentTarget.value.slice(1));
                            }}
                        />
                        <div className="flex ml-4 gap-2">
                            <img
                                className="w-6 h-6 cursor-pointer"
                                alt=""
                                src={isLoading ? loadingIcon : confirm}
                                onClick={() => {
                                    setIsLoading(true);
                                    createMenu(
                                        {
                                            idLocal: JSON.parse(localStorage.getItem('selectedRestaurant') as string).id,
                                            name: newMenu,
                                        },
                                        (response) => {
                                            showSuccessNotification('Menu', `${newMenu} creato con successo`, 2000);
                                            setTimeout(() => {
                                                window.location.reload();
                                            }, 2500);
                                        }
                                    );
                                }}
                            />
                            <img
                                className="w-6 h-6 cursor-pointer"
                                alt=""
                                src={isLoading ? loadingIcon : closeIcon}
                                onClick={() => {
                                    setAddMenu(false);
                                }}
                            />
                        </div>
                    </div>
                ) : modifyMenu ? (
                    <div className="w-full flex my-5 items-center">
                        <Input
                            type="text"
                            defaultValue={editableMenu?.name}
                            className="w-48 h-full bg-white text-black font-normal outline-none"
                            placeholder="Nome menu"
                            onChange={(event) => {
                                setEditableMenu({
                                    ...editableMenu!,
                                    name: event.currentTarget.value.charAt(0).toUpperCase() + event.currentTarget.value.slice(1),
                                });
                            }}
                        />
                        <div className="flex gap-2">
                            <img
                                className="w-6 h-6 ml-4 cursor-pointer"
                                alt=""
                                src={isLoading ? loadingIcon : confirm}
                                onClick={() => {
                                    setIsLoading(true);
                                    updateMenu(
                                        {
                                            idMenu: editableMenu?.id!,
                                            name: editableMenu?.name!,
                                        },
                                        (response) => {
                                            showSuccessNotification('Menu', `${editableMenu?.name} modificato con successo`, 2000);
                                            setTimeout(() => {
                                                window.location.reload();
                                            }, 2500);
                                        }
                                    );
                                }}
                            />
                            <img
                                className="w-6 h-6 ml-2 cursor-pointer"
                                alt=""
                                src={isLoading ? loadingIcon : closeIcon}
                                onClick={() => {
                                    setModifyMenu(false);
                                }}
                            />
                        </div>
                    </div>
                ) : null}

                {!isLoading && menuList.length > 0 ? (
                    <div className="w-auto h-auto py-5 flex justify-center">
                        <MenuAdminTable
                            menuList={menuList}
                            onEdit={(menu) => {
                                setEditableMenu(menu);
                                setModifyMenu(true);
                            }}
                            onClickItem={(menu) => {
                                localStorage.setItem('selectedMenu', JSON.stringify(menu));
                                localStorage.setItem('menuId', menu.id);
                                navigate(`/admin/prodotti`);
                            }}
                            onChangeStatus={(res) => {
                                localStorage.setItem('selectedRestaurantClient', JSON.stringify(res));
                                window.location.reload();
                            }}
                        />
                    </div>
                ) : (
                    <div className="w-full flex justify-center py-10">
                        <img alt="" className="w-24 h-24" src={loadingIcon} />
                    </div>
                )}
            </div>
        );
    } else {

        return (
            <div className={'w-full h-full  flex-col px-20  pt-[130px] '}>
                <div className={'w-full flex  items-center  '}>
                    <h1 className={'w-auto text-3xl font-poppins-bold my-5'}>Menu</h1>
                    <Tooltip radius={'lg'} classNames={{tooltip: 'font-poppins-bold'}} inline={false}
                             position={'right-end'}
                             label={'Scarica qui il menù del tuo Ristorante! Si aggiornerà automaticamente in base al menù attivo! '}>
                        <img alt={''} src={qrImage} className={'w-10 h-10 flex  ml-14  hover:scale-125 duration-500'}
                             onClick={() => {
                                 getMenuQr((response) => {
                                     const base64Data = response.qrMenu;
                                     const binaryString = atob(base64Data);
                                     const len = binaryString.length;
                                     const bytes = new Uint8Array(len);
                                     for (let i = 0; i < len; i++) {
                                         bytes[i] = binaryString.charCodeAt(i);
                                     }
                                     const blob = new Blob([bytes], {type: 'application/octet-stream'});
                                     const link = document.createElement('a');
                                     link.href = URL.createObjectURL(blob);
                                     link.download = `${JSON.parse(localStorage.getItem('selectedRestaurant') as string).nameRestaurant}.png`;
                                     document.body.appendChild(link);
                                     link.click();
                                     document.body.removeChild(link);
                                 })
                             }}/>
                    </Tooltip>

                </div>
                <div className={'w-full flex justify-between'}>
                    <h1 className={'w-auto font-poppins-bold my-5'}>NOME MENU</h1>
                    <CustomButton small={true} title={!addMenu ? 'Aggiungi Menu' : 'Annulla'} onClick={() => {
                        setAddMenu(!addMenu)
                    }} enable={true} isLoading={false}/>
                </div>
                {addMenu ?
                    <div className={'w-full flex my-10 items-center '}>
                        <Input type={'text'}
                               className={'w-[250px] h-full bg-white text-text-button-add font-poppins-regular outline-none '}
                               placeholder={'Nome menu'} onChange={(event) => {
                            setNewMenu(event.currentTarget.value.charAt(0).toUpperCase() + event.currentTarget.value.slice(1))
                        }}/>
                        <div className={'w-[100px] flex ml-10 gap-x-3'}>
                            <img className={'cursor-pointer w-6 h-6'} alt={''} src={isLoading ? loadingIcon : confirm}
                                 onClick={() => {
                                     setIsLoading(true)
                                     createMenu({
                                         idLocal: JSON.parse(localStorage.getItem('selectedRestaurant') as string).id,
                                         name: newMenu
                                     }, (response) => {
                                         showSuccessNotification('Menu', `${newMenu} creato con successo`, 2000)
                                         setTimeout(() => {
                                             window.location.reload()
                                         }, 2500)
                                     })
                                 }}/>
                            <img className={'cursor-pointer w-6 h-6'} alt={''} src={isLoading ? loadingIcon : closeIcon}
                                 onClick={() => {
                                     setAddMenu(false)

                                 }}/>
                        </div>
                    </div>

                    : modifyMenu ? <div className={'w-full flex my-10 items-center '}>
                        <Input type={'text'} defaultValue={editableMenu?.name}
                               className={'w-[250px] h-full bg-white text-text-button-add font-poppins-regular outline-none '}
                               placeholder={'Nome menu'} onChange={(event) => {
                            setEditableMenu({
                                ...editableMenu!,
                                name: event.currentTarget.value.charAt(0).toUpperCase() + event.currentTarget.value.slice(1)
                            })
                        }}/>
                        <div className={'w-[100px] flex ml-10 gap-x-3'}>
                            <img className={'cursor-pointer w-6 h-6'} alt={''} src={isLoading ? loadingIcon : confirm}
                                 onClick={() => {
                                     setIsLoading(true)
                                     updateMenu({
                                         idMenu: editableMenu?.id!,
                                         name: editableMenu?.name!
                                     }, (response) => {
                                         showSuccessNotification('Menu', `${editableMenu?.name} modificato con successo`, 2000)
                                         setTimeout(() => {
                                             window.location.reload()
                                         }, 2500)
                                     })
                                 }}/>
                            <img className={'cursor-pointer w-6 h-6'} alt={''} src={isLoading ? loadingIcon : closeIcon}
                                 onClick={() => {
                                     setModifyMenu(false)
                                 }}/>
                        </div>
                    </div> : null
                }

                {!isLoading && menuList.length > 0 ?
                    <div className={`py-5 bg-opacity-90 rounded-md px-2`}>
                        <MenuAdminTable menuList={menuList} onEdit={(menu) => {
                            setEditableMenu(menu)
                            setModifyMenu(true)
                        }} onClickItem={(menu) => {
                            localStorage.setItem('selectedMenu', JSON.stringify(menu))
                            localStorage.setItem('menuId', menu.id)
                            navigate(`/admin/prodotti`)
                        }} onChangeStatus={(res) => {
                            localStorage.setItem('selectedRestaurantClient', JSON.stringify(res))
                            window.location.reload()
                        }}/>
                    </div> : <div className={'w-full flex  justify-center py-10'}>
                        <img alt={''} className={'w-24 h-24'} src={loadingIcon}/>
                    </div>
                }
            </div>
        )

    }
}
