import RouteLocal from "../routing/RouteLocal";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default function LocalPage() {
    return (
        <div className={'w-full h-full'}>
            <RouteLocal/>
        </div>
    )
}
