import {Divider, Textarea} from "@mantine/core";
import {GetRequestType} from "../../types/RequestType";
import {isMobile} from "react-device-detect";

export default function RequestCardComponent(props: { data: GetRequestType }) {

    if (isMobile){
    return (
        <div className={'flex w-full h-full flex-col p-3 bg-black items-start gap-y-1 rounded-2xl'}>
            <div className={'w-full flex flex-col '}>
                <h1 className={'w-full] font-poppins-bold text-md text-amber-400 text-start'}>DATA INSERIMENTO:</h1>
                <h1 className={'w-full font-poppins-semi-bold text-md text-white text-center'}>{new Date(props.data.insertDate).toLocaleDateString()}</h1>
            </div>
            <div className={'w-full flex justify-between gap-x-10'}>
                <div
                    className={`w-full flex`}>
                    <Textarea classNames={{
                        root: 'w-full ',
                        input: 'bg-transparent font-poppins-semi-bold text-lg text-white text-end whitespace-normal  mr-4',
                        label: 'text-amber-400 font-poppins-bold text-md',

                    }} autosize maxRows={5} readOnly minRows={1} label={'MESSAGGIO:'} variant={'filled'}
                              value={props.data.messageClient }
                    />
                </div>
            </div>
            <div className={`${props.data.dateResponse ? 'visible' : 'hidden'} w-full flex flex-col `}>
                <h1 className={'w-full font-poppins-bold text-md text-amber-400 text-start'}>DATA RISPOSTA:</h1>
                <h1 className={'w-full h-auto flex font-poppins-semi-bold text-md text-white item text-center justify-center whitespace-normal'}>{props.data.dateResponse ? new Date(props.data.dateResponse).toLocaleDateString() : '-'}</h1>
            </div>
            <div className={`${props.data.dateResponse ? 'visible' : 'hidden'} w-full flex flex-col mb-3 `}>
                <h1 className={'w-full font-poppins-bold text-md text-amber-400 text-start'}>RISPOSTA:</h1>
                <h1 className={'w-full h-auto flex font-poppins-semi-bold text-md text-white item text-center justify-center whitespace-normal'}>{props.data.response}</h1>
            </div>
            <div className={'w-full'}>
                <Divider orientation={'horizontal'} size={'sm'} color={'yellow'}/>
            </div>

            <div className={'w-full flex flex-col mt-3 '}>
                <h1 className={'w-full font-poppins-bold text-md text-amber-400 text-start'}>NOME LOCALE:</h1>
                <h1 className={'w-full font-poppins-semi-bold text-md text-white text-end mr-4'}>{props.data.nameLocal}</h1>
            </div>
            <div className={'w-full flex flex-col '}>
                <h1 className={'w-full font-poppins-bold text-md text-amber-400 text-start'}>STATO:</h1>
                <h1 className={'w-full font-poppins-semi-bold text-md text-white text-end mr-4'}>{props.data.staus}</h1>
            </div>

            <div className={'w-full flex flex-col '}>
                <h1 className={'w-full font-poppins-bold text-md text-amber-400 text-start'}>NOME:</h1>
                <h1 className={'w-full font-poppins-semi-bold text-md text-white text-end mr-4'}>{props.data.nameClient}</h1>
            </div>
            <div className={'w-full flex flex-col '}>
                <h1 className={'w-full font-poppins-bold text-md text-amber-400 text-start'}>COGNOME:</h1>
                <h1 className={'w-full font-poppins-semi-bold text-md text-white text-end mr-4'}>{props.data.surnameClient}</h1>
            </div>
            <div className={'w-full flex flex-col '}>
                <h1 className={'w-full font-poppins-bold text-md text-amber-400 text-start'}>N. TELEFONO:</h1>
                <h1 className={'w-full font-poppins-semi-bold text-md text-white text-end mr-4'}>{props.data.phoneClient}</h1>
            </div>
            <div className={'w-full flex flex-col '}>
                <h1 className={'w-full font-poppins-bold text-md text-amber-400 text-start'}>EMAIL:</h1>
                <h1 className={'w-full font-poppins-semi-bold text-md text-white text-end mr-4'}>{props.data.emailClient}</h1>
            </div>


        </div>
    )
}else{
        return (
            <div className={'flex w-full h-full flex-col p-4 bg-black items-start gap-y-3 rounded-2xl'}>
                <div className={'w-full flex items-center justify-between gap-x-10'}>
                    <h1 className={'w-[50%] font-poppins-bold text-md text-white text-start'}>DATA INSERIMENTO:</h1>
                    <h1 className={'w-full font-poppins-semi-bold text-md text-white text-start ml-2'}>{new Date(props.data.insertDate).toLocaleDateString()}</h1>
                </div>
                <div className={'w-full flex justify-between gap-x-10'}>
                    <div
                        className={`w-full flex`}>
                        <Textarea classNames={{
                            root: 'w-full ',
                            input: 'bg-transparent font-poppins-semi-bold text-lg text-white text-start  pl-0 ml-0',
                            label: 'text-white font-poppins-bold text-lg',

                        }} autosize maxRows={5} readOnly minRows={1} label={'MESSAGGIO:'} variant={'filled'}
                                  value={props.data.messageClient}
                        />
                    </div>
                </div>
                <div className={`${props.data.dateResponse ? 'visible' : 'hidden'} w-full flex items-center justify-between gap-x-10`}>
                    <h1 className={'w-[50%] font-poppins-bold text-md text-white text-start'}>DATA RISPOSTA:</h1>
                    <h1 className={'w-full font-poppins-semi-bold text-md text-white text-start ml-2'}>{props.data.dateResponse ? new Date(props.data.dateResponse).toLocaleDateString() : '-'}</h1>
                </div>
                <div className={`${props.data.dateResponse ? 'visible' : 'hidden'} w-full flex items-center justify-between gap-x-10`}>
                    <h1 className={'w-[50%] font-poppins-bold text-md text-white text-start'}>RISPOSTA:</h1>
                    <h1 className={'w-full font-poppins-semi-bold text-md text-white text-start ml-2'}>{props.data.response}</h1>
                </div>
                <div className={'w-full'}>
                    <Divider orientation={'horizontal'} size={'sm'} color={'yellow'}/>
                </div>

                <div className={'w-full flex items-center justify-between gap-x-10'}>
                    <h1 className={'w-[50%] font-poppins-bold text-md text-white text-start '}>NOME LOCALE:</h1>
                    <h1 className={'w-full font-poppins-semi-bold text-md text-white text-start ml-2'}>{props.data.nameLocal}</h1>
                </div>
                <div className={'w-full flex items-center justify-between gap-x-10'}>
                    <h1 className={'w-[50%] font-poppins-bold text-md text-white text-start '}>STATO:</h1>
                    <h1 className={'w-full font-poppins-semi-bold text-md text-white text-start ml-2'}>{props.data.staus}</h1>
                </div>

                <div className={'w-full flex items-center justify-between gap-x-10'}>
                    <h1 className={'w-[50%] font-poppins-bold text-md text-white text-start'}>NOME:</h1>
                    <h1 className={'w-full font-poppins-semi-bold text-md text-white text-start ml-2'}>{props.data.nameClient}</h1>
                </div>
                <div className={'w-full flex items-center justify-between gap-x-10'}>
                    <h1 className={'w-[50%] font-poppins-bold text-md text-white text-start '}>COGNOME:</h1>
                    <h1 className={'w-full font-poppins-semi-bold text-md text-white text-start ml-2'}>{props.data.surnameClient}</h1>
                </div>
                <div className={'w-full flex items-center justify-between gap-x-10'}>
                    <h1 className={'w-[50%] font-poppins-bold text-md text-white text-start'}>N. TELEFONO:</h1>
                    <h1 className={'w-full font-poppins-semi-bold text-md text-white text-start ml-2'}>{props.data.phoneClient}</h1>
                </div>
                <div className={'w-full flex items-center justify-between gap-x-10'}>
                    <h1 className={'w-[50%] font-poppins-bold text-md text-white text-start'}>EMAIL:</h1>
                    <h1 className={'w-full font-poppins-semi-bold text-md text-white text-start ml-2'}>{props.data.emailClient}</h1>
                </div>


            </div>
        )
    }}
