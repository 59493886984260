import {deleteReservation} from "../../network/controllers/admin/ReservationController";
import {ReservationResponseType} from "../../types/ReservationResponseType";
import {StatusEnum} from "../../enums/StatusEnum";
import {useState} from "react";
import CustomButton from "../CustomButton";
import {showWarningNotification} from "../../utils/NotificationUtils";

export default function ReservationCardComponentClient(props: {
    reservation: ReservationResponseType,
    reload: () => void
}) {

    const [statusButton, setStatusButton] = useState<boolean>(false);
    const [noteAdmin, setNoteAdmin] = useState('');
    const [reservationCode, setReservationCode] = useState('');
    var colorStatus = 'bg-black'
    if (props.reservation.reservationStatus === StatusEnum.ACCEPTED) {
        colorStatus = 'bg-green-600'
    }
    if (props.reservation.reservationStatus === StatusEnum.PENDING) {
        colorStatus = 'bg-yellow'
    }
    if (props.reservation.reservationStatus === StatusEnum.REJECTED) {
        colorStatus = 'bg-red-600'
    }
    if (props.reservation.reservationStatus === StatusEnum.DONE) {
        colorStatus = 'bg-blue-600'
    }


    var data = new Date(props.reservation.reservationDateTime)


    return (
        <div className={'flex w-full  h-auto items-center justify-center'}>
            <div className='flex flex-col w-auto h-auto bg-black rounded-2xl p-3 '>

                <div className='flex flex-col w-full h-auto items-start pr-5 pl-5 gap-y-2'>
                    <h1 className=' w-full h-full flex text-yellow text-lg font-poppins-bold items-center justify-center'> {props.reservation.nameRestaurants}</h1>
                    <h1 className='text-white text-sm font-poppins-bold'>DATA
                        PRENOTAZIONE: {new Date(props.reservation.reservationDateTime).toLocaleString()}</h1>
                    <h1 className='text-white text-sm font-poppins-bold'>RICHIESTA
                        PRENOTAZIONE: {new Date(props.reservation.insertDate).toLocaleString()}</h1>

                    <h1 className='text-white text-sm font-poppins-bold'>PERSONE: {props.reservation.numberOfPeople}</h1>
                    <h1 className='text-white text-sm font-poppins-bold'>NOME: {props.reservation.userName}</h1>
                    <h1 className='text-white text-sm font-poppins-bold'>COGNOME: {props.reservation.userSurname}</h1>
                    <h1 className='text-white text-sm font-poppins-bold'>EMAIL: {props.reservation.reservationEmail}</h1>
                    <h1 className='text-white text-sm font-poppins-bold'>N.
                        TELEFONO: {props.reservation.reservationPhone}</h1>
                    <label className='text-white text-sm font-poppins-bold  '
                           htmlFor="noteInput">NOTE: {props.reservation.reservationInfo}</label>

                    <div className={'flex items-center'}>
                        <h1 className='text-white text-sm font-poppins-bold '>STATO: </h1>
                        <div className={`w-4 h-4 ml-3 rounded-full ${colorStatus}`}></div>
                    </div>
                    <h1 className='text-white text-sm font-poppins-bold h-16 max-h-16 overflow-y-scroll'>RISPOSTA: {props.reservation.reservationAdminNote ? ' ' + props.reservation.reservationAdminNote : ' -'}</h1>

                    {props.reservation.reservationStatus === StatusEnum.REJECTED ? null :
                        <div className={`flex flex-col w-full  justify-center items-center`}>
                            <div className={'w-full h-[1px] bg-yellow mt-2 mb-2'}/>
                            {props.reservation.reservationStatus === StatusEnum.ACCEPTED ?
                                <h1 className='text-white text-xs font-poppins-bold'>CODICE PRENOTAZIONE:</h1> :
                                <h1 className='text-white text-xs font-poppins-bold'>STATO PRENOTAZIONE:</h1>}
                            {props.reservation.reservationStatus === StatusEnum.PENDING || props.reservation.reservationStatus === StatusEnum.ACCEPTED ? props.reservation.reservationCode ?
                                    <div className={'w-full flex flex-col justify-center items-center gap-y-3'}>
                                        <h1 className='text-white text-xl font-poppins-bold'>{props.reservation.reservationCode}</h1>
                                        <CustomButton small={true} bgColor={'#DE0000'} title={'Annulla Prenotazione'}
                                                      onClick={() => {
                                                          showWarningNotification(`Vuoi eliminare la prenotazione presso ${props.reservation.nameRestaurants} per la data ${data.toLocaleDateString()} alle ${data.getHours()}:${data.getMinutes()}?`, () => {
                                                              deleteReservation(props.reservation.id, () => {
                                                                  props.reload()
                                                              })
                                                          })

                                                      }} enable={true} isLoading={false}/>
                                    </div> :
                                    <div className={'w-full flex flex-col justify-center items-center gap-y-3'}>
                                        <h1 className='text-white w-full text-center text-xl font-poppins-bold'>{'IN ATTESA DI CONFERMA'}</h1>
                                        <CustomButton small={true} bgColor={'#DE0000'} title={'Annulla prenotazione'}
                                                      onClick={() => {
                                                          showWarningNotification(`Vuoi eliminare la prenotazione presso ${props.reservation.nameRestaurants} per la data ${data.toLocaleDateString()} alle ${data.getHours()}:${data.getMinutes()}?`, () => {
                                                              deleteReservation(props.reservation.id, () => {
                                                                  props.reload()
                                                              })
                                                          })

                                                      }} enable={true} isLoading={false}/>
                                    </div>
                                : <h1 className='text-white text-lg font-poppins-bold'>GRAZIE PER AVERCI SCELTO !</h1>}
                        </div>}

                </div>

            </div>
        </div>

    )
}


