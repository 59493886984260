import CustomButton from "./CustomButton";
import {PackageType} from "../types/PackageType";
import {Grid, Select} from "@mantine/core";
import {useEffect, useState} from "react";
import {License} from "../types/AddRestaurantRequest";
import {formatStringDuration} from "../utils/StringUtils";
import GenericTooltip from "./generic/GenericTooltip";
import infoIcon from '../assets/info-tooltip-icon.svg'
import {getDimension} from "../utils/Utility";
import {isMobile} from "react-device-detect";
export default function SubscriptionPlanComponent(props:{
    allowNextStep: ()=>void,
    selectedLicense:License|undefined,licenseList:License[],pack:PackageType,isRestaurantAdding:boolean,onLicenseSelect:(license:License|null)=>void}){

    const [license, setLicense] = useState<{ label: string, value: string }[]>([]);
    const [defaultLicense, setDefaultLicense] = useState<string>('');
    const [selectedLicense, setSelectedLicense] = useState<License | null>(null);
    useEffect(() => {
        var tmpArr: { label: string, value: string }[] = []
        props.pack.license.map((license) => {
            if (!props.licenseList.includes(license)){
                tmpArr.push({value: JSON.stringify(license), label: license.name})
            }

            if (props.selectedLicense!==undefined){
                if (props.selectedLicense.id===license.id){
                    setDefaultLicense(JSON.stringify(license))
                    setSelectedLicense(license)
                }
            }

        })
        setLicense(tmpArr)
    }, []);

    const [dimension, setDimension] = useState(false)
    getDimension(1300, (res)=>{
        setDimension(res)
    })


    if (isMobile) {
        return (
            <div
                className="w-full h-auto flex flex-col items-center justify-center p-3 rounded-xl  shadow-lg">
                <img alt="" className="w-[60%] items-center justify-center p-2" src={props.pack.image}/>
                <h1 className="font-poppins-bold text-3xl">{props.pack.name}</h1>
                {props.pack.license.length > 0 ? (
                    <Select
                        classNames={{
                            label: 'font-poppins-regular',
                            option: 'font-poppins-regular',
                            input: 'font-poppins-regular',
                        }}
                        label="Seleziona Licenza"
                        value={defaultLicense}
                        data={license}
                        onChange={(v) => {
                            if (v !== null) {

                                props.onLicenseSelect(JSON.parse(v) as License);
                                setSelectedLicense(JSON.parse(v) as License);
                            } else {
                                var tmp: License | null = selectedLicense;
                                props.onLicenseSelect(tmp);
                                setSelectedLicense(null);
                            }
                        }}
                    />
                ) : (
                    <h1 className="text-3xl font font-poppins-regular">Prossimamente</h1>
                )}
                {selectedLicense !== null ? (
                    <div className="w-full flex flex-col items-center justify-center gap-y-3">
                        <h1 key={JSON.stringify(selectedLicense)} className="font-poppins-semi-bold text-2xl mt-2">
                            {selectedLicense.price !== 0
                                ? '€' + parseFloat(String(selectedLicense?.price / 12)).toFixed(2) + ' + iva / ' + formatStringDuration(selectedLicense.duration)
                                : `Gratis per ${formatStringDuration(selectedLicense.duration)}!`}
                        </h1>
                        {selectedLicense.price===0?<p key={'wewe'} className="font-poppins-semi-bold text-lg mt-1">
                            Poi 29.99€ al mese!
                        </p>:null}

                        <CustomButton
                            small={true}
                            enable={true}
                            isLoading={false}
                            onClick={() => {
                                props.allowNextStep();
                            }}
                            title="Avanti"
                        />
                    </div>
                ) : null}
                {props.isRestaurantAdding ? null : (
                    <div className="w-full h-auto py-1 flex items-center justify-center">
                        <CustomButton
                            small={true}
                            title="ABBONATI"
                            onClick={() => {
                            }}
                            enable={selectedLicense !== null}
                            isLoading={false}
                        />
                    </div>
                )}
                <ul className=" w-full pb-2 ">
                    {props.pack.listService.sort((a, b) => a.title.localeCompare(b.title)).map((service) => (
                        <li key={service.title} className="flex items-center w-full h-auto pt-1">
                            <div className={`flex justify-start w-full pl-2 py-1 `}>
                                <GenericTooltip
                                    txt={service.description}
                                    innerHtml={<img alt="" className="w-5 h-5 ml-1" src={infoIcon}/>}
                                />
                                <h1 className="pl-2 whitespace-normal">{service.title}</h1>
                            </div>

                        </li>
                    ))}
                </ul>
            </div>

        )
    } else {
        return (
            <div
                className={`${dimension ? 'w-full' : 'w-[480px]'} flex flex-col items-center justify-center p-5 rounded-xl gap-y-2 space-y-2  h-min shadow-2xl`}>
                <img alt={''} className=' w-auto pt-5 ' src={props.pack.image}/>
                <h1 className={'font-poppins-bold text-3xl'}>{props.pack.name}</h1>
                {props.pack.license.length > 0 ? <Select
                    classNames={{
                        label: 'font-poppins-regular',
                        option: 'font-poppins-regular',
                        input: 'font-poppins-regular'
                    }}
                    label={'Seleziona Licenza'}
                    value={defaultLicense}
                    data={license} onChange={(v) => {
                    if (v !== null) {
                        console.log(v)
                        props.onLicenseSelect(JSON.parse(v) as License)
                        setSelectedLicense(JSON.parse(v) as License)
                    } else {
                        var tmp: License | null = selectedLicense
                        props.onLicenseSelect(tmp)
                        setSelectedLicense(null)
                    }
                }}/> : <h1 className={'text-3xl font font-poppins-regular '}>Prossimamente</h1>}
                {selectedLicense !== null ?
                    <div className={'w-full flex flex-col items-center justify-center gap-y-3'}>
                        <h1 key={JSON.stringify(selectedLicense)}
                            className={'font-poppins-semi-bold text-2xl'}>{selectedLicense.price !== 0 ? '€' + parseFloat(String(selectedLicense?.price / 12)).toFixed(2) + ' + iva / ' + formatStringDuration(selectedLicense.duration) : `Gratis per ${formatStringDuration(selectedLicense.duration)}!`}</h1>
                        {selectedLicense.price===0?<p key={'wewe'} className="font-poppins-semi-bold text-lg mt-1">
                            Poi 29.99€ al mese!
                        </p>:null}
                        <CustomButton small={true} enable={true} isLoading={false} onClick={() => {
                            props.allowNextStep()
                        }} title={'Avanti'}/>
                    </div>
                    : null}
                {props.isRestaurantAdding ? null :
                    <div className={'w-full h-auto py-1 flex items-center justify-center'}>
                        <CustomButton small={true} title='ABBONATI' onClick={() => void {}}
                                      enable={selectedLicense !== null}
                                      isLoading={false}/>
                    </div>}
                <Grid>

                    {props.pack.listService.sort((a, b) => a.title.localeCompare(b.title)).map((service) => (
                        <Grid.Col span={{lg: 12, base: 12, sm: 12, xl: 12}}>
                            <div
                                className={`flex ${dimension ? 'justify-center' : 'justify-start'}   items-center w-auto h-auto pt-1`}>
                                <div className={'w-auto flex items-center justify-center '}>
                                    <GenericTooltip txt={service.description}
                                                    innerHtml={<img alt={''} className={'w-5 h-5 ml-1'}
                                                                    src={infoIcon}/>}/>
                                    <h1 className={'pl-2 text-nowrap'}>{service.title}</h1>

                                </div>
                            </div>
                        </Grid.Col>

                    ))}
                </Grid>



            </div>

        )
    }
}
