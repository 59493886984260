import {AllergenType} from "../../../types/AllergenType";
import {makeRequest} from "../../RestAdapter";
import {HttpMethodsEnum} from "../../HttpMethodsEnum";

export function getAllergeni(onFinish: (allergeniList: AllergenType[]) => void) {
    makeRequest<AllergenType[]>("/restaurants/allergen", HttpMethodsEnum.GET).then((res) => {
        if (res.data !== null) {
            onFinish(res.data)
        }
    })
}