import {Divider, Table} from "@mantine/core";
import {PrinterType} from "../../types/PrinterType";
import {isMobile} from "react-device-detect";
import {StatusPrinterEnum} from "../../enums/StatusPrinterEnum";


export default function PrinterAdminTable(props: {
    printersList: PrinterType[],
    onClickItem: (ingredient: PrinterType) => void
}) {


    const getStatusColor = (status: string) => {
        switch (status) {
            case StatusPrinterEnum.SENDED:
                return <div className={'w-5 h-5 bg-lime-400 rounded-full ml-2 border-[1px] border-white'}></div>;
            case StatusPrinterEnum.WORKING:
                return <div className={'w-5 h-5 bg-amber-400 rounded-full ml-2 border-[1px] border-white'}></div>;
            case StatusPrinterEnum.COMPLETE:
                return <div className={'w-5 h-5 bg-blue-800 rounded-full ml-2 border-[1px] border-white'}></div>;
            default:
                return "bg-gray-500";
        }
    }


    const rows = props.printersList.length > 0 ? props.printersList.map((element, index) => (
        <Table.Tr className={'w-full cursor-pointer '} key={Math.random()}>
            <Table.Td onClick={() => {
                props.onClickItem(element)
            }}>
                <div className={`w-full flex justify-start items-center ${isMobile ?'text-sm':''}`}>
                    <h1>{element.imei}</h1>
                </div>
            </Table.Td>

            <Table.Td onClick={() => {
                props.onClickItem(element)
            }}>
                <div className={'w-auto flex justify-start items-center '}>
                    <h1>{element.printerName}</h1>
                </div>
            </Table.Td>
            <Table.Td onClick={() => {
                props.onClickItem(element)
            }}>
                <div className={'w-auto flex flex-col justify-start items-start '}>
                    {element.sector.length > 0 ? element.sector.map((sector) => (
                        <div className={'w-full flex-col'}>
                            <h1>{sector.name}</h1>
                            {isMobile?<Divider/>:null}
                        </div>

                    )) : <h1>-</h1>}
                </div>
            </Table.Td>
            <Table.Td>
                <td className="p-2">{getStatusColor(element.status)}</td>

            </Table.Td>
        </Table.Tr>

    )) : null;

    if (isMobile) {
        return (
            <div className={`mt-10 w-auto h-auto`}>
                <Table.ScrollContainer minWidth={'auto'}>
                    <Table striped highlightOnHover withTableBorder withRowBorders={false}>
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th style={{ fontSize: '10px' }}>IMEI</Table.Th>
                                <Table.Th style={{ fontSize: '10px' }}>NOME</Table.Th>
                                <Table.Th style={{ fontSize: '10px' }}>SETTORI</Table.Th>
                                <Table.Th style={{ fontSize: '10px'  }}>STATUS</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody key={Math.random()}>{rows}</Table.Tbody>
                    </Table>
                </Table.ScrollContainer>
            </div>
        )

    }else{
        return (
            <div className={' mt-10'}>
                <Table.ScrollContainer minWidth={'auto'}>
                    <Table striped highlightOnHover withTableBorder withRowBorders={false}>
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th>IMEI
                                </Table.Th>
                                <Table.Th>NOME</Table.Th>
                                <Table.Th>SETTORI</Table.Th>
                                <Table.Th>STATUS</Table.Th>

                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody key={Math.random()}>{rows}</Table.Tbody>
                    </Table>
                </Table.ScrollContainer>
            </div>
        )

    }}
