import {Carousel} from "@mantine/carousel";
import GenericImg from "../components/generic/GenericImg";
import React, {useEffect, useRef, useState} from "react";
import bannerClientWeb1 from "../assets/banner/web/banner-client-web-1.webp";
import bannerClientWeb2 from "../assets/banner/web/banner-client-web-2.webp";
import bannerClientWeb3 from "../assets/banner/web/banner-client-web-3.webp";
import bannerClientWeb4 from "../assets/banner/web/banner-client-web-4.webp";
import bannerAdminWeb1 from "../assets/banner/web/banner-admin-web-1.webp";
import bannerAdminWeb2 from "../assets/banner/web/banner-admin-web-2.webp";
import bannerAdminWeb3 from "../assets/banner/web/banner-admin-web-3.webp";
import bannerAdminWeb4 from "../assets/banner/web/banner-admin-web-4.webp";
import bannerAdminMobile1 from "../assets/banner/mobile/banner-admin-mobile-1.webp";
import bannerAdminMobile2 from "../assets/banner/mobile/banner-admin-mobile-2.webp";
import bannerAdminMobile3 from "../assets/banner/mobile/banner-admin-mobile-3.webp";
import bannerAdminMobile4 from "../assets/banner/mobile/banner-admin-mobile-4.webp";
import bannerClientMobile1 from "../assets/banner/mobile/banner-client-mobile-1.webp";
import bannerClientMobile2 from "../assets/banner/mobile/banner-client-mobile-2.webp";
import bannerClientMobile3 from "../assets/banner/mobile/banner-client-mobile-3.webp";
import bannerClientMobile4 from "../assets/banner/mobile/banner-client-mobile-4.webp";
import CarouselWelcomPage from "../components/CarouselWelcomPage";
import Autoplay from 'embla-carousel-autoplay';
import {useNavigate} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {isTablet} from "../utils/WindowDimensionUtils";
import {IbanElement} from "@stripe/react-stripe-js";
import InfoWelcome from "../components/welcome/InfoWelcome";


export default function WelcomePage() {
    const [mockImg, setMockImg] = useState<Map<number, string>>(new Map());

    ;

    useEffect(() => {
        const loadImages = async () => {
            const imagesWeb = [
                bannerAdminWeb1,
                bannerAdminWeb2,
                bannerAdminWeb3,
                bannerAdminWeb4,
                bannerClientWeb1,
                bannerClientWeb2,
                bannerClientWeb3,
                bannerClientWeb4
            ];
            const imagesMobile = [
                bannerAdminMobile1,
                bannerAdminMobile2,
                bannerAdminMobile3,
                bannerAdminMobile4,
                bannerClientMobile1,
                bannerClientMobile2,
                bannerClientMobile3,
                bannerClientMobile4
            ];

            // Aggiornare le immagini usando la fnzione setMockImg
            setMockImg(isMobile && !isTablet() ? new Map(imagesMobile.map((img, idx) => [idx + 1, img])) : new Map(imagesWeb.map((img, idx) => [idx + 1, img])));


        };

        loadImages();
    }, []);

    const autoplay = useRef(Autoplay({delay: 3000}));
    const navigate = useNavigate();

    if (isMobile) {
        return (
            <div className="h-full bg-gray-100 ">
                <div className={'pt-[85px]'}>
                    <Carousel
                        withIndicators={Array.from(mockImg).length > 1}
                        withControls={false}
                        height={'max-content'}
                        slideSize="100%"
                        slideGap="md"
                        plugins={[autoplay.current]}
                        loop>
                        {Array.from(mockImg.values()).map((item, index) => (
                            <Carousel.Slide key={item}>
                                <GenericImg key={item} onCLick={() => {
                                    if (index < 4) {
                                        dispatchEvent(new CustomEvent("footerVisible", { detail: { isVisible: false } }));
                                        navigate('/restaurant')
                                    }
                                }} className={'w-full'} src={item + `?timestamp=${new Date().getMilliseconds()}`}/>
                            </Carousel.Slide>))}
                    </Carousel>
                    <InfoWelcome/>
                    {/*
<div className={'w-auto h-full pb-5'}>
   <CarouselWelcomPage />
</div>*/}
                </div>
            </div>
        );
    } else {
        return (
            <div className="relative h-full bg-gray-100">
                <div className={'pt-[85px]'}>
                    <Carousel
                        withIndicators={Array.from(mockImg).length > 1}
                        withControls={false}
                        height={'max-content'}
                        slideSize="100%"
                        slideGap="md"
                        plugins={[autoplay.current]}
                        loop>
                        {Array.from(mockImg.values()).map((item, index) => (
                            <Carousel.Slide key={item}>
                                <GenericImg key={item} onCLick={() => {
                                    if (index < 4) {
                                        dispatchEvent(new CustomEvent("footerVisible", { detail: { isVisible:false} }));
                                        navigate('/restaurant')
                                    }
                                }} className={'w-full'} src={item + `?timestamp=${new Date().getMilliseconds()}`}/>
                            </Carousel.Slide>))}
                    </Carousel>

                    <InfoWelcome/>

                </div>
            </div>
        );

    }
}
