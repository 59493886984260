import React, {useEffect, useState} from "react";
import {ReservationResponseType} from "../../types/ReservationResponseType";
import {getReservationRestaurants, sendResponseAdmin} from "../../network/controllers/admin/ReservationController";
import {StatusEnum} from "../../enums/StatusEnum";
import AccordionReservation from "../../components/AccordionReservation";
import HeaderReservationFilter from "../../components/HeaderReservationFilter";
import {addAlpha} from "../../utils/Utility";
import {connectMQTT, onMessage, subscribeTopic} from "../../utils/MqttUtility";
import {LocalType} from "../../types/LocalType";
import {isMobile} from "react-device-detect";

export default function ReservationAdminPage() {
    var local = JSON.parse(localStorage.getItem('selectedRestaurant') as string) as LocalType
    const [reload, setReload] = useState<boolean>(false);
    const [dataReservationAccepted, setDataReservationAccepted] = useState<Map<string, ReservationResponseType[]>>(new Map());
    const [dataReservationRejected, setDataReservationRejected] = useState<Map<string, ReservationResponseType[]>>(new Map());
    const [dataReservationDone, setDataReservationDone] = useState<Map<string, ReservationResponseType[]>>(new Map());
    const [dataReservationPending, setDataReservationPending] = useState<Map<string, ReservationResponseType[]>>(new Map());

    const [value, setValue] = useState<{ date: Date, status: StatusEnum }>({
        date: new Date(),
        status: StatusEnum.PENDING
    });
    useEffect(() => {
        var tmpData = value.date
        //aggiungo 2 ore per allinearmi al fuso orario dell'isostring
        tmpData.setHours(value.date.getHours() + 2)
        getReservationRestaurants(StatusEnum.ACCEPTED, value.date, (res) => {
            var tmp = new Map<string, ReservationResponseType[]>()
            Object.entries(res).forEach((ite) => {
                tmp.set(ite[0], ite[1])
            })
            setDataReservationAccepted(tmp)
        })
        getReservationRestaurants(StatusEnum.REJECTED, value.date, (res) => {
            var tmp = new Map<string, ReservationResponseType[]>()
            Object.entries(res).forEach((ite) => {
                tmp.set(ite[0], ite[1])
            })

            setDataReservationRejected(tmp)
        })
        getReservationRestaurants(StatusEnum.DONE, value.date, (res) => {
            var tmp = new Map<string, ReservationResponseType[]>()
            Object.entries(res).forEach((ite) => {
                tmp.set(ite[0], ite[1])
            })

            setDataReservationDone(tmp)
        })
        getReservationRestaurants(StatusEnum.PENDING, value.date, (res) => {
            var tmp = new Map<string, ReservationResponseType[]>()
            Object.entries(res).forEach((ite) => {
                tmp.set(ite[0], ite[1])
            })

            setDataReservationPending(tmp)
        })
    }, [value, reload]);


    useEffect(() => {
        var topic = '/reservation/' + local.nameRestaurant.replace(/ /g, "-").toLowerCase()
        subscribeTopic(topic)
        onMessage(topic, () => {
            setReload(!reload)
        })
    }, []);


    if (isMobile) {
        return (
            <div className={'flex w-full h-auto flex-col pt-[130px] '}>
                <div className={'w-full h-auto'}>
                    <HeaderReservationFilter reload={() => {
                        setReload(!reload)
                    }} isAdmin={true} onValueChange={(value) => {
                        setValue(value)
                    }}/>
                </div>
                <div className={'flex flex-col w-full h-full gap-y-10  justify-around mt-20'}>
                    <div className={`flex w-full h-full flex-col p-1`}>
                        <div className={'w-full flex justify-center'}>
                            <h1 className={'font-poppins-semi-bold text-lg text-amber-400 w-full text-center rounded-2xl'}
                                style={{backgroundColor: addAlpha('#FFBF00', 0.1)}}>In Attesa</h1>
                        </div>

                        <AccordionReservation isAdmin={true} dataReservation={dataReservationPending} reload={() => {
                            setReload(!reload)
                        }}/>
                    </div>
                    <div className={'flex w-full h-full flex-col p-1'}>
                        <div className={'w-full flex justify-center'}>
                            <h1 className={'font-poppins-semi-bold text-lg text-green-700 w-full text-center rounded-2xl'}
                                style={{backgroundColor: addAlpha('#00A86B', 0.1)}}>Accettati</h1>
                        </div>

                        <AccordionReservation isAdmin={true} dataReservation={dataReservationAccepted} reload={() => {
                            setReload(!reload)
                        }}/>
                    </div>
                    <div className={'flex w-full h-full flex-col p-1'}>
                        <div className={'w-full flex justify-center'}>
                            <h1 className={'font-poppins-semi-bold text-lg text-blue-700 w-full text-center rounded-2xl'}
                                style={{backgroundColor: addAlpha('#002db6', 0.1)}}>Completati</h1>
                        </div>
                        <AccordionReservation isAdmin={true} dataReservation={dataReservationDone} reload={() => {
                            setReload(!reload)
                        }}/>
                    </div>
                    <div className={'flex w-full h-full flex-col p-1 mb-10'}>
                        <div className={'w-full flex justify-center'}>
                            <h1 className={'font-poppins-semi-bold text-lg text-red-700 w-full text-center rounded-2xl'}
                                style={{backgroundColor: addAlpha('#de0000', 0.1)}}>Rifiutati</h1>
                        </div>
                        <AccordionReservation isAdmin={true} dataReservation={dataReservationRejected} reload={() => {
                            setReload(!reload)
                        }}/>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={'flex w-full h-full flex-col pt-[130px]'}>
                <div className={'w-full h-auto'}>
                    <HeaderReservationFilter reload={() => {
                        setReload(!reload)
                    }} isAdmin={true} onValueChange={(value) => {
                        setValue(value)
                    }}/>

                </div>

                <div className={'flex w-full h-full  justify-around'}>
                    <div className={`flex w-full h-full flex-col p-4`}>
                        <div className={'w-full flex justify-center'}>
                            <h1 className={'font-poppins-semi-bold text-lg text-amber-400 w-full text-center rounded-2xl'}
                                style={{backgroundColor: addAlpha('#FFBF00', 0.1)}}>In Attesa</h1>
                        </div>

                        <AccordionReservation isAdmin={true} dataReservation={dataReservationPending} reload={() => {
                            setReload(!reload)
                        }}/>
                    </div>
                    <div className={'w-[5px] h-full bg-gray-400 opacity-50'}></div>
                    <div className={'flex w-full h-full flex-col p-4'}>
                        <div className={'w-full flex justify-center'}>
                            <h1 className={'font-poppins-semi-bold text-lg text-green-700 w-full text-center rounded-2xl'}
                                style={{backgroundColor: addAlpha('#00A86B', 0.1)}}>Accettati</h1>
                        </div>

                        <AccordionReservation isAdmin={true} dataReservation={dataReservationAccepted} reload={() => {
                            setReload(!reload)
                        }}/>
                    </div>
                    <div className={'w-[5px] h-full bg-gray-400 opacity-50'}></div>
                    <div className={'flex w-full h-full flex-col p-4'}>
                        <div className={'w-full flex justify-center'}>
                            <h1 className={'font-poppins-semi-bold text-lg text-blue-700 w-full text-center rounded-2xl'}
                                style={{backgroundColor: addAlpha('#002db6', 0.1)}}>Completati</h1>
                        </div>
                        <AccordionReservation isAdmin={true} dataReservation={dataReservationDone} reload={() => {
                            setReload(!reload)
                        }}/>
                    </div>
                    <div className={'w-[5px] h-full bg-gray-400 opacity-50'}></div>
                    <div className={'flex w-full h-full flex-col p-4'}>
                        <div className={'w-full flex justify-center'}>
                            <h1 className={'font-poppins-semi-bold text-lg text-red-700 w-full text-center rounded-2xl'}
                                style={{backgroundColor: addAlpha('#DE0000', 0.1)}}>Rifiutati</h1>
                        </div>
                        <AccordionReservation isAdmin={true} dataReservation={dataReservationRejected} reload={() => {
                            setReload(!reload)
                        }}/>
                    </div>

                </div>
            </div>
        )
    }

}
