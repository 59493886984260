import {Accordion, Grid} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {GetRequestType} from "../types/RequestType";
import RequestCardComponent from "../assets/request/RequestCardComponent";

export default function AccordionRequest(props: {
    isAdmin: boolean,
    data: Map<string, GetRequestType[]>,
    reload?: () => void
}) {


    return (
        <div className={'w-full'}>
            {props.data ? <Accordion className={'w-full'}>
                {Array.from(props.data.keys()).sort().map((date) => (
                    <Accordion.Item key={date} value={date}>
                        <Accordion.Control>
                            <div className={'w-full flex flex-col'}>
                                <h1 className={'font-poppins-semi-bold'}>{date}</h1>
                            </div>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <Grid>{
                                props.data && props.data.get(date) !== undefined ? props.data.get(date)!.map((request: GetRequestType) => (
                                    <Grid.Col key={request.id} span={{
                                        base: 12,
                                        md: props.isAdmin ? 12 : 6,
                                        lg: props.isAdmin ? 12 : 4,
                                        xl: props.isAdmin ? 12 : 3
                                    }}>
                                        <RequestCardComponent data={request}/>
                                    </Grid.Col>
                                )) : null
                            }
                            </Grid>
                        </Accordion.Panel>
                    </Accordion.Item>))}

            </Accordion> : null}
        </div>
    )
}
