import {PaymentElement} from "@stripe/react-stripe-js";
import {useEffect, useState} from "react";
import {useStripe, useElements} from "@stripe/react-stripe-js";
import {showFailNotification} from "../utils/NotificationUtils";
import {isMobile} from "react-device-detect";
export default function CheckoutformRenew(props: { idStripe: string, idPackage:string, idLicense:string }) {

    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);




    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        setIsProcessing(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${process.env['REACT_APP_REDIRECT_STRIPE'] as string}/result?idStripe=${props.idStripe}&idPackage=${props.idPackage}&idLicense=${props.idLicense}`,
            },
        });

        if (error) {
            // @ts-ignore
            showFailNotification('Errore', error.message,3000);
            setIsProcessing(false); // Reset isProcessing state if there's an error
        }
    };


    if (isMobile){
        return (
            <div className={'w-full flex flex-col'}>
                <form className={'w-auto'} id="payment-form" onSubmit={handleSubmit}>
                    <div className={'w-full mt-10'}>
                        <PaymentElement id="payment-element"/>
                    </div>
                    <div className={'w-full flex justify-center items-center '}>
                        <div className={'bg-yellow p-4 rounded-xl mt-10 cursor-pointer hover:scale-110 duration-500'}>


                            <button disabled={isProcessing || !stripe || !elements} id="submit">
                            <span className={'font-poppins-semi-bold'} id="button-text">
                                {isProcessing ? "Processing ... " : "Paga Ora"}
                            </span>
                            </button>
                        </div>
                    </div>
                    {/* Show any error or success messages */}
                    {message && <div id="payment-message">{message}</div>}
                </form>
            </div>
        )
    }else{
        return (

            <div className={'w-full flex items-center justify-center p-5 '}>
                <form className={'w-full px-20'} id="payment-form" onSubmit={handleSubmit}>
                    <div className={'w-full mt-20'}>
                        <PaymentElement id="payment-element"/>
                    </div>
                    <div className={'w-full flex justify-center items-center '}>
                        <div className={'bg-yellow p-4 rounded-xl mt-10 cursor-pointer hover:scale-110 duration-500'}>


                            <button disabled={isProcessing || !stripe || !elements} id="submit">
                            <span className={'font-poppins-semi-bold'} id="button-text">
                                {isProcessing ? "Processing ... " : "Paga Ora"}
                            </span>
                            </button>
                        </div>
                    </div>
                    {/* Show any error or success messages */}
                    {message && <div id="payment-message">{message}</div>}
                </form>
            </div>

        );
    }
}
