import {Accordion, Grid} from "@mantine/core";
import ReservationCardComponentAdmin from "./reservation/ReservationCardComponentAdmin";
import React, {useEffect, useState} from "react";
import {ReservationResponseType} from "../types/ReservationResponseType";
import ReservationCardComponentClient from "./reservation/ReservationCardComponentClient";
import {calculatePosti} from "../utils/Utility";

export default function AccordionReservation(props: {
    isAdmin: boolean,
    dataReservation: Map<string, ReservationResponseType[]>,
    reload?: () => void
}) {
    const [defaluttOpen, setDefaluttOpen] = useState<string | null>(null);
    const [totali, setTotali] = useState({posti: 0, prenotazioni: 0});
    useEffect(() => {
        setDefaluttOpen(Array.from(props.dataReservation.keys()).sort()[0])
    }, [props.dataReservation]);


    return (
        <div className={'w-full'}>
            {props.dataReservation && defaluttOpen ?
                <Accordion defaultValue={Array.from(props.dataReservation.keys()).sort()[0]} className={'w-full'}>
                    {Array.from(props.dataReservation.keys()).sort().map((date) => (

                        <Accordion.Item key={date} value={date}>
                            <Accordion.Control>
                                <div className={'w-full flex flex-col'}>
                                    <h1 className={'font-poppins-semi-bold'}>{date}</h1>
                                    {props.isAdmin ? <div>
                                        <h1 className={'font-poppins-semi-bold'}>{`prenotazioni  totali: ${props.dataReservation.get(date)!.length}`}</h1>
                                        <h1 className={'font-poppins-semi-bold'}>{`posti totali: ${calculatePosti(props.dataReservation.get(date)!)}`}</h1>
                                    </div> : null}
                                </div>
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Grid>{
                                    props.dataReservation && props.dataReservation.get(date) !== undefined ? props.dataReservation.get(date)!.map((reservation: any) => (

                                        <Grid.Col key={reservation.id} span={{
                                            base: 12,
                                            md: props.isAdmin ? 12 : 6,
                                            lg: props.isAdmin ? 12 : 4,
                                            xl: props.isAdmin ? 12 : 3
                                        }}>
                                            {props.isAdmin ?
                                                <ReservationCardComponentAdmin reservation={reservation} client={false}
                                                                               onCallFinish={() => {
                                                                                   if (props.reload) {
                                                                                       props.reload()
                                                                                   }
                                                                               }}/> :
                                                <ReservationCardComponentClient reload={() => {
                                                    if (props.reload) {
                                                        props.reload()
                                                    }
                                                }} reservation={reservation}/>}
                                        </Grid.Col>
                                    )) : null
                                }
                                </Grid>
                            </Accordion.Panel>
                        </Accordion.Item>))}

                </Accordion> : null}
        </div>
    )
}
