import {Navigate, useRoutes} from 'react-router-dom';
import LoginPage from "../pages/LoginPage";
import HomePage from "../pages/HomePage";
import RegistrationPage from "../pages/RegistrationPage";
import RootPageAdmin from "../pages/admin/RootPageAdmin";
import CashDeskPage from "../pages/CashDeskPage";
import ResultPaymentPage from "../pages/admin/ResultPaymentPage";
import PaymentPage from "../components/PaymentPage";
import React from "react";
import RootLocalPage from "../pages/local registration/RootLocalPage";
import ResultShopPaymentPage from "../pages/admin/ResultShopPaymentPage";


export default function RouteLogin() {

    return useRoutes([
        {
            path: '/admin/*',
            element: <RootPageAdmin/>,
            children: []
        },{
            path: '/restaurant/*',
            element: <RootLocalPage/>,
            children: []
        },
        {
            path: '/login',
            element: <LoginPage/>,
            children: []
        }, {
            path: '/*',
            element: <HomePage/>,
            children: []
        },
        {
            path: '/registration',
            element: <RegistrationPage/>,
            children: []
        },
        {
            path: '/cassa/*',
            element: <CashDeskPage/>,
            children: []
        },
        {
            path: '/result',
            element: <ResultPaymentPage/>,
            children: []
        },
        {
            path: '/resultShop',
            element: <ResultShopPaymentPage/>,
            children: []
        },
        {
            path: '/payment',
            element: <PaymentPage/>
        },
        {
            path: '*', element: <Navigate to='/'/>
        }
    ])
}
