import React, {useEffect, useState} from "react";
import {AddRestaurantRequest, RestaurantType} from "../../../types/AddRestaurantRequest";
import {StatusEnum} from "../../../enums/StatusEnum";
import GoogleAddressSearch from "../../../components/GoogleAddressSearch";
import {getTypeLicensesList} from "../../../network/controllers/seller/GenericSellerController";
import CustomTextInput from "../../../components/generic/CustomTextInput";
import DrawerCropComponent from "../../../components/generic/cropper/DrawerCropComponent";
import restaurantPlaceholder from '../../../assets/placeolder-ristorante.svg'
import {Textarea} from "@mantine/core";
import {isMobile} from "react-device-detect";

export default function RestaurantDataForm(props: {
    data: AddRestaurantRequest,
    allowNext: (allowed: boolean, data: RestaurantType | null) => void
}) {
    const [localData, setLocalData] = useState<RestaurantType>();
    const [errorVisible, setErrorVisible] = useState(false);
    const [restaurantList, setRestaurantList] = useState<{ value: string, label: string }[]>([]);
    const [typeLicensesList, setTypeLicensesList] = useState<{ value: string, label: string }[]>([])
    const handleUpdate = (field: string, value: string | string[] | any | Date) => {
        if (localData) {
            if (field === 'status') {
                if (typeof value === "string") {
                    setLocalData({
                        ...localData,
                        [field]: StatusEnum.retrieveFromString(value)
                    })
                }
            } else {
                setLocalData({
                    ...localData,
                    [field]: value
                })
            }
        }

    }




    useEffect(() => {
        getTypeLicensesList((list) => {
            var tmpList: { value: string, label: string }[] = []
            list.forEach((item) => {
                tmpList.push({value: item.id, label: item.name})
            })
            setTypeLicensesList(tmpList)
        })
    }, []);
    useEffect(() => {
        if (localData && localData.description && localData.description !== '' && localData.contactEmail && localData.contactEmail!=='' && localData.foto && localData.legalForm && localData.legalForm!==''&& localData.vatNumber && localData.vatNumber!=='') {
            props.allowNext(true, localData)
        } else {
            props.allowNext(false, null)
        }
    }, [localData]);

    if (isMobile) {
        return (
            <div className="w-full h-full flex flex-col items-center justify-center p-4 max-w-screen-xl mx-auto">
                <div className="w-full">
                    <GoogleAddressSearch
                        isBlack={true}
                        filterSuggestions={true}
                        inputDimensions="w-full h-[60px]"
                        suggestionDimensions="h-auto w-full  rounded-2xl"
                        setNameTextInput="name"
                        width="w-full"
                        label="Cerca il tuo Locale"
                        onPlaceSelect={(place) => {
                            var ok = ['lunedì: Chiuso', 'martedì: Chiuso', 'mercoledì: Chiuso', 'giovedì: Chiuso', 'venerdì: Chiuso', 'sabato: Chiuso', 'domenica: Chiuso'];
                            setLocalData({
                                ...localData!,
                                nameRestaurant: place.name,
                                companyName: place.name,
                                webSite: place.website,
                                formattedAddress: place.formatted_address,
                                review: place.reviews,
                                rating: place.rating,
                                priceLevel: place.price_level,
                                place_id: place.place_id,
                                phoneNumber: place.formatted_phone_number,
                                openingHours: place.opening_hours ? place.opening_hours : {weekday_text: ok},
                                geometry: place.geometry,
                                addressComponent: place.address_components
                            });
                            setErrorVisible(!place.opening_hours);
                        }}
                    />
                </div>
                {localData ? (
                    <div className="w-full flex flex-col gap-y-4">
                        <div className="w-full flex flex-col gap-3 my-2 ">
                            <CustomTextInput
                                required={true}
                                isBlack={true}
                                readOnly={false}
                                defaultValue={localData.companyName}
                                label="Nome Azienda"
                                onChange={(value) => handleUpdate('companyName', value)}
                            />
                            <CustomTextInput
                                required={true}
                                isBlack={true}
                                readOnly={false}
                                defaultValue={localData.nameRestaurant}
                                label="Nome Ristorante"
                                onChange={(value) => handleUpdate('nameRestaurant', value)}
                            />
                            <CustomTextInput
                                required={true}
                                isBlack={true}
                                readOnly={localData.hours !== undefined}
                                defaultValue={localData.vatNumber}
                                label="P.IVA"
                                onChange={(value) => handleUpdate('vatNumber', value)}
                            />
                            <CustomTextInput
                                required={true}
                                isBlack={true}
                                readOnly={localData.hours !== undefined}
                                defaultValue={localData.legalForm}
                                label="SDI/PEC"
                                onChange={(value) => handleUpdate('legalForm', value)}
                            />
                            <CustomTextInput
                                isBlack={true}
                                readOnly={true}
                                defaultValue={localData.formattedAddress}
                                label="Indirizzo"
                            />
                            <CustomTextInput
                                required={true}
                                isBlack={true}
                                readOnly={true}
                                defaultValue={localData.phoneNumber ? localData.phoneNumber : 'Aggiorna i tuoi dati su Google per vederli anche su MaiSazio!'}
                                label="Telefono"
                                onChange={(value) => handleUpdate('phoneNumber', value)}
                            />
                            <CustomTextInput
                                required={true}
                                isBlack={true}
                                readOnly={true}
                                defaultValue={localData.webSite ? localData.webSite : 'Aggiorna i tuoi dati su Google per vederli anche su MaiSazio!'}
                                label="Sito Web"
                                onChange={(value) => handleUpdate('webSite', value)}
                            />
                            <CustomTextInput
                                required={true}
                                isBlack={true}
                                readOnly={false}
                                defaultValue={localData.contactEmail}
                                label="Email contatto"
                                onChange={(value) => handleUpdate('contactEmail', value)}
                            />
                        </div>

                        <div
                            className="w-full flex flex-col md:flex-row items-center justify-center gap-3 mt-4 md:mt-6">
                           <div className={'w-auto h-auto flex flex-col justify-center'}>
                               <h1 className={' w-auto h-auto font-poppins-semi-bold text-xs flex text-center mb-2'}>Inserisci qui l'immagine del tuo locale (Obbligatorio)</h1>
                            <DrawerCropComponent
                                onCroppedFinish={(imageSrc) => handleUpdate('foto', imageSrc)}
                                contentHtml={
                                    <div
                                        className="w-full md:w-[350px] h-auto bg-amber-300 p-4 md:p-5 border-[1px] border-black object-scale-down rounded-3xl">
                                        <img alt="" className="h-auto w-full rounded-2xl"
                                             src={localData.foto ? localData.foto : restaurantPlaceholder}/>
                                    </div>
                                }
                                aspect={9 / 4}
                            />
                           </div>
                            <div
                                className="w-full md:w-auto flex items-center bg-[#F2F2F2A8] sm:p-2  p-4 md:p-10 rounded-2xl justify-center">
                                <div className="w-full md:w-auto flex flex-col items-start justify-center text-justify">
                                    {localData.hours !== undefined ? (
                                        Object.entries(localData.hours!).map(([key, value]) => (
                                            <h1 key={key}
                                               className="text-start font-poppins-semi-bold whitespace-nowrap">{value}</h1>
                                        ))
                                    ) : localData.openingHours ? (
                                        localData.openingHours.weekday_text.map((item: any, index: number) => (
                                            <h1 key={index}
                                               className="text-start font-poppins-regular whitespace-nowrap">{item}</h1>
                                        ))
                                    ) : null}
                                </div>
                            </div>
                            <div className="w-full h-full flex items-center justify-center}">
                                <Textarea
                                    required={true}
                                    classNames={{
                                        root: 'w-full h-full p-1',
                                        label: 'h-full font-poppins-semi-bold',
                                        input: 'h-full bg-[#F2F2F2A8] rounded-3xl',
                                        wrapper: 'h-full bg-[#F2F2F2A8] rounded-2xl'
                                    }}
                                    autosize
                                    maxRows={5}
                                    minRows={5}
                                    label="Descrizione"
                                    variant="filled"
                                    placeholder="Inserisci il messaggio che vuoi inviare"
                                    onChange={(value) => handleUpdate('description', value.currentTarget.value)}
                                />
                            </div>
                        </div>
                        {errorVisible && (
                            <h1 className="w-full text-red-500 font-poppins-semi-bold text-center">
                                Non hai orari di apertura/chiusura impostati su Google. Vai su Google e aggiorna i tuoi
                                orari successivamente per aggiornarli!
                            </h1>
                        )}
                    </div>
                ) : null}
            </div>
        );
    } else {
        return (
            <div>
                <div className={'w-full flex flex-col gap-y-10 '}>
                    <div className={'w-full h-auto flex flex-col px-40 '}>
                        <GoogleAddressSearch
                            isBlack={true}
                            filterSuggestions={true}
                            inputDimensions={'w-full h-[60px]'}
                            suggestionDimensions={'h-auto w-full rounded-2xl'}
                            setNameTextInput={'name'} width={'100px'} label={'Cerca il tuo Locale'}
                            onPlaceSelect={(place) => {
                                var ok = ['lunedì: Chiuso', 'martedì: Chiuso', 'mercoledì: Chiuso', 'giovedì: Chiuso', 'venerdì: Chiuso', 'sabato: Chiuso', 'domenica: Chiuso']
                                setLocalData({
                                    ...localData!,
                                    nameRestaurant: place.name,
                                    companyName: place.name,
                                    webSite: place.website,
                                    formattedAddress: place.formatted_address,
                                    review: place.reviews,
                                    rating: place.rating,
                                    priceLevel: place.price_level,
                                    place_id: place.place_id,
                                    phoneNumber: place.formatted_phone_number,
                                    openingHours: place.opening_hours ? place.opening_hours : {weekday_text: ok},
                                    geometry: place.geometry,
                                    addressComponent: place.address_components
                                })
                                setErrorVisible(!place.opening_hours)
                            }}/>

                    </div>
                    {localData ? <div className={'w-full flex flex-col gap-y-10 '}>
                        <div className={'w-full flex gap-x-3 my-2'} key={localData.nameRestaurant}>
                            <CustomTextInput
                                required={true} isBlack={true} readOnly={false} defaultValue={localData.companyName}
                                             label={'Nome Azienda'}
                                             onChange={(value) => {
                                                 handleUpdate('companyName', value)
                                             }}/>
                            <CustomTextInput  required={true} isBlack={true} readOnly={false} defaultValue={localData.nameRestaurant}
                                             label={'Nome Ristorante'}
                                             onChange={(value) => {
                                                 handleUpdate('nameRestaurant', value)
                                             }}/>

                            <CustomTextInput  required={true} isBlack={true} readOnly={localData.hours !== undefined}
                                             defaultValue={localData.vatNumber} label={'P.IVA'}
                                             onChange={(value) => {
                                                 handleUpdate('vatNumber', value)
                                             }}/>

                            <CustomTextInput   required={true} isBlack={true} readOnly={localData.hours !== undefined}
                                             defaultValue={localData.legalForm} label={'SDI/PEC'}
                                             onChange={(value) => {
                                                 handleUpdate('legalForm', value)
                                             }}/>

                        </div>
                        <div className={'w-full flex gap-x-3 my-2'} key={localData.formattedAddress}>
                            <CustomTextInput   required={true} isBlack={true} readOnly={true} defaultValue={localData.formattedAddress}
                                             label={'Indirizzo'}
                                             onChange={(value) => {
                                             }}/>

                            <CustomTextInput   required={true} isBlack={true} readOnly={true}
                                             defaultValue={localData.phoneNumber ? localData.phoneNumber : 'Aggiorna i tuoi dati su Google per vederli anche su MaiSazio!'}
                                             label={'Telefono'}
                                             onChange={(value) => {
                                                 handleUpdate('phoneNumber', value)
                                             }}/>
                            <CustomTextInput   required={true} isBlack={true} readOnly={true}
                                             defaultValue={localData.webSite ? localData.webSite : 'Aggiorna i tuoi dati su Google per vederli anche su MaiSazio!'}
                                             label={'Sito Web'}
                                             onChange={(value) => {
                                                 handleUpdate('webSite', value)
                                             }}/>

                            <CustomTextInput   required={true} isBlack={true} readOnly={false} defaultValue={localData.contactEmail}
                                             label={'Email contatto'}
                                             onChange={(value) => {
                                                 handleUpdate('contactEmail', value)
                                             }}/>

                        </div>
                        <div className={'w-full flex items-center justify-center gap-x-3 mt-10 '}>
                            <div className={'w-auto h-auto flex flex-col'}>
                            <h1 className={'w-auto h-auto font-poppins-semi-bold text-xs flex justify-center mb-3'}>Inserisci qui l'immagine del tuo locale (Obbligatorio)</h1>
                            <DrawerCropComponent onCroppedFinish={(imageSrc) => {
                                handleUpdate('foto', imageSrc)
                            }} contentHtml={<div
                                className={'w-[350px] h-auto bg-amber-300 p-5  border-[1px] border-black  object-scale-down rounded-3xl'}>
                                <img alt={''} className={`h-auto w-full rounded-2xl`}
                                     src={localData.foto ? localData.foto : restaurantPlaceholder}/>
                            </div>} aspect={9 / 4}/>
                            </div>

                            <div
                                className={'w-auto  flex items-center bg-[#F2F2F2A8] p-10 rounded-2xl  justify-center '}>
                                <div className={'w-auto flex flex-col items-start justify-center text-justify'}>
                                    {localData.hours !== undefined ? Array.from(Object.entries(localData.hours!)).map((item: any) => (
                                        <h1 className={'text-start font-poppins-semi-bold whitespace-nowrap'}>{item[1]}</h1>
                                    )) : localData.openingHours ? localData.openingHours.weekday_text.map((item: any) => (
                                        <h1 className={'text-start font-poppins-regular whitespace-nowrap'}>{item}</h1>
                                    )) : null}
                                </div>
                            </div>

                            <div className={'w-full h-full flex  items-center  justify-center p-5'}>
                                <Textarea required={true} classNames={{

                                    root: 'w-full h-full p-1 ',
                                    label: 'h-full font-poppins-semi-bold',
                                    input: 'h-full bg-[#F2F2F2A8] rounded-3xl ',
                                    wrapper: 'h-full bg-[#F2F2F2A8] rounded-2xl '
                                }} autosize maxRows={5} minRows={5} label={'Descrizione'} variant={'filled'}
                                          placeholder={'inserisci il messaggio che vuoi inviare'}  onChange={(value) => {
                                    handleUpdate('description', value.currentTarget.value)
                                }}/>

                            </div>
                        </div>

                        {errorVisible ?
                            <h1 className={'w-full text-red-500 font-poppins-semi-bold text-center'}>Non hai orari di
                                apertura/chiusura impostati su Google. Vai Su google e aggiona i tuoi orari
                                successivamente
                                per aggiornarli!</h1> : null}
                    </div> : null
                    }
                </div>

        </div>
    )
}
}

